import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSingleCompany = state => state.company || initialState;

const getSingleCompany = () =>
  createSelector(
    selectSingleCompany,
    singleCompanySearchState => singleCompanySearchState.company,
  );

const getSingleLoading = () =>
  createSelector(
    selectSingleCompany,
    singleCompanySearchState => singleCompanySearchState.loading,
  );

const getSingleError = () =>
  createSelector(
    selectSingleCompany,
    singleCompanySearchState => singleCompanySearchState.error,
  );

export {
  selectSingleCompany,
  getSingleCompany,
  getSingleLoading,
  getSingleError,
};
