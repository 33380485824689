import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { convertFromRaw, EditorState } from 'draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'assets/scss/components/wysiwyg.scss'; //override build in styles

const ControlledEditor = (props) => {
	// eslint-disable-next-line
	const [contentState, setContentState] = useState("");
	// const [toolbarHidden, setToolbarHidden] = useState(true);

	const { value, helper } = props;

	useEffect(() => {
		if (value === undefined) return;

		const contentState =
			value === '' ? EditorState.createEmpty() : convertFromRaw(value);

		setContentState(contentState);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<React.Fragment>
			<FormControl fullWidth={true}>
				<Editor
					// toolbarHidden={toolbarHidden}
					initialContentState={value}
					wrapperClassName="wysiwyg"
					editorClassName="wysiwyg-editor"
					onEditorStateChange={(state) => {
						setContentState(state);
					}}
				// onFocus={() => setToolbarHidden(false)}
				// onBlur={() => setToolbarHidden(true)}
				/>
				<FormHelperText component="div">{helper}</FormHelperText>
			</FormControl>
		</React.Fragment>
	);
};

export default ControlledEditor;
