import React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as _ from 'lodash';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: '1rem',
        '& .MuiInput-root': {
            color: theme.palette.primary.main,
        },
    },
    label: {
        fontSize: '1.3rem',
        fontWeight: 600,
        color: theme.palette.primary.main,
        top: '-1rem',
    },
    item: {
        backgroundColor: theme.palette.primary.secondary,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: '0.5rem',
        '&:hover': {
            // opacity: 0.8
        },
    },
    menuItemTop: {
        color: theme.palette.textPrimary.main,
    },
    menuItem: {
        color: theme.palette.primary.main,
    },
    menuItemSelected: {
        color: theme.palette.success.main,
    },
}));

const SelectItem = ({ type, values, scenarios, control }) => {
    const classes = useStyles();

    return (
        <FormControl fullWidth className={classes.formControl}>
            <InputLabel id={type} className={classes.label}>
                <FormattedMessage id={`instrument.radar.choice.${type}`} />
            </InputLabel>
            <Controller
                name={type}
                control={control}
                defaultValue={scenarios[type] || 0}
                render={({ value, onChange }) => {
                    return (
                        <Select value={value} onChange={event => onChange(event.target.value)} classes={{ select: classes.item }}>
                            {!value && (
                                <MenuItem key={type} value={0} classes={{ root: classes.menuItemTop }}>
                                    <FormattedMessage id={`instrument.radar.choice.${type}.0`} />
                                </MenuItem>
                            )}
                            {_.map(values, (item, index) => {
                                return (
                                    <MenuItem
                                        key={type + item.value + index}
                                        value={item.value}
                                        classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}
                                    >
                                        <FormattedMessage id={`instrument.radar.choice.${type}.${index + 1}`} />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    );
                }}
            />
        </FormControl>
    );
};

export default SelectItem;
