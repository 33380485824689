import {
  LOAD_WORK_PACKAGE,
  LOAD_WORK_PACKAGES,
  LOAD_WORK_PACKAGE_ERROR,
  LOAD_WORK_PACKAGE_SUCCESS,
  SAVE_WORK_PACKAGE, 
  REMOVE_WORK_PACKAGE,
  REMOVE_WORK_PACKAGE_SUCCESS,
  REMOVE_WORK_PACKAGE_ERROR
} from './constants';

export function loadWorkPackage(businessId, packageId, messages) {
  return {
    type: LOAD_WORK_PACKAGE,
    businessId,
    packageId,
    messages
  };
}

export function loadWorkPackages(businessId, messages) {
  return {
    type: LOAD_WORK_PACKAGES,
    businessId,
    messages,
  };
}

export function loadWorkPackageSuccess(workPackage) {
  return {
    type: LOAD_WORK_PACKAGE_SUCCESS,
    workPackage,
  };
}

export function loadWorkPackageError(error, messages) {
  return {
    type: LOAD_WORK_PACKAGE_ERROR,
    error,
    messages,
  };
}

export function saveCompanyWorkPackage(workPackage, messages) {
  return {
    type: SAVE_WORK_PACKAGE,
    workPackage,
    messages,
  };
}

export function removeWorkPackage(workPackage, messages) { 
  return {
    type: REMOVE_WORK_PACKAGE,
    workPackage,
    messages,
  };
}

export function removeWorkPackageSuccess(workPackage, messages) { 
  return {
    type: REMOVE_WORK_PACKAGE_SUCCESS,
    workPackage,
    messages,
  };
}

export function removeWorkPackageError(error) { 
  return {
    type: REMOVE_WORK_PACKAGE_ERROR,
    error,
  };
}