import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';
import FormSpaceHeader from 'components/FormSpaceHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Robot from 'components/Source/Robot';

const ResponsiblesRobot = (props) => {
  const { data, messages } = props;
  const intl = useIntl();

  if (data === undefined || data === null || data.responsibles === null)
    return null;

  const renderTable = (data) => (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" aria-label="Responsibles table">
        <TableHead>
          <TableRow>
            <TableCell>
              {intl.formatMessage(messages.formdata.source)}
            </TableCell>
            <TableCell>{intl.formatMessage(messages.formdata.name)}</TableCell>
            <TableCell>{intl.formatMessage({ id: 'robot.deminimis.items' })}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.deminis.map((row, index) => {
            return (
              <TableRow key={row + index}>
                <TableCell>
                  <Robot />
                </TableCell>
                <TableCell>{row.source}</TableCell>
                <TableCell>
                  {row.gained.map((item, index) => {
                    return (
                      <React.Fragment key={item + index}>
                        {item.name}: {item.amount}<br />
                      </React.Fragment>
                    );
                  })}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <GridContainer>
      <GridItem xs={12}>
        <FormSpaceHeader
          header={intl.formatMessage({ id: 'robot.deminimis.title' })}
          anchor={intl.formatMessage(messages.formdata.description)}
        />
        {renderTable(data)}
      </GridItem>
    </GridContainer>
  );
};

export default ResponsiblesRobot;
