import React from 'react';
import { useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
}));

const FilterInput = ({ value, onChange }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <TextField
            id='search'
            value={value || ''}
            onChange={onChange}
            placeholder={intl.formatMessage({ id: 'instrument.edit.search.placeholder' })}
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            className={classes.root}
        />
    );
};

export default FilterInput;
