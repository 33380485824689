import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    tdColumn: {
        padding: 0,
    },
    tr: {
        backgroundColor: theme.palette.primary.secondary,
    },
}));

// have to have to get the colspan to work correctly
const TColumns = ({ numberOfMonth, columnWidth }) => {
    const classes = useStyles();

    let items = [];
    for (let i = 0; i < numberOfMonth; i++) {
        items.push(
            <td key={i} className={classes.tdColumn} width={columnWidth}>
                {' '}
            </td>
        );
    }
    return <tr className={classes.tr}>{items}</tr>;
};

export default TColumns;
