import React from 'react';
import { useIntl } from 'react-intl';
import CurrencyInput from 'react-currency-input-field';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
    },
    formGroup: {
        padding: 0,
        '&:hover': {
            // '& input': {},
            '&:before, &:after': {
                borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
            },
        },
        '&:before, &:after': {
            left: 0,
            right: 0,
            bottom: 0,
            content: "''",
            position: 'absolute',
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
            pointerEvents: 'none',
        },
    },
    formLabel: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    formInput: {
        fontSize: '1rem',
        border: 'none',
        padding: 0,
        backgroundColor: 'transparent',
        width: '100%',
    },
    prefix: {
        fontSize: '1rem',
        paddingRight: '0.25rem',
    },
    inputContainer: {
        flexWrap: 'nowrap',
        padding: '0.125rem 0',
    },
}));

const CurrencyField = ({
    label = '',
    prefix = '€',
    value = 0,
    basicdata = {},
    disabled = false,
    forbidNegative = false,
    positionEnd = false,
    onChange,
    className,
}) => {
    const classes = useStyles();
    const intl = useIntl();

    const handleFocus = event => event.target.select();

    return (
        <div className={`${classes.root} ${className ? className : ''}`}>
            <div className={classes.formGroup}>
                {label && (
                    <label htmlFor={label} className={classes.formLabel}>
                        {Object.keys(basicdata)?.length > 0 ? intl.formatMessage(basicdata[label]) : intl.formatMessage({ id: label })}
                    </label>
                )}
                <GridContainer alignItems='center' className={classes.inputContainer}>
                    <Typography component="span" className={classes.prefix}>{prefix}</Typography>
                    <GridContainer justify={positionEnd ? 'flex-end' : 'flex-start'}>
                        <CurrencyInput
                            id={label}
                            placeholder='0,00'
                            groupSeparator=' '
                            decimalSeparator=','
                            defaultValue={value}
                            decimalsLimit={2}
                            decimalScale={2}
                            fixedDecimalLength
                            allowNegativeValue={forbidNegative ? false : true}
                            maxLength={15}
                            onValueChange={value => { if (!onChange) return null; return onChange(value); }}
                            onFocus={handleFocus}
                            disabled={disabled}
                            step={1}
                            className={classes.formInput}
                            style={{
                                color: disabled && 'rgba(0, 0, 0, 0.38)',
                                textAlign: positionEnd && 'right',
                                paddingRight: positionEnd && '0.75rem',
                            }}
                        />
                    </GridContainer>
                </GridContainer>
            </div>
        </div>
    );
};

export default CurrencyField;
