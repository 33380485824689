import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_TOKEN, LOGIN_USER } from './constants';
import { loginUserSuccess, loginUserError } from './actions';

import publicApp, { config } from 'containers/Login/config';

const getToken = async () => {
	const accounts = publicApp.getAllAccounts();

	if (accounts.length <= 0) throw new Error('login_required');

	const silentResult = await publicApp.acquireTokenSilent({
		config,
		account: accounts[0],
	});

	return silentResult;
};

export function* setUserToken({ token }) {
	try {
		yield put(loginUserSuccess(token));
	} catch (err) {
		yield put(loginUserError(err));
	}
}

export function* getUserToken() {
	try {
		// const user = yield select(makeSelectCurrentUser());

		// const expires = new Date(user.expiresOn);
		// const now = Date.now();

		// if (expires.valueOf() > now.valueOf()) {
		//   return user.accessToken;
		// }

		const tok = yield call(getToken);
		yield put(loginUserSuccess(tok));

		return tok.accessToken;
	} catch (err) {
		yield put(loginUserError(err));
	}
}

export default function* loginData() {
	yield takeLatest(LOGIN_USER, setUserToken);
	yield takeLatest(GET_TOKEN, getUserToken);
}


