const accordion = {
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
};
const accordionSummary = {
  root: {
    backgroundColor: '#482E51',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
};
const accordionTitle = {
  titlePrefix: {
    color: '#E0D6C4',
    fontSize: '1rem',
    paddingTop: '0.1rem',
    fontVariantCaps: 'all-petite-caps',
    flexBasis: '15%',
    flexShrink: 0,
  },
  titleName: {
    color: '#fff',
    fontSize: '1.2rem',
    paddingLeft: '1rem',
    fontVariantCaps: 'all-petite-caps',
    flexBasis: '65%',
    flexShrink: 0,
  },
  rightContent: {
    color: '#fff',
    fontSize: '1.2rem',
    paddingLeft: '1rem',
    fontVariantCaps: 'all-petite-caps',
    textAlign: 'right',
    flexBasis: '20%',
    flexShrink: 0,
  },
};

export { accordion, accordionSummary, accordionTitle };
