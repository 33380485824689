import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import history from 'utils/history';
import 'assets/scss/style-override.scss';
import Toastr from 'components/Toast';
// pages for this product
import App from 'containers/App';
import configureStore from './configureStore';
import { IntlProviderWrapper } from './IntlWrapper';

const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <IntlProviderWrapper>
        <App />
        <Toastr />
      </IntlProviderWrapper>
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE
);
