import React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(5),
    },
    label: {
        left: 'auto',
        transform: 'none',
        fontWeight: 600,
        color: theme.palette.primary.main,
        marginBottom: '1rem',
        top: '-1rem',
    },
    slider: {
        '& .MuiSlider-markLabel:nth-child(5)': {
            transform: 'translateX(0%)',
        },
        '& .MuiSlider-markLabel:not(:nth-child(5), :nth-last-child(2))': {
            display: 'none',
        },
        '& span.MuiSlider-markLabel:nth-last-child(2)': {
            transform: 'translateX(-100%)',
        },
    },
    valueLabel: {
        position: 'absolute',
        top: '1.2rem',
        width: '100%',
        fontWeight: 400,
        color: theme.palette.primary.main,
    },
}));

const StyledSlider = withStyles({
    root: {
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#482E51',
        boxShadow: 'inherit',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {}, // empty object is required
    track: {
        height: 8,
    },
    rail: {
        height: 8,
        opacity: 0.5,
        backgroundColor: '#bab3a8',
    },
    mark: {
        height: 12,
        width: 1,
        marginTop: -2,
        opacity: 0.5,
        backgroundColor: '#bab3a8',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

const SliderItem = ({ type, values, scenarios, control }) => {
    const classes = useStyles();
    const intl = useIntl();

    const marks = values.map(({ value }, index) => ({ value: index, originalValue: value }));

    const getValueByOriginalValue = (originalValue, marks) =>
        marks.reduce((acc, item) => {
            return item.originalValue === originalValue ? item.value : acc;
        }, 0);

    const getOriginalValueByValue = (value, marks) =>
        marks.reduce((acc, item) => {
            return item.value === value ? item.originalValue : acc;
        }, 0);

    return (
        <FormControl fullWidth className={classes.formControl}>
            <InputLabel id={type} className={classes.label}>
                <FormattedMessage id={`instrument.radar.choice.${type}`} />
            </InputLabel>
            <Controller
                name={type}
                control={control}
                defaultValue={scenarios[type] || 1}
                render={({ value, onChange }) => {
                    const sliderValue = getValueByOriginalValue(value, marks);

                    return (
                        <>
                            <Box textAlign='center' className={classes.valueLabel}>
                                {intl.formatMessage({ id: `instrument.radar.choice.${type}.${sliderValue + 1}` })}
                            </Box>
                            <StyledSlider
                                aria-labelledby={type}
                                value={sliderValue}
                                marks={marks}
                                step={null}
                                min={marks[0].value}
                                max={marks[marks.length - 1].value}
                                onChange={(event, value) => onChange(getOriginalValueByValue(value, marks))}
                                className={classes.slider}
                            />
                        </>
                    );
                }}
            />
        </FormControl>
    );
};

export default SliderItem;
