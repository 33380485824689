import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    loading: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

const Loader = () => {
    const classes = useStyles();

    return (
        <Box className={classes.loading}>
            <CircularProgress size={48} />
        </Box>
    );
};

export default Loader;