import { put, takeLatest, select } from 'redux-saga/effects';

import { SAVE_BUSINESS_PLAN, LOAD_BUSINESS_PLAN } from './constants';
import {
  loadBusinessPlanDataError,
  singleBusinessPlanDataLoaded,
} from './actions';
import { getSingleCompany } from 'containers/CompanyBaseData/selectors';
import config from 'utils/config';
import axios from 'utils/axios';

export function* getSingleBusinessPlan({ businessId }) {
  const requestURL = `${config.FETCH_COMPANY_API_URI}/businessplan/${businessId}`;

  try {
    const response = yield axios.get(requestURL);

    yield put(singleBusinessPlanDataLoaded(response.data));
  } catch (err) {
    yield put(loadBusinessPlanDataError(err));
  }
}

export function* saveBusinessPlanData({ businessPlan }) {
  const company = yield select(getSingleCompany());

  const requestURL = `${config.FETCH_COMPANY_API_URI}/businessplan/${company.businessId}`;

  try {
    const response = yield axios.post(requestURL, businessPlan);

    yield put(singleBusinessPlanDataLoaded(response.data));
  } catch (err) {
    yield put(loadBusinessPlanDataError(err));
  }
}

export default function* businessPlanData() {
  yield takeLatest(LOAD_BUSINESS_PLAN, getSingleBusinessPlan);
  yield takeLatest(SAVE_BUSINESS_PLAN, saveBusinessPlanData);
}
