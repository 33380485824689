import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Dashboard from '@material-ui/icons/Dashboard';
import Schedule from '@material-ui/icons/TableChart';
import Domain from '@material-ui/icons/Domain';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Collapse from '@material-ui/core/Collapse';
import Logo from 'components/Logo/Logo';
import ViewListIcon from '@material-ui/icons/ViewList';
import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function MakeshiftDrawer(props) {
	const classes = useStyles();
	const {
		match: {
			params: { businessId },
		},
		location: { pathname },
	} = props;

	const [openCollapsed, setOpen] = React.useState(true);

	const handleClick = () => {
		setOpen(!openCollapsed);
	};

	const selected = pathname.split('/').filter(Boolean).pop();

	return (
		<div className={classes.root}>
			<List style={{ position: 'fixed' }} component="nav" aria-label="main mailbox folders">
				<ListItem onClick={handleClick}>
					<ListItemIcon>
						<Logo />
					</ListItemIcon>
					{/* {openCollapsed ? <ExpandLess /> : <ExpandMore />} */}
				</ListItem>
				<Collapse in={openCollapsed} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<Link to={`/company/${businessId}/grantedai`}>
							<ListItem button selected={selected === 'grantedai'} className={classes.nested}>
								<ListItemIcon>
									<AccountCircleIcon />
								</ListItemIcon>
								<ListItemText primary="Käyttäjähallinta" />
							</ListItem>
						</Link>
						<Link to={{
							pathname: `/company/${businessId}/radar`,
							hash: '#scenario',
							state: { fromDashboard: true }
						}} >
							<ListItem button /* selected={selected === 'radar'} */ className={classes.nested}>
								<ListItemIcon>
									<TrackChangesIcon />
								</ListItemIcon>
								<ListItemText primary="Skenaariot" />
							</ListItem>
						</Link>
						<Link to={{
							pathname: `/company/${businessId}/radar`,
							hash: '#plan',
							state: { fromDashboard: true }
						}}>
							<ListItem button /* selected={selected === 'radar'} */ className={classes.nested}>
								<ListItemIcon>
									<AssignmentIcon />
								</ListItemIcon>
								<ListItemText primary="Rahoitussuunnitelma" />
							</ListItem>
						</Link>
						<Link to={{
							pathname: `/company/${businessId}/radar`,
							hash: '#instruments',
							state: { fromDashboard: true }
						}}>
							<ListItem button /* selected={selected === 'radar'} */ className={classes.nested}>
								<ListItemIcon>
									<ViewListIcon />
								</ListItemIcon>
								<ListItemText primary="Instrumentit" />
							</ListItem>
						</Link>
					</List>
				</Collapse>
				<Link to={`/company/${businessId}`}>
					<ListItem button selected={selected === businessId}>
						<ListItemIcon>
							<Dashboard />
						</ListItemIcon>
						<ListItemText primary="Tarvekartoitus" />
					</ListItem>
				</Link>
				<Link to={`/company/${businessId}/story`}>
					<ListItem button selected={selected === 'story'}>
						<ListItemIcon>
							<Domain />
						</ListItemIcon>
						<ListItemText primary="LTS" />
					</ListItem>
				</Link>
				<Link to={`/company/${businessId}/finance`}>
					<ListItem button selected={selected === 'finance'}>
						<ListItemIcon>
							<Schedule />
						</ListItemIcon>
						<ListItemText primary="Taloustiedot" />
					</ListItem>
				</Link>
			</List>
		</div>
	);
}
