import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

import { getEuroFormatted } from 'utils/getEuroFormatted';

const useStyles = makeStyles(theme => ({
    // dialog: {
    //     '& .MuiBackdrop-root': {
    //         backgroundColor: 'rgba(0, 0, 0, 0)',
    //     },
    //     '& .MuiPaper-elevation24': {
    //         boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    //     },
    // },
    dialogTitle: {
        padding: 0,
        paddingLeft: theme.spacing(3),
        backgroundColor: theme.palette.primary.white,
    },
    dialogContent: {
        backgroundColor: theme.palette.primary.white,
    },
    dialogActions: {
        padding: theme.spacing(1),
        paddingRight: theme.spacing(2),
        backgroundColor: theme.palette.primary.white,
    },
    dialogTitleText: {
        fontSize: '1.2rem',
        fontWeight: 600,
        marginRight: theme.spacing(1),
    },
    dialogTitleDate: {
        fontWeight: 600,
        paddingTop: theme.spacing(0.5),
    },
    closeIcon: {
        color: theme.palette.primary.main,
    },
    cell: {
        padding: '6px 16px 6px 0',
        borderBottom: 'none',
    },
    tooltipKey: {
        fontSize: '0.875rem',
        fontWeight: 500,
    },
    tooltipProp: {
        fontSize: '0.875rem',
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const TooltipDialog = ({ open, close, name, startTime, endTime, provider, isUsed, grantAmount, projectSize, description }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Dialog
            aria-labelledby='timeline dialog'
            aria-describedby='timeline dialog'
            fullWidth
            maxWidth='sm'
            open={open}
            onClose={close}
            scroll='paper'
            TransitionComponent={Transition}
            className={classes.dialog}
        >
            <DialogTitle id='scroll-dialog-title' /*className={classes.dialogTitle} */>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <Typography variant='h2' color='primary' className={classes.dialogTitleText}>
                                    {name}
                                </Typography>
                            </div>
                            <div>
                                <Typography component='p' color='primary' className={classes.dialogTitleDate}>
                                    ({moment(startTime).format('MM.YYYY')}-{moment(endTime).format('MM.YYYY')})
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div>
                        <IconButton aria-label='close' color='primary' onClick={close} className={classes.closeButton}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <TableContainer>
                    <Table aria-label='timeline' size='small'>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    <Typography component='p' color='primary' className={classes.tooltipKey}>
                                        {intl.formatMessage({ id: 'timeline.tooltip.schedule' })}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <Typography component='p' color='primary' className={classes.tooltipProp}>
                                        {moment(startTime).format('DD.MM.YYYY')}-{moment(endTime).format('DD.MM.YYYY')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    <Typography component='p' color='primary' className={classes.tooltipKey}>
                                        {intl.formatMessage({ id: 'timeline.tooltip.provider' })}:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <Typography component='p' color='primary' className={classes.tooltipProp}>
                                        {provider}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    <Typography component='p' color='primary' className={classes.tooltipKey}>
                                        {intl.formatMessage({ id: 'timeline.tooltip.isUsed' })}:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {isUsed ? (
                                        <Typography component='p' color='primary' className={classes.tooltipProp}>
                                            {intl.formatMessage({ id: 'common.yes' })}
                                        </Typography>
                                    ) : (
                                        <Typography component='p' color='primary' className={classes.tooltipProp}>
                                            {intl.formatMessage({ id: 'common.no' })}
                                        </Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    <Typography component='p' color='primary' className={classes.tooltipKey}>
                                        {intl.formatMessage({ id: 'timeline.tooltip.grantAmount' })}:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <Typography component='p' color='primary' className={classes.tooltipProp}>
                                        {getEuroFormatted(grantAmount)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>
                                    <Typography component='p' color='primary' className={classes.tooltipKey}>
                                        {intl.formatMessage({ id: 'timeline.tooltip.projectSize' })}:
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <Typography component='p' color='primary' className={classes.tooltipProp}>
                                        {getEuroFormatted(projectSize)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography component='div'>{ReactHtmlParser(description)}</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button variant='outlined' size='small' color='primary' onClick={close}>
                    {intl.formatMessage({ id: 'common.close' })}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TooltipDialog;
