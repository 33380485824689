import { createMuiTheme } from '@material-ui/core/styles';
import * as colors from 'assets/jss/grants/common/colors';
import {
    primaryColor,
    warningColor,
    successColor,
    whiteColor,
    greyColor,
    lightGreyColor,
    lightGreyColor2,
    lightGreyColor3,
    lightPurpleColor,
    darkYellowColor,
    beigeColor,
    blueColor,
    darkBrown,
} from './common/colors';

const theme = createMuiTheme({
    breakpoints: {
        keys: ['xs', 'sm', 'smd', 'md', 'lg', 'xl'],
        values: {
            xs: 0,
            sm: 600,
            smd: 750,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        colorTextSecondary: {
            color: 'rgba(0, 0, 0, 0.88)',
        },
        fontFamily: 'Poppins, sans-serif',
        h1: {
            //fontFamily: 'Aleo, serif',
        },
        h2: {
            //fontFamily: 'Aleo, serif',
        },
        h3: {
            //fontFamily: 'Aleo, serif',
        },
    },
    palette: {
        primary: {
            main: primaryColor,
            secondary: lightGreyColor,
            white: whiteColor,
            darkYellow: darkYellowColor,
            beige: beigeColor,
            grey: greyColor,
            darkBrown: darkBrown,
        },
        secondary: {
            main: lightGreyColor2,
            light: lightGreyColor3,
        },
        error: {
            main: warningColor,
        },
        warning: {
            main: warningColor,
        },
        success: {
            main: successColor,
        },
        info: {
            main: blueColor,
        },
        textPrimary: {
            main: greyColor,
            secondary: lightPurpleColor,
            white: whiteColor,
            beige: beigeColor,
        },
        background: {
            default: whiteColor,
        },
    },
    overrides: {
        MuiButton: {
            // borderPadding: '6px',
        },
        MuiGridItem: {
            padding: 0,
        },
        MuiFormControl: {
            // padding: 0,
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: colors.primaryColor,
                },
            },
        },
        MuiFilledInput: {
            root: {
                borderRadius: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: lightGreyColor3,
                // '&:hover': {
                //   backgroundColor: 'yellow'
                // },
                // "&$focused": {
                //   backgroundColor: "blue !important",
                //   border: "2px dotted blue"
                // }
            },
        },
        MuiInputLabel: {
            shrink: {
                fontSize: '1rem',
            },
        },
        MuiInput: {
            root: {},
            underline: {
                //borderBottom: "1px solid " + colors.primaryColor,
                // '&$before': {
                // 	//borderBottom: "1px solid " + colors.primaryColor,
                // },
                '&$focused': {
                    borderBottom: '1px solid ' + colors.primaryColor,
                },
                '&:after': {
                    borderBottom: '1px solid ' + colors.primaryColor,
                },
                '&:focus::after': {
                    borderBottom: '1px solid ' + colors.primaryColor,
                },
                '&:focus::before': {
                    borderBottom: '1px solid ' + colors.primaryColor,
                },
                '&:hover::before': {
                    backgroundColor: colors.primaryColor,
                    height: 1,
                    borderBottom: '1px solid ' + colors.primaryColor,
                },
                '&:hover::after': {
                    backgroundColor: colors.primaryColor,
                    height: 1,
                    borderBottom: '1px solid ' + colors.primaryColor,
                    color: 'red',
                },
            },
        },
        MuiTypography: {
            body1: {
                fontSize: '0.875rem',
            },
            // subheading: {
            // 	fontSize: "0.875rem",
            // },
        },
        MuiDrawer: {
            paperAnchorRight: {
                backgroundColor: lightGreyColor,
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: lightGreyColor,
            },
        },
        MuiStepper: {
            root: {
                paddingTop: 0,
                paddingLeft: 0,
            },
        },
        MuiStepIcon: {
            text: {
                fill: 'none',
            },
        },
        MuiStepContent: {
            root: {
                marginLeft: '0.45rem',
            },
        },
        MuiStepConnector: {
            vertical: {
                marginLeft: '1.95rem',
            },
        },
        MuiBadge: {
            badge: {
                fontWeight: 900,
            },
            colorSecondary: {
                backgroundColor: successColor,
                color: whiteColor,
            },
        },
        MuiInputBase: {
            input: {
                // backgroundColor: beigeColor,
            },
        },
        MuiOutlinedInput: {
            adornedStart: {
                backgroundColor: beigeColor,
            },
        },
        MuiAccordion: {
            root: {
                '&$expanded': {
                    margin: '0.5rem 0',
                    '&:first-child': {
                        margin: '0.5rem 0',
                        marginTop: '0.5rem',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                '&$expanded': {
                    minHeight: '3rem',
                },
            },
            content: {
                '&$expanded': {
                    margin: '0.75rem 0',
                },
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: '#482E51',
                color: '#ffffff',
            },
            dayLabel: {
                color: '#482E51',
            },
            iconButton: {
                backgroundColor: '#482E51',
                color: '#ffffff',
            },
        },
        MuiPickersDay: {
            day: {
                color: '#482E51',
            },
            daySelected: {
                backgroundColor: '#482E51',
                '&:hover': {
                    backgroundColor: '#482E51',
                    opacity: 0.9,
                },
            },
            dayDisabled: {
                color: '#ccc',
            },
            current: {
                color: '#482E51',
            },
        },
    },
});

export default theme;
