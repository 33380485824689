import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.secondary.line,
        padding: theme.spacing(4),
        paddingLeft: theme.spacing(6),
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    contactPerson: {
        fontSize: '0.875rem',
        fontWeight: 700,
        marginBottom: theme.spacing(1.5),
    },
    expert: {
        fontSize: '0.875rem',
        fontWeight: 700,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
        fontSize: '0.875rem',
        textTransform: 'none',
        padding: 0,
        color: theme.palette.primary.main,
    },
    loading: {
        color: theme.palette.success.main,
    },
    icon: {
        fill: theme.palette.primary.main,
    },
}));

const SidebarFooter = ({ openChat }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Box className={classes.root}>
            <GridContainer spacing={2} alignItems='center'>
                <GridItem>
                    <Typography component='p' className={classes.contactPerson}>
                        {intl.formatMessage({ id: 'common.take.contact' }).toUpperCase()}
                    </Typography>
                    <Typography component='p' className={classes.expert}>
                        {intl.formatMessage({ id: 'financial.plan.financial.expert' })}
                    </Typography>
                    <Button
                        className={classes.link}
                        onClick={() =>
                            openChat({
                                title: false,
                                text: null,
                            })
                        }
                    >
                        {intl.formatMessage({ id: 'chat.icon.link' })}
                    </Button>
                </GridItem>
            </GridContainer>
        </Box>
    );
};

export default SidebarFooter;
