import React from 'react';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { GrantsInput } from 'components/GrantsComponents/Input';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import Tasks from './Tasks';
import { workpackage } from '../messages';

const WorkPackage = (props) => {
  const { control, index, item } = props;
  const intl = useIntl();

  const renderFields = () => (
    <GridContainer>
      <GridItem noPaddingSide xs={12}>
        <Controller
          name={`workPackage[${index}].name`}
          control={control}
          defaultValue={item.name ?? ''}
          render={(props) => (
            <GrantsInput label={intl.formatMessage(workpackage.name)} {...props} />
          )}
        />
      </GridItem>
      <GridItem noPaddingSide xs={12}>
        <Controller
          name={`workPackage[${index}].description`}
          control={control}
          defaultValue={item.description ?? ''}
          render={(props) => (
            <GrantsInput label={intl.formatMessage(workpackage.description)} multiline {...props} />
          )}
        />
      </GridItem>
      <GridItem noPaddingSide xs={12}>
        <Controller
          name={`workPackage[${index}].actions`}
          control={control}
          defaultValue={item.actions ?? ''}
          render={(props) => (
            <GrantsInput label={intl.formatMessage(workpackage.actions)} multiline {...props} />
          )}
        />
      </GridItem>
    </GridContainer>
  );

  const renderPackage = () => (
    <React.Fragment>
      <GridItem noPaddingSide xs={12}>{renderFields()}</GridItem>
      <GridItem noPaddingSide xs={12}>
        <Tasks item={item} control={control} index={index} />
      </GridItem>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {renderPackage()}
    </React.Fragment>
  );
};

export default WorkPackage;
