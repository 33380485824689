import React from 'react';
import { useFieldArray, Controller } from 'react-hook-form';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AddButton from 'components/GrantsComponents/AddButton';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Input';
import RemoveLineButton from 'components/GrantsComponents/RemoveLineButton';

import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '0px',
  },
}));

const EventList = (props) => {
  const { events, control, setValue } = props;

  const classes = useStyles();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'events',
  });

  const renderTable = () => {
    return (
      <TableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="Auxiliary names table">
          <TableHead>
            <TableRow>
              <TableCell>[Aika]</TableCell>
              <TableCell>[Tyyppi]</TableCell>
              <TableCell>[Kuvaus]</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => {
              item.time = new Date(item.time);
              item.removed = false;

              return (
                <React.Fragment key={item + index}>
                  <TableRow>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TableCell style={{ verticalAlign: 'top' }}>
                        <Controller
                          control={control}
                          name={`events[${index}].time`}
                          render={(rest) => (
                            <DateTimePicker
                              {...rest}
                              className={classes.root}
                              ampm={false}
                              disableToolbar
                              variant="inline"
                              margin="normal"
                              format="dd.MM.yyyy HH:MM"
                              inputVariant={rest.variant}
                            />
                          )}
                        />
                        <Controller
                          name={`events[${index}].rowKey`}
                          control={control}
                          defaultValue={item.rowKey}
                          render={() => (
                            <input type="hidden" value={item.rowKey} />
                          )}
                        />
                        <Controller
                          name={`events[${index}].createdBy`}
                          control={control}
                          defaultValue={item.createdBy}
                          render={() => (
                            <input type="hidden" value={item.createdBy} />
                          )}
                        />
                        <Controller
                          name={`events[${index}].customerId`}
                          control={control}
                          defaultValue={item.customerId}
                          render={() => (
                            <input type="hidden" value={item.customerId} />
                          )}
                        />
                      </TableCell>
                    </MuiPickersUtilsProvider>
                    <TableCell style={{ verticalAlign: 'top' }}>
                      <Controller
                        name={`events[${index}].type`}
                        control={control}
                        defaultValue={item.type}
                        as={
                          <Select>
                            <MenuItem>Valitse tyyppi</MenuItem>
                            <MenuItem value="firsMeeting">
                              Ensimmäinen tapaaminen
                            </MenuItem>
                            <MenuItem value="meeting">Tapaaminen</MenuItem>
                          </Select>
                        }
                      />
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                      <Controller
                        name={`events[${index}].notes`}
                        control={control}
                        defaultValue={item.notes}
                        as={
                          <TextField
                            placeholder="[placeholder]"
                            multiline
                            fullWidth
                            rows={4}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="filled"
                          />
                        }
                      />
                    </TableCell>
                    <TableCell style={{ verticalAlign: 'top' }}>
                      {item.rowKey !== undefined ? (
                        <React.Fragment>
                          <Controller
                            as={<Hidden style={{ display: 'none' }} />}
                            name={`events[${index}].removed`}
                            control={control}
                            defaultValue={item.removed}
                          />

                          <Checkbox
                            onChange={(e) => {
                              fields[index].removed = e.target.checked;
                              item.removed = e.target.checked;
                              setValue(
                                `events[${index}].removed`,
                                e.target.checked,
                                {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                }
                              );
                            }}
                            icon={<DeleteForeverOutlined />}
                            checkedIcon={<DeleteIcon />}
                          />
                        </React.Fragment>
                      ) : (
                        <RemoveLineButton onClick={() => remove(index)} />
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12}>{renderTable(events)}</GridItem>

      <GridItem xs={12}>
        <AddButton
          onClick={() => append({ name: 'append' })}
          text="[Add event]"
        />
      </GridItem>
    </GridContainer>
  );
};

export default EventList;
