import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as styles from 'assets/jss/grants/common/container';

const useStyles = makeStyles(styles);

export default function GridContainer(props) {
  const { children, className, noPadding, contentContainer, ...rest } = props;

  const classes = useStyles();
  let containerClasses = classNames({
    [classes.container]: true,
    [classes.contentContainer]: contentContainer,
    [classes.noPadding]: noPadding,
  });

  if (className) {
    const importedClasses = Array.isArray(className) ? className.join(' ') : className;

    containerClasses = `${containerClasses} ${importedClasses}`;
  }

  return (
    <Grid container {...rest} className={containerClasses}>
      {children}
    </Grid>
  );
}

GridContainer.defaultProps = {
  className: ''
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
