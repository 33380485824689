import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    td: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: 0,
        textAlign: 'center',
    },
    tdFooter: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
    },
    quarter: {
        fontSize: '0.75rem',
        fontWeight: 300,
        color: 'rgba(72, 46, 81, 0.6)',
    },
}));

const TFooterQuarter = ({ quarters }) => {
    const classes = useStyles();

    let count = 0;

    const items = quarters.map((item, index, items) => {
        count++;
        if (count === 5) {
            count = 1;
        }

        return (
            <td
                key={item}
                colSpan={3}
                className={`${classes.td} ${classes.tdFooter}`}
                style={{ position: items.length - 1 === index && 'relative', overflow: items.length - 1 === index && 'visible' }}
            >
                {items.length - 1 === index && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: '-2px',
                            bottom: 0,
                            left: 0,
                        }}
                    />
                )}
                <Typography
                    variant='subtitle2'
                    className={classes.quarter}
                    style={{ position: items.length - 1 === index && 'relative', zIndex: items.length - 1 === index && 10 }}
                >
                    Q{count}
                </Typography>
            </td>
        );
    });

    return <tr>{items}</tr>;
};

export default TFooterQuarter;
