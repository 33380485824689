import React, { memo, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import GridContainer from 'components/Grid/GridContainer';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import { getRadar, getRadarLoading, getRadarError, selectInstruments, selectSenarios } from './selectors';
import { setCompanyLimit, loadScenarios, loadInstrumentData, saveScenario, removeScenario, saveMainScenario } from './actions';
import saga from './saga';
import reducer from './reducer';
import ContentLoader from 'components/LoadingIndicator/ContentLoader';

import RadarForm from './Form';
import Instruments from 'containers/Instruments';
import Timeline from 'containers/Timeline';

import { openInstrumentModal } from 'containers/Timeline/actions';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';

const key = 'radar';

const useStyles = makeStyles(theme => ({
    accordionSummary: {
        fontSize: '1.2rem',
        color: '#ffffff',
        fontWeight: '600',
        width: '100%',
        boxShadow: 'none',
        '& p': {
            fontSize: '1.2rem',
            color: '#ffffff',
            fontWeight: '600',
            width: '100%',
        },
    },
    accordion: {
        margin: '0.5rem 0',
        borderRadius: '4px',
        boxShadow: 'none',
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid',
        borderWidth: '2px',
        borderColor: theme.palette.primary.main,
    },
    accordionDetails: {
        backgroundColor: '#fff',
    },
}));

const CompanyRadar = props => {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const scenariosRef = useRef(null);
    const planRef = useRef(null);
    const instrumentsRef = useRef(null);

    const dispatch = useDispatch();
    const classes = useStyles();
    const intl = useIntl();

    const {
        loading,
        onSubmit,
        loadScenarios,
        setCompareScenario,
        instruments,
        match: {
            params: { businessId },
        },
        scenario,
        onSubmitRadar,
        onRemoveScenario,
        onSaveAsPrimary,
    } = props;

    const getElementPos = hash => {
        if (hash === '#plan') return planRef.current?.getBoundingClientRect().top;

        if (hash === '#instruments') return instrumentsRef.current?.getBoundingClientRect().top;

        if (hash === '#scenario') return scenariosRef.current?.getBoundingClientRect().top;

        return 0;
    };

    const scrollTo = hash => {
        if (!hash || hash.length === 0) return;

        const headerOffset = 90;
        const elementPosition = getElementPos(hash);
        const offsetPosition = elementPosition - headerOffset;

        window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        scrollTo(props.location.hash);
    }, [props.location]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadScenarios(businessId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const addToTimeline = instrument => {
        dispatch(openInstrumentModal(instrument));
    };

    if (loading.radar || loading.instruments || loading.scenarios) {
        return <React.Fragment>{loading ? <ContentLoader /> : null}</React.Fragment>;
    }

    return (
        <GridContainer contentContainer>
            <Accordion ref={scenariosRef} className={classes.accordion} defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    className={classes.accordionSummary}
                >
                    <Typography>{intl.formatMessage({ id: 'fundinginfo.scenarios' })}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <RadarForm
                        onRemoveScenario={onRemoveScenario}
                        onScenarioChange={setCompareScenario}
                        onSubmit={onSubmit}
                        onSubmitRadar={onSubmitRadar}
                        onSaveAsPrimary={onSaveAsPrimary}
                        scenarios={scenario}
                        {...props}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion ref={planRef} className={classes.accordion} defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    className={classes.accordionSummary}
                >
                    <Typography>{intl.formatMessage({ id: 'fundinginfo.fundingplan' })}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <Timeline />
                </AccordionDetails>
            </Accordion>
            <Instruments ref={instrumentsRef} instruments={instruments} addToTimeline={addToTimeline} />
        </GridContainer>
    );
};

const mapStateToProps = state => ({
    loading: getRadarLoading()(state),
    error: getRadarError()(state),
    radar: getRadar()(state),
    scenario: selectSenarios()(state),
    instruments: selectInstruments()(state),
});

export function mapDispatchToProps(dispatch) {
    return {
        onSubmit: data => {
            dispatch(saveScenario(data));
            // dispatch(setCompanyLimit(data));
        },
        onRemoveScenario: data => dispatch(removeScenario(data.id)),
        setCompareScenario: data => dispatch(setCompanyLimit(data)),
        loadInstruments: () => dispatch(loadInstrumentData()),
        loadScenarios: businessId => dispatch(loadScenarios(businessId)),
        onSubmitRadar: data => dispatch(setCompanyLimit(data)),
        onSaveAsPrimary: data => dispatch(saveMainScenario(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(CompanyRadar);
