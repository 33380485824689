import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectEvents = (state) => state.events || initialState;

const makeSelectEvents = () =>
  createSelector(selectEvents, (eventsState) => eventsState.events);

const makeSelectLoading = () =>
  createSelector(selectEvents, (eventsState) => eventsState.loading);

const makeSelectError = () =>
  createSelector(selectEvents, (eventsState) => eventsState.error);

export { selectEvents, makeSelectEvents, makeSelectLoading, makeSelectError };
