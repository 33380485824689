import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import RobotIcon from 'components/Icons/RobotIcon';

export default ({ showText }) => {
  return (
    <Button round="true" color="primary" disabled endIcon={<RobotIcon />}>
      {showText ? <FormattedMessage id="robot.finance.footer" /> : null}
    </Button>
  );
};
