import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useReactToPrint } from 'react-to-print';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components/Grid';
import SidebarHeader from './SidebarHeader';
import SidebarContent from './SidebarContent';
import SidebarFooter from './SidebarFooter';
import MainContent from './MainContent';

import './index.css';

const useStyles = makeStyles(theme => ({
    nav: {
        flexShrink: 0,
    },
    sidebarContainer: {
        alignItems: 'stretch',
        minHeight: '100vh',
        flexWrap: 'nowrap',
    },
    sidebarHeader: {
        width: '100%',
    },
    sidebarContent: {
        width: '100%',
        flexGrow: 1,
        overflow: 'auto',
    },
    sidebarFooter: {
        width: '100%',
    },
    main: {
        flexGrow: 1,
    },
    iconsPanel: {
        minHeight: '100vh',
        maxWidth: theme.spacing(8.5),
    },
    buttonContainer: {
        padding: theme.spacing(1.2),
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.secondary.line,
        textAlign: 'center',
    },
}));

const FinancialPlanDrawer = ({
    open,
    close,
    data,
    company,
    scenario,
    instruments,
    mock,
    hasHorizontalScrollbar = false,
    openChat,
    openInstrumentModal,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const intl = useIntl();

    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const matchesBetween960And1035 = useMediaQuery(theme.breakpoints.between(960, 1035));
    const matchesBetween1035And1095 = useMediaQuery(theme.breakpoints.between(1035, 1095));
    const matchesBetween1125And1160 = useMediaQuery(theme.breakpoints.between(1125, 1160));
    const matchesBetween1160AndLg = useMediaQuery(theme.breakpoints.between(1160, 1280));
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const sidebarWidthMdDown = '80%';
    const sidebarWidthMdUp = '18rem';
    const sidebarWidthBetween1125And1160 = '20rem';
    const sidebarWidthBetween1160AndLg = '22rem';
    const sidebarWidthLgUp = '25rem';

    const [selectedId, setSelectedId] = useState();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleSidebarToggle = () => setSidebarOpen(!sidebarOpen);

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `${intl.formatMessage({ id: 'financial.plan.title' })}: ${company?.name}, ${company?.businessId}`,
    });

    const sidebar = (
        <GridContainer direction='column' className={classes.sidebarContainer}>
            <GridItem className={classes.sidebarHeader}>
                <SidebarHeader type={data?.type} companyName={company?.name} />
            </GridItem>
            <GridItem className={classes.sidebarContent}>
                <SidebarContent setSelectedId={setSelectedId} />
            </GridItem>
            <GridItem className={classes.sidebarFooter}>
                <SidebarFooter openChat={openChat} />
            </GridItem>
        </GridContainer>
    );

    return (
        <Box display='flex'>
            <Drawer anchor='bottom' open={open} onClose={close} transitionDuration={{ enter: 1000, exit: 1000 }}>
                <nav
                    className={classes.nav}
                    style={{
                        width: lgUp
                            ? sidebarWidthLgUp
                            : matchesBetween1160AndLg
                            ? sidebarWidthBetween1160AndLg
                            : matchesBetween1125And1160
                            ? sidebarWidthBetween1125And1160
                            : mdUp && sidebarWidthMdUp,
                    }}
                >
                    {mdUp ? (
                        <Drawer
                            variant='permanent'
                            open
                            PaperProps={{
                                style: {
                                    width: lgUp
                                        ? sidebarWidthLgUp
                                        : matchesBetween1160AndLg
                                        ? sidebarWidthBetween1160AndLg
                                        : matchesBetween1125And1160
                                        ? sidebarWidthBetween1125And1160
                                        : mdUp && sidebarWidthMdUp,
                                },
                            }}
                        >
                            {sidebar}
                        </Drawer>
                    ) : (
                        <Drawer
                            variant='temporary'
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={sidebarOpen}
                            onClose={handleSidebarToggle}
                            PaperProps={{
                                style: {
                                    width: sidebarWidthMdDown,
                                },
                            }}
                        >
                            {sidebar}
                        </Drawer>
                    )}
                </nav>
                <main
                    className={classes.main}
                    style={{
                        marginLeft: lgUp
                            ? sidebarWidthLgUp
                            : matchesBetween1160AndLg
                            ? sidebarWidthBetween1160AndLg
                            : matchesBetween1125And1160
                            ? sidebarWidthBetween1125And1160
                            : mdUp && sidebarWidthMdUp,
                    }}
                >
                    <Box display='flex'>
                        <MainContent
                            company={company}
                            scenario={scenario}
                            data={data}
                            instruments={instruments}
                            mock={mock}
                            hasHorizontalScrollbar={hasHorizontalScrollbar}
                            selectedId={selectedId}
                            printRef={printRef}
                            smallerTableFont={matchesBetween1035And1095}
                            smallerPadding={matchesBetween960And1035}
                            mdUp={mdUp}
                            openInstrumentModal={openInstrumentModal}
                        />
                        <GridContainer direction='column' className={classes.iconsPanel}>
                            <GridItem className={classes.buttonContainer}>
                                <IconButton aria-label='close' onClick={close}>
                                    <CloseIcon color='primary' />
                                </IconButton>
                            </GridItem>
                            {!mock && (
                                <GridItem className={classes.buttonContainer}>
                                    <IconButton aria-label='print' onClick={handlePrint}>
                                        <PrintIcon color='primary' />
                                    </IconButton>
                                </GridItem>
                            )}
                            {!mdUp && (
                                <GridItem className={classes.buttonContainer}>
                                    <IconButton aria-label='print' onClick={handleSidebarToggle}>
                                        <MenuOpenIcon color='primary' />
                                    </IconButton>
                                </GridItem>
                            )}
                        </GridContainer>
                    </Box>
                </main>
            </Drawer>
        </Box>
    );
};

export default FinancialPlanDrawer;
