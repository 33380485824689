import {
    LOAD_TIMELINE,
    LOAD_TIMELINE_ERROR,
    LOAD_TIMELINE_SUCCESS,
    ADD_INSTRUMENT,
    SAVE_TIMELINE,
    SAVE_TIMELINE_INSTRUMENT,
    SAVE_TIMELINE_INSTRUMENT_SUCCESS,
    SAVE_TIMELINE_INSTRUMENT_ERROR,
    DELETE_TIMELINE_INSTRUMENT,
    DELETE_TIMELINE_INSTRUMENT_SUCCESS,
    OPEN_INSTRUMENT_MODAL,
    CLOSE_INSTRUMENT_MODAL,
    ADD_INSTRUMENT_TO_TIMELINE,
    ADD_INSTRUMENT_TO_TIMELINE_ERROR,
    ADD_INSTRUMENT_TO_TIMELINE_SUCCESS,
    EDIT_INSTRUMENT_IN_TIMELINE,
    EDIT_INSTRUMENT_IN_TIMELINE_SUCCESS,
    EDIT_INSTRUMENT_IN_TIMELINE_ERROR,
    DELETE_INSTRUMENT_FROM_TIMELINE,
    DELETE_INSTRUMENT_FROM_TIMELINE_ERROR,
    DELETE_INSTRUMENT_FROM_TIMELINE_SUCCESS,
} from './constants.js';

export const loadTimeline = () => {
    return {
        type: LOAD_TIMELINE,
    };
};

export const loadTimelineSuccess = timeline => {
    return {
        type: LOAD_TIMELINE_SUCCESS,
        timeline,
    };
};

export const loadTimelineError = error => {
    return {
        type: LOAD_TIMELINE_ERROR,
        error,
    };
};

export const addInstrument = instrument => {
    return {
        type: ADD_INSTRUMENT,
        instrument,
    };
};
export const addInstrumentToTimeline = instrument => {
    return {
        type: ADD_INSTRUMENT_TO_TIMELINE,
        instrument,
    };
};

export const addInstrumentToTimelineError = error => {
    return {
        type: ADD_INSTRUMENT_TO_TIMELINE_ERROR,
        error,
    };
};

export const addInstrumentToTimelineSuccess = instrument => {
    return {
        type: ADD_INSTRUMENT_TO_TIMELINE_SUCCESS,
        instrument,
    };
};

export const saveTimeline = timeline => {
    return {
        type: SAVE_TIMELINE,
        timeline,
    };
};

export const saveTimelineInstrument = (instrument, index) => {
    return {
        type: SAVE_TIMELINE_INSTRUMENT,
        instrument,
        index,
    };
};

export const deleteTimelineInstrument = (instrument, index) => {
    return {
        type: DELETE_TIMELINE_INSTRUMENT,
        instrument,
        index,
    };
};

export const deleteTimelineInstrumentSuccess = (instrument, index) => {
    return {
        type: DELETE_TIMELINE_INSTRUMENT_SUCCESS,
        instrument,
        index,
    };
};

export const editInstrumentInTimeline = instrument => {
    return {
        type: EDIT_INSTRUMENT_IN_TIMELINE,
        instrument,
    };
};

export const editInstrumentInTimelineError = error => {
    return {
        type: EDIT_INSTRUMENT_IN_TIMELINE_ERROR,
        error,
    };
};

export const editInstrumentInTimelineSuccess = instrument => {
    return {
        type: EDIT_INSTRUMENT_IN_TIMELINE_SUCCESS,
        instrument,
    };
};

export const deleteInstrumentFromTimeline = instrument => {
    return {
        type: DELETE_INSTRUMENT_FROM_TIMELINE,
        instrument,
    };
};

export const deleteInstrumentFromTimelineError = error => {
    return {
        type: DELETE_INSTRUMENT_FROM_TIMELINE_ERROR,
        error,
    };
};

export const deleteInstrumentFromTimelineSuccess = rowKey => {
    return {
        type: DELETE_INSTRUMENT_FROM_TIMELINE_SUCCESS,
        rowKey,
    };
};

export const saveTimelineInstrumentSuccess = (instrument, index) => {
    return {
        type: SAVE_TIMELINE_INSTRUMENT_SUCCESS,
        instrument,
        index,
    };
};

export const saveTimelineInstrumentError = (error, index) => {
    return {
        type: SAVE_TIMELINE_INSTRUMENT_ERROR,
        error,
        index,
    };
};

export const openInstrumentModal = (instrument, editMode) => {
    return {
        type: OPEN_INSTRUMENT_MODAL,
        instrument,
        editMode,
    };
};

export const closeInstrumentModal = () => {
    return {
        type: CLOSE_INSTRUMENT_MODAL,
    };
};
