import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { GridItem, GridContainer } from 'components/Grid';
import Button from 'components/CustomButtons/Button';
import { gridTranslations } from 'translations/gridTranslations';

import LicenseModal from './LicenseManagementModalForm';
import { terminateLicense } from '../actions';
 
const useStyles = makeStyles(() => ({
    button: {
        marginTop: '1rem',
    },
    removeButton: {
        background: 'red',
        marginRight: '1rem',
    }
}));

const licenseObject = {
    email: '',
    firstname: '',
    lastname: '',
    type: '',
    roles: []
};

const LicenseManagement = () => {
    const intl = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();

    const license = useSelector(state => state.grantedai?.license);

    const loading = license?.loading;
    const [openModal, setOpenModal] = useState(false);
    const [modifyLicense, setLicense] = useState(licenseObject);

    const columns = [
        { headerName: `${intl.formatMessage({ id: 'account.license.grid.type' })}`, field: 'type', width: 150 },
        { headerName: `${intl.formatMessage({ id: 'account.license.grid.timestamp' })}`, field: 'timestamp', width: 150, valueFormatter: (params) => params.value ? moment(params.value).format('DD.MM.YYYY hh:mm') : '' },
        { headerName: `${intl.formatMessage({ id: 'account.license.grid.startDate' })}`, field: 'startDate', width: 150, valueFormatter: (params) => params.value ? moment(params.value).format('DD.MM.YYYY hh:mm') : '' },
        { headerName: `${intl.formatMessage({ id: 'account.license.grid.endDate' })}`, field: 'endDate', width: 150, valueFormatter: (params) => params.value ? moment(params.value).format('DD.MM.YYYY hh:mm') : '' },
        { headerName: `${intl.formatMessage({ id: 'account.license.grid.author' })}`, field: 'author', width: 150 },
        { headerName: `${intl.formatMessage({ id: 'account.license.grid.terminationDate' })}`, field: 'terminationDate', width: 150, valueFormatter: (params) => params.value ? moment(params.value).format('DD.MM.YYYY hh:mm') : '' },
        { headerName: `${intl.formatMessage({ id: 'account.license.grid.terminateAuthor' })}`, field: 'terminateAuthor', width: 150 },
    ];

    const close = (open) => {
        setLicense({});
        setOpenModal(open);
    };

    return (
        <GridContainer contentContainer noPadding>
            <GridItem xs={12} noPadding>
                <h4>{intl.formatMessage({ id: 'account.license.title.main' })}</h4>
                {loading ? (
                    <CircularProgress size={24} />
                ) : !loading && license ? (
                    <DataGrid
                        getRowId={(r) => r.rowKey}
                        rows={license ?? []}
                        columns={columns}
                        pageSize={5}
                        autoHeight={true}
                        localeText={gridTranslations(intl)}
                        onRowClick={(data) => { setLicense(data.row); setOpenModal(true); }}
                    />
                ) : (
                    <GridContainer spacing={2}>
                        <GridItem>
                            <Box textAlign='center'>
                                <Typography component='p' color='primary'>
                                    {intl.formatMessage({ id: 'account.license.notfound' })}
                                </Typography>
                            </Box>
                        </GridItem>
                    </GridContainer>
                )}
                <Box textAlign='end' className={classes.button} >
                    <Button round="true" variant='contained' className={classes.removeButton} endIcon={<RemoveIcon />} onClick={() => dispatch(terminateLicense())}>
                        {intl.formatMessage({ id: 'account.license.terminate.button' })}
                    </Button>
                    <Button round="true" variant='contained' color='primary' endIcon={<AddIcon />} onClick={() => { setLicense(licenseObject); setOpenModal(true); }}>
                        {intl.formatMessage({ id: 'account.license.add.button' })}
                    </Button>
                </Box>
            </GridItem>
            <GridItem xs={12} noPadding>
                <LicenseModal open={openModal} close={close} license={modifyLicense} />
            </GridItem>
        </GridContainer>
    );
};

export default LicenseManagement;