import moment from 'moment';

export const getAddress = address => {
    if (!address) return null;

    const street = address.street ? `${address.street},` : '';
    const zip = address.zipCode ?? '';
    const city = address.city ?? '';

    return `${street} ${zip} ${city}`;
};

export const getDate = date => {
    // return Date.parse(date);
    try {
        return moment(date).format('DD.MM.YYYY');
    } catch (error) {
        return '-';
    }
};
