export const source = {
  0: 'yhteinen',
  1: 'PRH',
  2: 'VERO',
  3: 'YTJ',
};

export const registers = {
  1: 'kaupparekisteri',
  2: 'säätiörekisteri',
  3: 'yhdistysrekisteriä',
  4: 'verohallinto',
  5: 'ennakkoperintärekisteri',
  6: 'arvonlisäverovelvollinen',
  7: 'työnantajarekisteriä',
  8: 'vakuutusmaksuverovelvollistenrekisteri',
};

export const changeTypes = {
  2: 'Yritystunnusten käytöstä poisto',
  3: 'Tuplatunnusten yhdistäminen',
  5: 'Tunnusmuutos',
  44: 'Sulautuminen',
  45: 'Alv-toiminnan jatkaja',
  46: 'Edeltäjä-suhde',
  47: 'Jakautuminen',
  48: 'Konkurssisuhde',
  49: 'Jatkaminen yksityisenä elinkeinonharjoittajana',
  57: 'Osittaisjakautuminen',
  DIF: 'Jakautuminen',
  FUU: 'Sulautuminen',
};
