import produce from 'immer';
import {
    GET_GRANTED_SCORE,
    GET_GRANTED_SCORE_SUCCESS,
    GET_GRANTED_SCORE_ERROR,
    RESET_GRANTED_SCORE,
    GET_SCENARIO_GRANTED_SCORE,
} from './constants.js';

export const initialState = {
    loading: false,
    error: false,
    score: null,
};

const scenarioReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case GET_GRANTED_SCORE_ERROR:
                draft.loading = false;
                draft.error = action.error;
                break;
            case GET_GRANTED_SCORE_SUCCESS:
                draft.loading = false;
                draft.score = action.score;
                break;
            case GET_GRANTED_SCORE:
                draft.loading = true;
                break;
            case RESET_GRANTED_SCORE:
                draft = initialState;
                break;
            case GET_SCENARIO_GRANTED_SCORE:
                draft.loading = true;
                break;
            default:
                return state;
        }
    });

export default scenarioReducer;
