import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import TinyMCE from 'components/EditableText/TinyMCE';
import Button from 'components/CustomButtons/Button';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ConfirmDialog from 'components/ConfirmDialog';
import TimelineTable from './TimelineTable';
import InstrumentModal from './TimelineTable/InstrumentModal';
import FinancialPlanDrawer from './TimelineTable/FinancialPlanDrawer';

import { replaceCompanyInformation } from './dynamicDataReplace';
import { openInstrumentModal, closeInstrumentModal, deleteInstrumentFromTimeline } from 'containers/Timeline/actions';

const useStyles = makeStyles(() => ({
    form: {
        width: '100%',
    },
    expertComments: {
        '& textarea': {
            // backgroundColor: '#E9E9E9',
        },
    },
    controls: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'row-reverse',
        '& button:last-child': {
            marginRight: '1rem',
        },
    },
    editor: {
        marginBottom: '1rem',
    },
    internal: {
        backgroundColor: '#ccc',
        '& label': {
            color: '#000',
        },
    },
}));

const TimelineForm = ({
    timeline,
    loading,
    company,
    scenario,
    instruments,
    instrument,
    saveTimeline,
    timelineContainerWidth,
    editMode,
    isOpenFinancialPlanDrawer,
    openFinancialPlanDrawer,
    closeFinancialPlanDrawer,
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const setConfirm = event => {
        event.preventDefault();
        setOpen(true);
    };

    const { control, handleSubmit } = useForm({
        defaultValues: {
            timeline,
        },
    });

    const getDate = date => {
        try {
            if (date === undefined) return '-';

            const da = moment(date);
            if (da.isValid()) return moment(date).format('LLL');

            return '-';
        } catch (error) {
            return '-';
        }
    };

    const getDates = timeline => {
        return (
            <GridContainer>
                <GridItem xs={12} sm={6} md={2}>
                    {intl.formatMessage({ id: 'timeline.saveTimeline.saved' })}:
                </GridItem>
                <GridItem xs={12} sm={6} md={10}>
                    {getDate(timeline.modified)}
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                    {intl.formatMessage({ id: 'timeline.saveTimeline.published' })}:
                </GridItem>
                <GridItem xs={12} sm={6} md={10}>
                    {getDate(timeline.published)}
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                    {intl.formatMessage({ id: 'timeline.saveTimeline.state' })}:
                </GridItem>
                <GridItem xs={12} sm={6} md={10}>
                    {timeline.modified !== timeline.published
                        ? intl.formatMessage({ id: 'timeline.saveTimeline.state.draft' })
                        : intl.formatMessage({ id: 'timeline.saveTimeline.state.published' })}
                </GridItem>
            </GridContainer>
        );
    };

    const saveDraft = handleSubmit(data => {
        data.timeline.isDraft = true;
        saveTimeline(data);
    });

    const publish = handleSubmit(data => {
        data.timeline.isDraft = false;
        saveTimeline(data);
    });

    // const [isHorizontalScrollbar, setIsHorizontalScrollbar] = useState(false);
    // const hasHorizontalScrollbar = tableWidth => setIsHorizontalScrollbar(tableWidth + 0 + 1 >= 0);

    // InsrumentModal
    const openModal = (instrument, editMode) => dispatch(openInstrumentModal(instrument, editMode));
    const closeModal = () => {
        dispatch(closeInstrumentModal());
    };
    const deleteInstrument = () => {
        dispatch(deleteInstrumentFromTimeline(instrument));
        dispatch(closeInstrumentModal());
    };

    const [confirmOpen, setConfirmOpen] = useState(false);
    const isInstrumentModalOpen = timeline?.isInstrumentModalOpen ?? false;

    if (!timeline) return null;

    return (
        <form /* onSubmit={handleSubmit(data => saveTimeline(data))} */ className={classes.form}>
            <GridContainer>
                <GridItem noPadding className={`${classes.editor}`}>
                    <Controller
                        name='timeline.internalInformation'
                        control={control}
                        defaultValue={replaceCompanyInformation(timeline.internalInformation, company) ?? ''}
                        as={
                            <TinyMCE
                                defaultValue={replaceCompanyInformation(timeline.internalInformation, company) ?? ''}
                                holderClassName={classes.internal}
                                wrapperClassName={classes.internal}
                                title={intl.formatMessage({ id: 'timeline.financeplan.internalInformation' })}
                            />
                        }
                    />
                </GridItem>
                <GridItem noPadding className={classes.editor}>
                    <Controller
                        name='timeline.base'
                        control={control}
                        defaultValue={replaceCompanyInformation(timeline.base, company) ?? ''}
                        as={<TinyMCE defaultValue={timeline.base} title={intl.formatMessage({ id: 'timeline.financeplan.base' })} />}
                    />
                </GridItem>
                <GridItem noPadding className={classes.editor}>
                    <Controller
                        name='timeline.expertComments'
                        control={control}
                        defaultValue={replaceCompanyInformation(timeline.expertComments, company) ?? ''}
                        as={
                            <TinyMCE
                                defaultValue={timeline.expertComments}
                                title={intl.formatMessage({ id: 'timeline.financeplan.expertComments' })}
                            />
                        }
                    />
                </GridItem>
                <GridItem noPadding className={classes.editor}>
                    <Controller
                        name='timeline.precondition'
                        control={control}
                        defaultValue={replaceCompanyInformation(timeline.precondition, company) ?? ''}
                        as={
                            <TinyMCE
                                defaultValue={replaceCompanyInformation(timeline.precondition, company) ?? ''}
                                title={intl.formatMessage({ id: 'timeline.financeplan.precondition' })}
                            />
                        }
                    />
                </GridItem>
                <GridItem noPadding className={classes.editor}>
                    <Controller
                        name='timeline.notice'
                        control={control}
                        defaultValue={replaceCompanyInformation(timeline.notice, company) ?? ''}
                        as={
                            <TinyMCE
                                defaultValue={replaceCompanyInformation(timeline.notice, company) ?? ''}
                                title={intl.formatMessage({ id: 'timeline.financeplan.notice' })}
                            />
                        }
                    />
                </GridItem>
                <GridItem noPadding className={classes.editor}>
                    <Controller
                        name='timeline.followup'
                        control={control}
                        defaultValue={replaceCompanyInformation(timeline.followup, company) ?? ''}
                        as={
                            <TinyMCE
                                defaultValue={replaceCompanyInformation(timeline.followup, company) ?? ''}
                                title={intl.formatMessage({ id: 'timeline.financeplan.followup' })}
                            />
                        }
                    />
                </GridItem>
                <GridItem>
                    <Controller
                        name='timeline.instruments'
                        control={control}
                        defaultValue={instruments}
                        render={() => <input type='hidden' value={instruments} />}
                    />
                </GridItem>
                <GridItem noPadding>
                    <TimelineTable
                        data={timeline}
                        loading={loading}
                        padding={8}
                        // hasHorizontalScrollbar={hasHorizontalScrollbar}
                        instruments={instruments}
                        timelineContainerWidth={timelineContainerWidth}
                        openInstrumentModal={openModal}
                        isOpenFinancialPlanDrawer={isOpenFinancialPlanDrawer}
                        openFinancialPlanDrawer={openFinancialPlanDrawer}
                        closeFinancialPlanDrawer={closeFinancialPlanDrawer}
                    />
                    <FinancialPlanDrawer
                        open={isOpenFinancialPlanDrawer}
                        close={closeFinancialPlanDrawer}
                        data={timeline}
                        company={company}
                        scenario={scenario}
                        instruments={instruments}
                        mock={false}
                        // hasHorizontalScrollbar={hasHorizontalScrollbar}
                        openInstrumentModal={openInstrumentModal}
                    />
                </GridItem>
                <InstrumentModal
                    open={isInstrumentModalOpen}
                    close={closeModal}
                    instruments={instruments}
                    editMode={editMode}
                    setConfirmOpen={setConfirmOpen}
                    loading={timeline?.loading}
                />
                <ConfirmDialog titleId='instrument.delete' open={confirmOpen} setOpen={setConfirmOpen} onConfirm={deleteInstrument}>
                    {intl.formatMessage({ id: 'confirm.title.delete.instrument' })}
                </ConfirmDialog>
                <GridItem noPadding className={classes.controls}>
                    <ConfirmDialog
                        open={open}
                        setOpen={setOpen}
                        onConfirm={publish}
                        buttonOkId='timeline.saveTimeline'
                        titleId='timeline.saveTimeline'
                    >
                        <p>{intl.formatMessage({ id: 'timeline.confirm' })}</p>
                    </ConfirmDialog>
                    <Button round color='primary' type='submit' onClick={setConfirm}>
                        {intl.formatMessage({ id: 'timeline.saveTimeline' })}
                    </Button>
                    <Button round color='secondary' type='submit' onClick={saveDraft}>
                        {intl.formatMessage({ id: 'timeline.saveTimeline.draft' })}
                    </Button>
                </GridItem>
                <GridItem>{getDates(timeline)}</GridItem>
            </GridContainer>
        </form>
    );
};

export default TimelineForm;
