import React, { forwardRef, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InstrumentForm from 'containers/Instruments/InstrumentForm';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        paddingRight: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.white,
    },
    dialogContent: {
        backgroundColor: theme.palette.primary.white,
    },
    dialogActions: {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(4),
        backgroundColor: theme.palette.primary.white,
    },
    dialogTitleText: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    closeIcon: {
        color: theme.palette.primary.main,
    },
    header: {
        fontSize: '1.6rem',
    },
    box: {
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: '1.1rem',
    },
    wrapper: {
        position: 'relative',
    },
    submitButton: {
        fontWeight: 600,
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            opacity: '0.8',
            backgroundColor: theme.palette.success.main,
        },
    },
    buttonLoading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: theme.spacing(-1.5),
        marginLeft: theme.spacing(-1.5),
        color: theme.palette.primary.white,
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const InstrumentModal = ({
    open,
    close,
    instrument,
    title,
    onSubmit,
    saveLoading,
    providers,
    projectAims,
    projectObjects,
    projectValues,
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const formRef = useRef();

    const [isDraft, setIsDraft] = useState();

    const submitForm = isDraft => {
        setIsDraft(isDraft);
        return formRef.current?.dispatchEvent(new CustomEvent('submit', { cancelable: true, bubbles: true, detail: isDraft }));
    };

    return (
        <Dialog
            aria-labelledby='add instrument'
            aria-describedby='add instrument'
            fullWidth
            maxWidth='lg'
            open={open}
            onClose={close}
            scroll='paper'
            TransitionComponent={Transition}
            disableEnforceFocus
        >
            <DialogTitle id='scroll-dialog-title' className={classes.dialogTitle}>
                <GridContainer justify='space-between' alignItems='center'>
                    <GridItem xs={11}>
                        <Typography variant='h2' className={classes.dialogTitleText}>
                            {title && intl.formatMessage({ id: `instrument.modal.title.${title}` })}
                        </Typography>
                    </GridItem>
                    <GridItem xs={1}>
                        <Box textAlign='end'>
                            <IconButton aria-label='close' onClick={close} className={classes.iconButton}>
                                <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                        </Box>
                    </GridItem>
                </GridContainer>
            </DialogTitle>
            <DialogContent dividers className={classes.dialogContent}>
                <InstrumentForm
                    ref={formRef}
                    onSubmit={onSubmit}
                    instrument={instrument}
                    providers={providers}
                    projectAims={projectAims}
                    projectObjects={projectObjects}
                    projectValues={projectValues}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <GridContainer justify='space-between'>
                    <GridItem xs={3}>
                        <Button variant='outlined' color='primary' onClick={close}>
                            {intl.formatMessage({ id: 'common.close' })}
                        </Button>
                    </GridItem>
                    <GridItem xs={7}>
                        <GridContainer justify='flex-end'>
                            <Box className={classes.wrapper}>
                                <Button variant='contained' type='submit' onClick={() => submitForm(true)} className={classes.submitButton}>
                                    {intl.formatMessage({ id: 'instrument.form.save.draft' })}
                                </Button>
                                {saveLoading && isDraft === true && <CircularProgress size={24} className={classes.buttonLoading} />}
                            </Box>
                            <Box className={classes.wrapper} style={{ paddingLeft: '1rem' }}>
                                <Button variant='contained' color='primary' type='submit' onClick={() => submitForm(false)}>
                                    {intl.formatMessage({ id: 'instrument.form.save' })}
                                </Button>
                                {saveLoading && isDraft === false && <CircularProgress size={24} className={classes.buttonLoading} />}
                            </Box>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </DialogActions>
        </Dialog>
    );
};

export default InstrumentModal;
