import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import { Helmet } from 'react-helmet';
import { Switch, Route, withRouter } from 'react-router-dom';
import CompanySearch from 'containers/CompanySearch';
import Company from 'containers/Company';
import NotFoundPage from 'containers/NotFoundPage';
import Header from 'components/Header/Header';
import withAuthProvider from '../Login/AuthProvider';
import HomePage from 'containers/Homepage';
import InstrumentsEditPage from 'containers/InstrumentsEditPage';
import InstrumentForm from 'containers/Instruments/InstrumentForm';
import Customers from 'containers/Customers';

import { makeSelectCurrentUser, makeSelectLoading } from './selectors';
import reducer from './reducer';
import saga from './saga';
import { loginUser } from './actions';

import { MuiThemeProvider } from '@material-ui/core/styles/';
import CssBaseline from '@material-ui/core/CssBaseline';

import theme from 'assets/jss/grants/theme';

const key = 'app';

function App(props) {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const { ...rest } = props;

    if (!props.isAuthenticated) {
        return <div>Authenticate</div>;
    }

    return (
        <div>
            <Helmet titleTemplate='%s - Grants UI' defaultTitle='Grants UI'>
                <meta name='description' content='Grants UI' />
            </Helmet>
            <Header {...rest} />
            <div
                style={{
                    background: '#FFFFFF',
                    position: 'relative',
                    zIndex: '3',
                    paddingTop: '5rem',
                }}
            >
                <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Switch>
                        <Route exact path='/' component={HomePage} />
                        <Route path='/instruments/:id' component={InstrumentForm} />
                        <Route path='/instruments' component={InstrumentsEditPage} />
                        <Route path='/customers' component={Customers} />
                        <Route path='/search' component={CompanySearch} />
                        <Route path='/company/:businessId' component={Company} />
                        <Route path='' component={NotFoundPage} />
                    </Switch>
                </MuiThemeProvider>
            </div>
            {/* <Footer /> */}
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    user: makeSelectCurrentUser(),
    loading: makeSelectLoading(),
});

export function mapDispatchToProps(dispatch) {
    return {
        onChangeToken: item => dispatch(loginUser(item)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const Application = withAuthProvider(withRouter(App));

export default compose(withConnect, memo)(Application);
