import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

export const GrantsSelect = ({ input, label, children, ...custom }) => (
	<FormControl fullWidth={true}>
		<InputLabel shrink={true}>{label}</InputLabel>
		<Select
			fullWidth
			defaultValue=""
			autoWidth={true}
			displayEmpty={true}
			{...input}
			{...custom}
			children={children}
		/>
	</FormControl>
);

export default GrantsSelect;