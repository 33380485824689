import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    logo: {
        '& .a': {
            fill: '#e0d6c4',
        },
        '& .b': {
            fill: '#fff',
        },
    },
}));

const GrantedLogo = ({ width = '270' }) => {
    const classes = useStyles();

    return (
        <Link to='/'>
            <svg
                version='1.0'
                className={classes.logo}
                xmlns='http://www.w3.org/2000/svg'
                width={width}
                height='50'
                viewBox='0 0 2314.000000 238.000000'
                preserveAspectRatio='xMidYMid meet'
            >
                <g transform='translate(0.000000,238.000000) scale(0.100000,-0.100000)' stroke='none'>
                    <path
                        className='a'
                        d='M1052 2039 c-259 -33 -508 -169 -648 -353 -135 -178 -192 -366 -181
                                                -596 14 -274 135 -505 353 -676 101 -79 273 -151 418 -175 95 -15 323 -6 416
                                                16 131 31 309 98 396 149 l89 52 0 319 0 320 -259 0 -260 0 -68 -170 c-37 -93
                                                -68 -170 -68 -172 0 -1 57 -4 128 -5 119 -3 127 -4 127 -23 0 -40 -146 -91
                                                -290 -102 -70 -5 -90 -2 -160 21 -95 32 -159 71 -213 130 -190 206 -184 545
                                                12 742 119 119 287 167 458 130 101 -22 202 -67 286 -130 34 -25 64 -46 66
                                                -46 3 0 62 70 131 156 142 175 139 160 45 224 -223 151 -518 223 -778 189z'
                    />
                    <path
                        className='b'
                        d='M14835 2025 c-89 -16 -152 -38 -240 -82 -88 -44 -133 -77 -213 -156
                                                -162 -158 -246 -344 -259 -572 -19 -348 168 -667 482 -820 207 -100 -238 -90
                                                3825 -90 4063 0 3618 -10 3825 90 286 139 463 408 482 730 20 347 -168 667
                                                -482 820 -60 29 -139 62 -175 72 -62 17 -209 18 -3620 20 -2891 1 -3568 -1
                                                -3625 -12z m4750 -357 c80 -26 141 -66 207 -136 65 -67 102 -136 123 -224 41
                                                -176 -7 -364 -123 -483 -109 -111 -220 -158 -377 -158 -112 0 -195 22 -276 74
                                                -64 41 -152 135 -183 197 -75 149 -73 346 6 494 39 74 147 177 224 213 118 56
                                                273 65 399 23z m-2563 -478 c97 -272 177 -501 178 -508 0 -10 -27 -12 -117
                                                -10 l-118 3 -31 90 -31 90 -174 0 -173 0 -32 -90 -32 -90 -116 -3 c-87 -2
                                                -116 0 -116 10 0 12 346 983 356 1000 3 5 56 7 117 6 l111 -3 178 -495z
                                                m-1711 459 c214 -101 247 -384 61 -526 -58 -44 -126 -63 -259 -70 l-113 -6 -2
                                                -186 -3 -186 -112 -3 -113 -3 0 506 0 507 243 -4 c237 -3 243 -4 298 -29z
                                                m559 -374 l0 -405 160 0 160 0 0 -100 0 -100 -270 0 -270 0 0 505 0 505 110 0
                                                110 0 0 -405z m2150 310 l0 -95 -125 0 -125 0 0 -410 0 -411 -112 3 -113 3 -3
                                                408 -2 407 -125 0 -125 0 0 95 0 95 365 0 365 0 0 -95z m780 0 l0 -95 -195 0
                                                -195 0 0 -105 0 -105 155 0 155 0 0 -95 0 -95 -155 0 -155 0 -2 -207 -3 -208
                                                -112 -3 -113 -3 0 506 0 505 310 0 310 0 0 -95z m1793 75 c89 -28 167 -98 197
                                                -178 57 -149 -13 -332 -148 -389 -23 -9 -32 -19 -28 -29 3 -8 57 -95 121 -195
                                                63 -99 115 -185 115 -190 0 -5 -57 -9 -129 -9 -106 0 -131 3 -142 16 -7 9 -61
                                                94 -119 188 -80 129 -111 172 -128 174 l-22 3 0 -191 0 -191 -112 3 -113 3 -3
                                                503 -2 502 224 0 c196 0 232 -2 289 -20z m801 -315 c82 -184 152 -333 154
                                                -330 3 2 71 152 151 332 l147 328 117 3 117 3 -2 -503 -3 -503 -112 -3 -113
                                                -3 -2 251 -3 251 -111 -248 -111 -248 -77 0 -78 0 -112 249 -111 249 -3 -252
                                                -2 -252 -113 3 -112 3 -3 503 -2 502 117 0 118 0 149 -335z'
                    />
                    <path
                        className='b'
                        d='M19306 1460 c-111 -43 -169 -140 -169 -280 0 -130 48 -220 148 -273
                                                32 -18 57 -22 130 -22 73 0 98 4 130 22 100 53 148 143 148 273 0 100 -23 163
                                                -81 223 -70 72 -203 97 -306 57z'
                    />
                    <path
                        className='b'
                        d='M16681 1212 c-24 -70 -46 -133 -49 -139 -3 -10 21 -13 98 -13 93 0
                                                102 2 95 18 -4 9 -25 69 -46 131 -21 63 -42 118 -47 123 -4 4 -27 -50 -51
                                                -120z'
                    />
                    <path
                        className='b'
                        d='M15000 1371 l0 -121 90 0 c49 0 101 5 115 12 35 16 55 57 55 112 0
                                                36 -6 52 -29 78 -28 32 -31 32 -130 36 l-101 4 0 -121z'
                    />
                    <path
                        className='b'
                        d='M20310 1370 l0 -120 95 0 c52 0 106 5 120 12 35 16 55 57 55 110 0
                                                86 -50 118 -185 118 l-85 0 0 -120z'
                    />
                    <path
                        className='a'
                        d='M6300 1150 l0 -880 215 0 215 0 2 520 3 519 394 -519 394 -520 204 0
                                                203 0 -2 878 -3 877 -212 3 -213 2 -2 -525 -3 -526 -239 316 c-131 174 -310
                                                410 -398 526 l-159 209 -199 0 -200 0 0 -880z'
                    />
                    <path
                        className='a'
                        d='M8170 1840 l0 -190 258 -2 257 -3 3 -687 2 -688 230 0 230 0 0 690 0
                                                690 260 0 260 0 0 190 0 190 -750 0 -750 0 0 -190z'
                    />
                    <path
                        className='a'
                        d='M9915 2018 c-3 -7 -6 -168 -7 -358 l-3 -345 -43 -1 c-23 0 -46 -5
                                                -51 -10 -12 -14 -132 -326 -127 -331 3 -3 53 -6 113 -6 l108 -2 3 -347 2 -348
                                                755 0 755 0 0 180 0 180 -522 2 -523 3 0 165 0 165 315 2 315 2 67 167 c37 92
                                                66 169 64 171 -2 2 -174 5 -382 6 l-379 2 -3 168 -2 167 525 0 525 0 0 190 0
                                                190 -750 0 c-591 0 -752 -3 -755 -12z'
                    />
                    <path
                        className='a'
                        d='M11660 1150 l0 -881 498 3 c477 4 500 5 567 26 313 98 530 312 611
                                                603 28 97 25 393 -5 503 -75 279 -308 506 -613 598 -70 21 -93 22 -565 26
                                                l-493 3 0 -881z m923 495 c169 -44 291 -164 341 -334 24 -81 22 -260 -4 -344
                                                -40 -130 -128 -229 -257 -290 l-68 -32 -233 -5 c-129 -3 -236 -3 -238 -1 -2 2
                                                -4 233 -4 513 l0 508 203 0 c147 0 218 -4 260 -15z'
                    />
                    <path
                        className='a'
                        d='M2418 1563 l-3 -458 -76 -3 -76 -3 -61 -157 c-34 -86 -64 -165 -68
                                                -174 -5 -16 7 -18 138 -20 l143 -3 3 -243 2 -242 228 2 227 3 3 243 2 242 169
                                                0 168 0 128 -245 129 -245 253 0 c166 0 253 4 253 10 0 6 -79 134 -175 286
                                                -96 152 -175 277 -175 278 0 1 22 17 49 34 164 107 261 305 261 536 0 288
                                                -141 478 -430 578 l-95 33 -497 3 -498 3 -2 -458z m852 86 c89 -14 158 -54
                                                195 -114 26 -42 30 -59 33 -136 3 -76 1 -95 -18 -135 -30 -61 -78 -106 -137
                                                -126 -58 -20 -71 -21 -290 -19 l-173 1 0 263 c0 145 3 267 7 270 10 11 312 8
                                                383 -4z'
                    />
                    <path
                        className='a'
                        d='M4923 2008 c-4 -7 -94 -213 -199 -458 l-191 -445 -115 -3 -115 -3
                                                -61 -157 c-34 -86 -64 -165 -68 -174 -6 -16 3 -18 93 -18 55 0 102 -2 105 -5
                                                3 -3 -27 -81 -67 -173 -40 -92 -86 -200 -103 -240 l-31 -72 236 2 237 3 96
                                                240 95 240 312 3 311 2 48 -122 c26 -68 68 -177 94 -243 l46 -120 243 -3 c229
                                                -2 243 -1 239 15 -3 10 -73 180 -156 378 -84 198 -246 585 -361 860 l-210 500
                                                -235 3 c-181 2 -236 -1 -243 -10z m310 -673 c41 -105 77 -200 81 -212 l7 -23
                                                -170 0 c-94 0 -171 3 -171 8 -1 20 166 430 172 424 4 -4 41 -93 81 -197z'
                    />
                </g>
            </svg>
        </Link>
    );
};

export default GrantedLogo;
