import React, { memo, useEffect } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import { getFinance, getError, getLoading } from './selectors';
import saga from './saga';
import reducer from './reducer';
import { saveCompanyFinance, loadFinanceData } from './actions';
import ContentLoader from 'components/LoadingIndicator/ContentLoader';
import FinderFinanceData from './FinderFinanceData';

const key = 'finance';

const FinanceData = (props) => {
	useInjectReducer({ key, reducer });
	useInjectSaga({ key, saga });

	const {
		loading,
		finance,
		onload,
		match: {
			params: { businessId },
		},
	} = props;

	useEffect(() => {
		onload(businessId);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (loading || finance === undefined || finance === null) {
		return (
			<React.Fragment>{loading ? <ContentLoader /> : null}</React.Fragment>
		);
	}

	return (
		<GridContainer contentContainer>
			<FinderFinanceData {...props} />
		</GridContainer>
	);
};

FinanceData.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	finance: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	finance: getFinance()(state),
	loading: getLoading()(state),
	error: getError()(state),
});

export function mapDispatchToProps(dispatch) {
	return {
		onSubmit: (values) => dispatch(saveCompanyFinance(values)),
		onload: (id) => dispatch(loadFinanceData(id)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FinanceData);
