import React from 'react';
import Menu from '@material-ui/core/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { color } from 'assets/jss/grants/common/grantsBase.js';

const useStyles = makeStyles(() => ({
    menu: {
        backgroundColor: color.whiteColor,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: color.lightBrown,
        borderRadius: 0,
    },
}));

const StyledMenu = props => {
    const { children } = props;
    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Menu
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            classes={{
                paper: classes.menu,
            }}
            PaperProps={{
                style: {
                    transform: sm ? 'translateY(0.35rem)' : 'translateY(0.5rem)',
                },
            }}
            {...props}
        >
            {children}
        </Menu>
    );
};

export default StyledMenu;
