import React, { forwardRef } from 'react';
import * as _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components/Grid';
import SelectItem from './SelectItem';
import SliderItem from './SliderItem';
import SwitchItem from './SwitchItem';
import SwitchGroup from './SwitchGroup';
import RadioItem from './RadioItem';

import scenarioValues from 'utils/mock/scenarioValues.json';

const useStyles = makeStyles(theme => ({
    container: {
        background: '#ffffff',
        marginTop: theme.spacing(4),
    },
    paddedBox: {
        padding: '1rem',
        background: '#ffffff',
    },
    containerMiddle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.secondary,
        backgroundClip: 'paddingBox',
    },
    title: {
        fontSize: '1.2rem',
    },
}));

const ScenarioForm = forwardRef(({ scenarios, control }, formRef) => {
    const classes = useStyles();

    if (scenarios === undefined || scenarios === null || scenarios === {}) return null;

    const scenarioValuesObj = _.chain(scenarioValues).keyBy('type').mapValues('values').value();

    const sortArray = (array, sortedTypes) => {
        const arrayMap = array.reduce(
            (acc, currentValue) => ({
                ...acc,
                [currentValue.type]: currentValue,
            }),
            {}
        );
        return sortedTypes.map(type => arrayMap[type]);
    };

    // Grouping
    const basicsSwitch = scenarioValues.filter(
        ({ type }) =>
            type === 'employeeRegister' || // switch
            type === 'advanceCollectionRegister' || // switch
            type === 'vatRegister' // switch
    );

    // Sorting:
    const sortedBasicsTypes = ['companyType', 'owner'];

    const sortedBasicsSwitchTypes = ['employeeRegister', 'advanceCollectionRegister', 'vatRegister'];

    const sortedEconomiesTypes = [
        'equityPercentageLevel',
        'balance',
        'cashFlow',
        'turnover',
        'equityAmount',
        'deminisHistory',
        'ratingAlpha',
        'responsiblesCreditRate',
    ];

    const sortedBusinessesTypes = ['internationalEta', 'team', 'ipr', 'concept'];

    const sortedBasicSwitchValues = sortArray(basicsSwitch, sortedBasicsSwitchTypes);

    return (
        <>
            <GridContainer ref={formRef} spacing={4} className={classes.container}>
                <GridItem xs={12}>
                    <h2 className={classes.title}>Perustiedot</h2>
                </GridItem>
                {sortedBasicsTypes.map((type, index) => (
                    <GridItem key={index} xs={12} sm={6}>
                        {type === 'companyType' || type === 'owner' ? (
                            <SelectItem type={type} values={scenarioValuesObj[type]} scenarios={scenarios} control={control} />
                        ) : null}
                    </GridItem>
                ))}
                <GridItem xs={12} sm={12}>
                    <GridContainer spacing={4} className={classes.containerMiddle}>
                        <GridItem xs={12} md={6}>
                            <SliderItem
                                type={'personnelSize'}
                                values={scenarioValuesObj['personnelSize']}
                                scenarios={scenarios}
                                control={control}
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <SliderItem type={'age'} values={scenarioValuesObj['age']} scenarios={scenarios} control={control} />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <SwitchGroup items={sortedBasicSwitchValues} scenarios={scenarios} control={control} name='registers' />
                </GridItem>
                <GridItem xs={12} sm={6} lg={4} xl={3}>
                    <RadioItem type='countrySide' values={scenarioValuesObj['countrySide']} scenarios={scenarios} control={control} />
                </GridItem>
            </GridContainer>
            <GridContainer spacing={4} className={classes.container}>
                <GridItem xs={12}>
                    <h2 className={classes.title}>Liiketoiminta</h2>
                </GridItem>
                {sortedBusinessesTypes.map((type, index) => (
                    <GridItem key={index} xs={12} sm={6} lg={6}>
                        {(type === 'internationalEta' || type === 'ipr' || type === 'team' || type === 'concept') && (
                            <SelectItem type={type} values={scenarioValuesObj[type]} scenarios={scenarios} control={control} />
                        )}
                    </GridItem>
                ))}
            </GridContainer>
            <GridContainer className={classes.paddedBox}>
                <GridItem xs={12}>
                    <h2 className={classes.title}>Taloustiedot</h2>
                </GridItem>
                <GridItem xs={12}>
                    <GridContainer spacing={4} className={classes.containerMiddle}>
                        <GridItem xs={12}>
                            <GridContainer spacing={4}>
                                {sortedEconomiesTypes.map((type, index) => (
                                    <GridItem item key={index} xs={12} sm={12} md={6} lg={4} xl={3}>
                                        <SliderItem type={type} values={scenarioValuesObj[type]} scenarios={scenarios} control={control} />
                                    </GridItem>
                                ))}
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer spacing={4} className={classes.container}>
                <GridItem key={'taxDepth'} xs={12} sm={6} lg={4}>
                    <RadioItem type='taxDepth' values={scenarioValuesObj['taxDepth']} scenarios={scenarios} control={control} />
                </GridItem>
                <GridItem key={'paysDivident'} xs={12} sm={6} lg={4}>
                    <SwitchItem type='paysDivident' values={scenarioValuesObj['paysDivident']} scenarios={scenarios} control={control} checkedVal={1} notCheckedVal={2} />
                </GridItem>
            </GridContainer>

        </>
    );
});

export default ScenarioForm;
