import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components/Grid';
import CustomTooltip from '../CustomTooltip';
import TooltipDialog from '../TooltipDialog';
import { getEuroFormatted } from 'utils/getEuroFormatted';
import './index.css';

const useStyles = makeStyles(theme => ({
    td: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: 0,
        textAlign: 'center',
    },
    container: {
        display: 'flex',
        marginTop: theme.spacing(1),
    },
    tooltipName: {
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    tooltipDate: {
        fontSize: '0.7rem',
        marginBottom: theme.spacing(1),
    },
    tooltipKey: {
        fontSize: '0.8rem',
        fontWeight: 500,
        marginRight: theme.spacing(1),
    },
    tooltipProp: {
        fontSize: '0.8rem',
    },
    tooltipDescription: {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        '& span, p': {
            fontSize: '0.8rem !important',
            color: `${theme.palette.primary.main} !important`, // TODO: check it
        },
    },
    instrument: {
        height: theme.spacing(5),
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        backgroundColor: theme.palette.primary.beige,
        cursor: 'pointer',
    },
    instrumentItem: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        zIndex: 10,
        width: '100%',
    },
    instrumentPrint: {
        height: theme.spacing(5),
        marginBottom: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    instrumentName: {
        fontSize: '0.8rem',
        fontWeight: 700,
        color: theme.palette.primary.white,
        height: '100%',
        width: '100%',
    },
    instrumentNamePrintRight: {
        fontSize: '0.8rem',
        fontWeight: 700,
        paddingLeft: theme.spacing(2),
        color: theme.palette.primary.main,
    },
    instrumentNamePrintLeft: {
        fontSize: '0.8rem',
        fontWeight: 700,
        paddingRight: theme.spacing(2),
        color: theme.palette.primary.main,
    },
    bgViolet: {
        backgroundColor: theme.palette.primary.main,
    },
    bgGreen: {
        backgroundColor: theme.palette.success.main,
    },
    bgRed: {
        backgroundColor: theme.palette.warning.main,
    },
    bgYellow: {
        backgroundColor: theme.palette.primary.darkYellow,
    },
    level: {
        border: '1px solid white',
        borderRadius: '50%',
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.primary.white,
    },
    button: {
        textTransform: 'none',
        color: theme.palette.success.main,
    },
    transparent: {
        display: 'none',
    },
    highlighted: {
        opacity: 0.8,
    },
}));

const TBody = ({ instruments, numberOfMonth, openInstrumentModal, mock, profile = false }) => {
    const classes = useStyles();
    const intl = useIntl();

    const [open, setOpen] = useState(false);
    const openModal = id => setOpen(id);
    const closeModal = () => setOpen(false);

    const [tooltipId, setTooltipId] = useState(false);

    return instruments?.map((instrument, index, array) => {
        const {
            id,
            rowKey,
            name,
            provider,
            level,
            description,
            startTime,
            endTime,
            monthFromStart,
            monthDuration,
            isUsed,
            grantAmount,
            projectSize,
            isCustom,
        } = instrument;

        const freeColumnsFromRight = numberOfMonth - (monthFromStart + monthDuration) ?? null;
        const freeColumnsFromLeft = monthFromStart ?? null;
        const instrumentTextSide = freeColumnsFromLeft - freeColumnsFromRight ?? null;

        const title = (
            <>
                <Typography component='p' align='center' className={classes.tooltipName}>
                    {name}
                </Typography>
                <Typography component='p' align='center' className={classes.tooltipDate}>
                    {moment(startTime).format('DD.MM.YYYY')}-{moment(endTime).format('DD.MM.YYYY')}
                </Typography>
                {provider && (
                    <div className={classes.container}>
                        <div>
                            <Typography component='p' className={classes.tooltipKey}>
                                {intl.formatMessage({ id: 'timeline.tooltip.provider' })}:
                            </Typography>
                        </div>
                        <div>
                            <Typography component='p' className={classes.tooltipProp}>
                                {provider}
                            </Typography>
                        </div>
                    </div>
                )}
                <div className={classes.container}>
                    <div>
                        <Typography component='p' className={classes.tooltipKey}>
                            {intl.formatMessage({ id: 'timeline.tooltip.isUsed' })}:
                        </Typography>
                    </div>
                    <div>
                        {isUsed ? (
                            <CheckCircleOutlineIcon fontSize='small' />
                        ) : (
                            <Typography component='p' className={classes.tooltipProp}>
                                {intl.formatMessage({ id: 'common.no' })}
                            </Typography>
                        )}
                    </div>
                </div>
                <div className={classes.container}>
                    <div>
                        <Typography component='p' className={classes.tooltipKey}>
                            {intl.formatMessage({ id: 'timeline.tooltip.grantAmount' })}:
                        </Typography>
                    </div>
                    <div>
                        <Typography component='p' className={classes.tooltipProp}>
                            {getEuroFormatted(grantAmount)}
                        </Typography>
                    </div>
                </div>
                <div className={classes.container}>
                    <div>
                        <Typography component='p' className={classes.tooltipKey}>
                            {intl.formatMessage({ id: 'timeline.tooltip.projectSize' })}:
                        </Typography>
                    </div>
                    <div>
                        <Typography component='p' className={classes.tooltipProp}>
                            {getEuroFormatted(projectSize)}
                        </Typography>
                    </div>
                </div>
                <div className={classes.container}>
                    <div>
                        <Typography component='p' className={classes.tooltipKey}>
                            {intl.formatMessage({ id: 'timeline.tooltip.description' })}:
                        </Typography>
                    </div>
                    <div>
                        <Typography component='div' className={classes.tooltipDescription}>
                            {ReactHtmlParser(description)}
                        </Typography>
                    </div>
                </div>
                <div className={classes.container} style={{ justifyContent: 'flex-end' }}>
                    {!profile && (
                        <div>
                            <Button disabled={mock} onClick={() => openInstrumentModal(instrument, true)} className={classes.button}>
                                {intl.formatMessage({ id: 'common.edit' })}
                            </Button>
                        </div>
                    )}
                    <div>
                        <Button disabled={mock} onClick={() => openModal(rowKey ?? id + startTime)} className={classes.button}>
                            {intl.formatMessage({ id: 'common.read.more' })}
                        </Button>
                    </div>
                </div>
            </>
        );

        return (
            <tr key={rowKey ?? id + endTime}>
                {monthFromStart > 0 && instrumentTextSide <= 0 && <td colSpan={monthFromStart} className={classes.td}></td>}
                {instrumentTextSide > 0 && (
                    <td colSpan={monthFromStart} className={classes.td}>
                        <div style={{ marginTop: array.length === 1 ? '0.5rem' : null }} className={`${classes.transparent} visible`}>
                            <GridContainer justify='flex-end' alignItems='center' className={classes.instrumentPrint}>
                                <GridItem>
                                    <Typography variant='subtitle1' className={classes.instrumentNamePrintLeft}>
                                        {name?.toUpperCase()}
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </td>
                )}
                <td colSpan={monthDuration} className={`${classes.td} tdPrint`}>
                    <CustomTooltip
                        onOpen={() => setTooltipId(id)}
                        onClose={() => setTooltipId(false)}
                        title={title}
                        placement='top'
                        interactive
                        enterTouchDelay={0}
                        leaveTouchDelay={2000}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 500 }}
                    >
                        <div style={{ marginTop: array.length === 1 ? '0.5rem' : null }}>
                            <GridContainer
                                justify='center'
                                alignItems='center'
                                className={`${classes.instrument}
                                                     ${level === 0 && !isCustom && classes.bgRed}
                                                     ${level === 1 && classes.bgYellow}
                                                     ${level === 2 && classes.bgGreen}
                                                     ${(level === 3 || isCustom) && classes.bgViolet}
                                                     ${id === tooltipId && classes.highlighted}`}
                            >
                                <GridItem className={classes.instrumentItem}>
                                    <Typography variant='subtitle1' className={`${classes.instrumentName} instrumentNamePrint`}>
                                        {name?.toUpperCase()}
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </CustomTooltip>
                    <TooltipDialog
                        open={open === rowKey || open === id + startTime}
                        close={closeModal}
                        name={name}
                        startTime={startTime}
                        endTime={endTime}
                        provider={provider}
                        isUsed={isUsed}
                        grantAmount={grantAmount}
                        projectSize={projectSize}
                        description={description}
                    />
                </td>
                {instrumentTextSide <= 0 && (
                    <td colSpan={freeColumnsFromRight} className={classes.td}>
                        <div
                            style={{ marginTop: array.length === 1 || index === 0 ? '0.5rem' : null }}
                            className={`${classes.transparent} visible`}
                        >
                            <GridContainer justify='flex-start' alignItems='center' className={classes.instrumentPrint}>
                                <GridItem>
                                    <Typography variant='subtitle1' className={classes.instrumentNamePrintRight}>
                                        {name.toUpperCase()}
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </td>
                )}
            </tr>
        );
    });
};

export default TBody;
