import { put, takeLatest, select, call } from 'redux-saga/effects';
import * as _ from 'lodash';
import {
	LOAD_INSTRUMENT_RADAR,
	SET_COMPANY_LIMIT,
	LOAD_SCENARIOS,
	LOAD_INSTRUMENTS,
	SAVE_SCENARIO,
	REMOVE_SCENARIO,
	SAVE_MAIN_SCENARIO,
} from './constants.js';
import {
	loadRadarDataError,
	loadRadarDataSuccess,
	loadInstrumentDataSuccess,
	loadInstrumentDataError,
	loadScenariosError,
	loadScenariosSuccess,
	loadScenarios,
} from './actions';
import { selectInstruments } from 'containers/InstrumentRadar/selectors';
import { getSingleCompany } from 'containers/CompanyBaseData/selectors';
import config from 'utils/config';
import { hasFlag } from 'utils/bitwiseOperations';
import axios from 'utils/axios';

export function* getInstrumentRadarData({ businessId }) {
	const requestURL = `${config.FETCH_COMPANY_API_URI}/radar/${businessId}`;

	try {
		const response = yield axios.get(requestURL);

		yield put(loadRadarDataSuccess(response.data));
	} catch (err) {
		yield put(loadRadarDataError(err));
	}
}

export function* setCompanyProfile({ company }) {
	const instruments = yield select(selectInstruments());

	let mapped = _.map(instruments, (item) => (item.points = 0));

	mapped = _.map(instruments, (instrument) => {
		_.map(instrument.limitators, (limitator) => {
			for (const [key, value] of Object.entries(company)) {
				if (key.toLowerCase() === limitator.compareProperty.toLowerCase()) {
					limitator.companyValue = parseInt(value);
					limitator.isValid = hasFlag(
						limitator.requiredValue,
						limitator.companyValue
					);

					if (limitator.isValid) {
						instrument.points++;
					} // add points value to sort if valid
				}
			}

			if (instrument.points === instrument.maxPoints) instrument.level = 2;
			else if (instrument.points >= (instrument.maxPoints - 3)) instrument.level = 1;
			else instrument.level = 0;
		});
		return instrument;
	});

	mapped = _.orderBy(mapped, ['points', 'sortOrder'], ['desc', 'desc']);

	yield put(loadInstrumentDataSuccess(mapped));
}

export function* getInstruments() {
	const requestURL = `${config.INSTRUMENT_URI}/instruments`;

	try {
		const response = yield axios.get(requestURL);

		yield put(loadInstrumentDataSuccess(response.data));
	} catch (err) {
		yield put(loadInstrumentDataError(err));
	}
}

export function* getScenarios({ businessId }) {
	const requestURL = `${config.INSTRUMENT_URI}/scenario/${businessId}`;

	try {
		const response = yield axios.get(requestURL);
		yield call(getInstruments);

		const mainScenario = response.data.find(item => item.isMainScenario);
		const initialScenario = mainScenario ? mainScenario : response.data[0];

		yield call(setCompanyProfile, { company: initialScenario });

		yield put(loadScenariosSuccess(response.data));
	} catch (err) {
		yield put(loadScenariosError(err));
	}
}

export function* saveScenario({ scenario }) {
	try {
		const company = yield select(getSingleCompany());
		scenario.businessId = company.businessId;

		const requestURL = `${config.INSTRUMENT_URI}/scenario`;

		const response = yield axios.post(requestURL, scenario);

		yield put(loadScenariosSuccess(response.data));
	} catch (error) {
		yield put(loadScenariosError(error));
	}
}

export function* removeScenario({ scenarioId }) {
	try {
		const requestURL = `${config.INSTRUMENT_URI}/scenario/id/${scenarioId}`;

		const response = yield axios.delete(requestURL);

		yield put(loadScenariosSuccess(response.data));
	} catch (error) {
		yield put(loadScenariosError(error));
	}
}

export function* saveMainScenario({ scenario }) {
	try {
		const company = yield select(getSingleCompany());
		scenario.businessId = company.businessId;

		const requestURL = `${config.INSTRUMENT_URI}/scenario/main/${company.businessId}`;

		const response = yield axios.post(requestURL, scenario); // eslint-disable-line no-unused-vars

		yield put(loadScenarios(scenario.businessId));
	} catch (error) {
		yield put(loadScenariosError(error));
	}
}

export default function* radarData() {
	yield takeLatest(LOAD_INSTRUMENT_RADAR, getInstrumentRadarData);
	yield takeLatest(SET_COMPANY_LIMIT, setCompanyProfile);
	yield takeLatest(LOAD_INSTRUMENTS, getInstruments);
	yield takeLatest(LOAD_SCENARIOS, getScenarios);
	yield takeLatest(SAVE_SCENARIO, saveScenario);
	yield takeLatest(REMOVE_SCENARIO, removeScenario);
	yield takeLatest(SAVE_MAIN_SCENARIO, saveMainScenario);
}
