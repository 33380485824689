import { setToken } from '../token';
import { getToken } from 'containers/Login/AuthProvider';

export const refreshUri = '/authentication/refresh';
export const ignoreUrls = [];

/**
 * attach token to the request Authorization header
 * You can add your own personal rules for params etc. tokens by URL
 * @param {*} request
 * @param {*} token
 */
export const attachTokenToRequest = (request, token) => {
    request.headers['Authorization'] = 'Bearer ' + token;

    // If there is an edge case where access token is also set in request query,
    // Example: /addr?token=xyz-old-token
    if (/\/addr/.test(request.url)) {
        request.params.token = token;
    }
};

/**
 * Should intercept, only 401 to refresh token
 * @param {Error object} error
 */
export const shouldIntercept = error => {
    try {
        if (ignoreUrls.includes(error.config.url)) return false;

        return error.response.status === 401;
    } catch (e) {
        return false;
    }
};

/**
 * Token data { token: {}, user: {} }
 * @param {Token onject token and user} token
 */
export const setTokenData = (token = {}) => {
    setToken(token);
};

/**
 * Handle token refresh call to the server
 */
export const handleTokenRefresh = async () => {
    return await getToken();
};