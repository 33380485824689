import React, { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
// import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { color } from 'assets/jss/grants/common/grantsBase.js';
import { getSingleCompany } from 'containers/CompanyBaseData/selectors';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { makeSelectCompanies, makeSelectLoading, makeSelectError, makeSelectedCompany } from './selectors';
import saga from './saga';
import reducer from './reducer';
import { loadCompanyData, selectCompany, clearCompanyData } from './actions';

const key = 'companySearch';
const useStyles = makeStyles(() => ({
    inputRoot: {
        padding: '1rem',
        '& .MuiInput-underline:before': {
            borderBottomColor: color.grayColor,
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: color.grayColor, // on hover
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: color.primaryColor, //  on focus
        },
    },
    inputText: {
        color: color.primaryColor,
    },
    spinner: {
        color: color.primaryColor,
    },
    endAdornment: {
        display: 'none',
    },
    noOptions: {
        display: 'none',
    },
    loading: {
        display: 'none',
    },
}));

export function CompanySearch({
    companies,
    loading,
    onChangeCompanyname,
    onSelectCompany,
    onClearCompanyData,
    variant,
    insideDrawer,
    //  currentCustomer,
    // disableUnderline,
}) {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const [open, setOpen] = React.useState(false);
    let timerID;

    const classes = useStyles();
    // const underline = disableUnderline ?? false;

    // const getPlaceholderValue = () => {
    //     if (!currentCustomer || currentCustomer.name === '') return 'Hae yritystä';

    //     return currentCustomer.name;
    // };

    const onChangeName = data => {
        if (data.target.value.length > 2) {
            clearTimeout(timerID);
            timerID = setTimeout(onChangeCompanyname, 1000, data.target.value);
        } else {
            onClearCompanyData();
        }
    };
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];

    const renderTextField = params => {
        return (
            <TextField
                variant={variant ?? 'standard'}
                placeholder='Hae yritystä' // {getPlaceholderValue()}
                {...params}
                onChange={onChangeName}
                classes={{
                    root: classes.inputRoot,
                }}
                InputProps={{
                    ...params.InputProps,
                    // disableUnderline: underline, // eslint-disable-line
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress size={20} className={classes.spinner} /> : <SearchIcon className={classes.icon} />}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        );
    };

    return (
        <Autocomplete
            // id="grants-ui-autocomplete"
            fullWidth
            disableClearable
            clearOnEscape
            open={open}
            groupBy={option => (option.isCustomer ? 'Asiakkat' : 'YTJ')}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => `${option.name} ${option.businessId}`}
            options={companies && !insideDrawer ? companies : []}
            onChange={onSelectCompany}
            classes={{
                endAdornment: classes.endAdornment,
                inputRoot: classes.inputText,
                noOptions: classes.noOptions,
                loading: classes.loading,
            }}
            renderInput={params => (
                <Grid container spacing={1} alignItems='flex-end'>
                    <Grid item xs={12}>
                        {renderTextField(params)}
                    </Grid>
                </Grid>
            )}
            renderOption={option => (
                <ListItem button key={option.key}>
                    <ListItemIcon>
                        {`${option.name} ${option.businessId}`}
                        <br />
                        {option.description}
                    </ListItemIcon>
                    <ListItemText primary={option.text} />
                </ListItem>
            )}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    companies: makeSelectCompanies(),
    selected: makeSelectedCompany(),
    loading: makeSelectLoading(),
    error: makeSelectError(),
    currentCustomer: getSingleCompany(),
});

export function mapDispatchToProps(dispatch) {
    return {
        onChangeCompanyname: search => dispatch(loadCompanyData(search)),
        onSelectCompany: (evt, selected) => {
            dispatch(selectCompany(selected));
        },
        onClearCompanyData: () => dispatch(clearCompanyData()),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(CompanySearch);
