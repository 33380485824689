import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InstrumentModal from './InstrumentModal';
import ConfirmDialog from 'components/ConfirmDialog';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import { loadInstruments, saveInstrument, openInstrumentModal, closeInstrumentModal, publishSelected } from './actions';
import { initialLimitators } from './limitators';
import { gridTranslations } from '../../translations/gridTranslations';
import FilterInput from './FilterInput';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    container: {
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
    tableTitle: {
        fontSize: '2rem',
        fontWeight: 700,
    },
    editButton: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.white,
    },
    dataGrid: {
        // TODO: same for enEn
        '& .row-bg--true': {
            backgroundColor: theme.palette.primary.secondary,
        },
    },
    popper: {
        top: '1.5rem !important',
    },
    tooltip: {
        padding: '0.5rem',
        border: '1px solid #ccc',
        background: 'rgba(255, 255, 255, 0.9)',
        color: theme.palette.primary.main,
        fontSize: '1rem',
    },
    silentButton: {
        marginRight: '1rem'
    }
}));

const key = 'instruments';

const InstrumentsEditPage = () => {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();

    const instruments = useSelector(state => state.instruments);
    const instrumentsData = instruments?.instruments;
    const instrumentsLoading = instruments?.loading;

    const isModalOpen = instruments?.modalOpen ?? false;
    const saveLoading = instruments?.loadingInstrument;

    const [editedInstrument, setEditedInstrument] = useState({ limitators: initialLimitators });
    const [modalTitle, setModalTitle] = useState('');
    const [openConfirm, setOpenConfirm] = useState(false);
    const [data, setData] = useState({});
    const [selected, setSelected] = useState({});
    const [openPublishSelectedSilentConfirm, setOpenPublishSelectedSilentConfirm] = useState(false);
    const [openPublishSelectedConfirm, setOpenPublishSelectedConfirm] = useState(false);

    useEffect(() => {
        if (!instruments) {
            dispatch(loadInstruments());
        }
    }, [instruments, dispatch]);

    const openModal = title => {
        setModalTitle(title);
        dispatch(openInstrumentModal());
    };

    const closeModal = () => {
        dispatch(closeInstrumentModal());
        setModalTitle('');
        setEditedInstrument({ limitators: initialLimitators });
    };

    const handleEdit = row => {
        const instrument = instrumentsData.reduce((acc, item) => {
            return item.id === row.id ? item : acc;
        }, null);
        setEditedInstrument(instrument);
        openModal('edit');
    };

    const handleCopy = row => {
        const instrument = instrumentsData.reduce((acc, item) => {
            return item.id === row.id ? item : acc;
        }, null);

        const instrumentWithoutId = Object.keys(instrument).reduce((object, key) => {
            if (key !== 'id') {
                object[key] = instrument[key];
            }
            if (key === 'name') {
                object[key] = `[KOPIO] ${instrument[key]}`;
            }
            return object;
        }, {});

        setEditedInstrument(instrumentWithoutId);
        openModal('copy');
    };

    const onSubmit = (data, isDraft) => {
        if (Array.isArray(data.projectAim)) {
            if (data.projectAim.length > 0) {
                data.projectAim = data.projectAim.join(';');
            } else {
                data.projectAim = '';
            }
        }

        if (Array.isArray(data.projectObjects)) {
            if (data.projectObjects.length > 0) {
                data.projectObjects = data.projectObjects.join(';');
            } else {
                data.projectObjects = '';
            }
        }

        if (Array.isArray(data.projectValue)) {
            if (data.projectValue.length > 0) {
                data.projectValue = data.projectValue.join(';');
            } else {
                data.projectValue = '';
            }
        }

        if (isDraft) {
            dispatch(saveInstrument(data, isDraft));
        } else {
            setData(data);
            setOpenConfirm(true);
        }

        setEditedInstrument({ limitators: initialLimitators });
    };

    const publish = () => {
        dispatch(saveInstrument(data, false));
        setData({});
    };

    const columns = [
        {
            field: 'editing',
            headerName: intl.formatMessage({ id: 'instruments.edit.actions' }),
            width: 120,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: ({ row }) => (
                <Button
                    className={classes.editButton}
                    onClick={() => handleEdit(row)}
                    variant='contained'
                    size='small'
                //startIcon={<EditIcon />}
                >
                    {intl.formatMessage({ id: 'common.edit' })}
                </Button>
            ),
        },
        {
            field: 'copy',
            headerName: intl.formatMessage({ id: 'instruments.edit.actions' }),
            width: 80,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: ({ row }) => (
                <IconButton aria-label='copy' onClick={() => handleCopy(row)} color='primary'>
                    <FileCopyIcon />
                </IconButton>
            ),
        },
        {
            field: 'isDraft',
            headerName: intl.formatMessage({ id: 'instruments.edit.status' }),
            width: 100,
            renderCell: params => (
                <Tooltip title={params.row.isDraft} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.isDraft}</span>
                </Tooltip>
            ),
        },
        {
            field: 'id',
            headerName: intl.formatMessage({ id: 'instruments.edit.id' }),
            width: 150, // flex: 1,
            hide: true,
            renderCell: params => (
                <Tooltip title={params.id} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.id}</span>
                </Tooltip>
            ),
        },
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'instruments.edit.name' }),
            width: 200,
            renderCell: params => (
                <Tooltip title={params.row.name} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.name}</span>
                </Tooltip>
            ),
        },
        {
            field: 'provider',
            headerName: intl.formatMessage({ id: 'instruments.edit.provider' }),
            width: 150,
            renderCell: params => (
                <Tooltip title={params.row.provider} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.provider}</span>
                </Tooltip>
            ),
        },
        {
            field: 'type',
            headerName: intl.formatMessage({ id: 'instruments.edit.type' }),
            width: 100,
            renderCell: params => (
                <Tooltip title={params.row.type} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.type}</span>
                </Tooltip>
            ),
        },
        {
            field: 'projectAimArr',
            headerName: intl.formatMessage({ id: 'instruments.edit.projectAims' }),
            width: 300,
            renderCell: params => (
                <Tooltip title={params.row.projectAimArr} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.projectAimArr}</span>
                </Tooltip>
            ),
        },
        {
            field: 'projectObjectsArr',
            headerName: intl.formatMessage({ id: 'instruments.edit.projectObjects' }),
            width: 300,
            renderCell: params => (
                <Tooltip
                    title={params.row.projectObjectsArr}
                    placement='top'
                    classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                >
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.row.projectObjectsArr}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'projectValueArr',
            headerName: intl.formatMessage({ id: 'instruments.edit.projectValues' }),
            width: 300,
            renderCell: params => (
                <Tooltip title={params.row.projectValueArr} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.projectValueArr}</span>
                </Tooltip>
            ),
        },
    ];

    const rows = instrumentsData
        ?.slice()
        ?.sort(({ isDraft }) => (isDraft ? -1 : 1))
        ?.map(({ id, name, provider, type, projectAimArr, projectObjectsArr, projectValueArr, isDraft }) => {
            return {
                id,
                name,
                provider,
                type: intl.formatMessage({ id: `instruments.edit.check.item.${type}` }),
                projectAimArr: projectAimArr.join(', '),
                projectObjectsArr: projectObjectsArr.join(', '),
                projectValueArr: projectValueArr.join(', '),
                isDraft: intl.formatMessage({ id: `instruments.edit.draft.${isDraft}` }),
                draft: isDraft
            };
        });

    const providers = instrumentsData?.map(({ provider }) => provider);
    const projectAims = instrumentsData?.map(({ projectAimArr }) => projectAimArr);
    const projectObjects = instrumentsData?.map(({ projectObjectsArr }) => projectObjectsArr);
    const projectValues = instrumentsData?.map(({ projectValueArr }) => projectValueArr);

    const uniqueProviders = providers ? [...new Set(providers)] : [];
    const uniqueProjectAims = projectAims ? [...new Set([].concat(...projectAims))] : [];
    const uniqueProjectObjects = projectObjects ? [...new Set([].concat(...projectObjects))] : [];
    const uniqueProjectValues = projectValues ? [...new Set([].concat(...projectValues))] : [];

    const [phrase, setPhrase] = useState('');
    const handleChange = event => setPhrase(event.target.value);

    const items = [
        {
            columnField: 'name',
            operatorValue: 'contains',
            value: phrase,
        },
    ];

    const publishSelectedInstruments = (silent = true) => {
        if (selected && selected.length > 0) {
            dispatch(publishSelected(silent, selected));
            setSelected([]);
        }
    };

    return (
        <Box className={classes.root}>
            <GridContainer className={classes.container}>
                <GridItem xs={8}>
                    <Typography variant='h1' color='primary' className={classes.tableTitle}>
                        {intl.formatMessage({ id: 'fundinginfo.title' })}
                    </Typography>
                </GridItem>
                {!instrumentsLoading && rows && rows.length !== 0 && (
                    <GridItem xs={4}>
                        <FilterInput value={phrase} onChange={handleChange} />
                    </GridItem>
                )}
            </GridContainer>

            {instrumentsLoading ? (
                <CircularProgress size={24} />
            ) : !instrumentsLoading && rows && rows.length !== 0 ? (
                <GridContainer spacing={2}>
                    <GridItem>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            autoHeight
                            hideFooter={true}
                            localeText={gridTranslations(intl)}
                            filterModel={{ items }}
                            getRowClassName={params => `row-bg--${params.row.draft}`}
                            checkboxSelection
                            isRowSelectable={(params) => params.row.draft === true}
                            onSelectionModelChange={(e) => setSelected(e)}
                            className={classes.dataGrid}
                        />
                    </GridItem>
                    {selected && selected.length > 0 ?
                        <GridItem>
                            <Box textAlign='end'>

                                <Button variant='contained' color='primary' endIcon={<VolumeOffIcon />} onClick={() => setOpenPublishSelectedSilentConfirm(true)} className={classes.silentButton}>
                                    {intl.formatMessage({ id: 'instruments.edit.publish.silent.button' })}
                                </Button>
                                <Button variant='contained' color='primary' endIcon={<VolumeUpIcon />} onClick={() => setOpenPublishSelectedConfirm(true)}>
                                    {intl.formatMessage({ id: 'instruments.edit.publish.button' })}
                                </Button>

                                <ConfirmDialog
                                    title={intl.formatMessage({ id: 'instrument.confirm.publish.selected.silent.title' })}
                                    open={openPublishSelectedSilentConfirm}
                                    setOpen={setOpenPublishSelectedSilentConfirm}
                                    onConfirm={() => publishSelectedInstruments(true)}
                                    buttonOkId='instrument.confirm.publish.button'
                                    titleId='instrument.confirm.publish.selected.silent.title'
                                >
                                    <p>{intl.formatMessage({ id: 'instrument.confirm.publish.selected.silent.body' })}</p>
                                </ConfirmDialog>

                                <ConfirmDialog
                                    title={intl.formatMessage({ id: 'instrument.confirm.publish.selected.title' })}
                                    open={openPublishSelectedConfirm}
                                    setOpen={setOpenPublishSelectedConfirm}
                                    onConfirm={() => publishSelectedInstruments(false)}
                                    buttonOkId='instrument.confirm.publish.button'
                                    titleId='instrument.confirm.publish.selected.title'
                                >
                                    <p>{intl.formatMessage({ id: 'instrument.confirm.publish.selected.body' })}</p>
                                </ConfirmDialog>
                            </Box>

                        </GridItem>
                        : null}
                    <GridItem>
                        <Box textAlign='end'>
                            <Button variant='contained' color='primary' endIcon={<AddIcon />} onClick={() => openModal('add')}>
                                {intl.formatMessage({ id: 'instruments.edit.add.button' })}
                            </Button>
                        </Box>
                    </GridItem>
                </GridContainer>
            ) : (
                <GridContainer spacing={2}>
                    <GridItem>
                        <Box textAlign='center'>
                            <Typography component='p' color='primary'>
                                {intl.formatMessage({ id: 'instruments.edit.no.instruments' })}
                            </Typography>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Box textAlign='end'>
                            <Button variant='contained' color='primary' endIcon={<AddIcon />} onClick={() => openModal('add')}>
                                {intl.formatMessage({ id: 'instruments.edit.add.button' })}
                            </Button>
                        </Box>
                    </GridItem>
                </GridContainer>
            )}
            <InstrumentModal
                open={isModalOpen}
                close={closeModal}
                instrument={editedInstrument}
                title={modalTitle}
                onSubmit={onSubmit}
                saveLoading={saveLoading}
                providers={uniqueProviders}
                projectAims={uniqueProjectAims}
                projectObjects={uniqueProjectObjects}
                projectValues={uniqueProjectValues}
            />
            <ConfirmDialog
                title={intl.formatMessage({ id: 'timeline.saveTimeline' })}
                open={openConfirm}
                setOpen={setOpenConfirm}
                onConfirm={publish}
                buttonOkId='instrument.confirm.save'
                titleId='instrument.confirm.save'
            >
                <p>{intl.formatMessage({ id: 'instrument.confirm.title' })}</p>
            </ConfirmDialog>
        </Box>
    );
};

export default InstrumentsEditPage;