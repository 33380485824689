import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
    },
    past: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.primary.white,
        opacity: 0.5,
        pointerEvents: 'none',
    },
    line: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        borderLeft: '1px dashed',
        borderColor: theme.palette.primary.main,
        zIndex: 20,
    },
    circle: {
        position: 'absolute',
        bottom: 0,
        borderRadius: '50%',
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        marginLeft: '-5px',
        backgroundColor: theme.palette.primary.main,
        zIndex: 20,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    tooltip: {
        position: 'absolute',
        bottom: theme.spacing(2),
        marginLeft: theme.spacing(-4),
    },
    now: {
        fontSize: '0.75rem',
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
}));

const PastNowDiv = ({ column, padding, startTime }) => {
    const classes = useStyles();
    const intl = useIntl();

    const monthsFromStartToNow = moment(new Date()).diff(new Date(startTime), 'months', true);

    const [isHover, setIsHover] = useState(false);
    const toggleHover = () => setIsHover(prevState => !prevState);

    return (
        <tr className={classes.container}>
            <td
                style={{
                    width: `${column * monthsFromStartToNow + padding}px`,
                }}
                className={classes.past}
            />
            <td
                style={{
                    left: `${column * monthsFromStartToNow + padding}px`,
                }}
                className={classes.line}
            />
            <td
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
                style={{
                    left: `${column * monthsFromStartToNow + padding}px`,
                }}
                className={classes.circle}
            />
            {isHover && (
                <td
                    style={{
                        left: `${column * monthsFromStartToNow + padding}px`,
                    }}
                    className={classes.tooltip}
                >
                    <Typography component='p' className={classes.now}>
                        {intl.formatMessage({ id: 'timeline.present.moment' }).toUpperCase()}
                    </Typography>
                </td>
            )}
        </tr>
    );
};

export default PastNowDiv;
