import React from 'react';
import { useFieldArray, Controller, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import AddButton from 'components/GrantsComponents/AddButton';
import RemoveLineButton from 'components/GrantsComponents/RemoveLineButton';
import WorkPackage from './NestedArrays/WorkPackage';

import Accordion from 'components/Accordion.js';

import { workpackage } from './messages';

const WorkPackageList = (props) => {
  const { data, control, onRemove } = props;
  const intl = useIntl();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'workPackage',
  });

  const [expanded, setExpanded] = React.useState('');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const watchTasks = useWatch({ name: 'workPackage', control });

  const getTitle = (index) => {
    try {
      return watchTasks[index].name;
    } catch (error) {
      return '';
    }
  };

  const renderTable = () => {
    return fields.map((item, index) => {
      item.removed = item.removed ?? false;

      return (
        <Accordion
          prefix={intl.formatMessage(workpackage.title)}
          key={item.id}
          expanded={expanded === `${item.id}`}
          handleChange={handleChange(`${item.id}`)}
          title={getTitle(index)}
        >
          <GridContainer>
            <Controller
              name={`workPackage[${index}].rowKey`}
              control={control}
              defaultValue={item.rowKey}
              render={() => <input type="hidden" value={item.rowKey} />}
            />
            <GridItem xs={12}>
              <WorkPackage index={index} control={control} item={item} />
            </GridItem>
            <GridItem xs={12}>
              {/* {item.rowKey !== undefined ? (
                <React.Fragment>
                  <Controller
                    as={<Hidden style={{ display: "none" }} />}
                    name={`workPackage[${index}].removed`}
                    control={control}
                    defaultValue={item.removed}
                  />

                  <Checkbox
                    onChange={(e) => {
                      fields[index].removed = e.target.checked;
                      item.removed = e.target.checked;
                      setValue(
                        `workPackage[${index}].removed`,
                        e.target.checked,
                        {
                          shouldValidate: true,
                          shouldDirty: true,
                        }
                      );
                    }}
                    icon={<DeleteForeverOutlined />}
                    checkedIcon={<DeleteIcon />}
                  />
                </React.Fragment>
              ) : ( */}
              <RemoveLineButton
                icon={false}
                onClick={() => {
                  remove(index);
                  onRemove(item);
                }}
              />
              {/* )} */}
            </GridItem>
          </GridContainer>
        </Accordion>
      );
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>{renderTable(data)}</GridItem>
      <GridItem xs={12}>
        <AddButton
          onClick={() => append({ show: true })}
          text={intl.formatMessage(workpackage.addPackage)}
        />
      </GridItem>
    </GridContainer>
  );
};

export default WorkPackageList;
