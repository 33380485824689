import {LOGIN_USER, LOGIN_USER_SUCCESS, LOGIN_USER_ERROR, GET_TOKEN } from './constants';

export function loginUser(token) {
  return {
    type: LOGIN_USER,
    token,
  };
}

export function loginUserSuccess(token) {
  return {
    type: LOGIN_USER_SUCCESS,
    token,
  };
}

export function loginUserError(error) {
  return {
    type: LOGIN_USER_ERROR,
    error,
  };
}

export function getToken() { 
  return {
    type: GET_TOKEN,
  };
}