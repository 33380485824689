import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';
import FormSpaceHeader from 'components/FormSpaceHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import { registers, source } from 'static/enumerations';

const CompanyRegisteredEntries = (props) => {
  const { data, lang, messages } = props;
  const intl = useIntl();

  if (!data) return null;

  const renderTable = (data) => (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" aria-label="Registration table">
        <TableHead>
          <TableRow>
            <TableCell>
              {intl.formatMessage(messages.formdata.source)}
            </TableCell>
            <TableCell>{intl.formatMessage(messages.formdata.name)}</TableCell>
            <TableCell>
              {intl.formatMessage(messages.formdata.registered)}
            </TableCell>
            <TableCell>
              {intl.formatMessage(messages.formdata.updated)}
            </TableCell>
            <TableCell>
              {intl.formatMessage(messages.formdata.enddate)}
            </TableCell>
            <TableCell>
              {intl.formatMessage(messages.formdata.description)}: YTJ
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            if (row && row.language !== lang) return <React.Fragment key={row + index}></React.Fragment>;

            return (
              <TableRow key={row + index}>
                <TableCell>{source[row.authority]}</TableCell>
                <TableCell>{registers[row.register]}</TableCell>
                <TableCell>{row.registrationDate}</TableCell>
                <TableCell>{row.statusDate}</TableCell>
                <TableCell>{row.endDate}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <GridContainer>
      <GridItem xs={12}>
        <FormSpaceHeader
          header={intl.formatMessage(messages.registeredEntriesData.title)}
          anchor={intl.formatMessage(messages.formdata.description)}
        />
        {renderTable(data)}
      </GridItem>
    </GridContainer>
  );
};

export default CompanyRegisteredEntries;
