import React from 'react';
import { useIntl } from 'react-intl';
import { ResponsiveContainer, Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, Tooltip } from 'recharts';
import { useTheme } from '@material-ui/core/styles';

import { getHighestIntegerValue, getLowestIntegerValue } from 'utils/bitwiseOperations';
import CustomTooltip from './CustomTooltip';

const InstrumentRadar = ({ name, data, companyName, height }) => {
    const theme = useTheme();
    const intl = useIntl();

    const radar =
        data && data.length
            ? data.map(item => ({
                  ...item,
                  requiredHighInt: getHighestIntegerValue(item.requiredValue),
                  companyHighInt: getHighestIntegerValue(item.companyValue),
                  requiredLowInt: getLowestIntegerValue(item.requiredValue),
              }))
            : {
                  ...data,
                  requiredHighInt: getHighestIntegerValue(data.requiredValue),
                  companyHighInt: getHighestIntegerValue(data.companyValue),
                  requiredLowInt: getLowestIntegerValue(data.requiredValue),
              };

    const translatedValue = key => {
        const val = intl.formatMessage({ id: key });

        return val === key ? 'undefined' : val;
    };

    return (
        <ResponsiveContainer width='100%' height={height}>
            <RadarChart data={radar}>
                <PolarGrid gridType='circle' />
                <PolarAngleAxis dataKey='compareProperty' tickFormatter={() => ''} />
                <PolarRadiusAxis angle={90} domain={[0, 5]} tick={true} tickCount={6} />
                <Tooltip content={<CustomTooltip translatedValue={translatedValue} />} />
                <Radar
                    name={name}
                    dataKey='requiredLowInt'
                    stroke={theme.palette.success.main}
                    strokeWidth={2}
                    fill={theme.palette.success.main}
                    fillOpacity={0.3}
                />
                <Radar
                    name={companyName ?? translatedValue('instrument.radar.tooltip.compareValue')}
                    dataKey='companyHighInt'
                    stroke={theme.palette.primary.main}
                    fill={theme.palette.primary.main}
                    fillOpacity={0.3}
                    strokeWidth={2}
                />
                <Legend />
            </RadarChart>
        </ResponsiveContainer>
    );
};

export default InstrumentRadar;
