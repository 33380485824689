
const calculationTablesStyles = {
  calculationTableItem: {
    paddingBottom: '1.5rem'
  },
  isCurrentYearColumn: {
    backgroundColor: '#aaaaaa',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    //marginLeft: theme.spacing(1),
    //marginRight: theme.spacing(1),
  },
  inputRoot: {
    '&$disabled': {
      color: 'black',
    },
  },
  disabled: { color: 'black' },
  gridItem: {
    width: '20%',
    maxWidth: '20%',
  },
};

export default calculationTablesStyles;
