import React from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import FormSpaceHeader from 'components/FormSpaceHeader';

import styles from 'assets/jss/grants/content';

const useStyles = makeStyles(styles);

export default () => {
  const classes = useStyles();

  return (
    <GridContainer
      spacing={6}
      direction="row"
      justify="space-evenly"
      alignItems="stretch"
    >
      <GridItem xs={12} sm={4}>
        <Paper elevation={3} className={classes.padding}>
          <FormSpaceHeader header="404" />
          <p>
            Not found
          </p>
        </Paper>
      </GridItem>
    </GridContainer>
  );
};