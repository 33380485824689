import { LOAD_CUSTOMERS, LOAD_CUSTOMERS_ERROR, LOAD_CUSTOMERS_SUCCESS } from './constants.js';

export const loadCustomers = () => {
    return {
        type: LOAD_CUSTOMERS,
    };
};

export const loadCustomersError = error => {
    return {
        type: LOAD_CUSTOMERS_ERROR,
        error,
    };
};

export const loadCustomersSuccess = customers => {
    return {
        type: LOAD_CUSTOMERS_SUCCESS,
        customers,
    };
};
