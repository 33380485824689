import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useForm } from 'react-hook-form';

import EventList from './EventList';

import Button from 'components/CustomButtons/Button';

const FinanceData = (props) => {
  const { events, pristine, submitting, onSubmit } = props;
  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      events,
    },
  });

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <GridItem>
        <EventList
          events={events}
          control={control}
          register={register}
          {...props}
          setValue={setValue}
        />

        <GridContainer
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          style={{ paddingTop: '3rem' }}
        >
          <Button round="true" disabled={pristine || submitting}>Peruuta</Button>

          <Button round="true" color="primary" disabled={submitting} type="submit">
            Tallenna
          </Button>
        </GridContainer>
      </GridItem>
    </form>
  );
};

export default FinanceData;
