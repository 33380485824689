export const LOAD_TIMELINE = 'Grants/timeline/LOAD_TIMELINE';
export const LOAD_TIMELINE_SUCCESS = 'Grants/timeline/LOAD_TIMELINE_SUCCESS';
export const LOAD_TIMELINE_ERROR = 'Grants/timeline/LOAD_TIMELINE_ERROR';
export const ADD_INSTRUMENT = 'Grants/timeline/ADD_INSTRUMENT';
export const SAVE_TIMELINE = 'Grants/timeline/SAVE_TIMELINE';
export const SAVE_TIMELINE_INSTRUMENT = 'Grants/timeline/SAVE_TIMELINE_INSTRUMENT';
export const SAVE_TIMELINE_INSTRUMENT_SUCCESS = 'Grants/timeline/SAVE_TIMELINE_INSTRUMENT_SUCCESS';
export const SAVE_TIMELINE_INSTRUMENT_ERROR = 'Grants/timeline/SAVE_TIMELINE_INSTRUMENT_ERROR';
export const DELETE_TIMELINE_INSTRUMENT = 'Grants/timeline/DELETE_TIMELINE_INSTRUMENT';
export const DELETE_TIMELINE_INSTRUMENT_SUCCESS = 'Grants/timeline/DELETE_TIMELINE_INSTRUMENT_SUCCESS';
export const OPEN_INSTRUMENT_MODAL = 'grants/timeline/OPEN_INSTRUMENT_MODAL';
export const CLOSE_INSTRUMENT_MODAL = 'Grants/timeline/CLOSE_INSTRUMENT_MODAL';

export const ADD_INSTRUMENT_TO_TIMELINE = 'Grants/timeline/ADD_INSTRUMENT_TO_TIMELINE';
export const ADD_INSTRUMENT_TO_TIMELINE_ERROR = 'Grants/timeline/ADD_INSTRUMENT_TO_TIMELINE_ERROR';
export const ADD_INSTRUMENT_TO_TIMELINE_SUCCESS = 'Grants/timeline/ADD_INSTRUMENT_TO_TIMELINE_SUCCESS';

export const EDIT_INSTRUMENT_IN_TIMELINE = 'Grants/timeline/EDIT_INSTRUMENT_IN_TIMELINE';
export const EDIT_INSTRUMENT_IN_TIMELINE_ERROR = 'Grants/timeline/EDIT_INSTRUMENT_IN_TIMELINE_ERROR';
export const EDIT_INSTRUMENT_IN_TIMELINE_SUCCESS = 'Grants/timeline/EDIT_INSTRUMENT_IN_TIMELINE_SUCCESS';

export const DELETE_INSTRUMENT_FROM_TIMELINE = 'Grants/timeline/DELETE_INSTRUMENT_FROM_TIMELINE';
export const DELETE_INSTRUMENT_FROM_TIMELINE_ERROR = 'Grants/timeline/DELETE_INSTRUMENT_FROM_TIMELINE_ERROR';
export const DELETE_INSTRUMENT_FROM_TIMELINE_SUCCESS = 'Grants/timeline/DELETE_INSTRUMENT_FROM_TIMELINE_SUCCESS';

export const SCROLL_TO_TIMELINE = 'Grants/timeline/SCROLL_TO_TIMELINE';
export const RESET_IS_SCROLLING = 'Grants/timeline/RESET_IS_SCROLLING';

export const RESET_TIMELINE = 'Grants/timeline/RESET_TIMELINE';
