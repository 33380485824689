import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'sticky',
        width: '100%',
        bottom: 0,
        right: 0,
        textAlign: 'right',
        boxShadow: ' rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',
        padding: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.secondary,
        zIndex: 10000,
    },
}));

const FloatingButton = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.root}>{children}</div>;
};

export default FloatingButton;
