import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import StyledMenu from '../StyledMenu';
import { color } from 'assets/jss/grants/common/grantsBase.js';

const useStyles = makeStyles(() => ({
    menuItem: {
        '& .MuiListItemText-root': {
            color: color.primaryColor,
        },
        '&:hover': {
            backgroundColor: color.primaryColor,
            '& .MuiListItemText-root, & .MuiListItemText-secondary': {
                color: color.whiteColor,
            },
        },
    },
}));

const DropdownHistory = props => {
    const { history, onClose } = props;
    const classes = useStyles();

    const getDate = time => {
        return new Date(time).toLocaleString();
    };

    return (
        <StyledMenu {...props}>
            {history?.map(({ company, time }) =>
                company.id && company.name ? (
                    <MenuItem key={time} onClick={() => onClose(company.id)} className={classes.menuItem}>
                        <ListItemText
                            primary={
                                <Typography className={classes.primary}>
                                    {company?.name}: {company?.id}
                                </Typography>
                            }
                            secondary={getDate(time)}
                        />
                    </MenuItem>
                ) : null
            )}
        </StyledMenu>
    );
};

export default DropdownHistory;
