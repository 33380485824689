import {
    LOAD_BUSINESS_PLAN,
    LOAD_BUSINESS_PLAN_ERROR,
    LOAD_BUSINESS_PLAN_SUCCESS,
    SAVE_BUSINESS_PLAN,
  } from './constants';
  
  export function loadBusinessPlanData(businessId) {
    return {
      type: LOAD_BUSINESS_PLAN,
      businessId,
    };
  }
  
  export function singleBusinessPlanDataLoaded(businessPlan) {
    return {
      type: LOAD_BUSINESS_PLAN_SUCCESS,
      businessPlan,
    };
  }
  
  export function loadBusinessPlanDataError(error) {
    return {
      type: LOAD_BUSINESS_PLAN_ERROR,
      error,
    };
  }
  
  export function saveBusinessPlanData(businessPlan) {
    return {
      type: SAVE_BUSINESS_PLAN,
      businessPlan
    };
  }