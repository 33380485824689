export const LOAD_INSTRUMENTS = 'Grants/instruments/LOAD_INSTRUMENTS';
export const LOAD_INSTRUMENTS_SUCCESS = 'Grants/instruments/LOAD_INSTRUMENTS_SUCCESS';
export const LOAD_INSTRUMENTS_ERROR = 'Grants/instruments/LOAD_INSTRUMENTS_ERROR';

export const SAVE_INSTRUMENT = 'Grants/instruments/SAVE_INSTRUMENT';
export const SAVE_INSTRUMENT_FAIL = 'Grants/instruments/SAVE_INSTRUMENT_FAIL';
export const SAVE_INSTRUMENT_SUCCESS = 'Grants/instruments/SAVE_INSTRUMENT_SUCCESS';

export const OPEN_INSTRUMENT_MODAL = 'Grants/instruments/OPEN_INSTRUMENT_MODAL';
export const CLOSE_INSTRUMENT_MODAL = 'Grants/instruments/CLOSE_INSTRUMENT_MODAL';
export const PUBLISH_SELECTED = 'Grants/instruments/PUBLISH_SELECTED';
