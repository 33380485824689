import React from 'react';
import { FormattedMessage } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';

import Button from 'components/CustomButtons/Button.js';

const ConfirmDialog = props => {
    const { titleId, children, open, setOpen, onConfirm, buttonOkId, title, okMessage } = props;

    const idOk = buttonOkId ?? 'shared.remove';
    const idTitle = titleId ?? 'confirm.title';

    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='confirm-dialog'>
            <DialogTitle id='confirm-dialog' className='class'>
                {title ? title : <FormattedMessage id={idTitle} />}
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button round variant='contained' onClick={() => setOpen(false)} color='secondary'>
                    <FormattedMessage id='shared.cancel' />
                </Button>
                <Button
                    round
                    variant='contained'
                    // startIcon={<DeleteIcon />}
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    color='primary'
                >
                    {okMessage ? okMessage : <FormattedMessage id={idOk} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
