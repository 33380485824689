import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components/Grid';

const useStyles = makeStyles(theme => ({
    name: {
        fontWeight: 600,
    },
    dialogName: {
        fontSize: '1.2rem',
        fontWeight: 600,
        paddingRight: theme.spacing(1),
        color: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.4rem',
        },
    },
}));

const filter = createFilterOptions({
    stringify: option => option.name + option.provider,
});

const InstrumentAutocomplete = ({ instrument, instruments, setNewInstrument, onChange }) => {
    const classes = useStyles();

    const renderOption = ({ name, provider }) =>
        provider ? (
            <GridContainer spacing={1}>
                <GridItem>
                    <Typography className={classes.name}>{name}</Typography>
                </GridItem>
                <GridItem>
                    <Typography>{provider}</Typography>
                </GridItem>
            </GridContainer>
        ) : (
            <Typography className={classes.name}>{name}</Typography>
        );

    const getOptionLabel = option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        if (!option) return '';

        // Regular option
        return option.provider ? `${option.name} - ${option.provider}` : option.name;
    };

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                name: `Lisää "${params.inputValue}"`,
            });
        }
        return filtered;
    };

    const handleChange = newValue => {
        setNewInstrument({
            ...instrument,
            isCustom: newValue.id ? false : true,
            name: newValue.name,
            type: newValue.type,
            provider: newValue.provider,
            id: newValue.id,
        });

        // Create a new value from the user input
        if (newValue && newValue.inputValue) {
            return newValue?.inputValue ?? '';
        } else if (newValue && typeof newValue === 'string') {
            return newValue ?? '';
        } else {
            return newValue?.name ?? '';
        }
    };

    return (
        <Autocomplete
            value={instrument}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            renderOption={renderOption}
            freeSolo
            disableClearable
            onChange={(_, data) => {
                onChange(handleChange(data));
            }}
            options={instruments}
            renderInput={params => (
                <TextField
                    {...params}
                    label='Instumentin nimi'
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                    }}
                />
            )}
        />
    );
};

export default InstrumentAutocomplete;
