import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';
import FormSpaceHeader from 'components/FormSpaceHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import { changeTypes, source } from 'static/enumerations';

const CompanyBusinessIdChanges = (props) => {
  const { data, messages } = props;
  const intl = useIntl();

  if (!data) return null;

  const renderTable = (data) => {
    return (
      <TableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="Business changes table">
          <TableHead>
            <TableRow>
              <TableCell>
                {intl.formatMessage(messages.formdata.source)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(messages.formdata.type)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(messages.businesschangesData.oldbusinessid)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(messages.businesschangesData.newbusinessid)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(messages.formdata.date)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row + index}>
                <TableCell>{source[row.source]}</TableCell>
                <TableCell>{changeTypes[row.change]}</TableCell>
                <TableCell>{row.oldBusinessId}</TableCell>
                <TableCell>{row.newBusinessId}</TableCell>
                <TableCell>{row.changeDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <FormSpaceHeader
          header={intl.formatMessage(messages.businesschangesData.title)}
          anchor="form_business_changes"
        />
        {renderTable(data)}
      </GridItem>
    </GridContainer>
  );
};

export default CompanyBusinessIdChanges;
