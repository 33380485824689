import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components/Grid';
// import SelectList from './SelectList';
import ChipList from './ChipList';

const useStyles = makeStyles(theme => ({
    accordion: {
        boxShadow: 'none',
        backgroundColor: 'inherit',
    },
    accordionSummary: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary.grey,
    },
    title: {
        fontSize: '1.4rem',
        fontWeight: 700,
    },
    filter: {
        color: theme.palette.textPrimary.main,
        fontWeight: 600,
    },
    icon: {
        color: theme.palette.primary.main,
    },
}));

const SuitableInstruments = ({
    uniqueTypes,
    dublicateTypes,
    uniqueProviders,
    dublicateProviders,
    filterList,
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => setExpanded(prevState => !prevState);

    return (
        <Accordion
            square
            expanded={expanded}
            onChange={handleChange}
            className={classes.accordion}
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary
                aria-controls='suitable instruments'
                expandIcon={
                    expanded ? (
                        <RemoveIcon className={classes.icon} />
                    ) : (
                        <AddIcon className={classes.icon} />
                    )
                }
                className={classes.accordionSummary}
            >
                <GridContainer justify='space-between' alignItems='center'>
                    <GridItem>
                        {/* <Typography component='p' color='primary' className={classes.title}>
                            {intl.formatMessage({ id: 'fundinginfo.title' })}
                        </Typography> */}
                    </GridItem>
                    <GridItem>
                        <Typography component='p' className={classes.filter}>
                            {intl.formatMessage({ id: 'suitable.instrument.panel.filter' })}
                        </Typography>
                    </GridItem>
                </GridContainer>
            </AccordionSummary>
            <AccordionDetails>
                <GridContainer justify='space-around' alignItems='center'>
                    <GridItem xs={12}>
                        <GridContainer alignItems='center'>
                            <ChipList label='types' uniqueItems={uniqueTypes} dublicateItems={dublicateTypes} filterList={filterList} />
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                        <GridContainer alignItems='center'>
                            <ChipList
                                label='providers'
                                uniqueItems={uniqueProviders}
                                dublicateItems={dublicateProviders}
                                filterList={filterList}
                            />
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </AccordionDetails>
        </Accordion>
    );
};

export default SuitableInstruments;
