import {
    GET_GRANTED_SCORE,
    GET_GRANTED_SCORE_SUCCESS,
    GET_GRANTED_SCORE_ERROR,
    GET_SCENARIO_GRANTED_SCORE
} from './constants.js';

export const getGrantedScore = () => {
    return {
        type: GET_GRANTED_SCORE,
    };
};
export const getGrantedScoreSuccess = score => {
    return {
        type: GET_GRANTED_SCORE_SUCCESS,
        score
    };
};
export const getGrantedScoreError = () => {
    return {
        type: GET_GRANTED_SCORE_ERROR,
    };
};

export const getScenarioGrantedScore = scenario => {
    return {
        type: GET_SCENARIO_GRANTED_SCORE,
        scenario: scenario
    };
};