/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectCompanySearch = state => state.companySearch || initialState;

const makeSelectCompanies = () =>
  createSelector(
    selectCompanySearch,
    companySearchState => companySearchState.companies,
  );

const makeSelectLoading = () =>
  createSelector(
    selectCompanySearch,
    companySearchState => companySearchState.loading,
  );

const makeSelectError = () =>
  createSelector(
    selectCompanySearch,
    companySearchState => companySearchState.error,
  );

const makeSelectedCompany = () =>
  createSelector(
    selectCompanySearch,
    companySearchState => companySearchState.selected,
  );

export {
  selectCompanySearch,
  makeSelectCompanies,
  makeSelectLoading,
  makeSelectError,
  makeSelectedCompany,
};
