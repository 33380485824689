import { put, takeLatest, select } from 'redux-saga/effects';

import { LOAD_COMPANY_FINANCE, SAVE_COMPANY_FINANCE } from './constants';
import { loadFinanceDataSuccess, loadFinanceDataError, } from './actions';
import { getSingleCompany} from 'containers/CompanyBaseData/selectors';
import config from 'utils/config';
import axios from 'utils/axios';

export function* getCompanyFinanceData({ businessId }) {
  const requestURL = `${config.FETCH_COMPANY_API_URI}/finance/${businessId}`;

  try {
    const response = yield axios.get(requestURL);

    yield put(loadFinanceDataSuccess(response.data));
  } catch (err) {
    yield put(loadFinanceDataError(err));
  }
}

export function* saveCompanyData({ values }) {
  const company = yield select(getSingleCompany());

  const requestURL = `${config.FETCH_COMPANY_API_URI}/finance/${company.businessId}`;

  try {
    const response = yield axios.post(requestURL, values);

    yield put(loadFinanceDataSuccess(response.data));
  } catch (err) {
    yield put(loadFinanceDataError(err));
  }
}

export default function* financeData() {
  yield takeLatest(LOAD_COMPANY_FINANCE, getCompanyFinanceData);
  yield takeLatest(SAVE_COMPANY_FINANCE, saveCompanyData);
}
