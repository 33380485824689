import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CompanySearch from 'containers/CompanySearch';
import styles from 'assets/jss/grants/homepage';
import BrowseHistory from './BrowseHistory';

const useStyles = makeStyles(styles);

export default () => {
    const classes = useStyles();
    return (
        <GridContainer spacing={6} direction='row' justify='space-evenly' alignItems='stretch'>
            <GridItem xs={12} sm={8}>
                <div className={classes.content}>
                    <CompanySearch variant='outlined' />
                </div>
            </GridItem>
            <GridItem xs={12}>
                <BrowseHistory />
            </GridItem>
        </GridContainer>
    );
};
