import { call, put, takeLatest } from 'redux-saga/effects';

import { GET_COMPANY_LITE_SCORE, LOAD_SINGLE_COMPANY, SAVE_SINGLE_COMPANY } from './constants';
import {
    singleCompanyDataLoaded,
    loadSingleCompanyDataError,
    saveCompanySucceeded,
    saveSingleCompanyDataError,
    getLiteScoreSuccess,
    getLiteScoreError,
    getLiteScore,
} from './actions';
import { loadFinanceData } from 'containers/Finance/actions';
import { loadEvents } from 'containers/Events/actions';
import { loadBusinessPlanData } from 'containers/BusinessPlan/actions';
import { loadWorkPackages } from 'containers/WorkPackage/actions';
import { saveToLocalStorage } from 'utils/localStorage/localStorageHandler';

import { error, success } from 'components/Toast';
import config from 'utils/config';
import axios from 'utils/axios';

export function* getSingleCompany({ businessId, messages }) {
    const requestURL = `${config.FETCH_COMPANY_API_URI}/v2/company/${businessId}`;

    try {
        const response = yield axios.get(requestURL);

        yield put(loadFinanceData(businessId));
        yield put(loadEvents(businessId));
        yield put(loadBusinessPlanData(businessId));
        yield put(loadWorkPackages(businessId));
        yield put(singleCompanyDataLoaded(response.data));
        yield put(getLiteScore(businessId));

        yield call(saveToLocalStorage, { id: response.data.company.businessId, name: response.data.company.name });
    } catch (err) {
        yield put(loadSingleCompanyDataError(err));
        error(messages.error.title, messages.error.body);
    }
}

export function* getCompanyLiteScore({ companyId }) {
    const requestURL = `${config.INSTRUMENT_URI}/score/lite/${companyId}`;

    try {
        const response = yield axios.get(requestURL);

        yield put(getLiteScoreSuccess(response.data));
    } catch (err) {
        console.log(err);

        yield put(getLiteScoreError(err));
    }
}

export function* saveCompanyData({ values, messages }) {
    const requestURL = `${config.FETCH_COMPANY_API_URI}/v2/company/save`;

    try {
        const response = yield axios.post(requestURL, values);

        yield put(saveCompanySucceeded(response.data));
        success(messages.success.title, messages.success.body);
    } catch (err) {
        yield put(saveSingleCompanyDataError(err));

        error(messages.error.title, `${err}`);
    }
}

export default function* companyData() {
    yield takeLatest(LOAD_SINGLE_COMPANY, getSingleCompany);
    yield takeLatest(SAVE_SINGLE_COMPANY, saveCompanyData);
    yield takeLatest(GET_COMPANY_LITE_SCORE, getCompanyLiteScore);
}
