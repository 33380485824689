export const customers = [
    {
        id: 1,
        customer_name: 'Torp, Cruickshank and Rau',
        profile_creation_date: '30.11.2020',
        latest_update: '22.02.2021',
        profiler: '2D4RN5DG7BR746762',
        gscore: 1,
        trend: 'up',
        profile_readiness: false,
        missing_questions: ['advanceCollectionRegister', 'age', 'balance'],
        financial_plan_published: false,
        customer_status: 'CO',
    },
    {
        id: 2,
        customer_name: 'Kuhic-Conn',
        profile_creation_date: '23.01.2020',
        latest_update: '20.02.2021',
        profiler: 'WA1EV74L88D558407',
        gscore: 2,
        trend: 'up',
        profile_readiness: false,
        missing_questions: ['cashFlow', 'companyType', 'concept'],
        financial_plan_published: false,
        customer_status: 'ID',
    },
    {
        id: 3,
        customer_name: 'Kohler, Donnelly and Balistreri',
        profile_creation_date: '12.08.2020',
        latest_update: '31.01.2021',
        profiler: 'WAULT58E03A285411',
        gscore: 3,
        trend: 'down',
        profile_readiness: false,
        missing_questions: ['countrySide', 'deminisHistory', 'personnelSize', 'employeeRegister'],
        financial_plan_published: true,
        customer_status: 'CR',
    },
    {
        id: 4,
        customer_name: 'Torphy-Frami',
        profile_creation_date: '30.01.2020',
        latest_update: '12.03.2021',
        profiler: 'JN1AZ4EH9CM117317',
        gscore: 4,
        trend: 'up',
        profile_readiness: false,
        missing_questions: ['equityAmount', 'equityPercentageLevel'],
        financial_plan_published: true,
        customer_status: 'PT',
    },
    {
        id: 5,
        customer_name: 'Kertzmann, Quitzon and Yost',
        profile_creation_date: '12.10.2020',
        latest_update: '18.05.2021',
        profiler: 'WBALX3C58CC706523',
        gscore: 5,
        trend: 'down',
        profile_readiness: false,
        missing_questions: ['internationalEta'],
        financial_plan_published: false,
        customer_status: 'ZA',
    },
    {
        id: 6,
        customer_name: 'Gibson, Fisher and Schoen',
        profile_creation_date: '21.11.2020',
        latest_update: '08.05.2021',
        profiler: '1FT7W2A6XEE283638',
        gscore: 6,
        trend: 'down',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: false,
        customer_status: 'ID',
    },
    {
        id: 7,
        customer_name: 'Willms, Walsh and Kautzer',
        profile_creation_date: '28.07.2020',
        latest_update: '12.03.2021',
        profiler: 'KMHEC4A46FA529408',
        gscore: 7,
        trend: 'down',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: false,
        customer_status: 'ID',
    },
    {
        id: 8,
        customer_name: 'Metz Inc',
        profile_creation_date: '05.01.2020',
        latest_update: '05.04.2021',
        profiler: '1FTWX3A52AE125438',
        gscore: 8,
        trend: 'up',
        profile_readiness: false,
        missing_questions: ['ipr'],
        financial_plan_published: false,
        customer_status: 'CN',
    },
    {
        id: 9,
        customer_name: 'Mitchell and Sons',
        profile_creation_date: '29.06.2020',
        latest_update: '07.04.2021',
        profiler: '1G6AB5S35E0550663',
        gscore: 9,
        trend: 'up',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: false,
        customer_status: 'EC',
    },
    {
        id: 10,
        customer_name: 'Jerde, Botsford and Kuhlman',
        profile_creation_date: '14.09.2020',
        latest_update: '22.02.2021',
        profiler: 'WVGAV7AX4BW878195',
        gscore: 10,
        trend: 'down',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: false,
        customer_status: 'CN',
    },
    {
        id: 11,
        customer_name: 'Bode-Sauer',
        profile_creation_date: '24.09.2020',
        latest_update: '20.04.2021',
        profiler: '1GYS4BKJ1FR682884',
        gscore: 11,
        trend: 'up',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: false,
        customer_status: 'ID',
    },
    {
        id: 12,
        customer_name: 'Johnston Group',
        profile_creation_date: '22.04.2020',
        latest_update: '03.01.2021',
        profiler: '3C3CFFDR8FT508773',
        gscore: 12,
        trend: 'down',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: false,
        customer_status: 'ID',
    },
    {
        id: 13,
        customer_name: 'Medhurst LLC',
        profile_creation_date: '03.02.2020',
        latest_update: '04.02.2021',
        profiler: '4T1BD1FK8EU416997',
        gscore: 13,
        trend: 'down',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: false,
        customer_status: 'PH',
    },
    {
        id: 14,
        customer_name: 'DuBuque, Yost and Schoen',
        profile_creation_date: '08.11.2020',
        latest_update: '20.05.2021',
        profiler: '5N1AN0NW7DN269099',
        gscore: 14,
        trend: 'up',
        profile_readiness: false,
        missing_questions: ['taxDepth', 'vatRegister', 'turnover'],
        financial_plan_published: false,
        customer_status: 'IL',
    },
    {
        id: 15,
        customer_name: 'Willms-Langosh',
        profile_creation_date: '29.08.2020',
        latest_update: '15.02.2021',
        profiler: '1N6AF0LX1FN698672',
        gscore: 15,
        trend: 'down',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: true,
        customer_status: 'ZA',
    },
    {
        id: 16,
        customer_name: 'Larson-Hintz',
        profile_creation_date: '04.09.2020',
        latest_update: '25.01.2021',
        profiler: 'WAUVC68E72A900128',
        gscore: 16,
        trend: 'up',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: true,
        customer_status: 'FR',
    },
    {
        id: 17,
        customer_name: 'Pollich-Nitzsche',
        profile_creation_date: '13.05.2020',
        latest_update: '12.02.2021',
        profiler: 'WAUKG98E36A188439',
        gscore: 17,
        trend: 'up',
        profile_readiness: false,
        missing_questions: ['ratingAlpha', 'responsiblesCreditRate'],
        financial_plan_published: false,
        customer_status: 'ID',
    },
    {
        id: 18,
        customer_name: 'Blanda, Gaylord and Langosh',
        profile_creation_date: '04.06.2020',
        latest_update: '10.04.2021',
        profiler: '1FTEX1CM5CF953386',
        gscore: 18,
        trend: 'up',
        profile_readiness: false,
        missing_questions: ['paysDivident'],
        financial_plan_published: false,
        customer_status: 'CN',
    },
    {
        id: 19,
        customer_name: 'Rutherford and Sons',
        profile_creation_date: '16.08.2020',
        latest_update: '27.03.2021',
        profiler: '5LMJJ2H59EE146896',
        gscore: 19,
        trend: 'down',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: false,
        customer_status: 'ID',
    },
    {
        id: 20,
        customer_name: 'Gerhold, Altenwerth and Beahan',
        profile_creation_date: '05.06.2020',
        latest_update: '07.03.2021',
        profiler: '1GYFK56219R563250',
        gscore: 20,
        trend: 'down',
        profile_readiness: true,
        missing_questions: [],
        financial_plan_published: true,
        customer_status: 'PH',
    },
];
