import produce from 'immer';
import { LOAD_CUSTOMERS, LOAD_CUSTOMERS_ERROR, LOAD_CUSTOMERS_SUCCESS } from './constants.js';

export const initialState = {
    loading: false,
    error: false,
    customers: [],
};

const customersReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case LOAD_CUSTOMERS:
                draft.loading = true;
                break;
            case LOAD_CUSTOMERS_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;
            case LOAD_CUSTOMERS_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.customers = action.customers;
                break;
            default:
                return state;
        }
    });

export default customersReducer;
