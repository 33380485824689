import { put, takeLatest, select } from 'redux-saga/effects';
import {
  LOAD_ROBOT_EVENTS,
  START_ROBOT
} from './constants';
import config from 'utils/config';
import axios from 'utils/axios';

import { loadRobotEventsError, loadRobotEventsSuccess, startRobotSuccess } from './actions';
import { getSingleCompany } from 'containers/CompanyBaseData/selectors';

export function* getRobotEvents() {
  const company = yield select(getSingleCompany());

  const requestURL = `${config.ROBOT_API_URI}/robot/history/${company.businessId}`;

  try {
    const response = yield axios.get(requestURL);

    yield put(loadRobotEventsSuccess(response.data));
  } catch (err) {
    yield put(loadRobotEventsError(err));
  }
}

export function* startRobot() {
  const company = yield select(getSingleCompany());
  const requestURL = `${config.FETCH_COMPANY_API_URI}/robot/start/${company.businessId}`;

  try {
    const response = yield axios.post(requestURL);

    yield put(startRobotSuccess(response.data));
    yield put(loadRobotEventsSuccess(response.data));
  } catch (err) {
    yield put(loadRobotEventsError(err));
  }
}

export default function* companyData() {
  yield takeLatest(LOAD_ROBOT_EVENTS, getRobotEvents);
  yield takeLatest(START_ROBOT, startRobot);
}
