import React, { useState, useRef, useLayoutEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import moment from 'moment';
import * as _ from 'lodash';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components/Grid';
import { getDate, getAddress } from './utils';
import Timeline from 'containers/Timeline/TimelineTable';
import { getEuroFormatted } from 'utils/getEuroFormatted';
import MainSection from './MainSection';

const useStyles = makeStyles(theme => ({
    main: {
        backgroundColor: theme.palette.primary.white,
        height: '100vh',
        overflow: 'auto',
    },
    contentHeader: {
        fontSize: '1.6rem',
        fontWeight: 900,
        marginBottom: theme.spacing(3),
    },
    keyText: {
        fontSize: '1rem',
        fontWeight: 600,
    },
    propText: {
        fontSize: '1rem',
        '& p': {
            marginBottom: 0,
        },
    },
    fontSize09rem: {
        fontSize: '0.9rem',
    },
    fontSize08rem: {
        fontSize: '0.8rem',
    },
    profileContainer: {
        [theme.breakpoints.down('md')]: {
            '&>div:nth-child(odd)': {
                backgroundColor: theme.palette.primary.secondary,
            },
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.primary.secondary,
            '& div:nth-child(4n), div:nth-child(4n-1)': {
                backgroundColor: theme.palette.primary.white,
            },
        },
    },
    profileContainerWithShadow: {
        boxShadow: '3px 5px 5px -1px rgba(0, 0, 0, 0.2)',
    },
    profileBox: {
        padding: theme.spacing(1),
        color: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            width: '50%',
            minHeight: theme.spacing(8),
            maxHeight: theme.spacing(8),
        },
    },
    instrumentTitle: {
        fontSize: '1.1rem',
        fontWeight: 500,
    },
    instrumentText: {
        fontSize: '0.875rem',
        color: theme.palette.primary.main,
    },
    instrumentName: {
        fontSize: '1.1rem',
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
    instrumentDate: {
        fontSize: '1.0rem',
        fontWeight: 500,
        paddingTop: '1px',
    },
    cell: {
        padding: '6px 16px 6px 0',
        borderBottom: 'none',
    },
}));

const MainContent = ({
    company,
    scenario,
    data,
    instruments,
    mock,
    hasHorizontalScrollbar,
    selectedId,
    printRef,
    smallerTableFont,
    smallerPadding,
    mdUp,
    openInstrumentModal,
}) => {
    const classes = useStyles();
    const intl = useIntl();

    const timelineInstruments = data?.instruments;

    const scenarioValues = [0, 1, 2, 4, 8, 16, 32];

    const timelineData = data;

    const { expertComments, base, precondition, notice, followup } = timelineData;

    const getIndex = (value, scenarioValues) =>
        scenarioValues.reduce((acc, item, index) => {
            return item === value ? index : acc;
        }, 0);

    const ref = useRef();
    const [mainContentWidth, setMainContentWidth] = useState(null);
    const timelineWidth = mainContentWidth - 96; // paddingLeft + paddingRight

    useLayoutEffect(() => {
        const updateWidth = () => {
            setMainContentWidth(ref.current?.clientWidth);
        };

        window.addEventListener('resize', updateWidth);
        updateWidth();

        return () => window.removeEventListener('resize', updateWidth);
    }, [ref]);

    return (
        <Box ref={ref} className={classes.main}>
            <Box ref={printRef}>
                {/* Cover letter */}
                <MainSection
                    hidden={timelineData?.type === 'Timeline'}
                    id='coverLetter'
                    selected={mdUp ? selectedId : null}
                    smallerPadding={smallerPadding}
                >
                    <Typography id='coverLetter' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.coverLetter' })}
                    </Typography>
                    <Typography component='div' className={classes.propText}>
                        {ReactHtmlParser(base)}
                    </Typography>
                </MainSection>
                {/* CompanyInfo: */}
                <MainSection id='companyinfo' selected={mdUp ? selectedId : null} smallerPadding={smallerPadding}>
                    <Typography id='companyinfo' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'editing.panel.companyinfo' })}
                    </Typography>
                    <GridContainer spacing={4}>
                        <GridItem>
                            <GridContainer direction='column'>
                                <GridItem>
                                    <Typography component='p' className={classes.keyText}>
                                        {intl.formatMessage({ id: 'common.business.id' })}:
                                    </Typography>
                                </GridItem>
                                <GridItem>
                                    <Typography component='p' className={classes.keyText}>
                                        {intl.formatMessage({ id: 'common.address' })}:
                                    </Typography>
                                </GridItem>
                                <GridItem>
                                    <Typography component='p' className={classes.keyText}>
                                        {intl.formatMessage({ id: 'common.foundation.year' })}:
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem>
                            <GridContainer direction='column'>
                                <GridItem>
                                    <Typography component='p' className={classes.propText}>
                                        {company?.businessId}
                                    </Typography>
                                </GridItem>
                                <GridItem>
                                    <Typography color='primary' className={classes.propText}>
                                        {getAddress(company?.address)}
                                    </Typography>
                                </GridItem>
                                <GridItem>
                                    <Typography component='p' className={classes.propText}>
                                        {getDate(company?.registrationDate) ?? '-'}
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </MainSection>
                {/* Profile Info: */}
                <MainSection id='financialInfo' selected={mdUp ? selectedId : null} smallerPadding={smallerPadding}>
                    <Typography id='financialInfo' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'basic.financial.info' })}
                    </Typography>
                    <Box className={`${classes.profileContainer} ${mdUp && classes.profileContainerWithShadow} profileContainerPrint`}>
                        {_.map(scenario, (value, key) => {
                            if (typeof value === 'number' && key !== 'grantedScore')
                                return (
                                    <Box key={key} className={`${classes.profileBox} profileBoxPrint`}>
                                        <GridContainer>
                                            <GridItem xs={12} lgx={6}>
                                                <Typography
                                                    component='label'
                                                    htmlFor={key}
                                                    className={`
                                                    ${classes.keyText}
                                                    ${smallerTableFont && classes.fontSize09rem}
                                                    ${smallerPadding && classes.fontSize08rem}
                                                    `}
                                                >
                                                    {intl.formatMessage({ id: `instrument.radar.choice.${key}` })}:
                                                </Typography>
                                            </GridItem>
                                            <GridItem xs={12} lgx={6}>
                                                <Typography
                                                    component='span'
                                                    className={`
                                                    ${classes.propText}
                                                    ${smallerTableFont && classes.fontSize09rem}
                                                    ${smallerPadding && classes.fontSize08rem}
                                                    `}
                                                >
                                                    {intl.formatMessage({
                                                        id: `instrument.radar.choice.${key}.${getIndex(value, scenarioValues)}`,
                                                    })}
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                    </Box>
                                );
                        })}
                    </Box>
                </MainSection>
                {/* General Conditions: */}
                <MainSection
                    hidden={timelineData?.type === 'Timeline'}
                    id='general'
                    selected={mdUp ? selectedId : null}
                    smallerPadding={smallerPadding}
                >
                    <Typography id='general' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.generalConditionsTitle' })}
                    </Typography>
                    <Typography component='div' className={classes.propText}>
                        {ReactHtmlParser(precondition)}
                    </Typography>
                </MainSection>
                {/* Notice: */}
                <MainSection
                    hidden={timelineData?.type === 'Timeline'}
                    id='notice'
                    selected={mdUp ? selectedId : null}
                    smallerPadding={smallerPadding}
                >
                    <Typography id='notice' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.noticeTitle' })}
                    </Typography>
                    <Typography component='div' className={classes.propText}>
                        {ReactHtmlParser(notice)}
                    </Typography>
                </MainSection>
                {/* Recomendations: */}
                <MainSection
                    hidden={timelineData?.type === 'Timeline'}
                    id='recommendations'
                    selected={mdUp ? selectedId : null}
                    smallerPadding={smallerPadding}
                >
                    <Typography id='recommendations' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.recomendationsTitle' })}
                    </Typography>
                    {timelineData && Object.keys(timelineData).length !== 0 && (
                        <Typography component='div' className={classes.propText}>
                            {ReactHtmlParser(expertComments)}
                        </Typography>
                    )}
                </MainSection>
                {/* Timeline: */}
                <MainSection id='timeline' selected={mdUp ? selectedId : null} smallerPadding={smallerPadding}>
                    <Typography id='timeline' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.timeline' })}
                    </Typography>
                    <Timeline
                        company={company}
                        scenario={scenario}
                        data={data}
                        instruments={instruments}
                        mock={mock}
                        hasHorizontalScrollbar={hasHorizontalScrollbar}
                        timelineContainerWidth={timelineWidth}
                        openInstrumentModal={openInstrumentModal}
                        showShadow={mdUp}
                        padding={8}
                        printView={true}
                    />
                </MainSection>
                {timelineInstruments?.map(
                    (
                        { id, name, instrumentIngress, description, startTime, endTime, provider, isUsed, grantAmount, projectSize },
                        index
                    ) => (
                        <MainSection
                            key={id + endTime}
                            id={`instrument_${index + 1}`}
                            selected={mdUp ? selectedId : null}
                            smallerPadding={smallerPadding}
                        >
                            <GridContainer alignItems='center' spacing={1}>
                                <GridItem>
                                    <Typography
                                        id={`instrument_${index + 1}`}
                                        component='h3'
                                        className={`${classes.instrumentName} tocTitle`}
                                    >
                                        {index + 1}. {name}
                                    </Typography>
                                </GridItem>
                                <GridItem>
                                    <Typography component='p' className={classes.instrumentDate}>
                                        ({moment(startTime).format('MM.YYYY')}-{moment(endTime).format('MM.YYYY')})
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                            <Typography component='div' className={classes.propText}>
                                {ReactHtmlParser(instrumentIngress)}
                            </Typography>
                            <Typography component='div' className={classes.propText}>
                                {ReactHtmlParser(description)}
                            </Typography>
                            <TableContainer>
                                <Table aria-label='timeline' size='small'>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.cell}>
                                                <Typography component='p' className={classes.keyText}>
                                                    {intl.formatMessage({ id: 'timeline.tooltip.schedule' })}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                <Typography component='p' className={classes.propText}>
                                                    {moment(startTime).format('DD.MM.YYYY')}-{moment(endTime).format('DD.MM.YYYY')}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.cell}>
                                                <Typography component='p' className={classes.keyText}>
                                                    {intl.formatMessage({ id: 'timeline.tooltip.provider' })}:
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                <Typography component='p' className={classes.propText}>
                                                    {provider}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.cell}>
                                                <Typography component='p' className={classes.keyText}>
                                                    {intl.formatMessage({ id: 'timeline.tooltip.isUsed' })}:
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                {isUsed ? (
                                                    <Typography component='p' className={classes.propText}>
                                                        {intl.formatMessage({ id: 'common.yes' })}
                                                    </Typography>
                                                ) : (
                                                    <Typography component='p' className={classes.propText}>
                                                        {intl.formatMessage({ id: 'common.no' })}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.cell}>
                                                <Typography component='p' className={classes.keyText}>
                                                    {intl.formatMessage({ id: 'timeline.tooltip.grantAmount' })}:
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                <Typography component='p' className={classes.propText}>
                                                    {getEuroFormatted(grantAmount)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.cell}>
                                                <Typography component='p' className={classes.keyText}>
                                                    {intl.formatMessage({ id: 'timeline.tooltip.projectSize' })}:
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                <Typography component='p' className={classes.propText}>
                                                    {getEuroFormatted(projectSize)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MainSection>
                    )
                )}
                {/* Follow Up: */}
                <MainSection
                    hidden={timelineData?.type === 'Timeline'}
                    id='followup'
                    selected={mdUp ? selectedId : null}
                    smallerPadding={smallerPadding}
                >
                    <Typography id='followup' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                        {intl.formatMessage({ id: 'financial.plan.followupTitle' })}
                    </Typography>
                    <Typography component='div' className={classes.propText}>
                        {ReactHtmlParser(followup)}
                    </Typography>
                </MainSection>
                {/* Instruments: */}
                {timelineInstruments?.some(item => item.instrumentDescription !== null) ? (
                    <MainSection id='instruments' selected={mdUp ? selectedId : null} smallerPadding={smallerPadding}>
                        <Typography id='instruments' variant='h2' className={`${classes.contentHeader} tocTitle`}>
                            {intl.formatMessage({ id: 'financial.plan.instrumentDescriptions' })}
                        </Typography>
                        {timelineInstruments?.map(instrument => {
                            const { id, name, instrumentDescription, provider, endTime } = instrument;

                            if (!instrumentDescription) return null;

                            return (
                                <Box key={id + endTime}>
                                    <Typography component='p' className={classes.instrumentTitle}>
                                        {provider} * {name}
                                    </Typography>
                                    <Typography component='div' className={classes.instrumentText}>
                                        {ReactHtmlParser(instrumentDescription)}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </MainSection>
                ) : null}
            </Box>
        </Box>
    );
};

export default MainContent;
