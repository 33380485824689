import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import { loadCustomers } from './actions';
import { gridTranslations } from '../../translations/gridTranslations';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    box: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    tableTitle: {
        fontSize: '2rem',
        fontWeight: 700,
    },
    dataGrid: {
        // '& .row-bg--Vedos': {
        //     backgroundColor: theme.palette.primary.secondary,
        // },
    },
    popper: {
        top: '1.5rem !important',
    },
    tooltip: {
        padding: '0.5rem',
        border: '1px solid #ccc',
        background: 'rgba(255, 255, 255, 0.9)',
        color: theme.palette.primary.main,
        fontSize: '1rem',
    },
    updateButton: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.white,
    },
}));

const key = 'customers';

const Customers = () => {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();

    const customers = useSelector(state => state.customers);
    const customersData = customers?.customers;
    const customersLoading = customers?.loading;

    useEffect(() => {
        if (!customers) {
            dispatch(loadCustomers());
        }
    }, [customers, dispatch]);

    const columns = [
        {
            field: 'id',
            headerName: intl.formatMessage({ id: 'customers.table.id' }),
            width: 150,
            hide: true,
            renderCell: params => (
                <Tooltip title={params.id} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.id}</span>
                </Tooltip>
            ),
        },
        {
            field: 'customer_name',
            headerName: intl.formatMessage({ id: 'customers.table.name' }),
            width: 200,
            renderCell: params => (
                <Tooltip title={params.row.customer_name} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.customer_name}</span>
                </Tooltip>
            ),
        },
        {
            field: 'profile_creation_date',
            headerName: intl.formatMessage({ id: 'customers.table.profile.creation.date' }),
            width: 200,
            renderCell: params => (
                <Tooltip
                    title={params.row.profile_creation_date}
                    placement='top'
                    classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                >
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.row.profile_creation_date}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'latest_update',
            headerName: intl.formatMessage({ id: 'customers.table.latest.update' }),
            width: 200,
            renderCell: params => (
                <Tooltip title={params.row.latest_update} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.latest_update}</span>
                </Tooltip>
            ),
        },
        {
            field: 'profiler',
            headerName: intl.formatMessage({ id: 'customers.table.profiler' }),
            width: 200,
            renderCell: params => (
                <Tooltip title={params.row.profiler} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.profiler}</span>
                </Tooltip>
            ),
        },
        {
            field: 'gscore',
            headerName: intl.formatMessage({ id: 'customers.table.gscore' }),
            width: 200,
            renderCell: params => (
                <Tooltip title={params.row.gscore} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.gscore}</span>
                </Tooltip>
            ),
        },
        {
            field: 'trend',
            headerName: intl.formatMessage({ id: 'customers.table.trend' }),
            width: 200,
            renderCell: params => (
                <Tooltip title={params.row.trend} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.trend}</span>
                </Tooltip>
            ),
        },
        {
            field: 'profile_readiness',
            headerName: intl.formatMessage({ id: 'customers.table.profile.readiness' }),
            width: 200,
            renderCell: params => (
                <Tooltip
                    title={params.row.profile_readiness}
                    placement='top'
                    classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                >
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.row.profile_readiness}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'missing_questions',
            headerName: intl.formatMessage({ id: 'customers.table.missing.questions' }),
            width: 500,
            renderCell: params => (
                <Tooltip
                    title={params.row.missing_questions}
                    placement='top'
                    classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                >
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.row.missing_questions}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'financial_plan_published',
            headerName: intl.formatMessage({ id: 'customers.table.financial.plan.published' }),
            width: 200,
            renderCell: params => (
                <Tooltip
                    title={params.row.financial_plan_published}
                    placement='top'
                    classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                >
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.row.financial_plan_published}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'customer_status',
            headerName: intl.formatMessage({ id: 'customers.table.customer.status' }),
            width: 200,
            renderCell: params => (
                <Tooltip title={params.row.customer_status} placement='top' classes={{ popper: classes.popper, tooltip: classes.tooltip }}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.customer_status}</span>
                </Tooltip>
            ),
        },
        {
            field: 'update_profile',
            headerName: intl.formatMessage({ id: 'customers.table.ask.update.profile' }),
            width: 150,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: ({ row }) => (
                <Button
                    className={classes.updateButton}
                    onClick={() => console.log('Pyydä asiakasta päivittämään profiili', row)}
                    variant='contained'
                    size='small'
                >
                    {intl.formatMessage({ id: 'common.submit' })}
                </Button>
            ),
        },
    ];

    const rows = customersData?.map(
        ({
            id,
            customer_name,
            profile_creation_date,
            latest_update,
            profiler,
            gscore,
            trend,
            profile_readiness,
            missing_questions,
            financial_plan_published,
            customer_status,
        }) => {
            return {
                id,
                customer_name,
                profile_creation_date,
                latest_update,
                profiler,
                gscore,
                trend: intl.formatMessage({ id: `customers.trend.${trend}` }),
                profile_readiness: intl.formatMessage({ id: `customers.profile.readiness.${profile_readiness}` }),
                missing_questions: missing_questions.length
                    ? missing_questions.map(item => intl.formatMessage({ id: `instrument.radar.choice.${item}` })).join(', ')
                    : '',
                financial_plan_published: intl.formatMessage({ id: `customers.financial.plan.published.${financial_plan_published}` }),
                customer_status,
            };
        }
    );

    return (
        <Box className={classes.root}>
            <Box className={classes.box}>
                <Typography variant='h1' color='primary' className={classes.tableTitle}>
                    {intl.formatMessage({ id: 'customers.table.title' })}
                </Typography>
            </Box>
            {customersLoading ? (
                <CircularProgress size={24} />
            ) : !customersLoading && rows && rows.length !== 0 ? (
                <GridContainer spacing={2}>
                    <GridItem>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            autoHeight
                            hideFooter={true}
                            localeText={gridTranslations(intl)}
                            // getRowClassName={params => `row-bg--${params.getValue('isDraft')}`}
                            className={classes.dataGrid}
                        />
                    </GridItem>
                    <GridItem>
                        <Box textAlign='end'>
                            <Button variant='contained' color='primary' endIcon={<AddIcon />} onClick={() => console.log('button clicked')}>
                                {intl.formatMessage({ id: 'instruments.edit.add.button' })}
                            </Button>
                        </Box>
                    </GridItem>
                </GridContainer>
            ) : (
                <GridContainer spacing={2}>
                    <GridItem>
                        <Box textAlign='center'>
                            <Typography component='p' color='primary'>
                                {intl.formatMessage({ id: 'customers.no.customers' })}
                            </Typography>
                        </Box>
                    </GridItem>
                </GridContainer>
            )}
        </Box>
    );
};

export default Customers;
