import React from 'react';
import * as _ from 'lodash';
import { useFieldArray } from 'react-hook-form';

import AddButton from 'components/GrantsComponents/AddButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { GridItem, GridContainer } from 'components/Grid';
import ScenarioForm from './ScenarioForm';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Logo from 'components/Logo/Logo';
import { FormattedMessage } from 'react-intl';
import RemoveLineButton from 'components/GrantsComponents/RemoveLineButton';

const getDefaultScenario = (scenarios) => {
	const mainScenario = scenarios.find(item => item.isMainScenario);

	return mainScenario ? mainScenario : scenarios[0];
};

const findScenario = (scenarios, selected) => {
	if (!selected) return getDefaultScenario(scenarios);

	var item = _.find(scenarios, (item) => item.id === selected);
	return item || getDefaultScenario(scenarios);
};

const CompanyFormValues = (props) => {
	const {
		scenarios,
		control,
		onScenarioChange,
		onRemoveScenario,
	} = props;
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'scenarios',
	});

	const [selected, setSelected] = React.useState(getDefaultScenario(scenarios).id);
	const [scenario, setScenario] = React.useState({});

	const findAndSetScenario = (selectedValue) => {
		const selecteds = findScenario(scenarios, selectedValue);

		setScenario(selecteds);
		onScenarioChange(selecteds);
	};

	const handleChange = (event) => {
		setSelected(event.target.value);
		findAndSetScenario(event.target.value);
	};

	React.useEffect(() => {
		findAndSetScenario();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const createDropdown = (scenarios) => {
		return (
			<FormControl fullWidth={true}>
				<InputLabel>
					<FormattedMessage id={'instrument.radar.scenarios.choose'} />
				</InputLabel>

				<Select
					defaultValue={selected}
					defaultChecked={true}
					labelId="scenarios-select-box"
					id="scenarios-select-box"
					onChange={handleChange}
					value={selected ?? ''}
				>
					{_.map(scenarios, (scenario) => {

						return (
							<MenuItem key={scenario.id + scenario} value={scenario.id}>
								{scenario.isMainScenario ? <Logo /> : null}
								{scenario.name}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		);
	};

	const broofa = () => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
			c
		) {
			var r = (Math.random() * 16) | 0,
				v = c == "x" ? r : (r & 0x3) | 0x8; // eslint-disable-line
			return v.toString(16);
		});
	};

	if (scenarios === undefined || scenarios.length === 0) return null;

	return (
		<React.Fragment>
			<GridContainer>
				<GridItem xs={12} md={6}>
					{createDropdown(scenarios)}
				</GridItem>
				<GridItem xs={12} md={6} contentRight={true}>
					<AddButton
						onClick={() => {
							const id = broofa();
							const scen = Object.assign({}, getDefaultScenario(scenarios));
							scen.id = id;
							var now = new Date();
							var datetime = now.getFullYear() + '/' + (now.getMonth() + 1) + '/' + now.getDate();
							datetime += ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();

							scen.name = `Skenaario: ${datetime}`;

							append(scen);
							scenarios.push(scen);
							setSelected(id);
							findAndSetScenario(id);
						}}
						text="Lisää Skenaario"
					/>
				</GridItem>
			</GridContainer>
			{scenario.id !== undefined
				? _.map(fields, (item, index) => {
					if (item.id === scenario.id)
						return (
							<GridContainer key={scenario.id}>
								<GridItem>
									<ScenarioForm
										key={item.id}
										control={control}
										scenarios={item}
										index={index}
									/>
								</GridItem>
								<GridItem>
									{item.isBase !== true && fields.length > 1 && 2 === 3 ? (
										<RemoveLineButton
											onClick={() => {
												remove(index);
												onRemoveScenario(item, index);
											}}
										/>
									) : (
										<React.Fragment></React.Fragment>
									)}
								</GridItem>
							</GridContainer>
						);
				})
				: null}
		</React.Fragment>
	);
};

export default CompanyFormValues;
