import { LOAD_COMPANY_DATA, LOAD_COMPANY_DATA_ERROR, LOAD_COMPANY_DATA_SUCCESS, SELECT_COMPANY, CLEAR_COMPANY_DATA } from './constants';

export function loadCompanyData(name) {
    return {
        type: LOAD_COMPANY_DATA,
        name,
    };
}

export function companyDataLoaded(companies) {
    return {
        type: LOAD_COMPANY_DATA_SUCCESS,
        companies,
    };
}

export function loadCompanyDataError(error) {
    return {
        type: LOAD_COMPANY_DATA_ERROR,
        error,
    };
}

export function selectCompany(selected) {
    return {
        type: SELECT_COMPANY,
        selected,
    };
}

export const clearCompanyData = () => {
    return {
        type: CLEAR_COMPANY_DATA,
    };
};
