import produce from 'immer';

import {
    LOAD_TIMELINE,
    LOAD_TIMELINE_ERROR,
    LOAD_TIMELINE_SUCCESS,
    ADD_INSTRUMENT_TO_TIMELINE,
    ADD_INSTRUMENT_TO_TIMELINE_ERROR,
    ADD_INSTRUMENT_TO_TIMELINE_SUCCESS,
    EDIT_INSTRUMENT_IN_TIMELINE,
    EDIT_INSTRUMENT_IN_TIMELINE_ERROR,
    EDIT_INSTRUMENT_IN_TIMELINE_SUCCESS,
    DELETE_INSTRUMENT_FROM_TIMELINE,
    DELETE_INSTRUMENT_FROM_TIMELINE_ERROR,
    DELETE_INSTRUMENT_FROM_TIMELINE_SUCCESS,
    OPEN_INSTRUMENT_MODAL,
    CLOSE_INSTRUMENT_MODAL,
    SCROLL_TO_TIMELINE,
    RESET_IS_SCROLLING,
    RESET_TIMELINE,
} from './constants.js';

export const initialState = {
    loading: false,
    error: false,
    timeline: null,
    isInstrumentModalOpen: false,
    isScrolling: false,
    instrument: null,
    editMode: false,
};

const timelineReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case LOAD_TIMELINE:
                draft.loading = true;
                break;
            case LOAD_TIMELINE_ERROR:
                draft.loading = false;
                draft.error = action.error;
                break;
            case LOAD_TIMELINE_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.timeline = action.timeline;
                break;
            case ADD_INSTRUMENT_TO_TIMELINE:
                draft.loading = true;
                break;
            case ADD_INSTRUMENT_TO_TIMELINE_ERROR:
                draft.loading = false;
                draft.error = action.error;
                break;
            case ADD_INSTRUMENT_TO_TIMELINE_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.timeline.instruments.push({
                    rowKey: action.instrument.rowKey,
                    id: action.instrument.id,
                    name: action.instrument.name,
                    type: action.instrument.type,
                    provider: action.instrument.provider,
                    projectSize: action.instrument.projectSize,
                    grantAmount: action.instrument.grantAmount,
                    description: action.instrument.description,
                    startTime: action.instrument.startTime,
                    endTime: action.instrument.endTime,
                    monthDuration: action.instrument.monthDuration,
                    monthFromStart: action.instrument.monthFromStart,
                    isUsed: action.instrument.isUsed,
                    eTag: action.instrument.eTag ?? null,
                    instrumentDescription: action.instrument.instrumentDescription ?? null,
                    instrumentIngress: action.instrument.instrumentIngress ?? null,
                    modified: action.instrument.modified ?? null,
                    partitionKey: action.instrument.partitionKey ?? null,
                    timestamp: action.instrument.timestamp ?? null,
                });
                break;
            case EDIT_INSTRUMENT_IN_TIMELINE:
                draft.loading = true;
                break;
            case EDIT_INSTRUMENT_IN_TIMELINE_ERROR:
                draft.loading = false;
                draft.error = action.error;
                break;
            case EDIT_INSTRUMENT_IN_TIMELINE_SUCCESS: {
                draft.loading = false;
                draft.error = false;
                const instrumentIndex = draft.timeline.instruments.findIndex(i => i.rowKey === action.instrument.rowKey);
                draft.timeline.instruments[instrumentIndex] = action.instrument;
                if (action.instrument.monthFromStart + action.instrument.monthDuration > draft.timeline.numberOfMonth)
                    draft.timeline.numberOfMonth = Math.ceil((action.instrument.monthFromStart + action.instrument.monthDuration) / 3) * 3;
                break;
            }
            case DELETE_INSTRUMENT_FROM_TIMELINE:
                draft.loading = true;
                break;
            case DELETE_INSTRUMENT_FROM_TIMELINE_ERROR:
                draft.loading = false;
                draft.error = action.error;
                break;
            case DELETE_INSTRUMENT_FROM_TIMELINE_SUCCESS: {
                draft.loading = false;
                draft.error = false;
                draft.timeline.instruments.splice(
                    draft.timeline.instruments.findIndex(item => item.rowKey === action.rowKey),
                    1
                );
                break;
            }
            case OPEN_INSTRUMENT_MODAL:
                draft.isInstrumentModalOpen = true;
                draft.instrument = action.instrument;
                draft.editMode = action.editMode;
                break;
            case CLOSE_INSTRUMENT_MODAL:
                draft.isInstrumentModalOpen = false;
                draft.instrument = null;
                draft.editMode = false;
                break;
            case SCROLL_TO_TIMELINE:
                draft.isScrolling = true;
                break;
            case RESET_IS_SCROLLING:
                draft.isScrolling = false;
                break;
            case RESET_TIMELINE:
                return initialState;
            default:
                return state;
        }
    });

export default timelineReducer;
