export const LOAD_INSTRUMENT_RADAR = 'Grants/Radar/LOAD_INSTRUMENT_RADAR';
export const LOAD_INSTRUMENT_RADAR_SUCCESS = 'Grants/Radar/LOAD_INSTRUMENT_RADAR_SUCCESS';
export const LOAD_INSTRUMENT_RADAR_ERROR = 'Grants/Radar/LOAD_INSTRUMENT_RADAR_ERROR';
export const SET_COMPANY_LIMIT = 'Grants/Radar/SET_COMPANY_LIMIT';

export const LOAD_INSTRUMENTS = 'Grants/Radar/LOAD_INSTRUMENTS';
export const LOAD_INSTRUMENTS_SUCCESS = 'Grants/Radar/LOAD_INSTRUMENTS_SUCCESS';
export const LOAD_INSTRUMENTS_ERROR = 'Grants/Radar/LOAD_INSTRUMENTS_ERROR';

export const LOAD_SCENARIOS = 'Grants/Radar/LOAD_SCENARIOS';
export const LOAD_SCENARIOS_SUCCESS = 'Grants/Radar/LOAD_SCENARIOS_SUCCESS';
export const LOAD_SCENARIOS_ERROR = 'Grants/Radar/LOAD_SCENARIOS_ERROR';
export const SAVE_SCENARIO = 'Grants/Radar/SAVE_SCENARIO';
export const REMOVE_SCENARIO = 'Grants/Radar/REMOVE_SCENARIO';
export const SAVE_MAIN_SCENARIO = 'Grants/Radar/SAVE_MAIN_SCENARIO';