export const LOAD_GRANTEDAI_USERS = 'Grants/App/LOAD_GRANTEDAI_USERS';
export const LOAD_GRANTEDAI_USERS_SUCCESS = 'Grants/App/LOAD_GRANTEDAI_USERS_SUCCESS';
export const LOAD_GRANTEDAI_USERS_ERROR = 'Grants/App/LOAD_GRANTEDAI_USERS_ERROR';
export const ADD_GRANTEDAI_USER = 'Grants/App/ADD_GRANTEDAI_USER';

export const SAVE_LICENSE_INFORMATION = 'Grants/App/SAVE_LICENSE_INFORMATION';
export const SAVE_LICENSE_INFORMATION_ERROR = 'Grants/App/SAVE_LICENSE_INFORMATION_ERROR';
export const SAVE_LICENSE_INFORMATION_SUCCESS = 'Grants/App/SAVE_LICENSE_INFORMATION_SUCCESS';
export const LOAD_LICENSE_INFORMATION = 'Grants/App/LOAD_LICENSE_INFORMATION';
export const LOAD_LICENSE_INFORMATION_ERROR = 'Grants/App/LOAD_LICENSE_INFORMATION_ERROR';
export const LOAD_LICENSE_INFORMATION_SUCCESS = 'Grants/App/LOAD_LICENSE_INFORMATION_SUCCESS';

export const TERMINATE_LICENSE = 'Grants/App/TERMINATE_LICENSE';

export const REACTIVATE_USER = 'Grants/App/REACTIVATE_USER';
