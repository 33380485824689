import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { loadSingleCompanyData } from 'containers/CompanyBaseData/actions';
import { LOAD_COMPANY_DATA, SELECT_COMPANY } from './constants';
import { companyDataLoaded, loadCompanyDataError } from './actions';
import config from 'utils/config';
import axios from 'utils/axios';

export function* getCompanyData(companyName) {
	try {
		companyName.name = companyName.name.trim();
	} catch (error) { console.log(error); }

	const regex = /^(\d{7}-\d{1})$/gm; // if businessId
	const match = companyName.name.match(regex); // does it match -> is businessId

	const requestURL = !match ? `${config.FETCH_COMPANY_API_URI}/company/name/${companyName.name}` : `${config.FETCH_COMPANY_API_URI}/v2/company/${companyName.name}?limited=true`;

	try {
		const response = yield axios.get(requestURL);

		if (match && response.data && response.data.company) { // is businessId search -> parse data
			const data = [];
			data.push(
				{
					businessId: response.data.company.businessId,
					name: response.data.company.name,
					registrationDate: response.data.company.registrationDate,
					companyForm: response.data.company.companyForm,
					isCustomer: response.data.company.isCustomer
				}
			);
			yield put(companyDataLoaded(data));

			return;
		} else if (match) {
			throw new Error('Company not found');
		} else {
			yield put(companyDataLoaded(response.data));
		}
	} catch (err) {
		yield put(loadCompanyDataError(err));
	}
}

export function* selectCompany({ selected }) {
	try {
		const { businessId } = selected;

		yield put(push(`/company/${selected.businessId}`));
		yield put(loadSingleCompanyData(businessId));
	} catch (err) {
		yield put(loadCompanyDataError(err));
	}
}

export default function* companyData() {
	yield takeLatest(LOAD_COMPANY_DATA, getCompanyData);
	yield takeLatest(SELECT_COMPANY, selectCompany);
}