import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from 'containers/Score/reducer';
import saga from 'containers/Score/saga';
import { getGrantedScore } from 'containers/Score/actions';

import { GridContainer, GridItem } from 'components/Grid';

const key = 'score';

const red = '133,36,68';
const yellow = '197,159,57';
const green = '7,120,113';

const useStyles = makeStyles(theme => ({
    root: {
        height: '25rem',
        backgroundColor: `rgba(72, 46, 81, 1)`,
        marginBottom: theme.spacing(3),
        '& h3': {
            textAlign: 'center',
            fontWeight: 600,
            padding: 0,
            margin: 0,
            marginBottom: '1.2rem',
            textTransform: 'uppercase',
            color: theme.palette.primary.white,
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
        },
    },
    lightGreen: { backgroundColor: `rgba(${green}, 0.9)` },
    lightYellow: { backgroundColor: `rgba(${yellow}, 0.9)` },
    lightRed: { backgroundColor: `rgba(${red}, 0.9)` },
    baseColor: { backgroundColor: `rgba(72, 46, 81, 1)` },
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
    },
    pill: {
        width: '6rem',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    graph: {
        height: '9rem',
        width: '4.5rem',
        borderRadius: '80px',
        backgroundColor: 'rgba(255, 255, 255, 0.24)',
    },
    description: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 600,
        marginTop: '0.5rem',
    },
    scorebox: {
        textAlign: 'center',
        color: '#fff',
        fontWeight: 600,
        fontSize: '2rem',
        marginBottom: '-0.8rem',
        width: '100%',
    },
    score: {
        width: '3.8rem',
        display: 'inline-block',
        position: 'relative',
        bottom: '1.5rem',
    },
    floater: {
        width: '4rem',
        display: 'inline-block',
        borderTop: '2px dashed rgba(255, 255, 255, 0.47)',
        height: '1.5rem',
        '&:first-child': {
            borderLeft: '2px dashed rgba(255, 255, 255, 0.47)',
        },
        '&:last-child': {
            borderRight: '2px dashed rgba(255, 255, 255, 0.47)',
        },
    },
    missingInfo: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem',
        fontSize: '0.8rem',
        fontWeight: '500',
        color: 'rgba(255, 255, 255, 0.47)',
        '& div': {
            textTransform: 'uppercase',
        },
        '& p': {
            width: '80%',
            color: 'rgba(255, 255, 255, 0.47)',
            textAlign: 'center',
        },
    },
    loader: {
        textAlign: 'center',
        '& h3': {},
    },
}));

const scoreLevel = {
    LOW: 'low',
    MED: 'med',
    HIGH: 'high',
};

const getScoreLevel = score => {
    if (score >= 80) return scoreLevel.HIGH;
    if (score < 80 && score > 20) return scoreLevel.MED;
    if (score <= 20) return scoreLevel.LOW;
};

const Pill = ({ title, score }) => {
    const classes = useStyles();

    const level = getScoreLevel(score);
    const gradient = level === scoreLevel.HIGH ? green : level === scoreLevel.MED ? yellow : red;
    const baseGradient = `linear-gradient(0deg, rgba(${gradient},1) ${score}%, rgba(255,255,255,0.24) ${score}%)`;

    return (
        <div className={classes.pill}>
            <div>{score}</div>
            <div className={classes.graph} style={{ background: baseGradient }}>
                &nbsp;
            </div>
            <div className={classes.description}>{title}</div>
        </div>
    );
};

const Score = ({ loading, missingValues }) => {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });
    const dispatch = useDispatch();

    const score = useSelector(state => state.score?.score);
    const company = useSelector(state => state.company?.company);
    const liteScore = useSelector(state => state.company?.lite);

    useEffect(() => {
        if (company)
            dispatch(getGrantedScore());
    }, [dispatch, company]);

    const classes = useStyles();
    const intl = useIntl();

    const content = (score, missingValues) => {
        return (
            <>
                <h3>{intl.formatMessage({ id: 'score.title' })}</h3>
                <div className={classes.scorebox}>
                    <div className={classes.floater}>&nbsp;</div>
                    <div className={classes.score}>{score?.grantedScore}</div>
                    <div className={classes.floater}>&nbsp;</div>
                </div>
                <div className={classes.box}>
                    <Pill title={intl.formatMessage({ id: 'score.grant' })} score={score?.grant} />
                    <Pill title={intl.formatMessage({ id: 'score.loan' })} score={score?.loan} />
                    <Pill title={intl.formatMessage({ id: 'score.investment' })} score={score?.investment} />
                    <Pill title={intl.formatMessage({ id: 'score.lite' })} score={liteScore?.liteScore} />
                </div>
                {missingValues !== null && missingValues?.length > 0 ? (
                    <div className={classes.missingInfo}>
                        <div>{intl.formatMessage({ id: 'score.missing' })}</div>
                        <div>{intl.formatMessage({ id: 'score.info' })}</div>
                        <p>{intl.formatMessage({ id: 'score.updateProfile' })}</p>
                    </div>
                ) : null}
            </>
        );
    };

    const loader = () => {
        return (
            <div className={classes.loader}>
                <h3>{intl.formatMessage({ id: 'score.calculating' })}</h3>
                <CircularProgress color='secondary' />
            </div>
        );
    };

    return (
        <GridContainer
            justify='center'
            alignItems='center'
            className={classes.root}
            style={{ paddingTop: missingValues !== null && missingValues?.length > 0 && '3rem' }}
        >
            <GridItem>{loading ? loader() : content(score, missingValues)}</GridItem>
        </GridContainer>
    );
};

export default Score;
