import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useForm } from 'react-hook-form';

import WorkPackageList from './WorkPackageList';

import Button from 'components/CustomButtons/Button';

const FinanceData = (props) => {
  const { workPackage, pristine, submitting, onSubmit, onRemove } = props;

  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      workPackage,
    },
  });

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))} style={{width: '100%'}}>
      <GridItem noPaddingSide>
        <WorkPackageList
          data={workPackage}
          control={control}
          register={register}
          setValue={setValue}
          handleSubmit={handleSubmit}
          onRemove={onRemove}
          {...props}
        />

        <GridContainer
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          style={{ paddingTop: '3rem' }}
        >
          <Button round="true" disabled={pristine || submitting}>Peruuta</Button>

          <Button round="true" color="primary" disabled={submitting} type="submit">
            Tallenna
          </Button>
        </GridContainer>
      </GridItem>
    </form>
  );
};

export default FinanceData;
