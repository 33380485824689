import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

import { GridItem, GridContainer } from 'components/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    roleItem: {
        paddingLeft: '1rem'
    },
    userList: {
        marginBottom: '2rem'
    },
    buttonGrid: {
        height: '100%'
    }
}));

const UserForm = ({ user, control, errors }) => {
    const intl = useIntl();
    const classes = useStyles();

    const [checkedRoles, setCheckedValues] = useState([]);

    function handleSelect(role) {
        const rolesNew = checkedRoles?.includes(role)
            ? checkedRoles?.filter(name => name !== role)
            : [...(checkedRoles ?? []), role];
        setCheckedValues(rolesNew);

        return rolesNew;
    }

    const getLabel = (name) => {
        switch (name) {
            case 'Company.Read':
                return `${intl.formatMessage({ id: 'account.users.roles.read' })}`;
            case 'Company.Contributor':
                return `${intl.formatMessage({ id: 'account.users.roles.contribute' })}`;
            case 'Company.Notified':
                return `${intl.formatMessage({ id: 'account.users.roles.notified' })}`;
            default:
                return name;
        }
    };

    return (
        <GridContainer noPadding>
            <GridItem xs={12} noPadding>
                <GridContainer noPadding>
                    <GridItem xs>
                        <FormControl fullWidth>
                            <Controller
                                name={'user.email'}
                                label={intl.formatMessage({ id: 'account.users.username' })}
                                value={user.email}
                                control={control}
                                error={errors.user?.email ? true : false}
                                helperText={errors.user?.email ? errors.user?.email.message : null}
                                as={
                                    <TextField
                                        placeholder={intl.formatMessage({ id: 'account.users.username.placeholder' })}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem xs={6}>
                        <FormControl fullWidth>
                            <Controller
                                value={user.firstname}
                                name={'user.firstname'}
                                label={intl.formatMessage({ id: 'account.users.firstname' })}
                                control={control}
                                error={errors.user?.firstname ? true : false}
                                helperText={errors.user?.firstname ? errors.user?.firstname.message : null}
                                as={
                                    <TextField
                                        placeholder={intl.formatMessage({ id: 'account.users.firstname' })}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem xs={6}>
                        <FormControl fullWidth>
                            <Controller
                                name={'user.lastname'}
                                label={intl.formatMessage({ id: 'account.users.lastname' })}
                                control={control}
                                error={errors.user?.lastname ? true : false}
                                helperText={errors.user?.lastname ? errors.user?.lastname.message : null}
                                as={
                                    <TextField
                                        placeholder={intl.formatMessage({ id: 'account.users.lastname' })}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                }
                            />
                        </FormControl>
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={12} noPadding>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Roolit</FormLabel>
                    <FormGroup>
                        {['Company.Read', 'Company.Contributor', 'Company.Notified'].map((name, index) => (
                            <FormControlLabel className={classes.roleItem} key={name + index}
                                control={
                                    <Controller
                                        name="user.roles"
                                        render={({ onChange: onCheckChange }) => {
                                            return (
                                                <Switch
                                                    checked={checkedRoles.includes(name)}
                                                    onChange={() => onCheckChange(handleSelect(name))}
                                                />
                                            );
                                        }}
                                        control={control}
                                        error={errors.user?.roles ? true : false}
                                        helperText={errors.user?.roles ? errors.user?.roles.message : null}
                                    />
                                }
                                label={getLabel(name)}
                            />
                        ))}
                    </FormGroup>
                    <FormHelperText error={errors.user?.roles ? true : false}>{intl.formatMessage({ id: 'account.users.roles.error.notchosen' })}</FormHelperText>
                </FormControl>
            </GridItem>
        </GridContainer>
    );
};

export default UserForm;