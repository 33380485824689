import {
  LOAD_ROBOT_EVENTS,
  LOAD_ROBOT_EVENTS_ERROR,
  LOAD_ROBOT_EVENTS_SUCCESS,
  START_ROBOT,
  START_ROBOT_SUCCESS
} from './constants';

export function loadRobotEvents(businessId) {
  return {
    type: LOAD_ROBOT_EVENTS,
    businessId
  };
}

export function loadRobotEventsSuccess(data) {
  return {
    type: LOAD_ROBOT_EVENTS_SUCCESS,
    data,
  };
}

export function loadRobotEventsError(error) {
  return {
    type: LOAD_ROBOT_EVENTS_ERROR,
    error,
  };
}

export function startRobot() {
  return {
    type: START_ROBOT
  };
}

export function startRobotSuccess(data) {
  return {
    type: START_ROBOT_SUCCESS,
    data,
  };
}