import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import tableStyles from 'assets/jss/grants/calculationTables';

const useStyles = makeStyles(tableStyles);

export const FinanceTableTitle = ({ value }) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      disabled
      placeholder={value}
      name={value}
      id={value}
      InputProps={{
        disableUnderline: true, // eslint-disable-line 
        classes: {
          root: classes.inputRoot,
          disabled: classes.disabled,
        },
      }}
    />
  );
};

export default FinanceTableTitle;
