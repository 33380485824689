import React from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/ControlPoint';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.success.main,
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));

const AddButton = ({ size = 'medium', text, onClick }) => {
    const classes = useStyles();

    return (
        <Button color='primary' size={size} onClick={onClick} className={classes.root}>
            <SaveIcon className={classes.icon} /> {text}
        </Button>
    );
};

export default AddButton;
