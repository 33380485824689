import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import InstrumentList from 'containers/Instruments/InstrumentList';

const useStyles = makeStyles(() => ({
    loading: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

const Instruments = forwardRef((props, ref) => {
    const { addToTimeline } = props;
    const classes = useStyles();
    const intl = useIntl();

    const instruments = useSelector(state => state.radar?.instruments);
    const company = useSelector(state => state.company);

    if (company === undefined || company === null) return null;

    if (instruments === undefined || instruments === null) {
        return <Typography align='center'>{intl.formatMessage({ id: 'instruments.no.instruments' })}</Typography>;
    }

    const loading = instruments && instruments.loading;

    const instrumentList = loading ? (
        <Box className={classes.loading}>
            <CircularProgress size={48} />
        </Box>
    ) : (
        <InstrumentList
            instruments={instruments}
            company={company.company}
            ref={ref}
            addToTimeline={addToTimeline}
        />
    );

    return instrumentList;
});

export default Instruments;
