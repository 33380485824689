import React, { useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import InstrumentItem from './InstrumentItem';
import SuitableInstrumentsPanel from './SuitableInstrumentsPanel';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '100%',
        padding: 0,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    list: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

const InstrumentList = forwardRef(({ instruments, company, openChat, addToTimeline }, ref) => {
    const types = instruments?.length ? instruments.map(({ type }) => type) : null;
    const uniqueTypes = types ? [...new Set(types)] : [];
    const dublicateTypes = types?.reduce(
        (acc, value) => ({
            ...acc,
            [value]: (acc[value] || 0) + 1,
        }),
        {}
    );

    const providers = instruments?.length ? instruments.map(({ provider }) => provider) : null;
    const uniqueProviders = providers ? [...new Set(providers)] : [];
    const dublicateProviders = providers?.reduce(
        (acc, value) => ({
            ...acc,
            [value]: (acc[value] || 0) + 1,
        }),
        {}
    );

    const classes = useStyles();
    const [checked, setChecked] = useState([...uniqueTypes, ...uniqueProviders]);

    const messages = useSelector(state => state.messages);

    if (instruments === undefined) return null;

    const renderItems =
        instruments?.length &&
        instruments.filter(({ type, provider }) => checked.includes(type) && checked.includes(provider));

    const filterList = (values, uniqueItems) => {
        const checkedWithoutUniqueItems = checked.filter(item => !uniqueItems.includes(item));
        const newChecked = [...checkedWithoutUniqueItems, ...values];

        setChecked(newChecked);
    };

    const customDataString = messages?.messages[0]?.customData;

    let customDataArray = [];
    if (customDataString) {
        try {
            customDataArray = JSON.parse(customDataString);
        } catch (error) {
            console.log(error);
        }
    }

    const newInstruments = customDataArray.filter(({ IsNew }) => IsNew).map(({ InstrumentId }) => InstrumentId);
    const improvedInstruments = customDataArray.filter(({ IsNew }) => IsNew === false).map(({ InstrumentId }) => InstrumentId);

    return (
        <Container className={classes.container} ref={ref}>
            <SuitableInstrumentsPanel
                uniqueTypes={uniqueTypes}
                dublicateTypes={dublicateTypes}
                uniqueProviders={uniqueProviders}
                dublicateProviders={dublicateProviders}
                filterList={filterList}
            />
            <List className={`${!renderItems.length && classes.list}`}>
                {renderItems && renderItems.length ? (
                    renderItems
                        .sort((a, b) => improvedInstruments.includes(b.id) - improvedInstruments.includes(a.id))
                        .sort((a, b) => newInstruments.includes(b.id) - newInstruments.includes(a.id))
                        .map(instrument => (
                            <InstrumentItem
                                key={instrument.id}
                                instrument={instrument}
                                company={company}
                                newInstruments={newInstruments}
                                improvedInstruments={improvedInstruments}
                                openChat={openChat}
                                addToTimeline={addToTimeline}
                            />
                        ))
                ) : (
                    <Typography component='p' color='primary'>
                        <FormattedMessage id='instrument.data.notfound' />
                    </Typography>
                )}
            </List>
        </Container>
    );
});

export default InstrumentList;
