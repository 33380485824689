import React, { forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

import InstrumentForm from './InstrumentForm';

import { addInstrumentToTimeline, editInstrumentInTimeline } from 'containers/Timeline/actions';

const useStyles = makeStyles(theme => ({
    dialogContent: {
        backgroundColor: theme.palette.primary.white,
    },
    dialogActions: {
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.primary.white,
    },
    dialogTitleSubtext: {
        fontSize: '0.875rem',
        paddingTop: theme.spacing(0.5),
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
        },
    },
    closeButton: {
        padding: 0,
    },
    closeIcon: {
        color: theme.palette.primary.main,
    },
    header: {
        fontSize: '1.6rem',
    },
    box: {
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: '1.1rem',
    },
    wrapper: {
        position: 'relative',
    },
    submitButton: {
        fontWeight: 600,
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            opacity: '0.8',
            backgroundColor: theme.palette.success.main,
        },
        '&:disabled': {
            // FIXME: this has no effect
            opacity: '0.6',
            backgroundColor: theme.palette.success.main,
        },
    },
    buttonLoading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: theme.spacing(-1.5),
        marginLeft: theme.spacing(-1.5),
        color: theme.palette.primary.white,
    },
    deleteButton: {
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.main,
            opacity: 0.8,
        },
    },
    error: {
        fontSize: '0.75rem',
        color: theme.palette.error.main,
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const InstrumentModal = ({ close, instruments, editMode, setConfirmOpen, loading }) => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    const formRef = useRef();
    const onSubmit = data => {
        if (typeof data.projectSize !== 'number') data.projectSize = parseFloat(data.projectSize);
        if (typeof data.grantAmount !== 'number') data.grantAmount = parseFloat(data.grantAmount);

        const confirmedInstrument = { ...instrument, ...data };

        if (editMode) {
            dispatch(editInstrumentInTimeline(confirmedInstrument));
        } else {
            dispatch(addInstrumentToTimeline(confirmedInstrument));
        }
    };

    const timeline = useSelector(state => state.timeline);
    const isOpen = timeline?.isInstrumentModalOpen;
    const instrument = timeline?.instrument;

    const submitForm = () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

    return (
        <Dialog
            aria-labelledby='add to timeline'
            aria-describedby='add to timeline'
            fullWidth
            maxWidth='lg'
            open={isOpen}
            onClose={close}
            scroll='paper'
            TransitionComponent={Transition}
        >
            <DialogContent dividers className={classes.dialogContent}>
                <InstrumentForm ref={formRef} instrument={instrument} instruments={instruments} onSubmit={onSubmit} />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {editMode && (
                    <Button variant='contained' onClick={() => setConfirmOpen(true)} className={classes.deleteButton}>
                        {intl.formatMessage({ id: 'shared.remove' })}
                    </Button>
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <div>
                        <Button variant='outlined' color='primary' onClick={close} style={{ marginRight: '1rem' }}>
                            {intl.formatMessage({ id: 'common.close' })}
                        </Button>
                    </div>
                    <div>
                        <Box className={classes.wrapper}>
                            <Button
                                type='submit'
                                onClick={submitForm}
                                variant='contained'
                                disabled={loading}
                                className={classes.submitButton}
                            >
                                {intl.formatMessage({ id: 'common.save' })}
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonLoading} />}
                        </Box>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default InstrumentModal;
