import {
  LOAD_INSTRUMENT_RADAR,
  LOAD_INSTRUMENT_RADAR_SUCCESS,
  LOAD_INSTRUMENT_RADAR_ERROR,
  SET_COMPANY_LIMIT,
  LOAD_INSTRUMENTS,
  LOAD_INSTRUMENTS_SUCCESS,
  LOAD_INSTRUMENTS_ERROR,
  LOAD_SCENARIOS,
  LOAD_SCENARIOS_SUCCESS,
  LOAD_SCENARIOS_ERROR,
  SAVE_SCENARIO,
  REMOVE_SCENARIO,
  SAVE_MAIN_SCENARIO
} from './constants.js';

export function loadRadarData(businessId) {
  return {
    type: LOAD_INSTRUMENT_RADAR,
    businessId,
  };
}

export function loadRadarDataSuccess(radar) {
  return {
    type: LOAD_INSTRUMENT_RADAR_SUCCESS,
    radar,
  };
}

export function loadRadarDataError(error) {
  return {
    type: LOAD_INSTRUMENT_RADAR_ERROR,
    error,
  };
}

export function setCompanyLimit(company) {
  return {
    type: SET_COMPANY_LIMIT,
    company,
  };
}

export function loadInstrumentData() {
  return {
    type: LOAD_INSTRUMENTS,
  };
}

export function loadInstrumentDataSuccess(instruments) {
  return {
    type: LOAD_INSTRUMENTS_SUCCESS,
    instruments,
  };
}

export function loadInstrumentDataError(error) {
  return {
    type: LOAD_INSTRUMENTS_ERROR,
    error,
  };
}

export function loadScenarios(businessId) {
  return {
    type: LOAD_SCENARIOS,
    businessId
  };
}

export function loadScenariosSuccess(scenarios) {
  return {
    type: LOAD_SCENARIOS_SUCCESS,
    scenarios,
  };
}

export function loadScenariosError(error) {
  return {
    type: LOAD_SCENARIOS_ERROR,
    error,
  };
}

export function saveScenario(scenario) {
  return {
    type: SAVE_SCENARIO,
    scenario
  };
}

export function saveMainScenario(scenario) {
  return {
    type: SAVE_MAIN_SCENARIO,
    scenario
  };
}

export function removeScenario(scenarioId) {
  return {
    type: REMOVE_SCENARIO,
    scenarioId,
  };
}