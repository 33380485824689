import {
  LOAD_COMPANY_FINANCE,
  LOAD_COMPANY_FINANCE_SUCCESS,
  LOAD_COMPANY_FINANCE_ERROR,
  SAVE_COMPANY_FINANCE,
} from './constants';

export function loadFinanceData(businessId) {
  return {
    type: LOAD_COMPANY_FINANCE,
    businessId,
  };
}

export function loadFinanceDataSuccess(finance) {
  return {
    type: LOAD_COMPANY_FINANCE_SUCCESS,
    finance,
  };
}

export function loadFinanceDataError(error) {
  return {
    type: LOAD_COMPANY_FINANCE_ERROR,
    error,
  };
}

export function saveCompanyFinance(values) {
  return {
    type: SAVE_COMPANY_FINANCE,
    values,
  };
}