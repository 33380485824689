import { defineMessages } from 'react-intl';

export const businessplanScope = defineMessages({
  businessIdea: {
    id: 'businessplan.businessIdea',
    title: {
      id: 'businessplan.businessIdea.title',
    },
    placeholder: {
      id: 'businessplan.businessIdea.placeholder',
    },
    helper: [
      {
        id: 'businessplan.businessIdea.helper.0'
      },
      {
        id: 'businessplan.businessIdea.helper.1'
      },
      {
        id: 'businessplan.businessIdea.helper.2'
      }
    ]
  },
  coreTeam: {
    id: 'businessplan.coreTeam',
    title: {
      id: 'businessplan.coreTeam.title',
    },
    placeholder: {
      id: 'businessplan.coreTeam.placeholder',
    },
    helper: [
      {
        id: 'businessplan.coreTeam.helper.0'
      },
      {
        id: 'businessplan.coreTeam.helper.1'
      },
      {
        id: 'businessplan.coreTeam.helper.2'
      }
    ]
  },
  generalPicture: {
    id: 'businessplan.generalPicture',
    title: {
      id: 'businessplan.generalPicture.title',
    },
    placeholder: {
      id: 'businessplan.generalPicture.placeholder',
    },
    helper: [
      {
        id: 'businessplan.generalPicture.helper.0'
      },
      {
        id: 'businessplan.generalPicture.helper.1'
      },
      {
        id: 'businessplan.generalPicture.helper.2'
      }
    ]
  },
  productsAndServices: {
    id: 'businessplan.productsAndServices',
    title: {
      id: 'businessplan.productsAndServices.title',
    },
    placeholder: {
      id: 'businessplan.productsAndServices.placeholder',
    },
    helper: [
      {
        id: 'businessplan.productsAndServices.helper.0'
      },
      {
        id: 'businessplan.productsAndServices.helper.1'
      },
      {
        id: 'businessplan.productsAndServices.helper.2'
      },
      {
        id: 'businessplan.productsAndServices.helper.3'
      },
      {
        id: 'businessplan.productsAndServices.helper.4'
      }
    ]
  },
  revenueModel: {
    id: 'businessplan.revenueModel',
    title: {
      id: 'businessplan.revenueModel.title',
    },
    placeholder: {
      id: 'businessplan.revenueModel.placeholder',
    },
    helper: [
      {
        id: 'businessplan.revenueModel.helper.0'
      },
      {
        id: 'businessplan.revenueModel.helper.1'
      },
      {
        id: 'businessplan.revenueModel.helper.2'
      }
    ]
  },
  targetMarkets: {
    id: 'businessplan.targetMarkets',
    title: {
      id: 'businessplan.targetMarkets.title',
    },
    placeholder: {
      id: 'businessplan.targetMarkets.placeholder',
    },
    helper: [
      {
        id: 'businessplan.targetMarkets.helper.0'
      },
      {
        id: 'businessplan.targetMarkets.helper.1'
      },
      {
        id: 'businessplan.targetMarkets.helper.2'
      }
    ]
  },
  customers: {
    id: 'businessplan.customers',
    title: {
      id: 'businessplan.customers.title',
    },
    placeholder: {
      id: 'businessplan.customers.placeholder',
    },
    helper: [
      {
        id: 'businessplan.customers.helper.0'
      },
      {
        id: 'businessplan.customers.helper.1'
      },
      {
        id: 'businessplan.customers.helper.2'
      }
    ]
  },
  markets: {
    id: 'businessplan.markets',
    title: {
      id: 'businessplan.markets.title',
    },
    placeholder: {
      id: 'businessplan.markets.placeholder',
    },
    helper: [
      {
        id: 'businessplan.markets.helper.0'
      },
      {
        id: 'businessplan.markets.helper.1'
      },
      {
        id: 'businessplan.markets.helper.2'
      }
    ]
  },
  competition: {
    id: 'businessplan.competition',
    title: {
      id: 'businessplan.competition.title',
    },
    placeholder: {
      id: 'businessplan.competition.placeholder',
    },
    helper: [
      {
        id: 'businessplan.competition.helper.0'
      },
      {
        id: 'businessplan.competition.helper.1'
      },
      {
        id: 'businessplan.competition.helper.2'
      },
      {
        id: 'businessplan.competition.helper.3'
      }
    ]
  },
  businessGoals: {
    id: 'businessplan.businessGoals',
    title: {
      id: 'businessplan.businessGoals.title',
    },
    placeholder: {
      id: 'businessplan.businessGoals.placeholder',
    },
    helper: [
      {
        id: 'businessplan.businessGoals.helper.0'
      },
      {
        id: 'businessplan.businessGoals.helper.1'
      },
      {
        id: 'businessplan.businessGoals.helper.2'
      }
    ]
  },
  schedule: {
    id: 'businessplan.schedule',
    title: {
      id: 'businessplan.schedule.title',
    },
    placeholder: {
      id: 'businessplan.schedule.placeholder',
    },
    helper: [
      {
        id: 'businessplan.schedule.helper.0'
      },
      {
        id: 'businessplan.schedule.helper.1'
      },
      {
        id: 'businessplan.schedule.helper.2'
      },
      {
        id: 'businessplan.schedule.helper.3'
      },
      {
        id: 'businessplan.schedule.helper.4'
      }
    ]
  },
});
