import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    title: {
        paddingLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: '100%',
    },
    chip: {
        margin: theme.spacing(0.5),
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '&.Mui-outlined:hover, &.Mui-outlined:focus, &.MuiChip-clickable:hover': {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.75,
        },
        '&.MuiChip-clickable:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    chipPossible: {
        margin: theme.spacing(0.5),
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        '&.Mui-outlined:hover, &.Mui-outlined:focus, &.MuiChip-clickable:hover': {
            backgroundColor: theme.palette.success.main,
            opacity: 0.75,
        },
        '&.MuiChip-clickable:focus': {
            backgroundColor: theme.palette.success.main,
        },
    },
    chipPlausible: {
        margin: theme.spacing(0.5),
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.primary.darkYellow,
        borderColor: theme.palette.primary.darkYellow,
        '&.Mui-outlined:hover, &.Mui-outlined:focus, &.MuiChip-clickable:hover': {
            backgroundColor: theme.palette.primary.darkYellow,
            opacity: 0.75,
        },
        '&.MuiChip-clickable:focus': {
            backgroundColor: theme.palette.primary.darkYellow,
        },
    },
    chipNotActive: {
        margin: theme.spacing(0.5),
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '&.Mui-outlined:hover, &.Mui-outlined:focus, &.MuiChip-clickable:hover': {
            opacity: 0.75,
        },
    },
    chipPossibleNotActive: {
        margin: theme.spacing(0.5),
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        '&.Mui-outlined:hover, &.Mui-outlined:focus, &.MuiChip-clickable:hover': {
            opacity: 0.75,
        },
    },
    chipPlausibleNotActive: {
        margin: theme.spacing(0.5),
        color: theme.palette.primary.darkYellow,
        borderColor: theme.palette.primary.darkYellow,
        '&.Mui-outlined:hover, &.Mui-outlined:focus, &.MuiChip-clickable:hover': {
            opacity: 0.75,
        },
    },
    chipsAll: {
        margin: theme.spacing(0.5),
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        '&.Mui-outlined:hover, &.Mui-outlined:focus, &.MuiChip-clickable:hover': {
            //color: theme.palette.primary.white,
            opacity: 0.75,
        },
        '&.MuiChip-clickable:focus': {
            //color: theme.palette.primary.white,
        },
        '& svg': {
            color: theme.palette.info.main
        }
    },
    chipsNone: {
        margin: theme.spacing(0.5),
        color: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        '&.Mui-outlined:hover, &.Mui-outlined:focus, &.MuiChip-clickable:hover': {
            color: theme.palette.info.main,
            opacity: 0.75,
        },
        '& svg': {
            color: theme.palette.info.main
        }
    },
}));

const ChipList = ({ label, uniqueItems, dublicateItems, filterList }) => {
    const classes = useStyles();
    const intl = useIntl();
    const [clicked, setClicked] = useState(uniqueItems);
    const [all, setAll] = useState(true);

    const handleClick = item => {
        const updatedItems = clicked.includes(item) ? clicked.filter(elem => elem !== item) : [...clicked, item];

        setClicked(updatedItems);
        filterList(updatedItems, uniqueItems);

        if (updatedItems.length !== uniqueItems.length) {
            setAll(false);
        } else {
            setAll(true);
        }
    };

    const toggleAll = () => {
        if (all) {
            setClicked([]);
            filterList([], uniqueItems);
            setAll(false);
        } else {
            setClicked(uniqueItems);
            filterList(uniqueItems, uniqueItems);
            setAll(true);
        }
    };

    const sumValues = Object.values(dublicateItems).reduce((a, b) => a + b, 0);

    return (
        <>
            <Typography component='p' className={classes.title}>
                {intl.formatMessage({ id: `check.item.${label}` })}:
            </Typography>
            <Box className={classes.root}>
                <Chip
                    variant='outlined'
                    label={`${intl.formatMessage({ id: 'common.all' })} (${sumValues} ${intl.formatMessage({ id: 'common.pcs' })})`}
                    onClick={toggleAll}
                    onDelete={toggleAll}
                    className={!all ? classes.chipsNone : classes.chipsAll}
                    deleteIcon={!all ? <RadioButtonUncheckedOutlinedIcon /> : <CheckCircleOutlineOutlinedIcon /> }
                />
                {uniqueItems?.map(item => {
                    return (
                        <li key={item}>
                            <Chip
                                variant='outlined'
                                label={`${label === 'providers' ? item : intl.formatMessage({ id: `check.item.${item}` })} (${
                                    dublicateItems[item]
                                } ${intl.formatMessage({
                                    id: 'common.pcs',
                                })})`}
                                onClick={() => handleClick(item)}
                                className={
                                    clicked.includes(item) && item !== 1 && item !== 2
                                        ? classes.chip
                                        : clicked.includes(item) && item === 1
                                        ? classes.chipPlausible
                                        : clicked.includes(item) && item === 2
                                        ? classes.chipPossible
                                        : item === 1
                                        ? classes.chipPlausibleNotActive
                                        : item === 2
                                        ? classes.chipPossibleNotActive
                                        : classes.chipNotActive
                                }
                            />
                        </li>
                    );
                })}
            </Box>
        </>
    );
};

export default ChipList;
