import React from 'react';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        paddingTop: theme.spacing(2),
    },
    formLabel: {
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    label: {
        color: theme.palette.primary.main,
    },
}));

const RadioItem = ({ type, values, scenarios, control, name }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <FormControl component='fieldset' className={classes.formControl}>
            <FormLabel component='legend' classes={{ root: classes.formLabel }}>
                {intl.formatMessage({ id: `instrument.radar.choice.${type}` })}
            </FormLabel>
            <Controller
                name={type}
                control={control}
                defaultValue={scenarios[type] || 1}
                render={({ value, onChange }) => {
                    return (
                        <RadioGroup aria-label={type} name={type} value={value} onChange={event => onChange(+event.target.value)}>
                            {values.map(({ value, content }) => (
                                <FormControlLabel
                                    key={value}
                                    value={value}
                                    control={<Radio color='primary' />}
                                    label={content}
                                    classes={{ root: classes.label }}
                                />
                            ))}
                        </RadioGroup>
                    );
                }}
            />
        </FormControl>
    );
};

export default RadioItem;
