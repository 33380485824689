import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    section: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        boxShadow: '0px 10px 12px -8px rgba(34, 60, 80, 0.1) inset',
    },
    sectionSmallerPadding: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
        '& h2, h3, h4, p, label, span, li, td': {
            color: theme.palette.primary.main,
        },
    },
    contentSmallerPadding: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
    },
    selected: {
        backgroundColor: 'rgba(7, 120, 113, 0.1)',
        borderLeft: '0.25rem solid rgba(7, 120, 113, 1)',
        '&>div': {
            paddingLeft: '2.75rem',
        },
        '& h2, h3, h4, p, li, td': {
            color: theme.palette.success.main,
        },
        '& img': {
            filter: 'invert(18%) sepia(88%) saturate(5304%) hue-rotate(171deg) brightness(94%) contrast(94%)',
        },
    },
    selectedSmallerPadding: {
        backgroundColor: 'rgba(7, 120, 113, 0.1)',
        borderLeft: '0.25rem solid rgba(7, 120, 113, 1)',
        '&>div': {
            paddingLeft: '1.75rem',
        },
    },
}));

const MainSection = ({ id, children, selected, smallerPadding, hidden }) => {
    const classes = useStyles();

    if (hidden) return null;

    return (
        <div
            id={id}
            className={`${classes.section} ${smallerPadding && classes.sectionSmallerPadding} ${
                selected === id && !smallerPadding
                    ? classes.selected
                    : selected === id && smallerPadding
                    ? classes.selectedSmallerPadding
                    : ''
            } ${id === 'timeline' && 'timelineBox'} sectionPrint`}
        >
            <div className={` ${smallerPadding ? classes.contentSmallerPadding : classes.content} contentPrint`}>{children}</div>
        </div>
    );
};

export default MainSection;
