import React from 'react';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        paddingTop: theme.spacing(2),
    },
    formLabel: {
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    root: {
        '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
        },
    },
    switchBase: {
        color: theme.palette.primary.white,
        '&$checked': {
            color: theme.palette.primary.white,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.success.main,
            opacity: 1,
        },
    },
    label: {
        color: theme.palette.primary.main,
    },
    track: {}, // empty object is required
    checked: {}, // empty object is required
}));

const SwitchGroup = ({ items, scenarios, control, name }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <FormControl component='fieldset' className={classes.formControl}>
            <FormLabel component='legend' classes={{ root: classes.formLabel }}>
                {intl.formatMessage({ id: `profile.modal.${name}` })}
            </FormLabel>
            <FormGroup>
                {items.map(({ type }) => (
                    <Controller
                        key={type}
                        name={type}
                        control={control}
                        defaultValue={scenarios[type] || 1}
                        render={({ value, onChange }) => {
                            const checked = value === 1 ? false : value === 2 ? true : value;

                            return (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checked}
                                            onChange={event => onChange(event.target.checked ? 2 : 1)}
                                            name={type}
                                            classes={{
                                                root: classes.root,
                                                switchBase: classes.switchBase,
                                                track: classes.track,
                                                checked: classes.checked,
                                            }}
                                        />
                                    }
                                    label={intl.formatMessage({ id: `instrument.radar.choice.${type}` })}
                                    classes={{ root: classes.label }}
                                />
                            );
                        }}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

export default SwitchGroup;
