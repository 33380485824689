import produce from 'immer';
import {
  LOAD_ROBOT_EVENTS,
  LOAD_ROBOT_EVENTS_ERROR,
  LOAD_ROBOT_EVENTS_SUCCESS,
  START_ROBOT,
  START_ROBOT_SUCCESS
} from './constants';

export const initialState = {
  loading: false,
  error: false,
  data: [],
  startData: null,
};

/* eslint-disable default-case, no-param-reassign */
const companySearchReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_ROBOT_EVENTS:
        draft.loading = LOAD_ROBOT_EVENTS;
        draft.error = false;
        break;

      case LOAD_ROBOT_EVENTS_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.data = action.data;
        break;

      case LOAD_ROBOT_EVENTS_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;

      case START_ROBOT:
        draft.loading = true;
        draft.error = false;
        break;

      case START_ROBOT_SUCCESS:
        draft.loading = false;
        draft.startData = action.data;
        draft.error = false;
        break;
    }
  });

export default companySearchReducer;
