import React, { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import GridContainer from 'components/Grid/GridContainer';
import {
	makeSelectLoading,
	makeSelectError,
	makeSelectWorkPackage,
} from './selectors';
import { saveCompanyWorkPackage, loadWorkPackages, removeWorkPackage } from './actions';
import saga from './saga';
import reducer from './reducer';
import Form from './form';
import ContentLoader from 'components/LoadingIndicator/ContentLoader';

const key = 'workPackage';
let toasterMessages = {};

export const WorkPackage = (props) => {
	useInjectReducer({ key, reducer });
	useInjectSaga({ key, saga });

	const intl = useIntl();

	toasterMessages = {
		success: {
			title: intl.formatMessage({
				id: 'messages.generic.success.title',
			}),
			body: intl.formatMessage({
				id: 'messages.workpackage.save.success',
			}),
			remove: {
				body: intl.formatMessage({
					id: 'messages.workpackage.remove.success',
				})
			}
		},
		error: {
			title: intl.formatMessage({
				id: 'messages.generic.error.title',
			}),
			body: intl.formatMessage({
				id: 'messages.workpackage.error.body',
			}),
		},
	};

	const {
		workPackage,
		onSubmit,
		loading,
		onload,
		onRemove,
		match: {
			params: { businessId },
		},
	} = props;

	useEffect(() => {
		onload(businessId);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (loading || workPackage === undefined || workPackage === null) {
		return (
			<React.Fragment>
				{loading ? <ContentLoader /> : null}
			</React.Fragment>
		);
	}

	return (
		<GridContainer>
			<Form workPackage={workPackage} onSubmit={onSubmit} onRemove={onRemove} />
		</GridContainer>
	);
};

WorkPackage.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	workPackage: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
	onSubmit: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	workPackage: makeSelectWorkPackage(),
	loading: makeSelectLoading(),
	error: makeSelectError(),
});

export function mapDispatchToProps(dispatch) {
	return {
		onSubmit: (data) => dispatch(saveCompanyWorkPackage(data, toasterMessages)),
		onload: (id) => dispatch(loadWorkPackages(id, toasterMessages)),
		onRemove: (data) => dispatch(removeWorkPackage(data, toasterMessages))
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(WorkPackage);
