import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import ContentLoader from 'components/LoadingIndicator/ContentLoader';

import { getBusinessPlan, getError, getLoading } from './selectors';
import saga from './saga';
import reducer from './reducer';
import { loadBusinessPlanData, saveBusinessPlanData } from './actions';

import Form from './form';

const key = 'businessPlan';

const BusinessPlanData = (props) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const {
    loading,
    businessPlan,
    onSubmit,
    onload,
    match: {
      params: { businessId },
    },
  } = props;

  useEffect(() => {
    onload(businessId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || businessPlan === undefined || businessPlan === null) {
    return (
      <React.Fragment>
          <React.Fragment>{loading ? <ContentLoader /> : null}</React.Fragment>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Form onSubmit={onSubmit} businessPlan={businessPlan} {...props} />
    </React.Fragment>
  );
};

BusinessPlanData.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  businessPlan: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  businessPlan: getBusinessPlan()(state),
  loading: getLoading()(state),
  error: getError()(state),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (values) => dispatch(saveBusinessPlanData(values)),
    onload: (id) => dispatch(loadBusinessPlanData(id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(BusinessPlanData);
