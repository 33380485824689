import React from 'react';
import { useIntl } from 'react-intl';
import { useFieldArray, Controller, useWatch } from 'react-hook-form';
import MenuItem from '@material-ui/core/MenuItem';

import { GrantsInput } from 'components/GrantsComponents/Input';
import Select from 'components/GrantsComponents/Select';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import RemoveLineButton from 'components/GrantsComponents/RemoveLineButton';
import AddButton from 'components/GrantsComponents/AddButton';
import CurrencyField from 'components/CurrencyField';
import { workpackageExpense } from '../messages';

const Tasks = props => {
    const { control, parentKey } = props;
    const intl = useIntl();

    const { fields, remove, append } = useFieldArray({
        control,
        name: `${parentKey}.expenses`,
    });

    const watchTasks = useWatch({
        name: `${parentKey}.expenses`,
        control,
    });

    const isSalaryExpense = index => {
        return watchTasks !== undefined &&
            watchTasks !== null &&
            watchTasks[index] !== undefined &&
            watchTasks[index].type === 'salaryExpense'
            ? true
            : false;
    };

    const renderTasks = () => {
        if (fields === undefined || fields === null) {
            return <React.Fragment></React.Fragment>;
        }

        return fields.map((item, index) => (
            <GridContainer key={item.id}>
                <GridItem xs={12} sm={4}>
                    <Controller
                        name={`${parentKey}.expenses[${index}].id`}
                        control={control}
                        defaultValue={item.id}
                        render={() => <input type='hidden' value={item.id} />}
                    />
                    <Controller
                        name={`${parentKey}.expenses[${index}].name`}
                        control={control}
                        defaultValue={item.name ?? ''}
                        render={props => <GrantsInput variant='standard' label={intl.formatMessage(workpackageExpense.name)} {...props} />}
                    />
                </GridItem>
                <GridItem xs={12} sm={4}>
                    <Controller
                        name={`${parentKey}.expenses[${index}].type`}
                        control={control}
                        defaultValue={item.type}
                        label={intl.formatMessage(workpackageExpense.type)}
                        as={
                            <Select>
                                <MenuItem value=''>
                                    <em>{intl.formatMessage(workpackageExpense.type)}</em>
                                </MenuItem>
                                <MenuItem value='servicePurchase'>{intl.formatMessage(workpackageExpense.typeServicePurchase)}</MenuItem>
                                <MenuItem value='salaryExpense'>{intl.formatMessage(workpackageExpense.typeSalaryExpense)}</MenuItem>
                                <MenuItem value='investment'>{intl.formatMessage(workpackageExpense.typeInvestment)}</MenuItem>
                                <MenuItem value='traveling'>{intl.formatMessage(workpackageExpense.typeTraveling)}</MenuItem>
                            </Select>
                        }
                    />
                </GridItem>
                <GridItem xs={12} sm={3}>
                    <Controller
                        name={`${parentKey}.expenses[${index}].amount`}
                        control={control}
                        defaultValue={item.amount ?? '0'}
                        render={props => (
                            <CurrencyField
                                value={item?.amount}
                                label={intl.formatMessage(workpackageExpense.amount)}
                                onChange={props.onChange}
                                positionEnd={true}
                            />
                        )}
                    />
                </GridItem>
                <GridItem xs={12} sm={1}>
                    <RemoveLineButton onClick={() => remove(index)} />
                </GridItem>
                {isSalaryExpense(index) === true ? (
                    <GridItem>
                        <GridContainer>
                            <GridItem xs={12} sm={3}>
                                <Controller
                                    name={`${parentKey}.expenses[${index}].resourceName`}
                                    control={control}
                                    defaultValue={item.resourceName ?? ''}
                                    render={props => (
                                        <GrantsInput
                                            variant='standard'
                                            label={intl.formatMessage({
                                                id: 'workpackage.resource.name',
                                            })}
                                            {...props}
                                        />
                                    )}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={3}>
                                <Controller
                                    name={`${parentKey}.expenses[${index}].resourceAmount`}
                                    control={control}
                                    defaultValue={item.resourceAmount ?? ''}
                                    render={props => (
                                        <GrantsInput
                                            variant='standard'
                                            type='number'
                                            label={intl.formatMessage({
                                                id: 'workpackage.resource.amount',
                                            })}
                                            {...props}
                                        />
                                    )}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={3}>
                                <Controller
                                    name={`${parentKey}.expenses[${index}].resourceType`}
                                    label={intl.formatMessage({
                                        id: 'workpackage.resource.type.unit',
                                    })}
                                    control={control}
                                    defaultValue={item.resourceType}
                                    as={
                                        <Select>
                                            <MenuItem value=''>
                                                <em>
                                                    {intl.formatMessage({
                                                        id: 'workpackage.resource.type.unit',
                                                    })}
                                                </em>
                                            </MenuItem>
                                            <MenuItem value='typeUnitDay'>
                                                {intl.formatMessage({
                                                    id: 'workpackage.resource.type.unit.day',
                                                })}
                                            </MenuItem>
                                            <MenuItem value='typeUnitMonth'>
                                                {intl.formatMessage({
                                                    id: 'workpackage.resource.type.unit.month',
                                                })}
                                            </MenuItem>
                                            <MenuItem value='typeUnitYear'>
                                                {intl.formatMessage({
                                                    id: 'workpackage.resource.type.unit.year',
                                                })}
                                            </MenuItem>
                                        </Select>
                                    }
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                ) : null}
            </GridContainer>
        ));
    };

    return (
        <React.Fragment>
            {renderTasks()}
            <GridItem noPaddingSide xs={12}>
                <AddButton onClick={() => append()} text={intl.formatMessage(workpackageExpense.addExpense)} />
            </GridItem>
        </React.Fragment>
    );
};

export default Tasks;
