import produce from 'immer';
import _ from 'lodash';
import {
    LOAD_INSTRUMENT_RADAR,
    LOAD_INSTRUMENT_RADAR_SUCCESS,
    LOAD_INSTRUMENT_RADAR_ERROR,
    SET_COMPANY_LIMIT,
    LOAD_INSTRUMENTS_ERROR,
    LOAD_INSTRUMENTS_SUCCESS,
    LOAD_INSTRUMENTS,
    LOAD_SCENARIOS_ERROR,
    LOAD_SCENARIOS_SUCCESS,
    LOAD_SCENARIOS,
    SAVE_SCENARIO,
    REMOVE_SCENARIO,
    SAVE_MAIN_SCENARIO,
} from './constants.js';

export const initialLoading = {
    radar: false,
    instruments: false,
    scenarios: false,
};

export const initialState = {
    loading: initialLoading,
    error: false,
    loadingInstruments: false,
    loadingScenarios: false,
    radar: [
        {
            comparerStringType: 'IntLimitator',
            compareProperty: 'internationalEta',
            requiredValue: 2,
            isValid: false,
            companyValue: 1,
        },
        {
            comparerStringType: 'IntLimitator',
            compareProperty: 'ipr',
            requiredValue: 3,
            isValid: false,
            companyValue: 2,
        },
        {
            comparerStringType: 'IntLimitator',
            compareProperty: 'team',
            requiredValue: 24,
            isValid: false,
            companyValue: 8,
        },
        {
            comparerStringType: 'IntLimitator',
            compareProperty: 'turnover',
            requiredValue: 16,
            isValid: false,
            companyValue: 2,
        },
        {
            comparerStringType: 'IntLimitator',
            compareProperty: 'owner',
            requiredValue: 8,
            isValid: false,
            companyValue: 12,
        },
        {
            comparerStringType: 'IntLimitator',
            compareProperty: 'countrySide',
            requiredValue: 5,
            isValid: false,
            companyValue: 2,
            maximumValue: 3,
            domain: [1, 3],
        },
    ],
    company: {},
    scenarios: [{}],
};

/* eslint-disable default-case, no-param-reassign */
const radarRedurecer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case LOAD_INSTRUMENT_RADAR:
                draft.loading.radar = true;
                draft.error = false;
                break;

            case LOAD_INSTRUMENT_RADAR_SUCCESS:
                draft.loading.radar = false;
                draft.error = false;
                draft.radar = action.radar;
                break;

            case (LOAD_INSTRUMENT_RADAR_ERROR, LOAD_INSTRUMENTS_ERROR, LOAD_SCENARIOS_ERROR):
                draft.error = action.error;
                draft.loading = initialLoading;
                break;

            case SET_COMPANY_LIMIT:
                draft.error = action.error;
                draft.company = _.assign({}, action.company);
                break;

            case LOAD_INSTRUMENTS:
                draft.loading.instruments = true;
                draft.error = false;
                break;

            case LOAD_INSTRUMENTS_SUCCESS:
                draft.loading.instruments = false;
                draft.instruments = action.instruments;
                draft.error = false;
                break;

            case LOAD_SCENARIOS:
                draft.loading.scenarios = true;
                draft.error = false;
                break;

            case LOAD_SCENARIOS_SUCCESS:
                draft.loading.scenarios = false;
                draft.scenarios = action.scenarios;
                draft.error = false;
                break;

            case SAVE_SCENARIO:
                draft.loading.scenarios = true;
                break;

            case SAVE_MAIN_SCENARIO:
                draft.loading.scenarios = true;
                break;

            case REMOVE_SCENARIO:
                draft.loading.scenarios = true;
                break;
        }
    });

export default radarRedurecer;
