import produce from 'immer';
import _ from 'lodash';
import {
  LOAD_BUSINESS_PLAN,
  LOAD_BUSINESS_PLAN_ERROR,
  LOAD_BUSINESS_PLAN_SUCCESS,
  SAVE_BUSINESS_PLAN,
} from './constants';

export const initialState = {
  loading: false,
  error: false,
  businessPlan: {
    businessGoals: '',
    businessIdea: '',
    competition: '',
    coreTeam: '',
    customers: '',
    generalPicture: '',
    markets: '',
    productsAndServices: '',
    revenueModel: '',
    schedule: '',
    targetMarkets: '',
  },
};

/* eslint-disable default-case, no-param-reassign */
const businessPlanhReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_BUSINESS_PLAN:
        draft.loading = true;
        draft.error = false;
        break;

      case LOAD_BUSINESS_PLAN_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.businessPlan = _.assign({}, action.businessPlan.businessPlan);
        break;

      case LOAD_BUSINESS_PLAN_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;

      case SAVE_BUSINESS_PLAN:
        draft.loading = true;
        draft.error = false;
        break;
    }
  });

export default businessPlanhReducer;
