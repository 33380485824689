import React, { useState, useEffect } from 'react';
import publicApp, { config } from './config';
import { setToken as utilsSetToken, getAccessToken, isExpired } from 'utils/token';

export const logout = () => {
    publicApp.logout();
};

const isInteractionRequired = error => {
    if (!error.message || error.message.length <= 0) {
        return false;
    }

    return (
        error.message.indexOf('consent_required') > -1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
    );
};

const getMsalToken = async () => {
    const accounts = publicApp.getAllAccounts();

    if (accounts.length <= 0) throw new Error('login_required');

    const silentResult = await publicApp.acquireTokenSilent({
        config,
        account: accounts[0],
    });

    return silentResult;
};

export const getToken = async () => {
    try {
        let accessToken = getAccessToken();

        if (!accessToken || isExpired(accessToken, 5)) {
            accessToken = await getMsalToken();
            utilsSetToken(accessToken);
        }

        return accessToken;
    } catch (error) {
        console.log(error);
    }
};

export default function withAuthProvider(WrappedComponent) {
    return props => {
        const [error, setError] = useState(null);
        const [isAuthenticated, setIsAuthenticated] = useState(false);
        const [loading, setLoading] = useState(false);
        const [user, setUser] = useState({});
        const [token, setToken] = useState(null);
        // eslint-disable-next-line no-unused-vars
        const [accessToken, setAccessToken] = useState(null); // TODO: check it

        const getAccessToken = async scopes => {
            const { onChangeToken } = props;

            try {
                const accounts = publicApp.getAllAccounts();

                if (accounts.length <= 0) throw new Error('login_required');
                // Get the access token silently
                // If the cache contains a non-expired token, this function
                // will just return the cached token. Otherwise, it will
                // make a request to the Azure OAuth endpoint to get a token
                const silentResult = await publicApp.acquireTokenSilent({
                    scopes,
                    account: accounts[0],
                });

                onChangeToken(silentResult);

                return silentResult;
            } catch (err) {
                // If a silent request fails, it may be because the user needs
                // to login or grant consent to one or more of the requested scopes
                if (isInteractionRequired(err)) {
                    const interactiveResult = await publicApp.acquireTokenRedirect({
                        scopes,
                    });

                    onChangeToken(interactiveResult);

                    return interactiveResult;
                }
                throw err;
            }
        };

        const getUserProfile = async () => {
            try {
                const accessToken = await getAccessToken(config.scopes);

                if (accessToken) {
                    setIsAuthenticated(true);
                    setLoading(false);
                    setAccessToken(accessToken);
                    utilsSetToken(accessToken);
                }

                return accessToken;
            } catch (err) {
                setIsAuthenticated(false);
                setLoading(false);
                setUser({});
                setError(normalizeError(err));
            }
        };

        const normalizeError = error => {
            let normalizedError = {};
            if (typeof error === 'string') {
                const errParts = error.split('|');
                normalizedError = errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: error };
            } else {
                normalizedError = {
                    message: error.message,
                    debug: JSON.stringify(error),
                };
            }
            return normalizedError;
        };

        const login = async () => {
            try {
                setLoading(true);

                const respo = await publicApp.handleRedirectPromise();

                if (respo == null) {
                    await publicApp.loginRedirect({
                        scopes: config.scopes,
                        prompt: 'select_account',
                    });
                }

                return await getUserProfile();
            } catch (err) {
                setError(normalizeError(err));
                setLoading(false);
                setIsAuthenticated(false);
                setUser({});
            }
        };

        useEffect(() => {
            const accounts = publicApp.getAllAccounts();

            if (!accounts || accounts.length === 0) {
                login();
            }

            if (accounts && accounts.length > 0) {
                setToken(getUserProfile());
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [publicApp.getAllAccounts]); // // TODO: check it

        return (
            <WrappedComponent
                error={error}
                isAuthenticated={isAuthenticated}
                loading={loading}
                user={user}
                login={() => login()}
                logout={() => logout()}
                token={token}
                getAccessToken={scopes => getAccessToken(scopes)}
                setError={(message, debug) => setError({ message, debug })}
                {...props}
            />
        );
    };
}
