import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/CustomButtons/Button';

import { reactivateUser } from '../actions';
import ConfirmDialog from 'components/ConfirmDialog';

const ConfirmButton = ({ params }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const username = params?.row?.grantedUsername;

    if (!username) return null;

    return (
        <>
            <ConfirmDialog
                title={'Uudelleen lähetä aktivointilinkki'}
                open={open}
                setOpen={setOpen}
                onConfirm={() => dispatch(reactivateUser(username))}
                okMessage={'Lähetä uudelleen'}
            >
                Lähetä aktivointilinkki: {username}
            </ConfirmDialog>
            <Button
                variant='outlined'
                size='small'
                color='primary'
                onClick={() => setOpen(true)}
            >
                Lähetä aktivointilinkki
            </Button>
        </>
    );


};

export default ConfirmButton;