import React, { forwardRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from 'date-fns/locale/fi';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
// import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components/Grid';
import InstrumentAutocomplete from '../InstrumentAutocomplete';
import CurrencyField from 'components/CurrencyField';
import ControlledEditor from 'components/ControlledEditor';
import HookSwitch from 'components/HookSwitch';

const useStyles = makeStyles(theme => ({
    error: {
        fontSize: '0.75rem',
        color: theme.palette.error.main,
    },
}));

// const notEmptyHtmlOrString = /^(?!(<p><\/p>\n|)$)/;
const numberWithDecimals = /^([0-9]+.?[0-9]*|.[0-9]+)$/;

const InstrumentForm = forwardRef(({ instrument, instruments, onSubmit }, formRef) => {
    const classes = useStyles();
    const intl = useIntl();

    const instrumentSchema = yup.object().shape({
        startTime: yup.date().typeError(
            `${intl.formatMessage({ id: 'add.to.timeline.startTime' })} ${intl.formatMessage({
                id: 'message.isRequired',
            })}`
        ),
        endTime: yup
            .date()
            .min(yup.ref('startTime'), intl.formatMessage({ id: 'add.to.timeline.endTime.mindate' }))
            .typeError(
                `${intl.formatMessage({ id: 'add.to.timeline.endTime' })} ${intl.formatMessage({
                    id: 'message.isRequired',
                })}`
            ),
        projectSize: yup
            .string()
            .required(
                `${intl.formatMessage({ id: 'timeline.tooltip.projectSize' })} ${intl.formatMessage({
                    id: 'message.isRequired',
                })}`
            )
            .matches(
                numberWithDecimals,
                `${intl.formatMessage({ id: 'timeline.tooltip.projectSize' })} ${intl.formatMessage({
                    id: 'message.isRequired',
                })}`
            ),
        grantAmount: yup
            .string()
            .required(
                `${intl.formatMessage({ id: 'timeline.tooltip.grantAmount' })} ${intl.formatMessage({
                    id: 'message.isRequired',
                })}`
            )
            .matches(
                numberWithDecimals,
                `${intl.formatMessage({ id: 'timeline.tooltip.grantAmount' })} ${intl.formatMessage({
                    id: 'message.isRequired',
                })}`
            ),
        // description: yup.string().matches(
        //     notEmptyHtmlOrString,
        //     `${intl.formatMessage({ id: 'timeline.tooltip.description' })} ${intl.formatMessage({
        //         id: 'message.isRequired',
        //     })}`
        // ),
        name: yup.string().required(
            `${intl.formatMessage({ id: 'timeline.tooltip.name' })} ${intl.formatMessage({
                id: 'message.isRequired',
            })}`
        ),
        isUsed: yup.boolean(),
    });

    const { control, handleSubmit, errors, watch, setValue } = useForm({
        defaultValues: {
            instrument,
        },
        resolver: yupResolver(instrumentSchema),
    });

    const watchStartTime = watch('startTime', instrument?.startTime);
    const watchId = watch('id', instrument?.id);
    const watchIsCustom = watch('isCustom', instrument?.isCustom);

    const setNewInstrument = data => {
        setValue('id', data.id);
        setValue('name', data.name);
        setValue('provider', data.provider);
        setValue('name', data.name);
        setValue('type', data.type);
        setValue('isCustom', data.isCustom);
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit(data => onSubmit(data))}>
            <Controller
                name='isCustom'
                defaultValue={instrument?.isCustom ?? ''}
                control={control}
                render={({ value }) => <input type='hidden' value={value ?? ''} />}
            />
            <Controller
                name='rowKey'
                defaultValue={instrument?.rowKey ?? ''}
                control={control}
                render={({ value }) => <input type='hidden' value={value ?? ''} />}
            />
            <Controller
                name='provider'
                defaultValue={instrument?.provider ?? ''}
                control={control}
                render={({ value }) => <input type='hidden' value={value ?? ''} />}
            />
            <Controller
                name='type'
                defaultValue={instrument?.type ?? ''}
                control={control}
                render={({ value }) => <input type='hidden' value={value ?? ''} />}
            />
            <Controller
                name='id'
                defaultValue={instrument?.id ?? ''}
                control={control}
                render={({ value }) => <input type='hidden' value={value ?? ''} />}
            />
            <GridContainer justify='space-between' alignItems='flex-start' spacing={2}>
                <GridItem xs={12}>
                    <FormControl fullWidth>
                        <Controller
                            name='name'
                            defaultValue={instrument?.name ?? ''}
                            control={control}
                            render={({ onChange }) => (
                                <InstrumentAutocomplete
                                    onChange={onChange}
                                    instrument={instrument}
                                    instruments={instruments}
                                    setNewInstrument={setNewInstrument}
                                    control={control}
                                />
                            )}
                        />
                        {errors && (
                            <Typography component='p' className={classes.error}>
                                {errors?.name?.message}
                            </Typography>
                        )}
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} lg={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                        <FormControl fullWidth>
                            <Controller
                                name='startTime'
                                control={control}
                                defaultValue={instrument?.startTime ?? new Date()}
                                render={({ value, onChange }) => (
                                    <KeyboardDatePicker
                                        value={value}
                                        onChange={onChange}
                                        disableToolbar
                                        autoOk
                                        variant='inline'
                                        format='dd.MM.yyyy'
                                        placeholder={intl.formatMessage({ id: 'add.to.timeline.datepicker.placeholder' })}
                                        margin='normal'
                                        label={intl.formatMessage({ id: 'add.to.timeline.startTime' })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        style={{ margin: 0, padding: 0, width: '100%' }}
                                    />
                                )}
                            />
                            {errors && (
                                <Typography component='p' className={classes.error}>
                                    {errors?.startTime?.message}
                                </Typography>
                            )}
                        </FormControl>
                    </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={6} lg={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                        <FormControl fullWidth>
                            <Controller
                                name='endTime'
                                control={control}
                                defaultValue={
                                    instrument?.endTime && moment(instrument?.endTime).isAfter(instrument?.startTime)
                                        ? instrument?.endTime
                                        : // : moment(instrument?.startTime).isAfter(moment())
                                          // ? instrument?.startTime
                                          new Date()
                                }
                                render={({ value, onChange }) => (
                                    <KeyboardDatePicker
                                        value={watchStartTime > value ? watchStartTime : value}
                                        onChange={onChange}
                                        disableToolbar
                                        autoOk
                                        variant='inline'
                                        format='dd.MM.yyyy'
                                        placeholder={intl.formatMessage({ id: 'add.to.timeline.datepicker.placeholder' })}
                                        margin='normal'
                                        label={intl.formatMessage({ id: 'add.to.timeline.endTime' })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        minDate={watchStartTime ?? new Date()}
                                        style={{ margin: 0, padding: 0, width: '100%' }}
                                    />
                                )}
                            />
                            {errors && (
                                <Typography component='p' className={classes.error}>
                                    {errors?.endTime?.message}
                                </Typography>
                            )}
                        </FormControl>
                    </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={12} sm={6} lg={3}>
                    <FormControl fullWidth>
                        <Controller
                            name='projectSize'
                            control={control}
                            defaultValue={instrument?.projectSize ?? 0}
                            render={({ value, onChange }) => (
                                <CurrencyField
                                    value={value}
                                    onChange={onChange}
                                    label='timeline.tooltip.projectSize'
                                    forbidNegative={true}
                                    positionEnd={true}
                                />
                            )}
                        />
                        {errors && (
                            <Typography component='p' className={classes.error}>
                                {errors?.projectSize?.message}
                            </Typography>
                        )}
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} lg={3}>
                    <FormControl fullWidth>
                        <Controller
                            name='grantAmount'
                            control={control}
                            defaultValue={instrument?.grantAmount ?? 0}
                            render={({ value, onChange }) => (
                                <CurrencyField
                                    value={value}
                                    onChange={onChange}
                                    label='timeline.tooltip.grantAmount'
                                    forbidNegative={true}
                                    positionEnd={true}
                                />
                            )}
                        />
                        {errors && (
                            <Typography component='p' className={classes.error}>
                                {errors?.grantAmount?.message}
                            </Typography>
                        )}
                    </FormControl>
                </GridItem>
                <GridItem xs={12}>
                    <FormControl fullWidth>
                        <Controller
                            name='description'
                            control={control}
                            defaultValue={instrument?.description ?? ''}
                            render={({ onChange }) => (
                                <ControlledEditor
                                    title={intl.formatMessage({ id: 'add.to.timeline.expert.description' })}
                                    value={instrument?.description ?? ''}
                                    onChange={onChange}
                                />
                            )}
                        />
                        {/* {errors && (
                            <Typography component='p' className={classes.error}>
                                {errors?.description?.message}
                            </Typography>
                        )} */}
                    </FormControl>
                </GridItem>
                {instruments
                    .filter(({ id }) => id === instrument?.id || (id === watchId && !watchIsCustom))
                    .map(({ id, ingress }) => {
                        if (ingress) {
                            return (
                                <GridItem key={id} xs={12}>
                                    <ControlledEditor
                                        disabled={true}
                                        menubar={false}
                                        toolbar={false}
                                        value={ingress}
                                        title={intl.formatMessage({ id: 'timeline.instrument.ingress' })}
                                    />
                                </GridItem>
                            );
                        } else {
                            return null;
                        }
                    })}
                <GridItem>
                    <FormControl>
                        <Controller
                            name='isUsed'
                            control={control}
                            defaultValue={instrument?.isUsed ?? false}
                            render={({ onChange, value }) => (
                                <HookSwitch
                                    checked={value}
                                    onChange={e => onChange(e.target.checked)}
                                    label={intl.formatMessage({ id: 'timeline.tooltip.isUsed' })}
                                />
                            )}
                        />
                    </FormControl>
                </GridItem>
            </GridContainer>
        </form>
    );
});

export default InstrumentForm;
