import {
  LOAD_GRANTEDAI_USERS,
  LOAD_GRANTEDAI_USERS_SUCCESS,
  LOAD_GRANTEDAI_USERS_ERROR,
  ADD_GRANTEDAI_USER,
  SAVE_LICENSE_INFORMATION,
  SAVE_LICENSE_INFORMATION_ERROR,
  SAVE_LICENSE_INFORMATION_SUCCESS,
  LOAD_LICENSE_INFORMATION,
  LOAD_LICENSE_INFORMATION_ERROR,
  LOAD_LICENSE_INFORMATION_SUCCESS,
  TERMINATE_LICENSE,
  REACTIVATE_USER,
} from './constants';

export function loadUsers() {
  return {
    type: LOAD_GRANTEDAI_USERS,
  };
}

export function loadUsersSuccess(users) {
  return {
    type: LOAD_GRANTEDAI_USERS_SUCCESS,
    users,
  };
}

export function loadUsersError(error) {
  return {
    type: LOAD_GRANTEDAI_USERS_ERROR,
    error,
  };
}

export function addUser({ user }) {
  return {
    type: ADD_GRANTEDAI_USER,
    user,
  };
}

export function saveLicenseInformation(license) {
  return {
    type: SAVE_LICENSE_INFORMATION,
    license,
  };
}

export function saveLicenseInformationSuccess(license) {
  return {
    type: SAVE_LICENSE_INFORMATION_SUCCESS,
    license,
  };
}

export function saveLicenseInformationError(error) {
  return {
    type: SAVE_LICENSE_INFORMATION_ERROR,
    error,
  };
}

export function loadLicenseInformation() {
  return {
    type: LOAD_LICENSE_INFORMATION,
  };
}

export function loadLicenseInformationSuccess(license) {
  return {
    type: LOAD_LICENSE_INFORMATION_SUCCESS,
    license,
  };
}

export function loadLicenseInformationError(error) {
  return {
    type: LOAD_LICENSE_INFORMATION_ERROR,
    error,
  };
}

export function terminateLicense() {
  return {
    type: TERMINATE_LICENSE
  };
}

export function reactivateUser(username) {
  return {
    type: REACTIVATE_USER,
    username: username
  };
}