import {
  primaryColor,
  dangerColor,
  grayColor,
} from 'assets/jss/grants/common/colors';

const iconsStyle = {
  iconsPrimary: {
    color: primaryColor,
  },
  removeIcon: {
    color: grayColor,
    '&:hover': {
      color: dangerColor,
    },
  },
};

export default iconsStyle;
