import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { color } from 'assets/jss/grants/common/grantsBase.js';

import { getFromLocalStorage } from 'utils/localStorage/localStorageHandler';

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(1),
        fontSize: '1.1rem',
        fontWeight: 600,
        color: color.primaryColor,
    },
    company: {
        color: color.primaryColor,
    },
}));

export default () => {
    const classes = useStyles();
    const [history, setHistory] = useState(null);

    useEffect(() => {
        const data = getFromLocalStorage();
        setHistory(data);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getDate = time => {
        return new Date(time).toLocaleString();
    };

    if (history === null) return <div>null</div>;

    return (
        <GridContainer spacing={6} justify='center' alignItems='stretch'>
            <GridItem xs={12} sm={8}>
                <GridContainer>
                    <GridItem>
                        <Typography variant='h4' className={classes.header}>
                            Historia
                        </Typography>
                    </GridItem>
                    {history.map(({ company, time }) => {
                        return company.id && company.name ? (
                            <React.Fragment key={time}>
                                <GridItem sm={6}>
                                    <Link to={`/company/${company.id}`}>
                                        <Typography component='p' className={classes.company}>
                                            {company.name}
                                        </Typography>
                                    </Link>
                                </GridItem>
                                <GridItem sm={6}>
                                    <Typography component='p' className={classes.company}>
                                        {getDate(time)}
                                    </Typography>
                                </GridItem>
                            </React.Fragment>
                        ) : null;
                    })}
                </GridContainer>
            </GridItem>
        </GridContainer>
    );
};
