export const gridTranslations = (intl) => {
    return {
        rootGridLabel: intl.formatMessage({ id: 'grid.rootGridLabel' }),
        noRowsLabel: intl.formatMessage({ id: 'grid.noRowsLabel' }),
        noResultsOverlayLabel: intl.formatMessage({ id: 'grid.noResultsOverlayLabel' }),
        errorOverlayDefaultLabel: intl.formatMessage({ id: 'grid.errorOverlayDefaultLabel' }),

        // Density selector toolbar button text
        toolbarDensity: intl.formatMessage({ id: 'grid.toolbarDensity' }),
        toolbarDensityLabel: intl.formatMessage({ id: 'grid.toolbarDensityLabel' }),
        toolbarDensityCompact: intl.formatMessage({ id: 'grid.toolbarDensityCompact' }),
        toolbarDensityStandard: intl.formatMessage({ id: 'grid.toolbarDensityStandard' }),
        toolbarDensityComfortable: intl.formatMessage({ id: 'grid.toolbarDensityComfortable' }),

        // Columns selector toolbar button text
        toolbarColumns: intl.formatMessage({ id: 'grid.toolbarColumns' }),
        toolbarColumnsLabel: intl.formatMessage({ id: 'grid.toolbarColumnsLabel' }),

        // Filters toolbar button text
        toolbarFilters: intl.formatMessage({ id: 'grid.toolbarFilters' }),
        toolbarFiltersLabel: intl.formatMessage({ id: 'grid.toolbarFiltersLabel' }),
        toolbarFiltersTooltipHide: intl.formatMessage({ id: 'grid.toolbarFiltersTooltipHide' }),
        toolbarFiltersTooltipShow: intl.formatMessage({ id: 'grid.toolbarFiltersTooltipShow' }),
        toolbarFiltersTooltipActive: count => (count !== 1
            ? intl.formatMessage({ id: 'grid.toolbarFiltersTooltipActive.single', }, { count: count })
            : intl.formatMessage({ id: 'grid.toolbarFiltersTooltipActive.plural', }, { count: count })),

        // Export selector toolbar button text
        toolbarExport: intl.formatMessage({ id: 'grid.toolbarExport' }),
        toolbarExportLabel: intl.formatMessage({ id: 'grid.toolbarExportLabel' }),
        toolbarExportCSV: intl.formatMessage({ id: 'grid.toolbarExportCSV' }),

        // Columns panel text
        columnsPanelTextFieldLabel: intl.formatMessage({ id: 'grid.columnsPanelTextFieldLabel' }),
        columnsPanelTextFieldPlaceholder: intl.formatMessage({ id: 'grid.columnsPanelTextFieldPlaceholder' }),
        columnsPanelDragIconLabel: intl.formatMessage({ id: 'grid.columnsPanelDragIconLabel' }),
        columnsPanelShowAllButton: intl.formatMessage({ id: 'grid.columnsPanelShowAllButton' }),
        columnsPanelHideAllButton: intl.formatMessage({ id: 'grid.columnsPanelHideAllButton' }),

        // Filter panel text
        filterPanelAddFilter: intl.formatMessage({ id: 'grid.filterPanelAddFilter' }),
        filterPanelDeleteIconLabel: intl.formatMessage({ id: 'grid.filterPanelDeleteIconLabel' }),
        filterPanelOperators: intl.formatMessage({ id: 'grid.filterPanelOperators' }),
        filterPanelOperatorAnd: intl.formatMessage({ id: 'grid.filterPanelOperatorAnd' }),
        filterPanelOperatorOr: intl.formatMessage({ id: 'grid.filterPanelOperatorOr' }),
        filterPanelColumns: intl.formatMessage({ id: 'grid.filterPanelColumns' }),
        filterPanelInputLabel: intl.formatMessage({ id: 'grid.filterPanelInputLabel' }),
        filterPanelInputPlaceholder: intl.formatMessage({ id: 'grid.filterPanelInputPlaceholder' }),

        // Filter operators text
        filterOperatorContains: intl.formatMessage({ id: 'grid.filterOperatorContains' }),
        filterOperatorEquals: intl.formatMessage({ id: 'grid.filterOperatorEquals' }),
        filterOperatorStartsWith: intl.formatMessage({ id: 'grid.filterOperatorStartsWith' }),
        filterOperatorEndsWith: intl.formatMessage({ id: 'grid.filterOperatorEndsWith' }),
        filterOperatorIs: intl.formatMessage({ id: 'grid.filterOperatorIs' }),
        filterOperatorNot: intl.formatMessage({ id: 'grid.filterOperatorNot' }),
        filterOperatorAfter: intl.formatMessage({ id: 'grid.filterOperatorAfter' }),
        filterOperatorOnOrAfter: intl.formatMessage({ id: 'grid.filterOperatorOnOrAfter' }),
        filterOperatorBefore: intl.formatMessage({ id: 'grid.filterOperatorBefore' }),
        filterOperatorOnOrBefore: intl.formatMessage({ id: 'grid.filterOperatorOnOrBefore' }),

        // Filter values text
        filterValueAny: intl.formatMessage({ id: 'grid.filterValueAny' }),
        filterValueTrue: intl.formatMessage({ id: 'grid.filterValueTrue' }),
        filterValueFalse: intl.formatMessage({ id: 'grid.filterValueFalse' }),

        // Column menu text
        columnMenuLabel: intl.formatMessage({ id: 'grid.columnMenuLabel' }),
        columnMenuShowColumns: intl.formatMessage({ id: 'grid.columnMenuShowColumns' }),
        columnMenuFilter: intl.formatMessage({ id: 'grid.columnMenuFilter' }),
        columnMenuHideColumn: intl.formatMessage({ id: 'grid.columnMenuHideColumn' }),
        columnMenuUnsort: intl.formatMessage({ id: 'grid.columnMenuUnsort' }),
        columnMenuSortAsc: intl.formatMessage({ id: 'grid.columnMenuSortAsc' }),
        columnMenuSortDesc: intl.formatMessage({ id: 'grid.columnMenuSortDesc' }),

        // Column header text
        columnHeaderFiltersTooltipActive: count => (count !== 1
            ? intl.formatMessage({ id: 'grid.columnHeaderFiltersTooltipActive.plural', }, { count: count })
            : intl.formatMessage({ id: 'grid.columnHeaderFiltersTooltipActive.single', }, { count: count })),

        columnHeaderFiltersLabel: intl.formatMessage({ id: 'grid.columnHeaderFiltersLabel' }),
        columnHeaderSortIconLabel: intl.formatMessage({ id: 'grid.columnHeaderSortIconLabel' }),

        // Rows selected footer text
        footerRowSelected: count => (count !== 1
            ? intl.formatMessage({ id: 'grid.footerRowSelected.plural', }, { count: count.toLocaleString() })
            : intl.formatMessage({ id: 'grid.footerRowSelected.single', }, { count: count.toLocaleString() })),

        // Total rows footer text
        footerTotalRows: intl.formatMessage({ id: 'grid.footerTotalRows' }),

        // Checkbox selection text
        checkboxSelectionHeaderName: intl.formatMessage({ id: 'grid.checkboxSelectionHeaderName' }),

        // Boolean cell text
        booleanCellTrueLabel: intl.formatMessage({ id: 'grid.booleanCellTrueLabel' }),
        booleanCellFalseLabel: intl.formatMessage({ id: 'grid.booleanCellFalseLabel' }),
    };
};

// export const gridTranslations = {
//     // Root
//     rootGridLabel: intl.formatMessage({ id: 'grid.rootGridLabel' }),
//     noRowsLabel: intl.formatMessage({ id: 'grid.noRowsLabel' }),
//     noResultsOverlayLabel: intl.formatMessage({ id: 'grid.noResultsOverlayLabel' }),
//     errorOverlayDefaultLabel: intl.formatMessage({ id: 'grid.errorOverlayDefaultLabel' }),

//     // Density selector toolbar button text
//     toolbarDensity: intl.formatMessage({ id: 'grid.toolbarDensity' }),
//     toolbarDensityLabel: intl.formatMessage({ id: 'grid.toolbarDensityLabel' }),
//     toolbarDensityCompact: intl.formatMessage({ id: 'grid.toolbarDensityCompact' }),
//     toolbarDensityStandard: intl.formatMessage({ id: 'grid.toolbarDensityStandard' }),
//     toolbarDensityComfortable: intl.formatMessage({ id: 'grid.toolbarDensityComfortable' }),

//     // Columns selector toolbar button text
//     toolbarColumns: intl.formatMessage({ id: 'grid.toolbarColumns' }),
//     toolbarColumnsLabel: intl.formatMessage({ id: 'grid.toolbarColumnsLabel' }),

//     // Filters toolbar button text
//     toolbarFilters: intl.formatMessage({ id: 'grid.toolbarFilters' }),
//     toolbarFiltersLabel: intl.formatMessage({ id: 'grid.toolbarFiltersLabel' }),
//     toolbarFiltersTooltipHide: intl.formatMessage({ id: 'grid.toolbarFiltersTooltipHide' }),
//     toolbarFiltersTooltipShow: intl.formatMessage({ id: 'grid.toolbarFiltersTooltipShow' }),
//     toolbarFiltersTooltipActive: count => (count !== 1
//         ? intl.formatMessage({ id: 'grid.toolbarFiltersTooltipActive.single', }, { count: count }
//         : intl.formatMessage({ id: 'grid.toolbarFiltersTooltipActive.plural', }, { count: count }),

//     // Export selector toolbar button text
//     toolbarExport: intl.formatMessage({ id: 'grid.toolbarExport' }),
//     toolbarExportLabel: intl.formatMessage({ id: 'grid.toolbarExportLabel' }),
//     toolbarExportCSV: intl.formatMessage({ id: 'grid.toolbarExportCSV' }),

//     // Columns panel text
//     columnsPanelTextFieldLabel: intl.formatMessage({ id: 'grid.columnsPanelTextFieldLabel' }),
//     columnsPanelTextFieldPlaceholder: intl.formatMessage({ id: 'grid.columnsPanelTextFieldPlaceholder' }),
//     columnsPanelDragIconLabel: intl.formatMessage({ id: 'grid.columnsPanelDragIconLabel' }),
//     columnsPanelShowAllButton: intl.formatMessage({ id: 'grid.columnsPanelShowAllButton' }),
//     columnsPanelHideAllButton: intl.formatMessage({ id: 'grid.columnsPanelHideAllButton' }),

//     // Filter panel text
//     filterPanelAddFilter: intl.formatMessage({ id: 'grid.filterPanelAddFilter' }),
//     filterPanelDeleteIconLabel: intl.formatMessage({ id: 'grid.filterPanelDeleteIconLabel' }),
//     filterPanelOperators: intl.formatMessage({ id: 'grid.filterPanelOperators' }),
//     filterPanelOperatorAnd: intl.formatMessage({ id: 'grid.filterPanelOperatorAnd' }),
//     filterPanelOperatorOr: intl.formatMessage({ id: 'grid.filterPanelOperatorOr' }),
//     filterPanelColumns: intl.formatMessage({ id: 'grid.filterPanelColumns' }),
//     filterPanelInputLabel: intl.formatMessage({ id: 'grid.filterPanelInputLabel' }),
//     filterPanelInputPlaceholder: intl.formatMessage({ id: 'grid.filterPanelInputPlaceholder' }),

//     // Filter operators text
//     filterOperatorContains: intl.formatMessage({ id: 'grid.filterOperatorContains' }),
//     filterOperatorEquals: intl.formatMessage({ id: 'grid.filterOperatorEquals' }),
//     filterOperatorStartsWith: intl.formatMessage({ id: 'grid.filterOperatorStartsWith' }),
//     filterOperatorEndsWith: intl.formatMessage({ id: 'grid.filterOperatorEndsWith' }),
//     filterOperatorIs: intl.formatMessage({ id: 'grid.filterOperatorIs' }),
//     filterOperatorNot: intl.formatMessage({ id: 'grid.filterOperatorNot' }),
//     filterOperatorAfter: intl.formatMessage({ id: 'grid.filterOperatorAfter' }),
//     filterOperatorOnOrAfter: intl.formatMessage({ id: 'grid.filterOperatorOnOrAfter' }),
//     filterOperatorBefore: intl.formatMessage({ id: 'grid.filterOperatorBefore' }),
//     filterOperatorOnOrBefore: intl.formatMessage({ id: 'grid.filterOperatorOnOrBefore' }),

//     // Filter values text
//     filterValueAny: intl.formatMessage({ id: 'grid.filterValueAny' }),
//     filterValueTrue: intl.formatMessage({ id: 'grid.filterValueTrue' }),
//     filterValueFalse: intl.formatMessage({ id: 'grid.filterValueFalse' }),

//     // Column menu text
//     columnMenuLabel: intl.formatMessage({ id: 'grid.columnMenuLabel' }),
//     columnMenuShowColumns: intl.formatMessage({ id: 'grid.columnMenuShowColumns' }),
//     columnMenuFilter: intl.formatMessage({ id: 'grid.columnMenuFilter' }),
//     columnMenuHideColumn: intl.formatMessage({ id: 'grid.columnMenuHideColumn' }),
//     columnMenuUnsort: intl.formatMessage({ id: 'grid.columnMenuUnsort' }),
//     columnMenuSortAsc: intl.formatMessage({ id: 'grid.columnMenuSortAsc' }),
//     columnMenuSortDesc: intl.formatMessage({ id: 'grid.columnMenuSortDesc' }),

//     // Column header text
//     columnHeaderFiltersTooltipActive: count => (count !== 1
//         ? intl.formatMessage({ id: 'grid.columnHeaderFiltersTooltipActive.plural', }, { count: count }
//         : intl.formatMessage({ id: 'grid.columnHeaderFiltersTooltipActive.single', }, { count: count }),

//     columnHeaderFiltersLabel: intl.formatMessage({ id: 'grid.columnHeaderFiltersLabel' }),
//     columnHeaderSortIconLabel: intl.formatMessage({ id: 'grid.columnHeaderSortIconLabel' }),

//     // Rows selected footer text
//     footerRowSelected: count => (count !== 1
//         ? intl.formatMessage({ id: 'grid.footerRowSelected.plural" values={{ count: count.toLocaleString() }} />
//         : intl.formatMessage({
//             id: 'grid.footerRowSelected.single" values={{ count: count.toLocaleString() }} />),

//     // Total rows footer text
//     footerTotalRows: intl.formatMessage({ id: 'grid.footerTotalRows' }),

//             // Checkbox selection text
//             checkboxSelectionHeaderName: intl.formatMessage({ id: 'grid.checkboxSelectionHeaderName' }),

//             // Boolean cell text
//             booleanCellTrueLabel: intl.formatMessage({ id: 'grid.booleanCellTrueLabel' }),
//             booleanCellFalseLabel: intl.formatMessage({ id: 'grid.booleanCellFalseLabel' }),
//         };
