import produce from 'immer';
import {
    LOAD_INSTRUMENTS,
    LOAD_INSTRUMENTS_ERROR,
    LOAD_INSTRUMENTS_SUCCESS,
    SAVE_INSTRUMENT,
    SAVE_INSTRUMENT_FAIL,
    SAVE_INSTRUMENT_SUCCESS,
    OPEN_INSTRUMENT_MODAL,
    CLOSE_INSTRUMENT_MODAL,
} from './constants.js';

export const initialState = {
    loading: false,
    error: false,
    instruments: [],
    modalOpen: false,
    loadingInstrument: false,
};

const instrumentsReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case LOAD_INSTRUMENTS:
                draft.loading = true;
                break;
            case LOAD_INSTRUMENTS_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;
            case LOAD_INSTRUMENTS_SUCCESS:
                draft.loading = false;
                draft.instruments = action.instruments;
                draft.error = false;
                break;
            case SAVE_INSTRUMENT:
                draft.loadingInstrument = true;
                break;
            case SAVE_INSTRUMENT_FAIL:
                draft.loadingInstrument = false;
                draft.error = action.error;
                break;
            case SAVE_INSTRUMENT_SUCCESS: {
                draft.loadingInstrument = false;
                draft.error = false;
                if (action.isNew) {
                    draft.instruments.push(action.instrument);
                } else {
                    const index = draft.instruments.findIndex(item => item.id === action.instrument.id);
                    draft.instruments[index] = action.instrument;
                }
                break;
            }
            case OPEN_INSTRUMENT_MODAL:
                draft.modalOpen = true;
                break;
            case CLOSE_INSTRUMENT_MODAL:
                draft.modalOpen = false;
                break;
            default:
                return state;
        }
    });

export default instrumentsReducer;
