import React from 'react';
import ReduxToastr, { toastr as reduxtoastr } from 'react-redux-toastr';

import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';

import 'assets/scss/components/toastr.scss';

const Toastr = () => (
  <ReduxToastr
    timeOut={118000}
    newestOnTop={false}
    preventDuplicates
    position="top-right"
    getState={(state) => state.toastr} // This is the default
    transitionIn="fadeIn"
    transitionOut="fadeOut"
    progressBar
    closeOnToastrClick
  />
);

//const getIntlMessage = (id) => <FormattedMessage id={id} />;

export const error = (title, message) => {
  const options = {
    className: 'error-toastr',
    icon: <ErrorSharpIcon style={{ color: '#db6a64' }} />,
    status: 'error',
  };

  reduxtoastr.light(title, message, options);
};

export const warning = (title, message) => {
  const options = {
    className: 'warning-toastr',
    icon: <ReportProblemOutlinedIcon style={{ color: '#f7a336' }} />,
    status: 'warning',
  };

  reduxtoastr.light(title, message, options);
};

export const info = (title, message) => {
  const options = {
    className: 'info-toastr',
    icon: <InfoOutlinedIcon style={{ color: '#58abc3' }} />,
    status: 'info',
  };

  reduxtoastr.light(title, message, options);
};

export const success = (title, message) => {
  const options = {
    className: 'success-toastr',
    icon: <CheckBoxOutlinedIcon style={{ color: '#60bb71' }} />,
    status: 'success',
  };

  reduxtoastr.light(title, message, options);
};

export const toastr = reduxtoastr;

export default Toastr;
