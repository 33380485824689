import React, { useState, useEffect, /* useRef, */ forwardRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components/Grid';
import TColumns from './TColumns';
import TBody from './TBody';
import TFooterQuarter from './TFooterQuarter';
import TFooterYear from './TFooterYear';
import PastNowDiv from './PastNowDiv';
import SmoothDiv from './SmoothDiv';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    timelineContainerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.primary.white,
        padding: theme.spacing(2),
        height: '100%',
        [theme.breakpoints.up('md')]: {
            // maxHeight: '30rem', // same as Scores height
        },
    },
    relativeDiv: {
        position: 'relative',
        flexGrow: 1,
    },
    container: {
        position: 'relative',
        overflow: 'auto',
        height: '100%',
        // custom scrollbar
        '&::-webkit-scrollbar': {
            height: theme.spacing(0.75),
            width: theme.spacing(0.75),
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 1px 5px #ccc, inset 0 -1px 5px #ccc',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(72, 46, 81, 0.8)',
        },
    },
    titleContainer: {
        marginBottom: theme.spacing(2),
        paddingRight: 0,
        paddingLeft: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            height: theme.spacing(5),
        },
    },
    title: {
        fontSize: '1.3125rem',
        fontWeight: 600,
    },
    demoLabel: {
        fontSize: '0.875rem',
        fontWeight: 700,
        paddingTop: theme.spacing(0.5),
        paddingRight: theme.spacing(1.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '0.875rem',
    },
    quarter: {
        fontSize: '0.8125rem',
    },
    table: {
        tableLayout: 'fixed',
        borderCollapse: 'collapse',
        background: 'linear-gradient(90deg, rgba(72, 46, 81, 0.2) 1px, transparent 1px)',
        backgroundColor: theme.palette.primary.white,
        height: '100%',
    },
    tfoot: {
        backgroundColor: theme.palette.primary.white,
        position: 'sticky',
        bottom: 0,
        zIndex: 21,
    },
    tbody: {
        position: 'relative',
        '& tr:last-child': {
            '& td:last-child > div': {
                marginBottom: theme.spacing(1),
            },
        },
    },
    tbodyFirstZero: {
        '& tr:nth-child(2)': {
            '& td:first-child > div': {
                marginTop: theme.spacing(1),
            },
        },
    },
    tbodyFirstNotZero: {
        '& tr:nth-child(2)': {
            '& td:nth-child(2) > div': {
                marginTop: theme.spacing(1),
            },
        },
    },
    openButtonWrapper: {
        paddingLeft: theme.spacing(2),
    },
    openButton: {
        textTransform: 'none',
        fontWeight: 600,
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.main,
            opacity: 0.8,
        },
    },
    contactButton: {
        textTransform: 'none',
        textDecoration: 'underline',
        fontWeight: 600,
        padding: 0,
    },
    quarterButton: {
        textTransform: 'none',
    },
    bookTime: {
        borderColor: theme.palette.success.main,
        color: theme.palette.success.main,
    },
    updated: {
        fontSize: '0.875rem',
        fontWeight: 300,
        color: 'rgba(72, 46, 81, 0.6)',
    },
    demoText: {
        fontSize: '0.875rem',
        color: theme.palette.primary.main,
    },
    addInstrumentButtonBox: {
        paddingTop: theme.spacing(2),
    },
    addInstrumentButton: {
        textTransform: 'none',
        fontWeight: 600,
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: theme.palette.success.main,
            opacity: 0.8,
        },
    },
}));

const Timeline = forwardRef(
    (
        {
            data,
            instruments,
            error,
            loading,
            onScroll,
            timelineContainerWidth,
            isRenderRightSmooth,
            isRenderLeftSmooth,
            openFinancialPlanDrawer,
            openInstrumentModal,
            padding,
            isAdvancedCompanyOrCustomer,
            isStaticProfileConst,
            scrollToNoInsruments,
            mock = false,
            // smDown = false,
            printView = false,
            showShadow = false,
            profile = false,
        },
        ref
    ) => {
        const classes = useStyles();
        const intl = useIntl();
        const theme = useTheme();
        const down480 = useMediaQuery(theme.breakpoints.down(480));

        let numberOfMonth = data?.numberOfMonth;
        if (numberOfMonth < 12) numberOfMonth = 12;

        const [column, setColumn] = useState(null);
        const [tableWidth, setTableWidth] = useState(timelineContainerWidth - 50);

        const minColumnWidth = 30;
        useEffect(() => {
            if (numberOfMonth && timelineContainerWidth) {
                const columnWidth = Math.floor((timelineContainerWidth - numberOfMonth - padding * 2) / numberOfMonth);

                if (columnWidth < minColumnWidth) {
                    setTableWidth((numberOfMonth * minColumnWidth + 1) * 1);
                    setColumn(minColumnWidth);
                } else {
                    setTableWidth((numberOfMonth * columnWidth + 1) * 1);
                    setColumn(columnWidth);
                }
            }
        }, [numberOfMonth, column, timelineContainerWidth, padding]);

        // useEffect(() => {
        //     hasHorizontalScrollbar(tableWidth);
        // }, [hasHorizontalScrollbar, tableWidth]);

        const startTime = data?.startTime;
        const timelineInstruments = data?.instruments;
        const tbodyFirstZero = instruments && instruments[0]?.monthFromStart === 0;

        const instrumentsWithLevel = !mock
            ? timelineInstruments?.map(instrument => ({
                  ...instrument,
                  level: instruments.find(item => item.id === instrument.id)?.level ?? 0,
              }))
            : timelineInstruments?.map(instrument => ({
                  ...instrument,
              }));

        let numberOfMonthArray = [];
        for (let i = 1; i <= numberOfMonth; i++) {
            numberOfMonthArray.push(i);
        }

        const quarters = numberOfMonthArray.filter(item => item % 3 === 0);

        const isContainerVerticalScrollbar = ref?.current?.scrollHeight > ref?.current?.clientHeight;
        const isContainerHorizontalScrollbar = ref?.current?.scrollWidth > ref?.current?.clientWidth;

        if (!loading && (data === undefined || data === null)) {
            return <div>Problem rendering component</div>;
        }

        return loading ? (
            <CircularProgress size={24} />
        ) : !loading /* && data  && Object.keys(data)?.length && instrumentsWithLevel?.length*/ ? (
            <>
                <div className={classes.timelineContainerWrapper} style={{ maxHeight: printView && 'none' }}>
                    {!printView && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexWrap: 'nowrap',
                                marginBottom: down480 && 0,
                            }}
                            className={classes.titleContainer}
                        >
                            <div style={{ flexGrow: 1 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <GridContainer>
                                            <GridItem>
                                                <Typography color='primary' className={classes.title}>
                                                    {intl.formatMessage({ id: 'financial.plan.timeline.title' })}
                                                </Typography>
                                            </GridItem>
                                            {mock && (
                                                <GridItem>
                                                    <Typography className={classes.demoLabel}>DEMO</Typography>
                                                </GridItem>
                                            )}
                                        </GridContainer>
                                    </div>
                                    <div xs={12} sm={6} style={{ justifyContent: 'flex-end' }}>
                                        <Typography component='p' className={classes.updated}>
                                            <FormattedMessage id='timeline.updated' /> {moment(data?.created).format('DD.MM.YYYY HH:mm')}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            {!down480 && !printView && !(mock && isAdvancedCompanyOrCustomer) && !(mock && isStaticProfileConst) && (
                                <div
                                    className={classes.openButtonWrapper}
                                    style={{ maxWidth: mock ? '207px' : data?.type === 'Timeline' ? '168px' : '202px' }}
                                >
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={openFinancialPlanDrawer}
                                        className={classes.openButton}
                                    >
                                        {mock
                                            ? intl.formatMessage({ id: 'financial.plan.open.button.mock' })
                                            : data?.type === 'Timeline'
                                            ? intl.formatMessage({ id: 'financial.plan.open.timeline.button' })
                                            : intl.formatMessage({ id: 'financial.plan.open.button' })}
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                    {down480 && !printView && (
                        <Box textAlign='right'>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={openFinancialPlanDrawer}
                                className={classes.openButton}
                                style={{ marginTop: '0.5rem', marginBottom: '0.75rem' }}
                            >
                                {mock
                                    ? intl.formatMessage({ id: 'financial.plan.open.button.mock' })
                                    : data?.type === 'Timeline'
                                    ? intl.formatMessage({ id: 'financial.plan.open.timeline.button' })
                                    : intl.formatMessage({ id: 'financial.plan.open.button' })}
                            </Button>
                        </Box>
                    )}
                    {mock && !printView && !profile && !isAdvancedCompanyOrCustomer && (
                        <GridContainer className={classes.titleContainer}>
                            <GridItem>
                                <Typography className={classes.demoText}>
                                    Voit ottaa meihin yhteyttä suoraan asiantuntijaan klikkaamalla alla olevaa{' '}
                                    <Button color='primary' onClick={scrollToNoInsruments} className={classes.contactButton}>
                                        {intl.formatMessage({ id: 'common.take.contact' })}
                                    </Button>
                                </Typography>
                            </GridItem>
                        </GridContainer>
                    )}
                    <div className={classes.relativeDiv}>
                        <div
                            ref={ref}
                            onScroll={onScroll}
                            style={{
                                paddingRight: `${padding}px`,
                                paddingLeft: `${padding}px`,
                                boxShadow: showShadow && '3px 5px 5px -1px rgba(0, 0, 0, 0.2)',
                            }}
                            className={`${classes.container} timelineContainerPrint`}
                        >
                            <table
                                className={classes.table}
                                style={{
                                    width: tableWidth,
                                    backgroundSize: `${column * 3}px`,
                                }}
                            >
                                <tfoot className={classes.tfoot}>
                                    <TFooterQuarter quarters={quarters} />
                                    <TFooterYear quarters={quarters} startTime={startTime} column={column} padding={padding} />
                                </tfoot>
                                <tbody
                                    className={`${classes.tbody} ${tbodyFirstZero ? classes.tbodyFirstZero : classes.tbodyFirstNotZero} `}
                                >
                                    <TColumns numberOfMonth={numberOfMonth} columnWidth={column} />
                                    <TBody
                                        instruments={instrumentsWithLevel}
                                        numberOfMonth={numberOfMonth}
                                        openInstrumentModal={openInstrumentModal}
                                        mock={mock}
                                        profile={profile}
                                    />
                                    <PastNowDiv column={column} padding={padding} startTime={startTime} />
                                </tbody>
                            </table>
                        </div>
                        {isRenderLeftSmooth && <SmoothDiv left={true} isHorizontalScrollbar={isContainerHorizontalScrollbar} />}
                        {isRenderRightSmooth && (
                            <SmoothDiv
                                right={true}
                                isHorizontalScrollbar={isContainerHorizontalScrollbar}
                                isVerticalScrollbar={isContainerVerticalScrollbar}
                            />
                        )}
                    </div>
                    {!printView && (
                        <Box textAlign='right' className={classes.addInstrumentButtonBox}>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => {
                                    openInstrumentModal(false, false);
                                }}
                                className={classes.addInstrumentButton}
                            >
                                <AddIcon />
                                {intl.formatMessage({ id: 'timeline.add.instrument.button' })}
                            </Button>
                        </Box>
                    )}
                </div>
            </>
        ) : (
            (!loading && error) ||
            (!loading && data && Object.keys(data)?.length === 0) ||
            (!loading && data && instrumentsWithLevel?.length === 0 && (
                <Typography color='primary' className={classes.noInstruments}>
                    {intl.formatMessage({ id: 'timeline.no.instruments' })}
                </Typography>
            ))
        );
    }
);

export default Timeline;
