const containerFluid = {
  paddingRight: '0',
  paddingLeft: '0',
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100%',
};

const container = {
  ...containerFluid,
  '@media (min-width: 576px)': {
    // maxWidth: "540px"
  },
  '@media (min-width: 768px)': {
    // maxWidth: "720px"
  },
  '@media (min-width: 992px)': {
    //maxWidth: "960px"
  },
  '@media (min-width: 1200px)': {
    //maxWidth: "1140px"
  },
};

const contentContainer = {
  ...containerFluid,
  padding: '1rem',
  paddingRight: '1rem',
  paddingLeft: '0',
};

const noPadding = {
  padding: '0 !important',
};

const noPaddingSide = {
  padding: '1rem 0 !important',
  paddingRight: '1rem !important',
};

const gridItem = {
  position: 'relative',
  width: '100%',
  minHeight: '1px',
  padding: '5px',
  flexBasis: 'auto',
};

const contentRight = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export { container, contentContainer, noPadding, gridItem, noPaddingSide, contentRight };
