import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import { startRobot, loadRobotEvents } from 'containers/Robot/actions';
import reducer from 'containers/Robot/reducer';
import saga from 'containers/Robot/saga';

import Button from 'components/CustomButtons/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import ConfirmDialog from 'components/ConfirmDialog';
import RobotIcon from 'components/Icons/RobotIcon';

const key = 'robot';

export function Robot({ businessId }) {
	useInjectReducer({ key, reducer });
	useInjectSaga({ key, saga });
	const dispatch = useDispatch();

	const company = useSelector(state => state.company);
	const robot = useSelector(state => state.robot);

	const [open, setOpen] = React.useState(false);

	const onRobotStart = () => {
		dispatch(startRobot());
	};

	useEffect(() => {
		loadRobotEvents(businessId);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (robot?.loading || businessId === undefined || businessId === null) {
		return (
			<React.Fragment>
				{robot?.loading ? (
					<React.Fragment>{robot?.loading ? <CircularProgress size={20} /> : null}</React.Fragment>
				) : (
					<div>No data</div>
				)}
			</React.Fragment>
		);
	}

	const lastRun = () => {
		if (company?.anorBot) {
			return company?.anorBot.created ? moment(company?.anorBot.created).format('D.M.YYYY hh:mm') : 'Ei aikaisempia ajoja';
		}

		return company?.company.robotData?.created ? moment(company?.company.robotData?.created).format('D.M.YYYY hh:mm') : 'Ei aikaisempia ajoja';
	};

	return (
		<React.Fragment>
			<GridContainer contentContainer>
				<GridItem>
					<Button round="true" startIcon={<RobotIcon />} onClick={() => setOpen(true)} color="primary">
						Käynnistä robotti
					</Button>
					{lastRun()
						? <div>Edellinen ajo päättyi: {lastRun()}</div>
						: ''}
					<ConfirmDialog
						title='Aja robotti'
						open={open}
						setOpen={setOpen}
						onConfirm={onRobotStart}
						okMessage='Käynnistä ajo'
					>
						Haluatko hakea robottidatan yritykselle: <b>{company?.company.name}</b>. Data-ajo voi kestää muutamia minuutteja.
					</ConfirmDialog>
				</GridItem>
			</GridContainer>
		</React.Fragment>
	);
}

export default Robot;
