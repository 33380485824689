import React from 'react';
import { useIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { makeStyles } from '@material-ui/core/styles';

import StyledMenu from '../StyledMenu';
import { color } from 'assets/jss/grants/common/grantsBase.js';

const useStyles = makeStyles(() => ({
    menuItem: {
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: color.primaryColor,
        },
        '&:hover': {
            backgroundColor: color.primaryColor,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: color.whiteColor,
            },
        },
    },
}));

const DropdownMenu = props => {
    const { onClose } = props;
    const classes = useStyles();
    const intl = useIntl();

    return (
        <StyledMenu {...props}>
            <MenuItem onClick={() => onClose('instruments')} className={classes.menuItem}>
                <ListItemIcon>
                    <ListAltIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'dropdown.instruments' })} />
            </MenuItem>
        </StyledMenu>
    );
};

export default DropdownMenu;
