import React from 'react';
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/grants/icons';
import IconButton from '@material-ui/core/IconButton';
import ConfirmDialog from 'components/ConfirmDialog';
import Button from 'components/CustomButtons/Button';

const useStyles = makeStyles(styles);

const RemoveButton = (props) => {
  const { onClick, title, confirmContent, icon } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const iconbutton = () => {
    return (
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => setOpen(true)}
      >
        <DeleteIcon className={classes.removeIcon} />
      </IconButton>
    );
  };

  const normalButton = () => {
    return (
      <Button round="true" startIcon={<DeleteIcon />} onClick={() => setOpen(true)} color="danger">
        Poista
      </Button>
    );
  };

  const button = () => { 
    if (icon === undefined || icon === true) return iconbutton();
    
    return normalButton();
  };

  return (
    <React.Fragment>
      <ConfirmDialog
        title={title}
        open={open}
        setOpen={setOpen}
        onConfirm={onClick}
      >
        {confirmContent}
      </ConfirmDialog>
      {button()}
    </React.Fragment>
  );
};

export default RemoveButton;
