import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { makeStyles } from '@material-ui/core/styles';

import { getHighestIntegerValue, getFlagsIntegerValue /*, getLowestIntegerValue */ } from 'utils/bitwiseOperations';

const useStyles = makeStyles(theme => ({
    icon: {
        height: '1rem',
        color: theme.palette.primary.darkYellow,
    },
    notvalidOrange: {
        color: theme.palette.primary.darkYellow,
        fontWeight: 'bold',
    },
    notvalidRed: {
        color: '#8E0D44',
        fontWeight: 'bold',
    },
    iconValid: {
        color: theme.palette.success.main,
    },
    withoutBorder: {
        borderBottom: 'none',
        verticalAlign: 'top',
    },
    firstColumn: {
        borderBottom: 'none',
        width: '20px',
        padding: '6px 0 6px 0',
        verticalAlign: 'top',
    },
    last: {
        backgroundColor: '#F3F1EF',
    },
    rowTitle: {
        fontWeight: 'bold',
    },
    limitatorTable: {
        '& th': {
            paddingTop: '1.5rem',
            fontSize: '1.1rem',
            fontWeight: 'bold',
        },
        '& td, th': {
            paddingLeft: '2rem',
        },
        '& tbody tr:last-child td': {
            paddingBottom: '1.5rem',
        },
    },
}));

const Limitators = ({ id, limitators, company }) => {
    const classes = useStyles();
    const getLimitatorValue = (compareProperty, value, func) => {
        if (value === null || value === 0) return 'instrument.radar.choice.novalue';
        if (func !== undefined && func !== null) return `instrument.radar.choice.${compareProperty}.${func(value)}`;

        return `instrument.radar.choice.${compareProperty}.${value}`;
    };

    return (
        <TableContainer /* component={Paper} */ className={classes.limitatorTable}>
            <Table size='small' aria-label='limitators'>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.firstColumn} colSpan={2}></TableCell>
                        <TableCell className={classes.withoutBorder}>
                            <FormattedMessage id='instrument.data.type.suitableValues' />
                        </TableCell>
                        <TableCell className={`${classes.withoutBorder} ${classes.last}`}>
                            {company?.name ?? <FormattedMessage id='instrument.data.type.scenarioValue' />}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_.map(limitators, ({ companyValue, compareProperty, requiredValue, isValid }) => {
                        return (
                            <TableRow key={id + compareProperty} /* hover */>
                                <TableCell className={classes.firstColumn}>
                                    <Brightness1Icon className={`${classes.icon} ${isValid && classes.iconValid}`} />
                                </TableCell>
                                <TableCell className={classes.withoutBorder}>
                                    <span className={classes.rowTitle}>
                                        <FormattedMessage id={`instrument.radar.choice.${compareProperty}`} />
                                    </span>
                                </TableCell>
                                <TableCell className={classes.withoutBorder}>
                                    <span className={`${!isValid && classes.notvalidOrange}`}>
                                        {/* <FormattedMessage id={getLimitatorValue(compareProperty, getLowestIntegerValue(requiredValue))} /> */}
                                        {getFlagsIntegerValue(requiredValue).map(flag => {
                                            return (
                                                <span key={flag} style={{ display: 'block' }}>
                                                    <FormattedMessage id={getLimitatorValue(compareProperty, flag)} />
                                                </span>
                                            );
                                        })}
                                    </span>
                                </TableCell>
                                <TableCell className={`${classes.withoutBorder} ${classes.last}`}>
                                    <span className={`${!isValid && classes.notvalidRed}`}>
                                        <FormattedMessage id={getLimitatorValue(compareProperty, companyValue, getHighestIntegerValue)} />
                                    </span>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Limitators;
