import React from 'react';
import GridItem from 'components/Grid/GridItem';
import InfoBox from 'components/Infobox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import { registers } from 'static/enumerations';

const CompanyRegisteredEntries = (props) => {
    const { data, lang } = props;

    const getDate = (strVal) => {
        try {
            const date = new Date(strVal);
            return date.toLocaleDateString('fi-FI');
        } catch (error) {
            return strVal;
        }
    };

    if (!data) return null;

    const renderTable = (data) =>
        data.map((row, index) => {
            if (row && row.language !== lang)
                return <React.Fragment key={row + index}></React.Fragment>;

            return (
                <GridItem xs={12} md={6} lg={4} key={row + index}>
                    <InfoBox
                        title={registers[row.register]}
                        content={getDate(row.registrationDate)}
                        isIcon
                        icon={
                            row.status === 1 && row.endDate === null ? (
                                <CheckCircleOutlineIcon fontSize="small" />
                            ) : (
                                <RadioButtonUncheckedOutlinedIcon fontSize="small" />
                            )
                        }
                    />
                </GridItem>
            );
        });

    return <React.Fragment>{renderTable(data)}</React.Fragment>;
};

export default CompanyRegisteredEntries;
