import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { GridContainer, GridItem } from 'components/Grid';
import { useWidth } from 'utils/useWidth';
import InstrumentRadar from '../InstrumentRadar';
import InstrumentDescription from '../InstrumentDescription';
import Limitators from '../Limitators';
import NoteItem from './NoteItem';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles(theme => ({
    iconButton: {
        padding: 0,
    },
    header: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.white,
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        // marginBottom: theme.spacing(2),
    },
    headerItem: {
        flexGrow: 1,
    },
    headerText: {
        fontSize: '1.2rem',
        fontWeight: 600,
    },
    suitabilityText: {
        fontSize: '1.6rem',
        fontWeight: 600,
        color: theme.palette.primary.white,
    },
    descriptionHeader: {
        fontSize: '2rem',
        fontWeight: 600,
    },
    description: {
        padding: theme.spacing(6),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    additionalInformation: {
        padding: theme.spacing(4),
        background: '#FBFAF7',
    },
    instrumentItem: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.white,
    },
    suitability: {
        textAlign: 'center',
        color: theme.palette.primary.white,
        padding: theme.spacing(2),
        border: '1px solid',
        borderBottomColor: theme.palette.success.main,
        borderLeft: 'none',
        backgroundColor: theme.palette.success.main,
    },
    bgYellow: {
        backgroundColor: theme.palette.primary.darkYellow,
        borderBottomColor: theme.palette.primary.darkYellow,
    },
    bgRed: {
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    limitatorsHeader: {
        fontSize: '1.6rem',
        fontWeight: 600,
        padding: theme.spacing(1),
    },
    limitators: {
        padding: theme.spacing(4),
        backgroundColor: '#FBFAF7',
    },
    buttonContainer: {
        textAlign: 'center',
        margin: theme.spacing(3),
    },
    buttonChanges: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
    },
    rightContent: {
        position: 'sticky',
        top: 0,
        // height: '100vh',
    },
    leftContent: {
        overflow: 'auto',
        background: 'linear-gradient(to bottom, #f7f7f7, #fff 7%)',
    },
    container: {
        minHeight: '100vh',
    },
    content: {
        flex: '1 0 auto',
        marginBottom: theme.spacing(2),
    },
    footer: {
        flexShrink: 0,
    },
    noteBox: {
        marginTop: theme.spacing(2),
    },
    noteText: {
        fontSize: '1.5rem',
        fontWeight: 600,
    },
    radarWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    link: {
        fontSize: '1.2rem',
        margin: '1rem 0',
        color: theme.palette.success.main,
        '&:hover': {
            color: theme.palette.primary.darkYellow,
        },
    },
}));

const InstrumentDrawer = ({ open, close, instrument, company, height }) => {
    const { id, name, description, limitators, additionalInformation, url } = instrument;
    const classes = useStyles();
    const intl = useIntl();
    const width = useWidth();

    const noValidLimitators = limitators.filter(({ isValid }) => !isValid);

    const radarContent = (
        <>
            <Box
                className={`${classes.suitability} ${instrument.level === 1 && classes.bgYellow} ${
                    instrument.level === 1 && classes.bgRed
                }`}
            >
                <Typography variant='h2' className={classes.suitabilityText}>
                    {intl.formatMessage({ id: 'table.header.text.suitability' })}
                </Typography>
            </Box>
            <Box className={classes.radarWrapper}>
                <InstrumentRadar data={limitators} name={name} height={height} companyName={company?.name} />
            </Box>
            {noValidLimitators.length > 0 && (
                <Box className={classes.noteBox}>
                    <Typography component='p' align='center' color='primary' className={classes.noteText}>
                        {intl.formatMessage({ id: 'instrument.radar.note.text' })}
                    </Typography>
                    {noValidLimitators.map(({ compareProperty }) => (
                        <NoteItem key={id + compareProperty} compareProperty={compareProperty} />
                    ))}
                </Box>
            )}
        </>
    );

    return (
        <Drawer anchor='bottom' open={open} onClose={close} transitionDuration={{ enter: 1000, exit: 1000 }}>
            <GridContainer>
                <GridItem xs={12} md={8}>
                    <GridContainer alignItems='center' className={classes.header}>
                        <GridItem xs={2}>
                            <IconButton aria-label='close' onClick={close} className={classes.iconButton}>
                                <CloseIcon color='primary' />
                            </IconButton>
                        </GridItem>
                        <GridItem xs={10} className={classes.headerItem}>
                            <Box textAlign='center'>
                                <Typography component='span' className={classes.headerText}>
                                    {instrument.provider} * {instrument.name}
                                </Typography>
                            </Box>
                        </GridItem>
                    </GridContainer>
                    <Box className={classes.leftContent}>
                        <Box className={classes.description}>
                            <Typography variant='h2' className={classes.descriptionHeader}>
                                {instrument.name}
                            </Typography>
                            <Box component='div' className={classes.link}>
                                {url ? <a href={`${url}`} rel='noopener noreferrer' target='_blank'>{`${url}`}</a> : null}
                            </Box>

                            <Typography component='div'>{ReactHtmlParser(description)}</Typography>
                        </Box>
                        <Box component='div' className={classes.description}>
                            <Typography component='h3'>
                                <strong>{intl.formatMessage({ id: 'instrument.additionalInfo.title' })}</strong>
                            </Typography>
                            <GridContainer component='div' className={classes.additionalInformation}>
                                <GridItem xs={1}>
                                    <PriorityHighIcon color='secondary' fontSize='large' />
                                </GridItem>
                                <GridItem xs={11}>{ReactHtmlParser(additionalInformation)}</GridItem>
                            </GridContainer>
                        </Box>
                        <Box className={classes.instrumentItem}>
                            <InstrumentDescription instrument={instrument} />
                        </Box>
                        {(width === 'xs' || width === 'sm') && radarContent}
                        <Box className={classes.limitators}>
                            <Typography variant='h2' className={classes.limitatorsHeader}>
                                {intl.formatMessage({ id: 'limitators.table.header' })}
                            </Typography>
                            <Limitators id={id} limitators={limitators} company={company} />
                            <Box className={classes.buttonContainer}>
                                {/* <Button variant='outlined' onClick={handleClick} className={classes.buttonChanges}>
                                    {intl.formatMessage({ id: 'button.report.changes' })}
                                </Button> */}
                            </Box>
                        </Box>
                    </Box>
                </GridItem>
                <GridItem xs={12} md={4}>
                    <Box className={`${width !== 'xs' && width !== 'sm' && classes.rightContent}`}>
                        <GridContainer direction='column' className={`${width !== 'xs' && width !== 'sm' && classes.container}`}>
                            <GridItem className={classes.content}>{width !== 'xs' && width !== 'sm' && radarContent}</GridItem>
                        </GridContainer>
                    </Box>
                </GridItem>
            </GridContainer>
        </Drawer>
    );
};

export default InstrumentDrawer;
