import React from 'react';
import { IntlProvider } from 'react-intl';
import fi_translations from './translations/fi.json';

const formats = {
  number: {
    EUR: {
      style: 'currency',
      currency: 'EUR',
    },
  },
};

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    // this.switchToEnglish = () =>
    //   this.setState({ locale: "fi", messages: fi_translations });

    this.state = {
      locale: 'fi',
      messages: fi_translations,
      //switchToEnglish: this.switchToEnglish,
    };
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="fi"
          formats={formats}
          defaultFormats={formats}
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
