import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import LicenseForm from './LicenseManagementFormContent';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { saveLicenseInformation } from '../actions';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        paddingRight: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.white,
    },
    dialogContent: {
        backgroundColor: theme.palette.primary.white,
    },
    dialogActions: {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(4),
        backgroundColor: theme.palette.primary.white,
    },
    dialogTitleText: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    closeIcon: {
        color: theme.palette.primary.main,
    },
    header: {
        fontSize: '1.6rem',
    },
    box: {
        marginTop: theme.spacing(2),
    },
    title: {
        fontSize: '1.1rem',
    },
    wrapper: {
        position: 'relative',
    },
    submitButton: {
        fontWeight: 600,
        color: theme.palette.primary.white,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            opacity: '0.8',
            backgroundColor: theme.palette.success.main,
        },
    },
    buttonLoading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: theme.spacing(-1.5),
        marginLeft: theme.spacing(-1.5),
        color: theme.palette.primary.white,
    },
    form: {
        marginBottom: '0'
    }
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const LicenseModal = ({ open, close, saveLoading, license }) => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();

    const createLicenseSchema = yup.object().shape({
        endDate: yup.date().nullable().default(null).test('is-greater', intl.formatMessage({ id: 'timeline.instrument.endTime.mindate' }), (value, { parent }) => {
            const { startDate } = parent;

            if (!startDate || !value) return true; // if either one is false no need for validation

            const start = moment(startDate);

            return moment(value, 'dd.MM.yyyy').isSameOrAfter(moment(start, 'dd.MM.yyyy'));
        }),
        type: yup.string().required(`${intl.formatMessage({ id: 'account.license.form.type' })} ${intl.formatMessage({ id: 'message.isRequired' })}`)
    });

    const { control, handleSubmit, errors, reset, watch, clearErrors } = useForm({
        defaultValues: {
            license,
        },
        resolver: yupResolver(createLicenseSchema),
    });

    const onSubmit = (data) => {
        dispatch(saveLicenseInformation(data));
        reset();
        close(false);
    };

    return (
        <Dialog
            aria-labelledby='add instrument'
            aria-describedby='add instrument'
            fullWidth
            maxWidth='md'
            open={open}
            onClose={close}
            scroll='paper'
            TransitionComponent={Transition}
        >
            <form className={classes.form} onSubmit={handleSubmit((data) => onSubmit(data))}>
                <DialogTitle id='scroll-dialog-title' className={classes.dialogTitle}>
                    <GridContainer justify='space-between' alignItems='center'>
                        <GridItem xs={11}>
                            <Typography variant='h2' className={classes.dialogTitleText}>
                                {intl.formatMessage({ id: 'account.license.title' })}
                            </Typography>
                        </GridItem>
                        <GridItem xs={1}>
                            <Box textAlign='end'>
                                <IconButton aria-label='close' onClick={() => {
                                    clearErrors();
                                    reset();
                                    close(false);
                                }} className={classes.iconButton}>
                                    <CloseIcon className={classes.closeIcon} />
                                </IconButton>
                            </Box>
                        </GridItem>
                    </GridContainer>
                </DialogTitle>
                <DialogContent dividers className={classes.dialogContent}>
                    {open ? <LicenseForm handleSubmit={handleSubmit} control={control} errors={errors} license={license} watch={watch} /> : null}
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <GridContainer justify='space-between'>
                        <GridItem xs={3}>
                            <Button variant='outlined' color='primary' onClick={() => {
                                clearErrors();
                                reset();
                                close(false);
                            }}>
                                {intl.formatMessage({ id: 'common.close' })}
                            </Button>
                        </GridItem>
                        <GridItem xs={7}>
                            <GridContainer justify='flex-end'>
                                <Box className={classes.wrapper} style={{ paddingLeft: '1rem' }}>
                                    <Button variant='contained' color='primary' type='submit'>
                                        {intl.formatMessage({ id: 'account.license.form.save' })}
                                    </Button>
                                    {saveLoading && <CircularProgress size={24} className={classes.buttonLoading} />}
                                </Box>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </DialogActions>
            </form>
        </Dialog >
    );
};

export default LicenseModal;
