import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const CustomTooltip = withStyles(theme => ({
    tooltip: {
        padding: theme.spacing(1),
        border: '1px solid #ccc',
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(255, 255, 255, 1)',
    },
    tooltipPlacementTop: {
        margin: theme.spacing(0.5),
    },
}))(Tooltip);

export default CustomTooltip;
