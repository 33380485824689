import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    yearContainer: {
        position: 'relative',
    },
    yearText: {
        position: 'absolute',
        top: theme.spacing(-3.35),
        left: theme.spacing(-1.5),
        transform: 'rotate(-90deg)',
        fontSize: '0.6875rem',
        fontWeight: 500,
        color: 'rgba(72, 46, 81, 0.6)',
    },
    yearTextLast: {
        left: theme.spacing(-1.75),
    },
}));

const TFooterYear = ({ quarters, startTime }) => {
    const classes = useStyles();

    let startYear = +moment(startTime).format('YYYY');
    let lastYear = null;

    const multiplesOf12 = quarters.filter(item => item % 12 === 0);
    const lastElementOfQuarters = quarters[quarters.length - 1];
    const lastElementOfMultiplesOf12 = multiplesOf12[multiplesOf12.length - 1];

    const items = multiplesOf12.map((_, index, arr) => {
        lastYear = startYear + arr.length;

        return (
            <td key={startYear + index} colSpan={12}>
                <div className={classes.yearContainer}>
                    <Typography variant='subtitle2' className={classes.yearText}>
                        {startYear + index}
                    </Typography>
                </div>
            </td>
        );
    });

    const lastYearTd = (
        <td key={lastYear} colSpan={lastElementOfQuarters - lastElementOfMultiplesOf12}>
            <div className={classes.yearContainer}>
                <Typography variant='subtitle2' className={`${classes.yearText} ${classes.yearTextLast}`}>
                    {lastYear}
                </Typography>
            </div>
        </td>
    );

    const itemsWithLastYear = [...items, lastYearTd];

    return <tr>{itemsWithLastYear}</tr>;
};

export default TFooterYear;
