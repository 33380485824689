import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RemoveIcon from '@material-ui/icons/Remove';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(5),
    },
    title: {
        fontSize: '0.875rem',
        fontWeight: 700,
        paddingLeft: theme.spacing(1),
        marginTop: 0,
        color: theme.palette.primary.grey,
    },
    list: {
        padding: 0,
    },
    listItemMain: {
        padding: 0,
        paddingLeft: theme.spacing(1),
    },
    listItemNested: {
        padding: 0,
        paddingLeft: theme.spacing(3),
    },
    selectedMain: {
        padding: 0,
        '& div, a': {
            color: theme.palette.success.main,
        },
    },
    selectedNested: {
        '& div, a': {
            color: theme.palette.success.main,
        },
    },
    itemText: {
        '& span': {
            fontSize: '0.875rem',
            fontWeight: 600,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    itemTextNested: {
        '& span': {
            fontWeight: 400,
        },
    },
    listIcon: {
        minWidth: '1rem',
    },
    dotIcon: {
        width: 5,
        height: 5,
        color: theme.palette.primary.main,
    },
    removeIcon: {
        height: 18,
    },
}));

const Headings = ({ headings, activeId }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <div className={classes.root}>
            <p className={classes.title}>{intl.formatMessage({ id: 'financial.plan.table.of.contents' })}</p>
            <List className={classes.list}>
                {headings.map(({ id, title, items }) => (
                    <div key={id}>
                        <ListItem className={`${classes.listItemMain} ${id === activeId && classes.selectedMain}`}>
                            <ListItemIcon className={classes.listIcon}>
                                {id === activeId ? (
                                    <RemoveIcon className={classes.removeIcon} />
                                ) : (
                                    <FiberManualRecordIcon className={classes.dotIcon} />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                component='a'
                                href={`#${id}`}
                                onClick={e => {
                                    e.preventDefault();
                                    document.querySelector(`#${id}`).scrollIntoView({
                                        behavior: 'smooth',
                                    });
                                }}
                                className={classes.itemText}
                            >
                                {title}
                            </ListItemText>
                        </ListItem>
                        <Collapse in={true}>
                            {items.length > 0 && (
                                <List className={classes.list}>
                                    {items.map(({ id, title }) => (
                                        <ListItem
                                            key={id}
                                            className={`${classes.listItemNested} ${id === activeId && classes.selectedNested}`}
                                        >
                                            <ListItemText
                                                component='a'
                                                href={`#${id}`}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    document.querySelector(`#${id}`).scrollIntoView({
                                                        behavior: 'smooth',
                                                    });
                                                }}
                                                className={`${classes.itemText} ${classes.itemTextNested}`}
                                            >
                                                {title}
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                        </Collapse>
                    </div>
                ))}
            </List>
        </div>
    );
};

const useHeadingsData = () => {
    const [nestedHeadings, setNestedHeadings] = useState([]);

    useEffect(() => {
        const headingElements = Array.from(document.querySelectorAll('main .tocTitle, main  .tocTitle'));

        const newNestedHeadings = getNestedHeadings(headingElements);
        setNestedHeadings(newNestedHeadings);
    }, []);

    return { nestedHeadings };
};

const getNestedHeadings = headingElements => {
    const nestedHeadings = [];

    headingElements.forEach((heading, index) => {
        const { innerText: title, id } = heading;

        if (heading.nodeName === 'H2') {
            nestedHeadings.push({ id, title, items: [] });
        } else if (heading.nodeName === 'H3' && nestedHeadings.length > 0) {
            nestedHeadings[nestedHeadings.length - 1].items.push({
                id,
                title,
            });
        }
    });

    return nestedHeadings;
};

const useIntersectionObserver = setSelectedItem => {
    const headingElementsRef = useRef({});

    useEffect(() => {
        const callback = headings => {
            headingElementsRef.current = headings.reduce((map, headingElement) => {
                map[headingElement.target.id] = headingElement;
                return map;
            }, headingElementsRef.current);

            // Get all headings that are currently visible on the page
            const visibleHeadings = [];
            Object.keys(headingElementsRef.current).forEach(key => {
                const headingElement = headingElementsRef.current[key];
                if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
            });

            // const getIndexFromId = id => headingElements.findIndex(heading => heading.id === id);

            // If there is only one visible heading, this is our "active" heading
            if (visibleHeadings.length === 1) {
                setSelectedItem(visibleHeadings[0].target.id);
                // If there is more than one visible heading,
                // choose the one that is closest to the top of the page
            } else if (visibleHeadings.length > 1) {
                const sortedVisibleHeadings = visibleHeadings.filter(({ target }) => target.id);
                // .sort((a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id));

                setSelectedItem(sortedVisibleHeadings[0].target.id);
            }
        };

        const observer = new IntersectionObserver(callback, {
            threshold: 0.025,
        });

        const headingElements = Array.from(document.querySelectorAll('div[id]:not([value=""])'));

        headingElements
            .filter(({ id }) => id !== 'undefined' && id !== 'timeline-content' && id !== 'root') // FIXME: if main content will be changed
            .forEach(element => observer.observe(element));

        return () => observer.disconnect();
    }, [setSelectedItem]);
};

const TableOfContents = ({ setSelectedId }) => {
    const [activeId, setActiveId] = useState();
    const { nestedHeadings } = useHeadingsData();

    const setSelectedItem = data => {
        setActiveId(data);
        setSelectedId(data);
    };

    useIntersectionObserver(setSelectedItem);

    return (
        <nav aria-label='TOC'>
            <Headings headings={nestedHeadings} activeId={activeId} />
        </nav>
    );
};

export default TableOfContents;
