import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import FormSpaceHeader from 'components/FormSpaceHeader';
import { GridContainer, GridItem } from 'components/Grid/';
import FinanceTableTitle from 'components/FinanceTableTitle';
import CurrencyField from 'components/CurrencyField';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { gridTranslations } from 'translations/gridTranslations';

const useStyles = makeStyles(() => ({
    currencyField: {
        '&>div::before, &>div::after': { display: 'none', border: 'none', transition: 'none' },
        '& input': { padding: '5px' }
    },
    marker: {

    }
}));

const FinderFinanceData = () => {
    const intl = useIntl();
    const finder = useSelector(state => state?.company?.finder);
    const robotData = useSelector(state => state?.company?.company?.robotData);

    const classes = useStyles();

    const renderCurrencyTextField = value => {
        return (
            <CurrencyField
                className={classes.currencyField}
                value={value}
            //disabled={true}
            />
        );
    };

    const renderCurrency = value => {
        try {
            value = value ? value : 0;
        } catch (error) {
            value = 0;
        }

        return renderCurrencyTextField(value);
    };

    const columns = [
        { headerName: `${intl.formatMessage({ id: 'robot.finance.data.period' })}`, field: 'year', width: 150 },
        { headerName: `${intl.formatMessage({ id: 'robot.finance.data.currentRatio' })}`, field: 'currentRatio', width: 150 },
        { headerName: `${intl.formatMessage({ id: 'robot.finance.data.ebitdas' })}`, field: 'ebitdas', width: 150 },
        { headerName: `${intl.formatMessage({ id: 'robot.finance.data.employees' })}`, field: 'employees', width: 150 },
        { headerName: `${intl.formatMessage({ id: 'robot.finance.data.operatingMargin' })}`, field: 'operatingMargin', width: 150 },
        {
            headerName: `${intl.formatMessage({ id: 'robot.finance.data.profits', })}`, field: 'profits', width: 150, renderCell: (params) => (
                renderCurrency(params.value)
            )
        },
        { headerName: `${intl.formatMessage({ id: 'robot.finance.data.quickRatio', })}`, field: 'quickRatio', width: 150 },
        { headerName: `${intl.formatMessage({ id: 'robot.finance.data.solvency', })}`, field: 'solvency', width: 150 },
        { headerName: `${intl.formatMessage({ id: 'robot.finance.data.percentageChange', })}`, field: 'percentageChange', width: 150 },
        {
            headerName: `${intl.formatMessage({ id: 'robot.finance.data.balance', })}`, field: 'balance', width: 150, renderCell: (params) => (
                renderCurrency(params.value)
            )
        },
        {
            headerName: `${intl.formatMessage({ id: 'robot.finance.data.turnover', })}`, field: 'turnover', width: 150, renderCell: (params) => (
                renderCurrency(params.value)
            )
        },
        {
            headerName: `${intl.formatMessage({ id: 'robot.finance.data.equity', })}`, field: 'equity', width: 150, renderCell: (params) => (
                renderCurrency(params.value)
            )
        },
    ];

    const rows = finder?.economicalInfo?.map((item, index) => ({
        id: item + index,
        year: item.year,
        currentRatio: item.currentRatio,
        ebitdas: item.ebitdas,
        employees: item.employees,
        operatingMargin: item.operatingMargin,
        profits: item.profits * 1000,
        quickRatio: item.quickRatio,
        solvency: item.solvency,
        percentageChange: item.percentageChange,
        balance: item.balance,
        turnover: item.turnover * 1000,
        equity: item.equity
    }));

    return (
        <>
            <GridContainer>
                <FormSpaceHeader header={<FormattedMessage id='robot.finance.title' />} anchor='robot_financial_data' />
                {robotData?.osakepaaoma ?
                    <>
                        <GridItem xs={3}>
                            <FinanceTableTitle value={intl.formatMessage({ id: 'robot.finance.stockinfo.amount' })} />
                        </GridItem>
                        <GridItem xs={9}>
                            <GridContainer>
                                <GridItem noPadding xs={4}>
                                    <Typography
                                        component='div'
                                        style={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {renderCurrencyTextField(robotData?.osakepaaoma.maara)}
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </> : null}

                {robotData?.osakemaara ?
                    <>
                        <GridItem xs={3}>
                            <FinanceTableTitle value={intl.formatMessage({ id: 'robot.finance.stockinfo.amount' })} />
                        </GridItem>
                        <GridItem xs={9}>
                            <GridContainer>
                                <GridItem noPadding xs={4}>
                                    <Typography
                                        component='div'
                                        style={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FinanceTableTitle value={robotData?.osakemaara + ' kpl'} />
                                    </Typography>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </> : null}

                {rows && (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        autoHeight
                        hideFooter={true}
                        localeText={gridTranslations(intl)}
                        className={classes.dataGrid}
                    />
                )}
            </GridContainer>
        </>
    );
};

export default FinderFinanceData;
