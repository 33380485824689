import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    smooth: {
        position: 'absolute',
        top: 0,
        width: theme.spacing(12),
        pointerEvents: 'none',
    },
}));

const SmoothDiv = ({ left = false, right = false, isHorizontalScrollbar = false, isVerticalScrollbar = false }) => {
    const classes = useStyles();

    return (
        <div
            className={classes.smooth}
            style={{
                bottom: isHorizontalScrollbar ? '7.5px' : 0,
                background: right
                    ? 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 100%)'
                    : 'linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%)',
                right: right && isVerticalScrollbar ? '6px' : 0,
                left: left && 0,
            }}
        />
    );
};

export default SmoothDiv;
