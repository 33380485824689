import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';
import FormSpaceHeader from 'components/FormSpaceHeader';

import { source } from 'static/enumerations';

const CompanyOffices = (props) => {
  const { data, messages } = props;
  const intl = useIntl();

  if (!data) return null;

  const renderTable = (data) => {
    return (
      <TableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="Auxiliary names table">
          <TableHead>
            <TableRow>
              <TableCell>
                {intl.formatMessage(messages.formdata.source)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(messages.formdata.name)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(messages.formdata.registered)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(messages.formdata.enddate)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row + index}>
                <TableCell>{source[row.source]}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.registrationDate}</TableCell>
                <TableCell>{row.endDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <FormSpaceHeader
          header={intl.formatMessage(messages.auxiliarynamesData.title)}
          anchor={intl.formatMessage(messages.auxiliarynamesData.title)}
        />
        {renderTable(data)}
      </GridItem>
    </GridContainer>
  );
};

export default CompanyOffices;
