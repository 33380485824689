import React from 'react';
import { useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.secondary.line,
        padding: theme.spacing(6),
    },
    title: {
        fontSize: '1.8rem',
        fontWeight: 900,
        overflowWrap: 'break-word',
        hyphens: 'auto',
    },
    companyName: {
        fontSize: '1.2rem',
        fontWeight: 900,
        color: theme.palette.success.main,
        marginTop: theme.spacing(1),
    },
    lastModified: {
        fontSize: '0.75rem',
        marginTop: theme.spacing(3),
    },
}));

const SidebarHeader = ({ companyName, type }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Box className={classes.root}>
            <Typography variant='h1' className={classes.title}>
                {type === 'Timeline' ? intl.formatMessage({ id: 'financial.plan.timeline.title' }) : intl.formatMessage({ id: 'financial.plan.title' })}
            </Typography>
            <Typography variant='h2' className={classes.companyName}>
                {companyName}
            </Typography>
            <Typography component='p' className={classes.lastModified}>
                {intl.formatMessage({ id: 'financial.plan.last.modified' })} eilen klo 8.12
            </Typography>
        </Box>
    );
};

export default SidebarHeader;
