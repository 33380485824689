import { defineMessages } from 'react-intl';

const basicdataScope = 'company.basicdata';
export const basicdata = defineMessages({
  streetaddress: {
    id: `${basicdataScope}.streetaddress`,
    defaultMessage: 'Missing',
  },
  name: {
    id: `${basicdataScope}.name`,
    defaultMessage: 'Missing',
  },
  personnelSize: {
    id: `${basicdataScope}.personnelSize`,
    defaultMessage: 'Henkilöstömäärä',
  },
  businessid: {
    id: `${basicdataScope}.businessid`,
    defaultMessage: 'Missing',
  },
  form: {
    id: `${basicdataScope}.form`,
    defaultMessage: 'Missing',
  },
  postcode: {
    id: `${basicdataScope}.postcode`,
    defaultMessage: 'Missing',
  },
  city: {
    id: `${basicdataScope}.city`,
    defaultMessage: 'Missing',
  },
  customerCategory: {
    id: `${basicdataScope}.customerCategory`,
    type: {
      id: `${basicdataScope}.customerCategory.type`,
      toBeEstablished: {
        id: `${basicdataScope}.customerCategory.type.toBeEstablished`,
      },
      existingNoSupport: {
        id: `${basicdataScope}.customerCategory.type.existingNoSupport`,
      },
      existingHasSupport: {
        id: `${basicdataScope}.customerCategory.type.existingHasSupport`,
      },
      noSupportCapable: {
        id: `${basicdataScope}.customerCategory.type.noSupportCapable`,
      },
    },
  },
  internationalization: {
    id: `${basicdataScope}.internationalization`,
    type: {
      id: `${basicdataScope}.internationalization.type`,
      hasInternationalWill: {
        id: `${basicdataScope}.internationalization.type.hasInternationalWill`,
      },
      hasInternationGrowtAbility: {
        id: `${basicdataScope}.internationalization.type.hasInternationGrowtAbility`,
      },
      alreadyInInternationalBusiness: {
        id: `${basicdataScope}.internationalization.type.alreadyInInternationalBusiness`,
      },
      onlyInDomesticMarkets: {
        id: `${basicdataScope}.internationalization.type.onlyInDomesticMarkets`,
      },
    },
  },
  outskirts: {
    id: `${basicdataScope}.outskirts`,
    type: {
      id: `${basicdataScope}.outskirts.type`,
      yes: {
        id: `${basicdataScope}.outskirts.type.yes`,
      },
      no: {
        id: `${basicdataScope}.outskirts.type.no`,
      },
      maybe: {
        id: `${basicdataScope}.outskirts.type.maybe`,
      },
    },
    mapLink: {
      id: `${basicdataScope}.outskirts.mapLink`,
    },
  },
  hasIPR: {
    id: `${basicdataScope}.hasIPR`,
  },
  iprDescription: {
    id: `${basicdataScope}.iprDescription`,
    helper: {
      id: `${basicdataScope}.iprDescription.helper`,
    },
  },
  equity: {
    id: `${basicdataScope}.equity`,
  },
  foreignEquity: {
    id: `${basicdataScope}.foreignEquity`,
  },
  deminimisAmount: {
    id: `${basicdataScope}.deminimisAmount`,
  },
  equityArrangements: {
    id: `${basicdataScope}.equityArrangements`,
    helper: {
      id: `${basicdataScope}.equityArrangements.helper`,
    },
  },
  decriptiveTitle: { id: `${basicdataScope}.decriptiveTitle` },
  businessDescription: {
    id: `${basicdataScope}.businessDescription`,
    helper: { id: `${basicdataScope}.businessDescription.helper` },
  },
  projectCosts: {
    id: `${basicdataScope}.projectCosts`,
    helper: { id: `${basicdataScope}.projectCosts.helper` },
  },
  other: {
    id: `${basicdataScope}.other`,
    helper: { id: `${basicdataScope}.other.helper` },
  },
  previousSupport: {
    id: `${basicdataScope}.previousSupport`,
    helper: { id: `${basicdataScope}.previousSupport.helper` },
  },
  commonEconomicalSituation: {
    id: `${basicdataScope}.commonEconomicalSituation`,
    helper: { id: `${basicdataScope}.commonEconomicalSituation.helper` },
  },
});

const formdataScope = 'company.formdata';
export const formdata = defineMessages({
  source: {
    id: `${formdataScope}.source`,
    defaultMessage: 'Missing',
  },
  type: {
    id: `${formdataScope}.type`,
    defaultMessage: 'Missing',
  },
  value: {
    id: `${formdataScope}.value`,
    defaultMessage: 'Missing',
  },
  registered: {
    id: `${formdataScope}.registered`,
    defaultMessage: 'Missing',
  },
  enddate: {
    id: `${formdataScope}.enddate`,
    defaultMessage: 'Missing',
  },
  date: {
    id: `${formdataScope}.date`,
    defaultMessage: 'Missing',
  },
  submit: {
    id: `${formdataScope}.submit`,
    defaultMessage: 'Missing',
  },
  cancel: {
    id: `${formdataScope}.cancel`,
    defaultMessage: 'Missing',
  },
  name: {
    id: `${formdataScope}.name`,
    defaultMessage: 'Missing',
  },
  add: {
    id: `${formdataScope}.add`,
    defaultMessage: 'Missing',
  },
  description: {
    id: `${formdataScope}.description`,
    defaultMessage: 'Missing',
  },
  updated: {
    id: `${formdataScope}.updated`,
    defaultMessage: 'Missing',
  },
});

const addressDataScope = 'company.formdata.address';
export const addressData = defineMessages({
  title: {
    id: `${addressDataScope}.title`,
    defaultMessage: 'Missing',
  },
  add: {
    id: `${addressDataScope}.add`,
    defaultMessage: 'Missing',
  },
});

const officesDataScope = 'company.formdata.offices';
export const officesData = defineMessages({
  title: {
    id: `${officesDataScope}.title`,
    defaultMessage: 'Missing',
  },
});

const businessChangesScope = 'company.formdata.businesschanges';
export const businesschangesData = defineMessages({
  title: {
    id: `${businessChangesScope}.title`,
    defaultMessage: 'Missing',
  },
  oldbusinessid: {
    id: `${businessChangesScope}.oldbusinessid`,
    defaultMessage: 'Missing',
  },
  newbusinessid: {
    id: `${businessChangesScope}.newbusinessid`,
    defaultMessage: 'Missing',
  },
});

const contanctDetailScope = 'company.formdata.contactdetails';
export const contactdetailsData = defineMessages({
  title: {
    id: `${contanctDetailScope}.title`,
    defaultMessage: 'Missing',
  },
  add: {
    id: `${contanctDetailScope}.add`,
    defaultMessage: 'Missing',
  },
});

const contanctownershipScope = 'company.formdata.ownership';
export const ownershipData = defineMessages({
  title: {
    id: `${contanctownershipScope}.title`,
    defaultMessage: 'Missing',
  },
  share: {
    id: `${contanctownershipScope}.share`,
    defaultMessage: 'Missing',
  },
  add: {
    id: `${contanctownershipScope}.add`,
    defaultMessage: 'Missing',
  },
});

const auxiliarynamesDataScope = 'company.formdata.auxiliarynames';
export const auxiliarynamesData = defineMessages({
  title: {
    id: `${auxiliarynamesDataScope}.title`,
    defaultMessage: 'Missing',
  },
});

const registeredEntriesDataScope = 'company.formdata.registeredEntries';
export const registeredEntriesData = defineMessages({
  title: {
    id: `${registeredEntriesDataScope}.title`,
    defaultMessage: 'Rekisteröinnit',
  },
});

const companyBusinessLinesDataScope = 'company.formdata.businessLines';
export const companyBusinessLinesData = defineMessages({
  title: {
    id: `${companyBusinessLinesDataScope}.title`,
    defaultMessage: 'Liiketoiminta',
  },
});

const namesDataScope = 'company.formdata.names';
export const namesData = defineMessages({
  title: {
    id: `${namesDataScope}.title`,
    defaultMessage: 'Nimet',
  },
});

const responsiblesDataScope = 'company.formdata.responsibles';
export const responsiblesData = defineMessages({
  title: {
    id: `${responsiblesDataScope}.title`,
    defaultMessage: 'Missing',
  },
  firstname: {
    id: `${responsiblesDataScope}.firstname`,
    defaultMessage: 'Missing',
  },
  lastname: {
    id: `${responsiblesDataScope}.lastname`,
    defaultMessage: 'Missing',
  },
  role: {
    id: `${responsiblesDataScope}.role`,
    defaultMessage: 'Missing',
  },
  iscontact: {
    id: `${responsiblesDataScope}.iscontact`,
    defaultMessage: 'Missing',
  },
  email: {
    id: `${responsiblesDataScope}.email`,
    defaultMessage: 'Missing',
  },
  tel: {
    id: `${responsiblesDataScope}.tel`,
    defaultMessage: 'Missing',
  },
  add: {
    id: `${responsiblesDataScope}.add`,
    defaultMessage: 'Missing',
  },
});