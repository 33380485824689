import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { reduxForm } from 'redux-form';
// import * as _ from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import reducer from './reducer';
import saga from './saga';
import { getSingleCompany, getSingleLoading } from './selectors';
import { saveSingleCompanyData } from './actions';
import * as messages from './messages';

import businessPlan from 'containers/BusinessPlan/saga';
import events from 'containers/Events/saga';
import workPackage from 'containers/WorkPackage/saga';
import businessPlanR from 'containers/BusinessPlan/reducer';
import eventsR from 'containers/Events/reducer';
import workPackageR from 'containers/WorkPackage/reducer';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CancelIcon from '@material-ui/icons/Cancel';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import CompanyAddresses from './components/CompanyAddresses';
import CompanyOffices from './components/CompanyOffices';
import CompanyBasicInformation from './components/CompanyBasicInfo';
import CompanyBusinessLines from './components/CompanyBusinessLines';
import CompanyRegisteredEntries from './components/CompanyRegisteredEntries';
import CompanyBusinessIdChanges from './components/CompanyBusinessIdChanges';
import CompanyNames from './components/CompanyNames';
import CompanyAuxiliaryNames from './components/CompanyAuxiliaryNames';
import ResponsiblePersons from './components/ResponsiblePersons';
import CompanyOwnership from './components/CompanyOwnership';
import ResponsiblesRobot from './components/ResponsiblesRobot';
import OtherRobot from './components/OtherRobot';
import Deminimis from './components/Deminimis';
import ContentLoader from 'components/LoadingIndicator/ContentLoader';
import FloatingButton from 'components/FloatingButton';
// import CustomPrompt from 'components/CustomPrompt';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
    },
    cancelButton: {
        marginRight: theme.spacing(2),
        color: theme.palette.primary.darkBrown,
        backgroundColor: theme.palette.primary.white,
        border: '1px solid',
        borderColor: theme.palette.primary.darkBrown,
    },
    accordionWrapper: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    accordion: {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
        '&.MuiAccordion-root.Mui-expanded': {
            margin: 0,
        },
    },
    accordionDetails: {
        padding: 0,
        backgroundColor: theme.palette.primary.white,
    },
    accordionTitle: {
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.palette.primary.white,
    },
    expandIcon: {
        color: theme.palette.primary.white,
    },
}));

// const getObjectDiff = (obj1, obj2) => {
//     if (!obj1 || !obj2) return null;

//     const diff = Object.keys(obj1).reduce((result, key) => {
//         if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
//             result.push(key);
//         } else if (_.isEqual(obj1[key], obj2[key])) {
//             const resultKeyIndex = result.indexOf(key);
//             result.splice(resultKeyIndex, 1);
//         }

//         return result;
//     }, Object.keys(obj2));

//     const obj = {};
//     for (let i = 0; i < diff.length; i++) {
//         const value = diff[i];
//         obj[value] = true;
//     }

//     return obj;
// };

const formName = 'companyBasicData';

const lang = 'FI';
const key = 'company';
let toasterMessages;

let CompanyBaseData = props => {
    const { handleSubmit, pristine, reset, submitting /* test, dirty */ } = props;
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();

    const selected = useSelector(getSingleCompany());
    const loading = useSelector(getSingleLoading());

    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    useInjectReducer({ key: 'businessPlan', reducer: businessPlanR });
    useInjectSaga({ key: 'businessPlan', saga: businessPlan });

    useInjectReducer({ key: 'events', reducer: eventsR });
    useInjectSaga({ key: 'events', saga: events });

    useInjectReducer({ key: 'workPackage', reducer: workPackageR });
    useInjectSaga({ key: 'workPackage', saga: workPackage });

    const onSubmit = values => dispatch(saveSingleCompanyData(values, toasterMessages));

    if (loading || events === undefined || events === null) {
        return <React.Fragment>{loading ? <ContentLoader /> : null}</React.Fragment>;
    }

    toasterMessages = {
        success: {
            title: intl.formatMessage({ id: 'messages.generic.success.title' }),
            body: intl.formatMessage({ id: 'messages.company.save.success' }),
        },
        error: {
            title: intl.formatMessage({ id: 'messages.generic.error.title' }),
            body: intl.formatMessage({ id: 'messages.company.save.error' }),
        },
    };

    return (
        <GridContainer className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* <CustomPrompt message={dirty} /> */}
                <CompanyBasicInformation messages={messages} initialValues={selected} selected={selected} />
                <ResponsiblePersons messages={messages} />
                <CompanyOwnership messages={messages} />
                <CompanyAddresses messages={messages} />
                {/* <CompanyContactDetails lang={lang} messages={messages} /> */}
                <GridContainer className={classes.accordionWrapper}>
                    <GridItem>
                        <Accordion square TransitionProps={{ unmountOnExit: true }} className={classes.accordion}>
                            <AccordionSummary aria-controls='company-tables' expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
                                <Typography component='p' className={classes.accordionTitle}>
                                    {intl.formatMessage({ id: 'company.information.system.data' })}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                <GridContainer direction='column'>
                                    <CompanyNames data={selected.names} messages={messages} />
                                    <CompanyBusinessLines data={selected.businessLines} lang={lang} messages={messages} />
                                    <Deminimis {...props} data={selected.robotData} messages={messages} />
                                    <ResponsiblesRobot {...props} data={selected.robotData} messages={messages} />
                                    <OtherRobot {...props} data={selected.robotData} messages={messages} />
                                    <CompanyRegisteredEntries
                                        messages={messages}
                                        {...props}
                                        data={selected.registeredEntries}
                                        lang={lang}
                                    />
                                    <CompanyOffices data={selected.registedOffices} lang={lang} messages={messages} />
                                    <CompanyBusinessIdChanges messages={messages} data={selected.businessIdChanges} />
                                    <CompanyAuxiliaryNames data={selected.auxiliaryNames} messages={messages} />
                                </GridContainer>
                            </AccordionDetails>
                        </Accordion>
                    </GridItem>
                </GridContainer>
                <FloatingButton>
                    <Button round variant='outlined' disabled={pristine || submitting} onClick={reset} className={classes.cancelButton}>
                        <CancelIcon />
                        {intl.formatMessage({ id: 'common.cancel' })}
                    </Button>
                    <Button round color='primary' disabled={submitting} type='submit'>
                        <SaveAltIcon /> {intl.formatMessage({ id: 'company.information.save' })}
                    </Button>
                </FloatingButton>
            </form>
        </GridContainer>
    );
};

CompanyBaseData = reduxForm({
    // a unique name for the form
    form: formName,
    enableReinitialize: true,
})(CompanyBaseData);

export default CompanyBaseData;
