import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';
import FormSpaceHeader from 'components/FormSpaceHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const CompanyBusinessLines = (props) => {
  const { data, lang, messages } = props;
  const intl = useIntl();

  if (!data) return null;

  const renderTable = (data) => {
    return (
      <TableContainer component={Paper} elevation={0}>
        <Table size="small" aria-label="Registration table">
          <TableHead>
            <TableRow>
              <TableCell>
                {intl.formatMessage(messages.formdata.name)}
              </TableCell>
              <TableCell>
                {intl.formatMessage(messages.formdata.date)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              if (row && row.language !== lang)
                return <React.Fragment key={row + index}></React.Fragment>;

              return (
                <TableRow key={row + index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.registrationDate}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <FormSpaceHeader
          header={intl.formatMessage(messages.companyBusinessLinesData.title)}
          anchor="form_business_lines"
        />
        {renderTable(data)}
      </GridItem>
    </GridContainer>
  );
};

export default CompanyBusinessLines;
