import React from 'react';
import { useIntl } from 'react-intl';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    item: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.primary.white,
        marginTop: theme.spacing(2),
    },
    circle: {
        borderRadius: '50%',
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.darkYellow,
        marginTop: theme.spacing(0.5),
    },
    itemText: {
        marginLeft: theme.spacing(2),
        marginTop: 0,
    },
    primary: {
        fontSize: '0.9rem',
        fontWeight: 600,
        color: theme.palette.primary.darkYellow,
    },
    secondary: {
        fontSize: '0.8rem',
    },
}));

const NoteItem = ({ compareProperty }) => {
    const classes = useStyles();
    const intl = useIntl();

    const primary = (
        <Typography component='p' gutterBottom className={classes.primary}>
            {intl.formatMessage({ id: `instrument.radar.choice.${compareProperty}` }).toUpperCase()}
        </Typography>
    );

    const secondary = (
        <Typography component='p' className={classes.secondary}>
            {intl.formatMessage({ id: `notice.item.${compareProperty}` })}
        </Typography>
    );

    return (
        <Container>
            <ListItem alignItems='flex-start' className={classes.item}>
                <Box>
                    <Box className={classes.circle}></Box>
                </Box>
                <ListItemText primary={primary} secondary={secondary} className={classes.itemText} />
            </ListItem>
        </Container>
    );
};

export default NoteItem;
