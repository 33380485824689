import { put, takeLatest } from 'redux-saga/effects';

import { LOAD_CUSTOMERS } from './constants.js';
import { loadCustomersError, loadCustomersSuccess } from './actions';
// import config from 'utils/config';
// import axios from 'utils/axios';
// import { error, success } from 'components/Toast';
import { customers } from 'utils/mock/customers';

export function* getCustomers() {
    // const requestURL = `${config.CUSTOMERS_URI}/customers`;

    try {
        // const { data } = yield axios.get(requestURL);

        // yield put(loadCustomersSuccess(data));
        yield put(loadCustomersSuccess(customers));
    } catch (err) {
        yield put(loadCustomersError(err));
    }
}

export default function* instrumentsWatcher() {
    yield takeLatest(LOAD_CUSTOMERS, getCustomers);
}
