import React, { useState, forwardRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import TinyMCE from 'components/EditableText/TinyMCE';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from 'date-fns/locale/fi';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ConfirmDialog from 'components/ConfirmDialog';
import { GrantsInput } from 'components/GrantsComponents/Input';
import Switch from 'components/SwitchItem';
import InstrumentLimiters from './InstrumentLimiters';
import CurrencyField from 'components/CurrencyField';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        padding: '2rem',
    },
    editor: {
        marginBottom: '1rem',
    },
    error: {
        color: theme.palette.error.main,
    },
    menuItem: {
        color: theme.palette.primary.main,
    },
    menuItemSelected: {
        fontWeight: 700,
    },
    internal: {
        backgroundColor: '#ccc',
        '& label': {
            color: '#000',
        },
    },
}));

const notEmptyHtmlOrString = /^(?!(<p><\/p>\n|)$)/; // reverse is not empty html with empty paragraph or empty string

const InstrumentForm = forwardRef(({ instrument, onSubmit, providers, projectAims, projectObjects, projectValues }, formRef) => {
    const classes = useStyles();
    const intl = useIntl();

    const [open, setOpen] = useState(false);
    const createInstrumentSchema = yup.object().shape({
        name: yup.string().required(
            `${intl.formatMessage({ id: 'instrument.form.name' })} ${intl.formatMessage({
                id: 'message.isRequired',
            })}`
        ),
        url: yup.string().url(`${intl.formatMessage({ id: 'instrument.form.url.validation' })}`),
        provider: yup.string().required(
            `${intl.formatMessage({ id: 'instrument.form.provider' })} ${intl.formatMessage({
                id: 'message.isRequired',
            })}`
        ),
        type: yup.string().required(
            `${intl.formatMessage({ id: 'instrument.form.type' })} ${intl.formatMessage({
                id: 'message.isRequired',
            })}`
        ),
        endTime: yup
            .date()
            .nullable()
            .default(null)
            .test('is-greater', intl.formatMessage({ id: 'timeline.instrument.endTime.mindate' }), (value, { parent }) => {
                const { startTime } = parent;

                if (!startTime || !value) return true; // if either one is false no need for validation

                const start = moment(startTime);

                return moment(value, 'dd.MM.yyyy').isSameOrAfter(moment(start, 'dd.MM.yyyy'));
            }),
        ingress: yup.string().matches(
            notEmptyHtmlOrString,
            `${intl.formatMessage({ id: 'instrument.form.ingress' })} ${intl.formatMessage({
                id: 'message.isRequired',
            })}`
        ),
        description: yup.string().matches(
            notEmptyHtmlOrString,
            `${intl.formatMessage({ id: 'instrument.form.description' })} ${intl.formatMessage({
                id: 'message.isRequired',
            })}`
        ),
        limitators: yup
            .array()
            .transform((_, orig) => {
                const entries = Object.entries(orig).map(([key, value]) => {
                    return { type: key, values: value };
                });

                return entries;
            })
            .of(
                yup.object().shape({
                    type: yup.string().required(),
                    values: yup.array().required().min(1),
                })
            ),
    });
    const { control, handleSubmit, errors, watch } = useForm({
        defaultValues: {
            instrument,
        },
        resolver: yupResolver(createInstrumentSchema),
    });

    const startTimeWatch = watch('startTime', instrument?.startTime);

    const publish = handleSubmit(data => (data.isDraft = false));

    const [filteredProviders, setFilteredProviders] = useState(providers);
    const filterProviders = provider => providers?.filter(p => p.toLowerCase().includes(provider.toLowerCase()));

    return (
        <form ref={formRef} onSubmit={handleSubmit((data, event) => onSubmit(data, event.nativeEvent.detail))} className={classes.form}>
            <GridContainer>
                <GridItem className={classes.editor} xs={12} md={6}>
                    <Controller
                        name='id'
                        control={control}
                        defaultValue={instrument?.id ?? ''}
                        render={() => <input type='hidden' value={instrument?.id ?? ''} />}
                    />
                    <Controller
                        name='name'
                        control={control}
                        defaultValue={instrument?.name ?? ''}
                        rules={{
                            required: `${intl.formatMessage({ id: 'instrument.form.name' })} ${intl.formatMessage({
                                id: 'message.isRequired',
                            })}`,
                        }}
                        render={props => (
                            <GrantsInput variant='standard' label={intl.formatMessage({ id: 'instrument.form.name' })} {...props} />
                        )}
                    />
                    {errors && (
                        <Typography component='p' className={classes.error}>
                            {errors?.name?.message}
                        </Typography>
                    )}
                </GridItem>
                <GridItem className={classes.editor} xs={12} md={6}>
                    <Controller
                        name='provider'
                        control={control}
                        defaultValue={instrument?.provider ?? ''}
                        rules={{
                            required: `${intl.formatMessage({ id: 'instrument.form.provider' })} ${intl.formatMessage({
                                id: 'message.isRequired',
                            })}`,
                        }}
                        render={props => (
                            <Autocomplete
                                {...props}
                                options={filteredProviders}
                                freeSolo
                                getOptionLabel={option => option}
                                onChange={(_, data) => {
                                    if (data !== null) return props.onChange(data);
                                }}
                                onInputChange={(_, data) => {
                                    setFilteredProviders(filterProviders(data));
                                    props.onChange(data);
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={intl.formatMessage({ id: 'instrument.form.provider' })}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        )}
                    />
                    {errors && (
                        <Typography component='p' className={classes.error}>
                            {errors?.provider?.message}
                        </Typography>
                    )}
                </GridItem>
                <GridItem className={classes.editor} xs={12} md={12}>
                    <Controller
                        name='url'
                        control={control}
                        defaultValue={instrument?.url ?? ''}
                        render={props => (
                            <GrantsInput variant='standard' label={intl.formatMessage({ id: 'instrument.form.url.label' })} {...props} />
                        )}
                    />
                    {errors && (
                        <Typography component='p' className={classes.error}>
                            {errors?.url?.message}
                        </Typography>
                    )}
                </GridItem>
                <GridItem className={classes.editor} xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                        <Controller
                            name='startTime'
                            defaultValue={instrument?.startTime ?? null}
                            control={control}
                            render={rest => (
                                <KeyboardDatePicker
                                    style={{ margin: 0, padding: 0, width: '100%' }}
                                    {...rest}
                                    disableToolbar
                                    autoOk
                                    variant='inline'
                                    format='dd.MM.yyyy'
                                    invalidDateMessage={intl.formatMessage({ id: 'timeline.instrument.date.invalid' })}
                                    placeholder={intl.formatMessage({ id: 'instrument.form.datepicker.placeholder' })}
                                    margin='normal'
                                    label={intl.formatMessage({ id: 'timeline.instrument.startTime' })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    leftArrowButtonProps={{}}
                                    InputLabelProps={{ shrink: true }}
                                    // minDate={new Date()}
                                    // shouldDisableDate={day => day.getDay() === 0 || day.getDay() === 6}
                                />
                            )}
                        />
                    </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem className={classes.editor} xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                        <Controller
                            name='endTime'
                            defaultValue={instrument?.endTime ?? null}
                            control={control}
                            render={rest => (
                                <KeyboardDatePicker
                                    style={{ margin: 0, padding: 0, width: '100%' }}
                                    {...rest}
                                    clearable='true'
                                    // openTo="year"
                                    disableToolbar
                                    autoOk
                                    variant='inline'
                                    format='dd.MM.yyyy'
                                    invalidDateMessage={intl.formatMessage({ id: 'timeline.instrument.date.invalid' })}
                                    placeholder={intl.formatMessage({ id: 'instrument.form.datepicker.placeholder' })}
                                    margin='normal'
                                    label={intl.formatMessage({ id: 'timeline.instrument.endTime' })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={startTimeWatch}
                                    minDateMessage='' //{intl.formatMessage({ id: 'timeline.instrument.endTime.mindate' })}
                                />
                            )}
                        />
                    </MuiPickersUtilsProvider>
                    {errors && (
                        <Typography component='p' className={classes.error}>
                            {errors?.endTime?.message}
                        </Typography>
                    )}
                </GridItem>
                <GridItem className={classes.editor} xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id='sortOrder-label'>{intl.formatMessage({ id: 'instrument.form.sortOrder' })}</InputLabel>
                        <Controller
                            name='sortOrder'
                            control={control}
                            defaultValue={instrument?.sortOrder ?? ''}
                            as={
                                <Select>
                                    <MenuItem value={0} classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                        {intl.formatMessage({ id: 'instrument.form.choose' })}
                                    </MenuItem>
                                    <MenuItem value={1} classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                        {intl.formatMessage({ id: 'instrument.form.sortorder.1' })}
                                    </MenuItem>
                                    <MenuItem value={2} classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                        {intl.formatMessage({ id: 'instrument.form.sortorder.2' })}
                                    </MenuItem>
                                    <MenuItem value={3} classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                        {intl.formatMessage({ id: 'instrument.form.sortorder.3' })}
                                    </MenuItem>
                                </Select>
                            }
                        />
                    </FormControl>
                </GridItem>
                <GridItem className={classes.editor} xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>{intl.formatMessage({ id: 'instrument.form.type' })}</InputLabel>
                        <Controller
                            name='type'
                            control={control}
                            defaultValue={instrument?.type ?? ''}
                            rules={{
                                required: `${intl.formatMessage({ id: 'instrument.form.type' })} ${intl.formatMessage({
                                    id: 'message.isRequired',
                                })}`,
                            }}
                            as={
                                <Select>
                                    <MenuItem value='loan' classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                        {intl.formatMessage({ id: 'instrument.form.type.loan' })}
                                    </MenuItem>
                                    <MenuItem value='support' classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                        {intl.formatMessage({ id: 'instrument.form.type.support' })}
                                    </MenuItem>
                                    <MenuItem value='guarantee' classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                        {intl.formatMessage({ id: 'instrument.form.type.guarantee' })}
                                    </MenuItem>
                                </Select>
                            }
                        />
                        {errors && (
                            <Typography component='p' className={classes.error}>
                                {errors?.type?.message}
                            </Typography>
                        )}
                    </FormControl>
                </GridItem>
                <GridItem className={classes.editor} xs={6}>
                    <Switch
                        type='isDeminimis'
                        checkedId='instrument.form.isDeminimis.checked'
                        labelId='instrument.form.isDeminimis.label'
                        value={instrument?.isDeminimis ?? false}
                        control={control}
                        checkedVal={true}
                        notCheckedVal={false}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={6}>
                    <Switch
                        type='disabled'
                        checkedId='instrument.form.disabled.checked'
                        labelId='instrument.form.disabled.label'
                        value={instrument?.disabled ?? false}
                        control={control}
                        checkedVal={false}
                        notCheckedVal={true}
                    />
                </GridItem>
                <GridItem className={classes.editor}>
                    <GridItem className={classes.editor} xs={12}>
                        <Controller
                            name='ingress'
                            control={control}
                            defaultValue={instrument?.ingress ?? ''}
                            rules={{
                                required: `${intl.formatMessage({ id: 'instrument.form.ingress' })} ${intl.formatMessage({
                                    id: 'message.isRequired',
                                })}`,
                            }}
                            as={
                                <TinyMCE defaultValue={instrument?.ingress} title={intl.formatMessage({ id: 'instrument.form.ingress' })} />
                            }
                        />
                    </GridItem>
                    {errors && (
                        <Typography component='p' className={classes.error}>
                            {errors?.ingress?.message}
                        </Typography>
                    )}
                </GridItem>
                <GridItem className={classes.editor}>
                    <Controller
                        name='description'
                        control={control}
                        defaultValue={instrument?.description ?? ''}
                        rules={{
                            required: `${intl.formatMessage({ id: 'instrument.form.description' })} ${intl.formatMessage({
                                id: 'message.isRequired',
                            })}`,
                        }}
                        as={
                            <TinyMCE
                                defaultValue={instrument?.description}
                                title={intl.formatMessage({ id: 'instrument.form.description' })}
                            />
                        }
                    />
                    {errors && (
                        <Typography component='p' className={classes.error}>
                            {errors?.description?.message}
                        </Typography>
                    )}
                </GridItem>
                <GridItem className={classes.editor}>
                    <Controller
                        name='additionalInformation'
                        control={control}
                        defaultValue={instrument?.additionalInformation ?? ''}
                        as={
                            <TinyMCE
                                defaultValue={instrument?.additionalInformation}
                                title={intl.formatMessage({ id: 'instrument.form.additionalInformation' })}
                                holderClassName={classes.internal}
                            />
                        }
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12} sm={6} md={3}>
                    <Controller
                        name='minAmount'
                        control={control}
                        defaultValue={instrument?.minAmount ?? 0}
                        as={<CurrencyField label='instrument.form.minAmount' value={instrument?.minAmount} />}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12} sm={6} md={3}>
                    <Controller
                        name='maxAmount'
                        control={control}
                        defaultValue={instrument?.maxAmount ?? 0}
                        as={<CurrencyField label='instrument.form.maxAmount' value={instrument?.maxAmount} />}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12} sm={6} md={3}>
                    <Controller
                        name='prepayment'
                        control={control}
                        defaultValue={instrument?.prepayment ?? 0}
                        as={<CurrencyField label='instrument.form.prepayment' value={instrument?.prepayment} />}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12} sm={6} md={3}>
                    <Controller
                        name='projectSizeEur'
                        control={control}
                        defaultValue={instrument?.projectSizeEur ?? 0}
                        as={<CurrencyField label='instrument.form.projectSizeEur' value={instrument?.projectSizeEur} />}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12} sm={6} md={3}>
                    <Controller
                        name='amountMinPercentage'
                        control={control}
                        defaultValue={instrument?.amountMinPercentage ?? ''}
                        render={props => (
                            <GrantsInput
                                variant='standard'
                                type='number'
                                label={intl.formatMessage({ id: 'instrument.form.amountMinPercentage' })}
                                {...props}
                            />
                        )}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12} sm={6} md={3}>
                    <Controller
                        name='amountMaxPercentage'
                        control={control}
                        defaultValue={instrument?.amountMaxPercentage ?? ''}
                        render={props => (
                            <GrantsInput
                                variant='standard'
                                type='number'
                                label={intl.formatMessage({ id: 'instrument.form.amountMaxPercentage' })}
                                {...props}
                            />
                        )}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12} sm={6} md={3}>
                    <Controller
                        name='installmentsFreeTime'
                        control={control}
                        defaultValue={instrument?.installmentsFreeTime ?? ''}
                        render={props => (
                            <GrantsInput
                                variant='standard'
                                type='number'
                                label={intl.formatMessage({ id: 'instrument.form.installmentsFreeTime' })}
                                {...props}
                            />
                        )}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12} sm={6} md={3}>
                    <Controller
                        name='expectedPendingTime'
                        control={control}
                        defaultValue={instrument?.expectedPendingTime ?? ''}
                        render={props => (
                            <GrantsInput
                                variant='standard'
                                type='number'
                                label={intl.formatMessage({ id: 'instrument.form.expectedPendingTime' })}
                                {...props}
                            />
                        )}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12}>
                    <Controller
                        name='projectAim'
                        control={control}
                        defaultValue={instrument?.projectAimArr ?? []}
                        render={props => (
                            <Autocomplete
                                {...props}
                                freeSolo
                                multiple
                                options={projectAims}
                                getOptionLabel={option => option}
                                onChange={(_, data) => props.onChange(data)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={intl.formatMessage({ id: 'instrument.form.projectAim' })}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        )}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12}>
                    <Controller
                        name='projectObjects'
                        control={control}
                        defaultValue={instrument?.projectObjectsArr ?? []}
                        render={props => (
                            <Autocomplete
                                {...props}
                                freeSolo
                                multiple
                                options={projectObjects}
                                getOptionLabel={option => option}
                                onChange={(_, data) => props.onChange(data)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={intl.formatMessage({ id: 'instrument.form.projectObjects' })}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        )}
                    />
                </GridItem>
                <GridItem className={classes.editor} xs={12}>
                    <Controller
                        name='projectValue'
                        control={control}
                        defaultValue={instrument?.projectValueArr ?? []}
                        render={props => (
                            <Autocomplete
                                {...props}
                                freeSolo
                                multiple
                                options={projectValues}
                                getOptionLabel={option => option}
                                onChange={(_, data) => props.onChange(data)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={intl.formatMessage({ id: 'instrument.form.projectValue' })}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        )}
                    />
                </GridItem>
                <InstrumentLimiters control={control} errors={errors} />
                <ConfirmDialog
                    title={intl.formatMessage({ id: 'instrument.form.save' })}
                    open={open}
                    setOpen={setOpen}
                    onConfirm={publish}
                    buttonOkId='instrument.form.save'
                    titleId='instrument.form.save'
                >
                    <p>{intl.formatMessage({ id: 'instrument.form.confirm' })}</p>
                </ConfirmDialog>
            </GridContainer>
        </form>
    );
});

export default InstrumentForm;
