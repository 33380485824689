import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { GrantsInput } from 'components/GrantsComponents/Input';
import { useIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/icons/Link';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormSpaceHeader from 'components/FormSpaceHeader';
import { GrantsSwitch } from 'components/GrantsComponents/Switch';
import Select from 'components/GrantsComponents/Select';
import RobotData from './RobotData';
import Registers from './Registers';
import FinanceAccordion from 'containers/Finance/FinanceAccordion';
import { required, businessIdFI } from 'utils/validators';
import CurrencyField from 'components/CurrencyField';

//TODO: create component of link button

const useStyles = makeStyles(() => ({
    root: {
        margin: 0,
        padding: 0,
        color: '#E0D6C4',
    },
}));

const CompanyBasicInformation = props => {
    const { messages, selected, hasIPR } = props;
    const { basicdata } = messages;
    const intl = useIntl();
    const classes = useStyles();

    const finder = useSelector(state => state.company?.finder);
    const anorBot = useSelector(state => state.company?.anorBot);

    return (
        <GridContainer>
            <GridItem noPadding xs={12} lg={6}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Field
                            name='name'
                            type='text'
                            component={GrantsInput}
                            validate={[required]}
                            label={intl.formatMessage(messages.basicdata.name)}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <Field
                            name='businessId'
                            type='text'
                            component={GrantsInput}
                            validate={[required, businessIdFI]}
                            label={intl.formatMessage(messages.basicdata.businessid)}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                        <Field name='companyForm' type='text' component={GrantsInput} label={intl.formatMessage(messages.basicdata.form)} />
                    </GridItem>
                    <GridItem xs={12} md={12} lg={12}>
                        <Field
                            name='address.street'
                            type='text'
                            component={GrantsInput}
                            label={intl.formatMessage(messages.basicdata.streetaddress)}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6} lg={6}>
                        <Field
                            name='address.postCode'
                            type='text'
                            component={GrantsInput}
                            label={intl.formatMessage(messages.basicdata.postcode)}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6} lg={6}>
                        <Field
                            name='address.city'
                            type='text'
                            component={GrantsInput}
                            label={intl.formatMessage(messages.basicdata.city)}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem noPadding xs={12} lg={6}>
                <GridContainer >
                    <GridItem xs={12} md={6}>
                        <Field
                            name='personnelSize'
                            type='number'
                            parse={value => Number(value)}
                            component={GrantsInput}
                            label={intl.formatMessage(messages.basicdata.personnelSize)}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Field
                            name='funds.ownerFunds'
                            component={props => (
                                <CurrencyField
                                    label='equity'
                                    value={props.input.value}
                                    basicdata={basicdata}
                                    onChange={props.input.onChange}
                                />
                            )}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Field
                            name='funds.achievedFunds'
                            component={props => (
                                <CurrencyField
                                    label='foreignEquity'
                                    value={props.input.value}
                                    basicdata={basicdata}
                                    onChange={props.input.onChange}
                                />
                            )}
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Field
                            name='funds.supportFunds'
                            component={props => (
                                <CurrencyField
                                    label='deminimisAmount'
                                    value={props.input.value}
                                    basicdata={basicdata}
                                    onChange={props.input.onChange}
                                />
                            )}
                        />
                    </GridItem>
                    <RobotData anorBot={anorBot} robot={selected.robotData} finder={finder} messages={messages}></RobotData>
                    <GridItem xs={12}>
                        <Field
                            label={intl.formatMessage(messages.basicdata.equityArrangements)}
                            helperText={intl.formatMessage(messages.basicdata.equityArrangements.helper)}
                            name='funds.ownerFundsDescription'
                            multiline
                            rows={4}
                            parse={value => (!value ? null : value)}
                            component={GrantsInput}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
            <Registers messages={messages} {...props} data={selected.registeredEntries} lang='FI' />

            <FinanceAccordion />

            <GridContainer>
                <GridItem xs={12}>
                    <GridContainer>
                        <GridItem xs={12} md={6} lg={4}>
                            <Field
                                label={intl.formatMessage(messages.basicdata.customerCategory)}
                                name='companyStatus'
                                parse={value => (!value ? null : value)}
                                component={Select}
                            >
                                <MenuItem value=''>
                                    <em>{intl.formatMessage(messages.basicdata.customerCategory.type)}</em>
                                </MenuItem>
                                <MenuItem value='toBeEstablished'>
                                    {intl.formatMessage(messages.basicdata.customerCategory.type.toBeEstablished)}
                                </MenuItem>
                                <MenuItem value='existingNoSupport'>
                                    {intl.formatMessage(messages.basicdata.customerCategory.type.existingNoSupport)}
                                </MenuItem>
                                <MenuItem value='existingHasSupport'>
                                    {intl.formatMessage(messages.basicdata.customerCategory.type.existingHasSupport)}
                                </MenuItem>
                                <MenuItem value='noSupportCapable'>
                                    {intl.formatMessage(messages.basicdata.customerCategory.type.noSupportCapable)}
                                </MenuItem>
                            </Field>
                        </GridItem>
                        <GridItem xs={12} md={6} lg={4}>
                            <Field
                                label={intl.formatMessage(messages.basicdata.internationalization)}
                                name='internationalization'
                                parse={value => (!value ? null : value)}
                                component={Select}
                            >
                                <MenuItem value=''>
                                    <em>{intl.formatMessage(messages.basicdata.internationalization.type)}</em>
                                </MenuItem>
                                <MenuItem value='hasInternationalWill'>
                                    {intl.formatMessage(messages.basicdata.internationalization.type.hasInternationalWill)}
                                </MenuItem>
                                <MenuItem value='hasInternationGrowtAbility'>
                                    {intl.formatMessage(messages.basicdata.internationalization.type.hasInternationGrowtAbility)}
                                </MenuItem>
                                <MenuItem value='alreadyInInternationalBusiness'>
                                    {intl.formatMessage(messages.basicdata.internationalization.type.alreadyInInternationalBusiness)}
                                </MenuItem>
                                <MenuItem value='onlyInDomesticMarkets'>
                                    {intl.formatMessage(messages.basicdata.internationalization.type.onlyInDomesticMarkets)}
                                </MenuItem>
                            </Field>
                            {/* <FormHelperText> </FormHelperText> */}
                        </GridItem>
                        <GridItem xs={12} md={6} lg={4}>
                            <Field
                                label={intl.formatMessage(messages.basicdata.outskirts)}
                                name='countrySide'
                                parse={value => (!value ? null : value)}
                                component={Select}
                            >
                                <MenuItem value=''>
                                    <em>{intl.formatMessage(messages.basicdata.outskirts.type)}</em>
                                </MenuItem>
                                <MenuItem value='yes'>{intl.formatMessage(messages.basicdata.outskirts.type.yes)}</MenuItem>
                                <MenuItem value='no'>{intl.formatMessage(messages.basicdata.outskirts.type.no)}</MenuItem>
                                <MenuItem value='maybe'>{intl.formatMessage(messages.basicdata.outskirts.type.maybe)}</MenuItem>
                            </Field>
                            <FormHelperText>
                                <Button
                                    round='true'
                                    className={classes.root}
                                    variant='text'
                                    color='primary'
                                    href='https://hkp.maanmittauslaitos.fi/hkp/published/fi/3a69127d-40ff-47dc-bb78-914a41dbf589'
                                    target='blank'
                                >
                                    {intl.formatMessage(messages.basicdata.outskirts.mapLink)}
                                    <Link />
                                </Button>
                            </FormHelperText>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridItem xs={12}>
                <Field name='hasIPR' component={GrantsSwitch} label={intl.formatMessage(messages.basicdata.hasIPR)} />
            </GridItem>
            {hasIPR && (
                <GridItem xs={12}>
                    <Field
                        name='iprDescription'
                        multiline
                        parse={value => (!value ? null : value)}
                        component={GrantsInput}
                        label={intl.formatMessage(messages.basicdata.iprDescription)}
                        helperText={intl.formatMessage(messages.basicdata.iprDescription.helper)}
                    />
                </GridItem>
            )}
            <GridContainer>
                <FormSpaceHeader header={intl.formatMessage(messages.basicdata.decriptiveTitle)} />
                <GridItem xs={6}>
                    <Field
                        label={intl.formatMessage(messages.basicdata.businessDescription)}
                        helperText={intl.formatMessage(messages.basicdata.businessDescription.helper)}
                        name='descriptions.businessDescription'
                        multiline
                        parse={value => (!value ? null : value)}
                        component={GrantsInput}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <Field
                        label={intl.formatMessage(messages.basicdata.previousSupport)}
                        helperText={intl.formatMessage(messages.basicdata.previousSupport.helper)}
                        name='descriptions.previousInstruments'
                        multiline
                        parse={value => (!value ? null : value)}
                        component={GrantsInput}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <Field
                        label={intl.formatMessage(messages.basicdata.projectCosts)}
                        helperText={intl.formatMessage(messages.basicdata.projectCosts.helper)}
                        name='descriptions.developmentCosts'
                        multiline
                        parse={value => (!value ? null : value)}
                        component={GrantsInput}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <Field
                        label={intl.formatMessage(messages.basicdata.commonEconomicalSituation)}
                        helperText={intl.formatMessage(messages.basicdata.commonEconomicalSituation.helper)}
                        name='descriptions.generalEconomy'
                        multiline
                        parse={value => (!value ? null : value)}
                        component={GrantsInput}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <Field
                        label={intl.formatMessage(messages.basicdata.other)}
                        helperText={intl.formatMessage(messages.basicdata.other.helper)}
                        name='descriptions.otherNotes'
                        multiline
                        parse={value => (!value ? null : value)}
                        component={GrantsInput}
                    />
                </GridItem>
            </GridContainer>
        </GridContainer>
    );
};

const selector = formValueSelector('companyBasicData');

const CompanyBasicInformationConnected = connect(state => {
    const hasIPR = selector(state, 'hasIPR');
    return {
        hasIPR,
    };
})(CompanyBasicInformation);

export default CompanyBasicInformationConnected;
