import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectBusinessPlan = state => state.businessPlan || initialState;

const getBusinessPlan = () =>
  createSelector(
    selectBusinessPlan,
    businessPlanSearchState => businessPlanSearchState.businessPlan,
  );

const getLoading = () =>
  createSelector(
    selectBusinessPlan,
    businessPlanSearchState => businessPlanSearchState.loading,
  );

const getError = () =>
  createSelector(
    selectBusinessPlan,
    businessPlanSearchState => businessPlanSearchState.error,
  );

export {
  selectBusinessPlan,
  getBusinessPlan,
  getLoading,
  getError,
};
