import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import { GridItem, GridContainer } from 'components/Grid';
import Button from 'components/CustomButtons/Button';
import { gridTranslations } from 'translations/gridTranslations';

import UserModal from './UserModalForm';

import ConfirmButton from './ConfirmButton';

const useStyles = makeStyles(() => ({
    button: {
        marginTop: '1rem',
    },
}));

const UserManagement = () => {
    const intl = useIntl();
    const classes = useStyles();
    const users = useSelector(state => state.grantedai?.users);
    const usersLoading = users?.loading;
    const [openModal, setOpenModal] = useState(false);

    const columns = [
        { field: 'grantedUsername', headerName: 'Käyttäjänimi', width: 400 },
        { field: 'firstname', headerName: 'Etunimi', width: 150 },
        { field: 'lastname', headerName: 'Sukunimi', width: 150 },
        {
            field: 'created', headerName: 'Luontiaika', width: 150, renderCell: params => (
                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{moment(params.row.created).format('DD.MM.yyyy hh:mm')}</span>
            ),
        },
        {
            field: 'activate', headerName: 'Lähetä aktivointi', width: 400,
            renderCell: params => (
                <>
                    <ConfirmButton params={params} />
                </>
            )
        }
    ];

    return (
        <GridContainer contentContainer noPadding>
            <GridItem xs={12} noPadding>
                <h4>{intl.formatMessage({ id: 'account.users.title.main' })}</h4>
                {usersLoading ? (
                    <CircularProgress size={24} />
                ) : !usersLoading && users ? (
                    <DataGrid
                        getRowId={(r) => r.grantedUsername}
                        rows={users ?? []}
                        columns={columns}
                        pageSize={5}
                        autoHeight={true}
                        localeText={gridTranslations(intl)}
                    />
                ) : (
                    <GridContainer spacing={2}>
                        <GridItem>
                            <Box textAlign='center'>
                                <Typography component='p' color='primary'>
                                    {intl.formatMessage({ id: 'account.users.notfound' })}
                                </Typography>
                            </Box>
                        </GridItem>
                    </GridContainer>
                )}
                <Box textAlign='end' className={classes.button} >
                    <Button round="true" variant='contained' color='primary' endIcon={<AddIcon />} onClick={() => setOpenModal(true)}>
                        {intl.formatMessage({ id: 'account.users.add.button' })}
                    </Button>
                </Box>
            </GridItem>
            <GridItem xs={12} noPadding>
                <UserModal open={openModal} close={setOpenModal} />
            </GridItem>
        </GridContainer>
    );
};

export default UserManagement;