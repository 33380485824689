import React, { memo, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import ContentLoader from 'components/LoadingIndicator/ContentLoader';

import Form from './form';

import {
  makeSelectLoading,
  makeSelectError,
  makeSelectEvents,
} from './selectors';
import { loadEvents, saveCompanyEvents } from './actions';
import saga from './saga';
import reducer from './reducer';

const key = 'events';

export const Events = (props) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const {
    events,
    onSubmit,
    loading,
    onload,
    match: {
      params: { businessId },
    },
  } = props;

  useEffect(() => {
    onload(businessId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || events === undefined || events === null) {
    return (
      <React.Fragment>{loading ? <ContentLoader /> : null}</React.Fragment>
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h3>Tuotteet ja palvelut</h3>
      </GridItem>
      <Form events={events} onSubmit={onSubmit} />
    </GridContainer>
  );
};

Events.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  events: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onSubmit: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  events: makeSelectEvents(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (data) => dispatch(saveCompanyEvents(data)),
    onload: (id) => dispatch(loadEvents(id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Events);
