import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import styles from 'assets/jss/grants/formControls.js';

const useStyles = makeStyles(styles);

export const GrantsInput = ({
    label,
    input,
    type,
    placeholder,
    helperText,
    rows,
    multiline,
    nopadding,
    variant,
    readOnly,
    meta,
    // min,
    // max,
    ...props
}) => {
    const classes = useStyles();
    const nopaddingClass = nopadding && classes.multilineText;

    const { touched, error, warning } = meta ?? { touched: false, error: false, warning: false };

    const renderMultiline = () => {
        return (
            <TextField
                {...props}
                {...input}
                placeholder={placeholder}
                helperText={helperText}
                label={label}
                type={type ?? 'text'}
                fullWidth
                multiline
                rows={rows || 5}
                InputLabelProps={{
                    shrink: true,
                }}
                FormHelperTextProps={{
                    component: 'div',
                }}
                variant={variant || 'filled'}
                InputProps={{
                    className: nopaddingClass,
                    readOnly: readOnly,
                }}
                error={error ? true : false}
            />
        );
    };

    const renderTextBox = () => {
        return (
            <TextField
                {...props}
                {...input}
                placeholder={placeholder}
                helperText={helperText}
                label={label}
                type={type ?? 'text'}
                fullWidth
                FormHelperTextProps={{
                    component: 'div',
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    className: nopaddingClass,
                    readOnly: readOnly,
                    // inputProps: {
                    //     max,
                    //     min,
                    // },
                }}
                variant={variant}
                error={error ? true : false}
            />
        );
    };

    return (
        <React.Fragment>
            {multiline ? renderMultiline() : renderTextBox()}
            {touched && ((error && <span className={classes.errorMessage}>{error}</span>) || (warning && <span>{warning}</span>))}
        </React.Fragment>
    );
};
