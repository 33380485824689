import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    paddingTop: '1rem',
    color: theme.palette.primary,
    width: '100%',
    paddingLeft: '0.3rem',
  },
  title: {
    padding: '1rem',
    paddingLeft: '1rem',
  },
  dash: {
    display: 'inline-block',
    marginRight: '2rem',
  },
  header: {
    fontSize: '1.2rem',
  },
}));

function FormSpaceHeader({ header }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <h4 className={classes.headerRoot}>{header}</h4>
    </React.Fragment>
  );
}

FormSpaceHeader.propTypes = {
  header: PropTypes.any,
};

export default FormSpaceHeader;
