import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import GrantedLogo from './GrantedLogo';
import CompanySearch from 'containers/CompanySearch';
import DropdownMenu from './DropdownMenu';
import DropdownHistory from './DropdownHistory';
import CompanyList from 'components/CompanyList';

import { color } from 'assets/jss/grants/common/grantsBase';
import { useWidth } from 'utils/useWidth';
import { selectCompany } from 'containers/CompanySearch/actions';
import { getFromLocalStorage } from 'utils/localStorage/localStorageHandler';

const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
    appBar: {
        backgroundColor: color.primaryColor,
        width: '100%',
    },
    search: {
        backgroundColor: color.secondaryColor,
    },
    divider: {
        backgroundColor: color.greyColor,
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
        opacity: 0.25,
    },
    icon: {
        color: color.secondaryColor,
    },
    drawerPaper: {
        width: '50%',
    },
    drawerPaperSM: {
        width: '65%',
    },
    drawerPaperXS: {
        width: '80%',
    },
    companyName: {
        fontSize: '1rem',
        fontWeight: 600,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    companyLoading: {
        color: color.whiteColor,
    },
}));

const Header = () => {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const { pathname } = useLocation();
    const width = useWidth();
    const companySearch = useSelector(state => state.companySearch);
    const dispatch = useDispatch();

    const companies = companySearch?.companies;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const openDropdown = event => setAnchorEl(event.currentTarget);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    const handleClose = name => {
        setAnchorEl(null);
        name && typeof name === 'string' && history.push(`/${name}`);
    };

    const chooseCompany = company => {
        dispatch(selectCompany(company));
        closeDrawer();
    };

    const company = useSelector(state => state.company);
    const companyData = company?.company;
    const companyLoading = company?.loading;
    const comanyName = companyData?.name;

    const [anchorHistory, setAnchorHistory] = useState(null);
    const isOpenHistory = Boolean(anchorHistory);
    const openHistory = event => setAnchorHistory(event.currentTarget);

    const handleCloseHistory = id => {
        setAnchorHistory(null);
        id && typeof id === 'string' && history.push(`/company/${id}`);
    };

    const [companyHistory, setCompanyHistory] = useState(null);
    useEffect(() => {
        const data = getFromLocalStorage();
        setCompanyHistory(data);
    }, []);

    const companyHistoryExceptSelectedCompany = companyHistory?.filter(({ company }) => company.id !== companyData?.businessId);

    return (
        <AppBar className={classes.appBar}>
            <Toolbar>
                <GridContainer justify='space-between' alignItems='center' spacing={4} style={{ flexWrap: 'nowrap' }}>
                    <GridItem noPadding>
                        <GrantedLogo width={width === 'xs' ? 150 : 270} />
                    </GridItem>
                    <GridItem noPadding>
                        <GridContainer justify='flex-end' alignItems='center' style={{ flexWrap: 'nowrap' }}>
                            <div style={{ maxWidth: '30%' }}>
                                {companyLoading ? (
                                    <Box textAlign='center'>
                                        <CircularProgress size={24} className={classes.companyLoading} />
                                    </Box>
                                ) : (
                                    <Typography className={classes.companyName}>
                                        {comanyName && pathname !== '/' ? comanyName : intl.formatMessage({ id: 'header.choose.company' })}
                                    </Typography>
                                )}
                            </div>
                            {companyHistory && (
                                <IconButton aria-label='drop down' onClick={openHistory} className={classes.iconButton}>
                                    <ArrowDropDownIcon className={classes.icon} />
                                </IconButton>
                            )}
                            <Divider orientation='vertical' flexItem light className={classes.divider} />
                            <IconButton aria-label='open drawer' onClick={openDrawer}>
                                <SearchIcon className={classes.icon} />
                            </IconButton>
                            <Divider orientation='vertical' flexItem light className={classes.divider} />
                            <IconButton aria-label='menu' onClick={openDropdown} className={classes.iconButton}>
                                <Menu className={classes.icon} />
                            </IconButton>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </Toolbar>
            <Drawer
                variant='temporary'
                anchor='right'
                open={isDrawerOpen}
                onClose={closeDrawer}
                classes={{
                    paper: width === 'xs' ? classes.drawerPaperXS : width === 'sm' ? classes.drawerPaperSM : classes.drawerPaper,
                }}
            >
                <CompanySearch insideDrawer />
                {companies?.length !== 0 && <CompanyList companies={companies} selectCompany={chooseCompany} />}
            </Drawer>
            <DropdownMenu anchorEl={anchorEl} open={open} onClose={handleClose} />
            <DropdownHistory
                anchorEl={anchorHistory}
                open={isOpenHistory}
                onClose={handleCloseHistory}
                history={companyHistoryExceptSelectedCompany}
            />
        </AppBar>
    );
};

export default Header;
