import * as _ from 'lodash';
import moment from 'moment';


const getMonths = momentDate => {
    // Get months function for getting month number according the year
    const months = Number(momentDate.format('MM'));
    const years = Number(momentDate.format('YYYY'));

    return months + years * 12;
};

/**
 * Mutates the timeline object and loop instruments for date and month calculation
 * @param {'Timeline object from the service'} timeline
 * @returns
 */
export const getStartDateArr = timeline => {
    if (!timeline?.instruments) return null;

    const start = _.map(timeline.instruments, item => item.startTime); // map timelime instruments first dates
    const end = _.map(timeline.instruments, item => item.endTime); // map timeline instruments last dates

    const minDate = moment(_.min(start)); // get min val from the array
    const maxDate = moment(_.max(end)); // get max val from the array

    const startTime = minDate.startOf('year'); // only full years: first of january
    const endTime = maxDate.endOf('month'); // only full years: last of december

    let numberOfMonth = maxDate.diff(minDate, 'month') + 1; // get number of months from years in timeline

    while (numberOfMonth % 3 !== 0) {
        numberOfMonth += 1;
    }

    timeline = Object.assign(timeline, { startTime, endTime, numberOfMonth }); // mutate the object

    timeline.instruments.forEach(instrument => {
        // loop instruments and mutate the object
        const startMonth = getMonths(moment(instrument.startTime));
        const endMonth = getMonths(moment(instrument.endTime));

        const monthDuration = endMonth - startMonth + 1;

        const monthFromStart = startMonth - getMonths(timeline.startTime);

        instrument = Object.assign(instrument, { monthDuration, monthFromStart });
    });
};

export const handleInstrument = (instrument, timeline) => {
    // loop instruments and mutate the object
    const startMonth = getMonths(moment(instrument.startTime));
    const endMonth = getMonths(moment(instrument.endTime));

    const monthDuration = endMonth - startMonth + 1;

    const monthFromStart = startMonth - getMonths(timeline.startTime);

    return Object.assign(instrument, { monthDuration, monthFromStart });
};