import produce from 'immer';
import _ from 'lodash';
import {
  LOAD_COMPANY_FINANCE,
  LOAD_COMPANY_FINANCE_SUCCESS,
  LOAD_COMPANY_FINANCE_ERROR,
  SAVE_COMPANY_FINANCE,
} from './constants';

export const initialState = {
  loading: false,
  error: false,
  finance: null,
};

/* eslint-disable default-case, no-param-reassign */
const financeRedurecer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_COMPANY_FINANCE:
        draft.loading = true;
        draft.error = false;
        break;

      case LOAD_COMPANY_FINANCE_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.finance = _.assign({}, action.finance);
        break;

      case LOAD_COMPANY_FINANCE_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;

      case SAVE_COMPANY_FINANCE:
        draft.loading = true;
        draft.error = false;
        break;
    }
  });

export default financeRedurecer;
