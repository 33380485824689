const primaryColor = '#482E51';
const primaryColorRGB = '72, 46, 81, 1)';
const secondaryColor = '#E0D6C4';
const green = '#077871';
const red = '#DB0769';
const darkBrown = '#BAB3A8';
const brown = '#E0D6C4';
const lightBrown = '#F7F5F0';
const lightBrown50 = '#F7F5F0';
const dangerColor = red;
const roseColor = '#000';
const buttonPrimary = green;
const warningColor = '#8e0e44';
const successColor = '#077871';
const whiteColor = '#ffffff';
const greyColor = '#bab3a8';
const lightGreyColor = '#f7f5f0';
const lightGreyColor2 = '#e2ddde';
const lightGreyColor3 = '#f8f8f7';
const lightPurpleColor = '#b2a5b1';
const darkYellowColor = '#cca111';
const beigeColor = '#e0d6c4';
const blueColor = '#1976d2';
const grayColor = '#ccc';
const infoColor = '';

export { 
    primaryColor,
    primaryColorRGB,
    secondaryColor,
    dangerColor,
    roseColor,
    buttonPrimary,
    red,
    darkBrown, 
    lightBrown, 
    lightBrown50,
    brown,
    warningColor,
    successColor,
    whiteColor,
    greyColor,
    lightGreyColor,
    lightGreyColor2,
    lightGreyColor3,
    lightPurpleColor,
    darkYellowColor,
    beigeColor,
    blueColor,
    grayColor,
    infoColor
};