import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

// import styles from 'assets/jss/grants/components/switchHook.js';

const useStyles = makeStyles(theme => ({
    checkRoot: {
        padding: '12px',
        '&:hover': {
            backgroundColor: 'unset',
        },
    },
    radioRoot: {
        padding: '12px',
        '&:hover': {
            backgroundColor: 'unset',
        },
    },
    labelRoot: {
        marginLeft: '-14px',
    },
    checkboxAndRadio: {
        position: 'relative',
        display: 'block',
        marginTop: '10px',
        marginBottom: '10px',
    },
    checkboxAndRadioHorizontal: {
        position: 'relative',
        display: 'block',
        '&:first-child': {
            marginTop: '10px',
        },
        '&:not(:first-child)': {
            marginTop: '-14px',
        },
        marginTop: '0',
        marginBottom: '0',
    },
    checked: {
        color: theme.palette.primary.main + '!important',
    },
    checkedIcon: {
        width: '20px',
        height: '20px',
        border: '1px solid rgba(0, 0, 0, .54)',
        borderRadius: '3px',
    },
    uncheckedIcon: {
        width: '0px',
        height: '0px',
        padding: '9px',
        border: '1px solid rgba(0, 0, 0, .54)',
        borderRadius: '3px',
    },
    disabledCheckboxAndRadio: {
        opacity: '0.45',
    },
    label: {
        cursor: 'pointer',
        paddingLeft: '0',
        color: 'rgba(0, 0, 0, 0.26)',
        fontSize: '14px',
        lineHeight: '1.428571429',
        fontWeight: '400',
        display: 'inline-flex',
        transition: '0.3s ease all',
        letterSpacing: 'unset',
    },
    labelHorizontal: {
        color: 'rgba(0, 0, 0, 0.26)',
        cursor: 'pointer',
        display: 'inline-flex',
        fontSize: '14px',
        lineHeight: '1.428571429',
        fontWeight: '400',
        paddingTop: '39px',
        marginRight: '0',
        '@media (min-width: 992px)': {
            float: 'right',
        },
    },
    labelHorizontalRadioCheckbox: {
        paddingTop: '22px',
    },
    labelLeftHorizontal: {
        color: 'rgba(0, 0, 0, 0.26)',
        cursor: 'pointer',
        display: 'inline-flex',
        fontSize: '14px',
        lineHeight: '1.428571429',
        fontWeight: '400',
        paddingTop: '22px',
        marginRight: '0',
    },
    labelError: {
        color: theme.palette.warning.main,
    },
    radio: {
        color: theme.palette.primary.main + '!important',
    },
    radioChecked: {
        width: '16px',
        height: '16px',
        border: '1px solid ' + theme.palette.primary.main,
        borderRadius: '50%',
    },
    radioUnchecked: {
        width: '0px',
        height: '0px',
        padding: '7px',
        border: '1px solid rgba(0, 0, 0, .54)',
        borderRadius: '50%',
    },
    inlineChecks: {
        marginTop: '8px',
    },
    iconCheckbox: {
        height: '116px',
        width: '116px',
        color: theme.palette.primary.grey,
        '& > span:first-child': {
            borderWidth: '4px',
            borderStyle: 'solid',
            borderColor: '#ccc',
            textAlign: 'center',
            verticalAlign: 'middle',
            borderRadius: '50%',
            color: 'inherit',
            margin: '0 auto 20px',
            transition: 'all 0.2s',
        },
        '&:hover': {
            color: '#000',
            '& > span:first-child': {
                borderColor: '#000',
            },
        },
    },
    iconCheckboxChecked: {
        color: '#000',
        '& > span:first-child': {
            borderColor: '#000',
        },
    },
    iconCheckboxIcon: {
        fontSize: '40px',
        lineHeight: '111px',
    },
    switchBase: {
        color: theme.palette.primary.main + '!important',
        marginLeft: 0,
    },
    switchIcon: {
        boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.4)',
        color: '#fff !important',
        border: '1px solid rgba(0, 0, 0, .54)',
    },
    switchBar: {
        width: '30px',
        height: '15px',
        backgroundColor: 'rgb(80, 80, 80)',
        borderRadius: '15px',
        opacity: '0.7!important',
    },
    switchChecked: {
        '& + $switchBar': {
            backgroundColor: 'rgba(72, 46, 81, 1) !important',
        },
        '& $switchIcon': {
            borderColor: '#482E51',
        },
    },
    switchRoot: {
        height: '48px',
    },
    switchLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontFamily: 'Poppsins, sans-serif',
        fontSize: '16px',
        transform: 'translate(0, 1.5px) scale(0.75)',
        marginLeft: 0,
    },
}));

const HookSwitch = ({ checked, onChange, label }) => {
    const classes = useStyles();

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={onChange}
                    classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar,
                    }}
                />
            }
            classes={{
                label: classes.switchLabel,
            }}
            style={{ marginLeft: '0px' }}
            label={label}
            labelPlacement='top'
        />
    );
};

export default HookSwitch;
