import produce from 'immer';
import {
	LOAD_GRANTEDAI_USERS,
	LOAD_GRANTEDAI_USERS_SUCCESS,
	LOAD_GRANTEDAI_USERS_ERROR,
	ADD_GRANTEDAI_USER,
	SAVE_LICENSE_INFORMATION,
	SAVE_LICENSE_INFORMATION_ERROR,
	SAVE_LICENSE_INFORMATION_SUCCESS,
	LOAD_LICENSE_INFORMATION,
	LOAD_LICENSE_INFORMATION_ERROR,
	LOAD_LICENSE_INFORMATION_SUCCESS,
	TERMINATE_LICENSE
} from './constants';

export const initialState = {
	loading: false,
	error: false,
	users: [],
	license: []
};

/* eslint-disable default-case, no-param-reassign */
const usersManagementReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case LOAD_GRANTEDAI_USERS:
				draft.loading = true;
				draft.error = false;
				break;

			case LOAD_GRANTEDAI_USERS_SUCCESS:
				draft.loading = false;
				draft.error = false;
				draft.users = action.users;
				break;

			case LOAD_GRANTEDAI_USERS_ERROR:
				draft.error = action.error;
				draft.loading = false;
				break;

			case ADD_GRANTEDAI_USER:
				draft.loading = true;
				draft.error = false;
				break;

			case SAVE_LICENSE_INFORMATION:
				draft.loading = true;
				draft.error = false;
				break;

			case SAVE_LICENSE_INFORMATION_ERROR:
				draft.error = action.error;
				draft.loading = false;
				break;

			case SAVE_LICENSE_INFORMATION_SUCCESS:
				draft.loading = false;
				draft.error = false;
				draft.license = [action.license, ...draft.license];
				//draft.license.push(action.license);
				break;

			case LOAD_LICENSE_INFORMATION:
				draft.loading = true;
				break;

			case LOAD_LICENSE_INFORMATION_ERROR:
				draft.loading = false;
				draft.error = action.error;
				break;

			case LOAD_LICENSE_INFORMATION_SUCCESS:
				draft.loading = false;
				draft.error = false;
				draft.license = action.license;
				break;

			case TERMINATE_LICENSE:
				draft.loading = true;
				break;
		}
	});

export default usersManagementReducer;
