import React from 'react';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        paddingTop: theme.spacing(2),
    },
    formLabel: {
        // color: theme.palette.primary.main,
        // fontWeight: 600,
        fontSize: '0.75rem'
    },
    root: {
        '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
        },
    },
    switchBase: {
        color: theme.palette.primary.white,
        '&$checked': {
            color: theme.palette.primary.white,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.success.main,
            opacity: 1,
        },
    },
    label: {
        color: theme.palette.primary.main,
    },
    track: {}, // empty object is required
    checked: {}, // empty object is required
}));

/**
 * Renders switch form control (checkbox) parameters:
 * @param {type} type of the scenario item
 * @param {scenarios} scenarios scenario object 
 * @param {control} control form control (react hook form)
 * @param {checkedVal} checkedVal value -> when checked is true
 * @param {notCheckedVal} notCheckedVal value -> when checked is false
 * @returns 
 */
const SwitchItem = ({ type, value, control, checkedVal, notCheckedVal, labelId, checkedId }) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <FormControl component='fieldset' className={classes.formControl}>
            <FormLabel component='legend' classes={{ root: classes.formLabel }}>
                {intl.formatMessage({ id: `${labelId}` })}
            </FormLabel>
            <Controller
                name={type}
                control={control}
                defaultValue={value}
                render={({ value, onChange }) => {
                    const checked = value === notCheckedVal ? false : value === checkedVal ? true : value;
                    return (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={checked}
                                    onChange={event => onChange(event.target.checked ? checkedVal : notCheckedVal)}
                                    name={type}
                                    classes={{
                                        root: classes.root,
                                        switchBase: classes.switchBase,
                                        track: classes.track,
                                        checked: classes.checked,
                                    }}
                                />
                            }
                            label={intl.formatMessage({ id: `${checkedId}.${checked}` })}
                            classes={{ root: classes.label }}
                        />
                    );
                }}
            />
        </FormControl>
    );
};

export default SwitchItem;
