import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { useForm, Controller } from 'react-hook-form';

import EditableText from 'components/EditableText/wysi';

import Button from 'components/CustomButtons/Button';

import { businessplanScope } from './messages';

const BusinessPlan = (props) => {
  const { businessPlan, pristine, submitting, onSubmit } = props;
  const intl = useIntl();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      businessPlan,
    },
  });

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <GridItem>
        <h4>{intl.formatMessage(businessplanScope.businessIdea.title)}</h4>
        <Controller
          name={'businessPlan.businessIdea'}
          control={control}
          defaultValue={businessPlan.businessIdea}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.businessIdea.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }
            />
          }
        />

        <h4>{intl.formatMessage(businessplanScope.coreTeam.title)}</h4>
        <Controller
          name={'businessPlan.coreTeam'}
          control={control}
          defaultValue={businessPlan.coreTeam}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.coreTeam.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }
            />
          }
        /> 

        <h4>{intl.formatMessage(businessplanScope.generalPicture.title)}</h4>
        <Controller
          name={'businessPlan.generalPicture'}
          control={control}
          defaultValue={businessPlan.generalPicture}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.generalPicture.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }/>
          }
        />

        <h4>{intl.formatMessage(businessplanScope.productsAndServices.title)}</h4>
        <Controller
          name={'businessPlan.productsAndServices'}
          control={control}
          defaultValue={businessPlan.productsAndServices}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.productsAndServices.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }/>
          }
        />

        <h4>{intl.formatMessage(businessplanScope.revenueModel.title)}</h4>
        <Controller
          name={'businessPlan.revenueModel'}
          control={control}
          defaultValue={businessPlan.revenueModel}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.revenueModel.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }/>
          }
        />

        <h4>{intl.formatMessage(businessplanScope.targetMarkets.title)}</h4>
        <Controller
          name={'businessPlan.targetMarkets'}
          control={control}
          defaultValue={businessPlan.targetMarkets}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.targetMarkets.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }/>
          }
        />

        <h4>{intl.formatMessage(businessplanScope.customers.title)}</h4>
        <Controller
          name={'businessPlan.customers'}
          control={control}
          defaultValue={businessPlan.customers}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.customers.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }/>
          }
        />

        <h4>{intl.formatMessage(businessplanScope.markets.title)}</h4>
        <Controller
          name={'businessPlan.markets'}
          control={control}
          defaultValue={businessPlan.markets}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.markets.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }/>
          }
        />

        <h4>{intl.formatMessage(businessplanScope.competition.title)}</h4>
        <Controller
          name={'businessPlan.competition'}
          control={control}
          defaultValue={businessPlan.competition}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.competition.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }/>
          }
        />

        <h4>{intl.formatMessage(businessplanScope.businessGoals.title)}</h4>
        <Controller
          name={'businessPlan.businessGoals'}
          control={control}
          defaultValue={businessPlan.businessGoals}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.businessGoals.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }/>
          }
        />

        <h4>{intl.formatMessage(businessplanScope.schedule.title)}</h4>
        <Controller
          name={'businessPlan.schedule'}
          control={control}
          defaultValue={businessPlan.schedule}
          as={
            <EditableText
              helper={
                <ul>
                  {businessplanScope.schedule.helper.map((message) => (
                    <li key={message.id}>
                      <FormattedMessage {...message} />
                    </li>
                  ))}
                </ul>
              }/>
          }
        />

        <GridContainer
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          style={{ paddingTop: '3rem' }}
        >
          <Button style={{ marginRight: '1rem' }} round disabled={pristine || submitting}>Peruuta</Button>

          <Button round color="primary" disabled={submitting} type="submit">
            Tallenna
          </Button>
        </GridContainer>
      </GridItem>
    </form>
  );
};

export default BusinessPlan;
