import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useIntl } from 'react-intl';
import FormSpaceHeader from 'components/FormSpaceHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Robot from 'components/Source/Robot';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';

const ResponsiblesRobot = (props) => {
  const { data, lang, messages } = props;
  const intl = useIntl();

  if (data === undefined || data === null || data.responsibles === null)
    return null;

  const renderTable = (data) => (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" aria-label="Responsibles table">
        <TableHead>
          <TableRow>
            <TableCell>
              {intl.formatMessage(messages.formdata.source)}
            </TableCell>
            <TableCell>{intl.formatMessage(messages.formdata.name)}</TableCell>
            <TableCell>
              {intl.formatMessage(messages.formdata.registered)}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: 'robot.responsibles.hasOtherConnections',
              })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.responsibles.map((row, index) => {
            if (row && row.language !== lang)
              return <React.Fragment key={row + index}></React.Fragment>;

            return (
              <TableRow key={row + index}>
                <TableCell>
                  <Robot />
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.registrationDate}</TableCell>
                <TableCell>
                  {row.hasOtherBusinessConnections ? (
                    <CheckCircleOutlineIcon />
                  ) : (
                    <RadioButtonUncheckedOutlinedIcon />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <GridContainer>
      <GridItem xs={12}>
        <FormSpaceHeader
          header={intl.formatMessage({ id: 'robot.responsibles.title' })}
          anchor={intl.formatMessage(messages.formdata.description)}
        />
        {renderTable(data)}
      </GridItem>
    </GridContainer>
  );
};

export default ResponsiblesRobot;
