import { PublicClientApplication } from '@azure/msal-browser';
import appconfig from 'utils/config';

export const config = {
	clientId: 'fbaa4816-042d-4f4d-903c-ce319b7bd6ff',
	authority: 'https://login.microsoftonline.com/grantsfunding.onmicrosoft.com',
	redirectUri: `${appconfig.REDIRECT_URI}`,
	scopes: ['https://grants-api.azurewebsites.net/default'],
	response_mode: 'fragment',
};

const publicApp = new PublicClientApplication({
	auth: {
		clientId: config.clientId,
		redirectUri: config.redirectUri,
		authority: config.authority,
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: true,
	},
});


export default publicApp;
