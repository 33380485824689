import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as styles from 'assets/jss/grants/common/container';

const useStyles = makeStyles(styles);

export default function GridItem(props) {
  const { children, className, noPadding, noPaddingSide, contentRight, ...rest } = props;
  const classes = useStyles();
  const containerClasses = classNames({
    [classes.gridItem]: true,
    [classes.noPadding]: noPadding,
    [classes.noPaddingSide]: noPaddingSide,
    [className]: true,
    [classes.contentRight]: contentRight
  });
  
  return (
    <Grid item {...rest} className={containerClasses}>
      {children}
    </Grid>
  );
}

GridItem.defaultProps = {
  className: ''
};

GridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
