import React from 'react';
import { useForm } from 'react-hook-form';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import ConfirmDialog from 'components/ConfirmDialog';
import CompanyFormValues from './CompanyFormValues';

const RadarForm = props => {
    const { scenarios, pristine, submitting, onSubmit, onScenarioChange, onRemoveScenario, onSubmitRadar, onSaveAsPrimary } = props;

    const { register, control, handleSubmit, setValue } = useForm({
        defaultValues: {
            scenarios,
        },
    });

    const [open, setOpen] = React.useState(false);

    const onSubmitRadarTest = handleSubmit(values => onSubmitRadar(values));

    const onSubmitApproval = handleSubmit(values => {
        if (values.isMainScenario) {
            values.isMainScenario = false;
            values.id = null;
        }

        onSubmit(values);
    });

    const saveAsPrimary = handleSubmit(values => {
        if (values.isMainScenario) {
            values.isMainScenario = false;
            values.id = null;
        }

        onSaveAsPrimary(values);
    });

    const setConfirm = () => setOpen(true);

    return (
        <GridContainer style={{ padding: '1rem', background: '#f7f5f0', borderRadius: '6px' }}>
            <GridItem>
                <GridItem noPadding>
                    <GridContainer direction='row' justify='flex-end' alignItems='flex-end'>
                        <form style={{ width: '100%' }}>
                            <CompanyFormValues
                                onRemoveScenario={onRemoveScenario}
                                scenarios={scenarios}
                                control={control}
                                register={register}
                                {...props}
                                setValue={setValue}
                                onScenarioChange={onScenarioChange}
                            />
                        </form>
                    </GridContainer>
                </GridItem>
            </GridItem>
            <GridItem contentRight={true}>
                <Button
                    round
                    color='secondary'
                    disabled={pristine || submitting}
                    onClick={onSubmitRadarTest}
                    style={{ marginRight: '1rem' }}
                >
                    Testaa skenaariota
                </Button>
                <Button
                    round
                    color='primary'
                    disabled={submitting}
                    type='submit'
                    onClick={onSubmitApproval}
                    style={{ marginRight: '1rem' }}
                >
                    Tallenna skenaario
                </Button>
                <ConfirmDialog
                    title='test'
                    open={open}
                    setOpen={setOpen}
                    onConfirm={saveAsPrimary}
                    buttonOkId='shared.yes'
                    titleId='confirm.savemainscenario'
                >
                    <p>Oletko varma, että haluat asettaa skenaariosta uuden GrantedAI skenaarion?</p>
                    <p>Skenaario yliajaa nykyisen GranteAI skenaarion.</p>
                </ConfirmDialog>
                <Button round color='primary' disabled={submitting} type='submit' onClick={setConfirm}>
                    Tallenna pääskenaarioksi
                </Button>
            </GridItem>
        </GridContainer>
    );
};

export default RadarForm;
