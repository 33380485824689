import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadUsers, loadLicenseInformation } from './actions';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';

import { GridItem, GridContainer } from 'components/Grid';

import UserManagement from './UserForm/UserManagement';
import CompanyLicenseManagement from './LicenseForm/LicenseManagement';

const key = 'grantedai';

const AccountManagement = () => {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadUsers());
        dispatch(loadLicenseInformation());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <GridContainer contentContainer>
            <GridItem xs={12}>
                <h3>GrantedAI tilihallinta</h3>
                <p>Näkymältä voidaan aktivoida GrantedAI käyttäjä valitulle yritykselle.</p><br />
            </GridItem>
            <GridItem xs={12}>
                <CompanyLicenseManagement />
            </GridItem>
            <GridItem xs={12}>
                <UserManagement />
            </GridItem>

        </GridContainer>
    );
};

export default AccountManagement;