import { put, takeLatest, select } from 'redux-saga/effects';

import {
	loadUsersError, loadUsersSuccess, saveLicenseInformationSuccess,
	saveLicenseInformationError, loadLicenseInformationError, loadLicenseInformationSuccess,
	loadLicenseInformation,
} from './actions';
import { LOAD_GRANTEDAI_USERS, ADD_GRANTEDAI_USER, SAVE_LICENSE_INFORMATION, LOAD_LICENSE_INFORMATION, TERMINATE_LICENSE, REACTIVATE_USER } from './constants';
import { getSingleCompany } from 'containers/CompanyBaseData/selectors';

import config from 'utils/config';
import axios from 'utils/axios';
import { error, success } from 'components/Toast';

export function* loadUsers() {
	const company = yield select(getSingleCompany());

	if (!company.businessId) return;

	const requestURL = `${config.FETCH_COMPANY_API_URI}/users/${company.businessId}`;

	try {
		const response = yield axios.get(requestURL);
		yield put(loadUsersSuccess(response.data));
	} catch (err) {
		yield put(loadUsersError(err));
	}
}

export function* addUser({ user }) {

	const company = yield select(getSingleCompany());

	if (!company.businessId) return;

	const requestURL = `${config.ID_API}/user/create/${company.businessId}`;

	try {
		const response = yield axios.post(requestURL, user); // returns users
		yield put(loadUsersSuccess(response.data));
		success('Käyttäjän lisääminen onnistui');
	} catch (err) {
		error('Käytäjän lisääminen epäonnistui: ' + err);
		yield put(loadUsersError(err));
	}
}

export function* saveLicense({ license }) {
	const company = yield select(getSingleCompany());

	if (!company.businessId) return;

	const requestURL = `${config.ID_API}/license/${company.businessId}`;

	try {
		const response = yield axios.post(requestURL, license); // save license
		yield put(saveLicenseInformationSuccess(response.data));
		yield put(loadLicenseInformation());
	} catch (err) {
		yield put(saveLicenseInformationError(err));
	}
}

export function* loadLicenses() {
	const company = yield select(getSingleCompany());

	if (!company.businessId) return;

	const requestURL = `${config.ID_API}/license/${company.businessId}`;

	try {
		const response = yield axios.get(requestURL); // returns users
		yield put(loadLicenseInformationSuccess(response.data));
	} catch (err) {
		yield put(loadLicenseInformationSuccess([]));

		yield put(loadLicenseInformationError(err));
	}
}

export function* terminateLicense() {
	const company = yield select(getSingleCompany());

	if (!company.businessId) return;

	const requestURL = `${config.ID_API}/license/${company.businessId}/terminate`;

	try {
		const response = yield axios.post(requestURL); // save license

		yield put(saveLicenseInformationSuccess(response.data));
		yield put(loadLicenseInformation());
	} catch (err) {
		yield put(saveLicenseInformationError(err));
	}
}

export function* resendUserActivation({ username }) {
	const company = yield select(getSingleCompany());

	if (!company.businessId) return;

	const body = {
		Username: username,
		Email: username
	};

	const requestURL = `${config.ID_API}/user/reactivate`;

	try {
		const response = yield axios.post(requestURL, body); // save license
		alert(`Käyttäjän aktivointilikki lähetetty uudelleen: ${username} ${response.status}`);
	} catch (err) {
		alert(err);
	}
}


export default function* companyData() {
	yield takeLatest(LOAD_GRANTEDAI_USERS, loadUsers);
	yield takeLatest(ADD_GRANTEDAI_USER, addUser);
	yield takeLatest(SAVE_LICENSE_INFORMATION, saveLicense);
	yield takeLatest(LOAD_LICENSE_INFORMATION, loadLicenses);
	yield takeLatest(TERMINATE_LICENSE, terminateLicense);
	yield takeLatest(REACTIVATE_USER, resendUserActivation);
}