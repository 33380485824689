import { logout } from 'containers/Login/AuthProvider';
import { handleTokenRefresh, attachTokenToRequest, shouldIntercept, refreshUri, setTokenData } from './axiosFunctions';
import storage from 'utils/localstorage';
import { TOKEN_KEY } from 'utils/constants';
import appConfig from 'utils/config';
import { getToken } from 'containers/Login/AuthProvider';

/**
 * export axios client to the requester for implementing axios requests
 */
export default (axiosClient, customOptions = {}) => {
    let isRefreshing = false;
    let failedQueue = [];

    const options = {
        attachTokenToRequest,
        handleTokenRefresh,
        setTokenData,
        shouldIntercept,
        ...customOptions,
    };

    const isReFreshRequestError = (error) => {
        try {
            const isRefreshRequest = error.config.url === refreshUri;

            if (isRefreshRequest) { // if request uri is refresh throw user out to login, maybe refresh request has mulfunction
                failedQueue = [];
                storage.remove(TOKEN_KEY);
                window.location.assign('/login');
                logout();

                return true;
            }
        } catch (error) {
            // don't handle exceptions 
        }
    };

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    const responseInterceptor = async (error) => {

        if (isReFreshRequestError(error)) {
            return Promise.reject(error);
        }

        if (!options.shouldIntercept(error)) {
            return Promise.reject(error);
        }

        if (error.config._retry || error.config._queued) {
            return Promise.reject(error);
        }

        const originalRequest = error.config;
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject });
            }).then(token => {
                originalRequest._queued = true;
                options.attachTokenToRequest(originalRequest, token);

                return axiosClient.request(originalRequest);
            }).catch(() => {
                return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
            options.handleTokenRefresh
                .call(options.handleTokenRefresh)
                .then(tokenData => {
                    options.setTokenData(tokenData, axiosClient);
                    options.attachTokenToRequest(originalRequest, tokenData.accessToken);

                    processQueue(null, tokenData.accessToken);

                    resolve(axiosClient.request(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                });
        });
    };

    axiosClient.interceptors.request.use(
        async config => {
            const token = await getToken();
            config.headers = {
                Authorization: `Bearer ${token.accessToken}`,
                'Ocp-Apim-Subscription-Key': appConfig.APIKEY
            };

            return config;
        },
        error => {
            Promise.reject(error);
        }
    );

    axiosClient.interceptors.response.use(response => {
        return response;
    }, responseInterceptor);
};
