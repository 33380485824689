
export const LOAD_SINGLE_COMPANY = 'Grants/Company/LOAD_SINGLE_COMPANY';
export const LOAD_SINGLE_COMPANY_SUCCESS =
  'Grants/Company/LOAD_SINGLE_COMPANY_SUCCESS';
export const LOAD_SINGLE_COMPANY_ERROR =
  'Grants/Company/LOAD_SINGLE_COMPANY_ERROR';

export const SAVE_SINGLE_COMPANY = 'Grants/Company/SAVE_SINGLE_COMPANY';
export const SAVE_SINGLE_COMPANY_SUCCESS =
  'Grants/Company/SAVE_SINGLE_COMPANY_SUCCESS';
export const SAVE_SINGLE_COMPANY_ERROR =
  'Grants/Company/SAVE_SINGLE_COMPANY_ERROR';

export const GET_COMPANY_LITE_SCORE_SUCCESS = 'Grants/Company/GET_COMPANY_LITE_SCORE_SUCCESS';
export const GET_COMPANY_LITE_SCORE_ERROR = 'Grants/Company/GET_COMPANY_LITE_SCORE_ERROR';
export const GET_COMPANY_LITE_SCORE = 'Grants/Company/GET_COMPANY_LITE_SCORE';
