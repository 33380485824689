import React from 'react';
import { FormattedMessage, useIntl, FormattedNumber } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.primary.white,
        padding: '0 2rem',
    },
}));

const typeEnum = { bool: 1, percentage: 2, currency: 3 };

const InstrumentDescription = ({ instrument }) => {
    const {
        isDeminimis,
        projectSizeEur,
        expectedPendingTime,
        amountMinPercentage,
        amountMaxPercentage,
        minAmount,
        maxAmount,
        prepayment,
        timeFrame,
        installmentsFreeTime,
    } = instrument;

    const classes = useStyles();

    const { formatMessage } = useIntl();

    const renderParameterRow = (titleId, value, max, type) => {
        if (value == null && max == null) return null;

        return (
            <TableRow>
                <TableCell>
                    <FormattedMessage id={titleId} />
                </TableCell>
                <TableCell>{handleByType(type, value)}</TableCell>
                <TableCell>{handleByType(type, max)}</TableCell>
            </TableRow>
        );
    };

    const handleByType = (type, value) => {
        const props = { style: 'currency', currency: 'EUR' };

        if (value === null) return null;
        if (type === typeEnum.bool) return formatMessage({ id: `instrument.data.type.bool.${value}` });
        if (type === typeEnum.percentage) return formatMessage({ id: 'instrument.data.type.percentage' }, { value: value });
        if (type === typeEnum.currency) return <FormattedNumber value={value} {...props} />;

        return value;
    };

    return (
        <TableContainer /*component={Paper}*/ className={classes.container}>
            <Table size='small' aria-label='instrument description'>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage id='instrument.data.parameterName' />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id='instrument.data.minValue' />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage id='instrument.data.maxValue' />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderParameterRow('instrument.data.isdeminimis', isDeminimis, null, typeEnum.bool)}
                    {renderParameterRow('instrument.data.projectSize', projectSizeEur)}
                    {renderParameterRow('instrument.data.expectedPendingTime', expectedPendingTime)}
                    {renderParameterRow('instrument.data.amountPercentage', amountMinPercentage, amountMaxPercentage, typeEnum.percentage)}
                    {renderParameterRow('instrument.data.amount', minAmount, maxAmount, typeEnum.currency)}
                    {renderParameterRow('instrument.data.prepayment', prepayment, null, typeEnum.currency)}
                    {renderParameterRow('instrument.data.timeframe', timeFrame)}
                    {renderParameterRow('instrument.data.installmentsFreeTime', installmentsFreeTime)}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default InstrumentDescription;
