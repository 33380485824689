import { put, takeLatest, select } from 'redux-saga/effects';
import {
  LOAD_WORK_PACKAGE,
  SAVE_WORK_PACKAGE,
  LOAD_WORK_PACKAGES,
  REMOVE_WORK_PACKAGE,
} from './constants';
import {
  loadWorkPackageSuccess,
  loadWorkPackageError,
  removeWorkPackageSuccess,
  removeWorkPackageError,
} from './actions';
import { getSingleCompany } from 'containers/CompanyBaseData/selectors';
import { error, success } from 'components/Toast';
import config from 'utils/config';
import axios from 'utils/axios';

export function* getWorkPackages({ businessId, messages }) {
  const requestURL = `${config.FETCH_COMPANY_API_URI}/workPackage/${businessId}`;

  try {
    const response = yield axios.get(requestURL);

    yield put(loadWorkPackageSuccess(response.data));
  } catch (err) {
    yield put(loadWorkPackageError(err));
    error(messages.error.title, `${messages.error.body}: ${err}`);
  }
}

export function* getWorkPackage({ businessId, packageId }) {
  const requestURL = `${config.FETCH_COMPANY_API_URI}/workPackage/${businessId}/${packageId}`;

  try {
    const response = yield axios.get(requestURL);

    yield put(loadWorkPackageSuccess(response.data));
  } catch (err) {
    yield put(loadWorkPackageError(err));
  }
}

export function* saveWorkPackage({ workPackage, messages }) {
  const company = yield select(getSingleCompany());

  const requestURL = `${config.FETCH_COMPANY_API_URI}/workPackage/${company.businessId}`;

  try {

    const response = yield axios.post(requestURL, workPackage);

    yield put(loadWorkPackageSuccess(response.data));
    success(messages.success.title, messages.success.body);
  } catch (err) {
    yield put(loadWorkPackageError(err));
    error(messages.error.title, `${messages.error.body}: ${err}`);
  }
}

export function* removeWorkPackage({ workPackage, messages }) {
  const company = yield select(getSingleCompany());

  const requestURL = `${config.FETCH_COMPANY_API_URI}/workPackage/delete/${company.businessId}/${workPackage.rowKey}`;

  try {

    const response = yield axios.post(requestURL); // eslint-disable-line

    yield put(removeWorkPackageSuccess());
    success(messages.success.title, messages.success.remove.body);
  } catch (err) {
    yield put(removeWorkPackageError(err));
    error(messages.error.title, `${messages.error.body}: ${err}`);
  }
}

export default function* companyData() {
  yield takeLatest(LOAD_WORK_PACKAGES, getWorkPackages);
  yield takeLatest(LOAD_WORK_PACKAGE, getWorkPackage);
  yield takeLatest(SAVE_WORK_PACKAGE, saveWorkPackage);
  yield takeLatest(REMOVE_WORK_PACKAGE, removeWorkPackage);
}
