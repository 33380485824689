import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from 'containers/Timeline/reducer';
import saga from 'containers/Timeline/saga';
import { loadTimeline, saveTimeline } from 'containers/Timeline/actions';

import Loader from 'components/Loader';
import TimelineForm from './TimelineForm';

const key = 'timeline';

const Timeline = () => {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });
    const dispatch = useDispatch();

    const timeline = useSelector(state => state.timeline);
    const timelineLoading = timeline?.loading;
    const timelineData = timeline?.timeline;
    const instrument = timeline?.instrument;
    const editMode = timeline?.editMode;

    const company = useSelector(state => state.company?.company);
    const radar = useSelector(state => state.radar);
    const scenarios = radar?.scenarios;
    const instruments = radar?.instruments;

    const mainScenario = scenarios
        ?.filter(({ isMainScenario }) => isMainScenario)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0];

    const timelineContainerRef = useRef();
    const [timelineContainerWidth, setTimelineContainerWidth] = useState(null);

    useLayoutEffect(() => {
        const updateWidth = () => {
            setTimelineContainerWidth(timelineContainerRef.current?.clientWidth);
        };

        window.addEventListener('resize', updateWidth);
        updateWidth();

        return () => window.removeEventListener('resize', updateWidth);
    });

    const saveTimelineChanges = ({ timeline }) => {
        dispatch(saveTimeline(timeline));
    };

    useEffect(() => {
        if (!company) return;

        dispatch(loadTimeline());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [isOpenFinancialPlanDrawer, setIsOpenFinancialPlanDrawer] = useState(false);
    const openFinancialPlanDrawer = () => setIsOpenFinancialPlanDrawer(true);
    const closeFinancialPlanDrawer = () => setIsOpenFinancialPlanDrawer(false);

    if (!timeline) return null;

    return (
        <div
            ref={timelineContainerRef}
            style={{
                width: '100%',
            }}
        >
            <div
                style={{
                    maxWidth: timelineContainerWidth,
                }}
            >
                {timeline.loading ? (
                    <Loader />
                ) : (
                    <TimelineForm
                        timeline={timelineData}
                        loading={timelineLoading}
                        company={company}
                        scenario={mainScenario}
                        instruments={instruments}
                        instrument={instrument}
                        saveTimeline={saveTimelineChanges}
                        timelineContainerWidth={timelineContainerWidth}
                        editMode={editMode}
                        isOpenFinancialPlanDrawer={isOpenFinancialPlanDrawer}
                        openFinancialPlanDrawer={openFinancialPlanDrawer}
                        closeFinancialPlanDrawer={closeFinancialPlanDrawer}
                    />
                )}
            </div>
        </div>
    );
};

export default Timeline;
