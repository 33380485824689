const formControls = {
  multilineText: {
    padding: '0.2rem 0.5rem',
  },
  errorMessage: {
    color: 'red',
  }
};

export default formControls;
