import {
  LOAD_SINGLE_COMPANY,
  LOAD_SINGLE_COMPANY_ERROR,
  LOAD_SINGLE_COMPANY_SUCCESS,
  SAVE_SINGLE_COMPANY,
  SAVE_SINGLE_COMPANY_SUCCESS,
  SAVE_SINGLE_COMPANY_ERROR,
  GET_COMPANY_LITE_SCORE,
  GET_COMPANY_LITE_SCORE_SUCCESS,
  GET_COMPANY_LITE_SCORE_ERROR
} from './constants';

export function loadSingleCompanyData(businessId, messages) {
  return {
    type: LOAD_SINGLE_COMPANY,
    businessId,
    messages,
  };
}

export function singleCompanyDataLoaded(company) {
  return {
    type: LOAD_SINGLE_COMPANY_SUCCESS,
    company,
  };
}

export function loadSingleCompanyDataError(error, messages) {
  return {
    type: LOAD_SINGLE_COMPANY_ERROR,
    error,
    messages,
  };
}

export function saveSingleCompanyData(values, messages) {
  return {
    type: SAVE_SINGLE_COMPANY,
    values,
    messages,
  };
}

export function saveCompanySucceeded(company) {
  return {
    type: SAVE_SINGLE_COMPANY_SUCCESS,
    company,
  };
}

export function saveSingleCompanyDataError(error, messages) {
  return {
    type: SAVE_SINGLE_COMPANY_ERROR,
    error,
    messages,
  };
}


export function getLiteScore(companyId) {
  return {
    type: GET_COMPANY_LITE_SCORE,
    companyId,
  };
}
export function getLiteScoreSuccess(score) {
  return {
    type: GET_COMPANY_LITE_SCORE_SUCCESS,
    score,
  };
}
export function getLiteScoreError(error) {
  return {
    type: GET_COMPANY_LITE_SCORE_ERROR,
    error,
  };
}