import React from 'react';
import { useIntl } from 'react-intl';

import { useFieldArray, Controller, useWatch } from 'react-hook-form';
import { GrantsInput } from 'components/GrantsComponents/Input';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import RemoveLineButton from 'components/GrantsComponents/RemoveLineButton';
import AddButton from 'components/GrantsComponents/AddButton';
import Accordion from 'components/Accordion.js';
import Typography from '@material-ui/core/Typography';
import fiLocale from 'date-fns/locale/fi';

import TaskExpenses from './TaskExpenses';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { workpackage, workpackageTask } from '../messages';

const Tasks = props => {
    const { control, index } = props;
    const intl = useIntl();

    const { fields, remove, append } = useFieldArray({
        control,
        name: `workPackage[${index}].tasks`,
    });

    const [expanded, setExpanded] = React.useState('');
    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const watchTasks = useWatch({ name: `workPackage[${index}].tasks`, control });

    const getTitle = k => {
        try {
            return watchTasks[k] ? watchTasks[k].name : '';
        } catch (error) {
            return '';
        }
    };

    const renderTasks = () =>
        fields.map((item, k) => (
            <Accordion
                prefix={intl.formatMessage(workpackageTask.title)}
                expanded={expanded === `${item.id}`}
                key={item.id}
                handleChange={handleChange(`${item.id}`)}
                // title={intl.formatMessage(workpackageTask.title)}
                title={getTitle(k)}
            >
                <GridContainer>
                    <GridItem xs={12}>
                        <Controller
                            name={`workPackage[${index}].tasks[${k}].id`}
                            control={control}
                            defaultValue={item.id}
                            render={() => <input type='hidden' value={item.id} />}
                        />
                        <Controller
                            name={`workPackage[${index}].tasks[${k}].name`}
                            control={control}
                            defaultValue={item.name ?? ''}
                            render={props => <GrantsInput label={intl.formatMessage(workpackageTask.name)} {...props} />}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <Controller
                            name={`workPackage[${index}].tasks[${k}].description`}
                            control={control}
                            defaultValue={item.description ?? ''}
                            render={props => <GrantsInput label={intl.formatMessage(workpackageTask.description)} multiline {...props} />}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        <TaskExpenses parentKey={`workPackage[${index}].tasks[${k}]`} control={control} />
                    </GridItem>
                    {/* <GridItem xs={12}>
            <Controller
              name={`workPackage[${index}].tasks[${k}].corrective`}
              control={control}
              defaultValue={item.corrective ?? ""}
              render={(props) => (
                <GrantsInput label={intl.formatMessage(workpackageTask.corrective)} {...props} />
              )}
            />
          </GridItem> */}
                    {/* <GridItem xs={12}>
            <Controller
              name={`workPackage[${index}].tasks[${k}].content`}
              control={control}
              defaultValue={item.content ?? ""}
              render={(props) => <GrantsInput label={intl.formatMessage(workpackageTask.content)} {...props} />}
            />
          </GridItem> */}
                    <GridItem xs={12}>
                        <Controller
                            name={`workPackage[${index}].tasks[${k}].content`}
                            control={control}
                            defaultValue={item.content ?? ''}
                            render={props => (
                                <GrantsInput label={intl.formatMessage({ id: 'workpackage.task.results' })} multiline {...props} />
                            )}
                        />
                    </GridItem>
                    <GridItem xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                            <Controller
                                control={control}
                                name={`workPackage[${index}].tasks[${k}].starts`}
                                render={rest => (
                                    <KeyboardDatePicker
                                        {...rest}
                                        disableToolbar
                                        autoOk
                                        variant='inline'
                                        format='dd.MM.yyyy'
                                        placeholder={intl.formatMessage({ id: 'instrument.form.datepicker.placeholder' })}
                                        margin='normal'
                                        label={intl.formatMessage(workpackageTask.startingDate)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem>
                    <GridItem xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                            <Controller
                                control={control}
                                name={`workPackage[${index}].tasks[${k}].ends`}
                                render={rest => (
                                    <KeyboardDatePicker
                                        {...rest}
                                        disableToolbar
                                        autoOk
                                        variant='inline'
                                        format='dd.MM.yyyy'
                                        placeholder={intl.formatMessage({ id: 'instrument.form.datepicker.placeholder' })}
                                        margin='normal'
                                        label={intl.formatMessage(workpackageTask.endDate)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem>
                    <GridItem xs={12}>
                        <Typography align='right'>
                            <RemoveLineButton icon={false} onClick={() => remove(k)} />
                        </Typography>
                    </GridItem>
                </GridContainer>
            </Accordion>
        ));

    return (
        <React.Fragment>
            {renderTasks()}
            <GridItem noPaddingSide xs={12}>
                <AddButton onClick={() => append()} text={intl.formatMessage(workpackage.addTask)} />
            </GridItem>
        </React.Fragment>
    );
};

export default Tasks;
