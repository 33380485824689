import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { color } from 'assets/jss/grants/common/grantsBase.js';

const useStyles = makeStyles(() => ({
    item: {
        color: color.primaryColor,
        '&:hover': {
            cursor: 'pointer',
            color: '#fff',
            backgroundColor: color.primaryColor,
        },
    },
    icon: {
        color: color.primaryColor,
    },
    itemIcon: {
        minWidth: '2rem !important;',
    },
}));

const CompanyList = ({ companies, selectCompany }) => {
    const classes = useStyles();

    return (
        <List className={classes.root}>
            {companies?.map(company => {
                const { name, businessId, isCustomer } = company;

                return (
                    <ListItem
                        key={businessId + isCustomer}
                        alignItems='flex-start'
                        onClick={() => selectCompany(company)}
                        className={classes.item}
                    >
                        {isCustomer && (
                            <ListItemIcon classes={{ root: classes.itemIcon }}>
                                <RecentActorsIcon fontSize='small' className={classes.icon} />
                            </ListItemIcon>
                        )}
                        <ListItemText
                            primary={
                                <Typography component='span' variant='subtitle1'>
                                    {name}&nbsp;
                                </Typography>
                            }
                            secondary={
                                <Typography component='span' variant='body2'>
                                    {businessId}
                                </Typography>
                            }
                        />
                    </ListItem>
                );
            })}
        </List>
    );
};

export default CompanyList;
