import { put, takeLatest } from 'redux-saga/effects';

import { LOAD_INSTRUMENTS, SAVE_INSTRUMENT, PUBLISH_SELECTED } from './constants.js';
import { loadInstruments, loadInstrumentsSuccess, loadInstrumentsError, saveInstrumentSuccess, saveInstrumentFail, closeInstrumentModal } from './actions';
import config from 'utils/config';
import axios from 'utils/axios';
import { error, success } from 'components/Toast';

export function* getInstruments() {
    const requestURL = `${config.INSTRUMENT_URI}/instruments?isDraft=true`;

    try {
        const { data } = yield axios.get(requestURL);

        yield put(loadInstrumentsSuccess(data));
    } catch (err) {
        yield put(loadInstrumentsError(err));
    }
}

export function* saveInstrument({ instrument, isDraft }) {
    instrument.limitators = instrument.limitators.map(limitator => ({
        companyValue: null,
        compareProperty: limitator.type,
        comparerStringType: 'IntLimitator',
        isValid: null,
        requiredValue: limitator.values.reduce((a, b) => {
            return a + b;
        }, 0),
    }));

    const requestURL = `${config.INSTRUMENT_URI}/instruments?isDraft=${isDraft}`;

    try {
        const { data } = yield axios.post(requestURL, instrument);

        let isNew = false;
        if (!instrument.id) {
            isNew = true;
        }

        yield put(saveInstrumentSuccess(data, isNew));
        yield put(closeInstrumentModal());
        // success(<FormattedMessage id='instruments.edit.id' />, <FormattedMessage id='instruments.edit.id' />);
        success('Success', isNew ? 'Instrument added successfully' : 'Instrument edited successfully');
    } catch (err) {
        yield put(saveInstrumentFail(err));
        yield put(closeInstrumentModal());
        error('Error' + err.response?.status, err.response?.statusText);
    }
}

export function* publishSelected({ silent, selected }) {
    const requestURL = `${config.INSTRUMENT_URI}/instruments/publish?silentPublish=${silent}`;

    try {
        yield axios.post(requestURL, selected);
        yield put(loadInstruments());
        success('Success', 'Instrumentit julkaistu onnistuneesti');
    } catch (err) {
        yield put(saveInstrumentFail(err));
        error('Error' + err.response?.status, err.response?.statusText);
    }
}

export default function* instrumentsWatcher() {
    yield takeLatest(LOAD_INSTRUMENTS, getInstruments);
    yield takeLatest(SAVE_INSTRUMENT, saveInstrument);
    yield takeLatest(PUBLISH_SELECTED, publishSelected);

}