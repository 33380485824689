import { put, takeLatest, delay, select } from 'redux-saga/effects';

import { GET_GRANTED_SCORE, GET_SCENARIO_GRANTED_SCORE } from './constants.js';
import { getGrantedScoreError, getGrantedScoreSuccess } from './actions';
import { getSingleCompany } from 'containers/CompanyBaseData/selectors';
import axios from 'utils/axios';
import config from 'utils/config';

export function* getGrantedScore() {
    try {
        const company = yield select(getSingleCompany());
        let url = `${config.INSTRUMENT_URI}/scenario/score/entity/${company.businessId}`;
        let tryCount = 1;
        let scenario = yield select(state => state.scenario);

        do {
            scenario = yield select(state => state.scenario);
            tryCount++;
            if (tryCount <= 15 && scenario?.loading?.scenarios && !scenario?.scenarios?.rowKey) {
                yield delay(1000);
            }
        } while (tryCount <= 15 && scenario?.loading?.scenarios && !scenario?.scenarios?.rowKey);

        const response = yield axios.get(url);

        yield put(getGrantedScoreSuccess(response.data));
    } catch (err) {
        yield put(getGrantedScoreError(err));
    }
}

export function* getScenarioGrantedScore({ scenario }) {
    try {
        const company = yield select(getSingleCompany());

        let url = `${config.INSTRUMENT_URI}/scenario/score/entity/${company.businessId}`;

        let tryCount = 1;
        let response;
        do {
            response = yield axios.get(url);

            if (response.data?.rowKey === scenario?.rowKey) {
                // compare that the score is calculated to correct scenario
                yield put(getGrantedScoreSuccess(response.data));
                return;
            }

            tryCount++;
            if (response.data?.rowKey !== scenario?.rowKey && tryCount <= 3) {
                yield delay(2000);
            } // give some time for counting scores TODO: replace with socket
        } while (response.data?.rowKey !== scenario?.rowKey && tryCount <= 3);

        yield put(getGrantedScoreSuccess(response.data));
    } catch (err) {
        yield put(getGrantedScoreError(err));
    }
}

export default function* scenarioWatcher() {
    yield takeLatest(GET_GRANTED_SCORE, getGrantedScore);
    yield takeLatest(GET_SCENARIO_GRANTED_SCORE, getScenarioGrantedScore);
}
