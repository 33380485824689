import {
  LOAD_COMPANY_EVENTS,
  LOAD_COMPANY_EVENTS_ERROR,
  LOAD_COMPANY_EVENTS_SUCCESS,
  SAVE_COMPANY_EVENTS
} from './constants';

export function loadEvents(businessId) {
  return {
    type: LOAD_COMPANY_EVENTS,
    businessId,
  };
}

export function loadEventsSuccess(events) {
  return {
    type: LOAD_COMPANY_EVENTS_SUCCESS,
    events,
  };
}

export function loadEventsError(error) {
  return {
    type: LOAD_COMPANY_EVENTS_ERROR,
    error,
  };
}

export function saveCompanyEvents(events) {
  return {
    type: SAVE_COMPANY_EVENTS,
    events,
  };
}
