import produce from 'immer';
import { LOAD_COMPANY_DATA, LOAD_COMPANY_DATA_ERROR, LOAD_COMPANY_DATA_SUCCESS, SELECT_COMPANY, CLEAR_COMPANY_DATA } from './constants';

export const initialState = {
    loading: false,
    error: false,
    companies: [],
    selected: {},
};

/* eslint-disable default-case, no-param-reassign */
const companySearchReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case LOAD_COMPANY_DATA:
                draft.loading = true;
                draft.error = false;
                break;

            case LOAD_COMPANY_DATA_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.companies = action.companies;
                break;

            case LOAD_COMPANY_DATA_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;

            case SELECT_COMPANY:
                draft.selected = action.selected;
                draft.loading = false;
                draft.error = false;
                break;

            case CLEAR_COMPANY_DATA:
                draft.loading = false;
                draft.error = false;
                draft.companies = [];
                break;
        }
    });

export default companySearchReducer;
