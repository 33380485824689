import produce from 'immer';
import _ from 'lodash';
import {
  LOAD_SINGLE_COMPANY,
  LOAD_SINGLE_COMPANY_ERROR,
  LOAD_SINGLE_COMPANY_SUCCESS,
  SAVE_SINGLE_COMPANY,
  SAVE_SINGLE_COMPANY_SUCCESS,
  SAVE_SINGLE_COMPANY_ERROR,
  GET_COMPANY_LITE_SCORE,
  GET_COMPANY_LITE_SCORE_SUCCESS,
  GET_COMPANY_LITE_SCORE_ERROR
} from './constants';

const companyModel = {
  businessId: '',
  name: '',
  registrationDate: '',
  companyForm: '',
  liquidations: [],
  names: [],
  auxiliaryNames: [],
  addresses: [],
  businessLines: [],
  registedOffices: [],
  contactDetails: [],
  registeredEntries: [],
  businessIdChanges: [],
  responsiblePersons: [],
  contactPerson: {},
  ownership: [],
};

const liteModel = {
  companyId: null,
  score: null,
  maxScore: null,
  type: null,
  companyRegister: false,
  liteScore: null,
  scores: null,
  companyName: null,
  companyRegisterDescription: null
};

export const initialState = {
  loading: false,
  error: false,
  company: companyModel,
  ytj: null,
  finder: null,
  anorBot: null,
  loadingLite: false,
  liteError: null,
  lite: liteModel
};

/* eslint-disable default-case, no-param-reassign */
const companyhReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOAD_SINGLE_COMPANY:
        draft.loading = true;
        draft.loadingLite = true;
        draft.error = false;
        break;

      case LOAD_SINGLE_COMPANY_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.company = _.assign({}, companyModel, action.company.company);
        draft.ytj = _.assign({}, action.company.ytj);
        draft.finder = _.assign({}, action.company.finder);
        draft.anorBot = _.assign({}, action.company.anorBotDataModel);

        break;

      case LOAD_SINGLE_COMPANY_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;

      case SAVE_SINGLE_COMPANY:
        draft.loading = true;
        draft.error = false;
        break;

      case SAVE_SINGLE_COMPANY_SUCCESS: {
        draft.loading = false;
        draft.error = false;
        draft.company = _.assign({}, companyModel, action.company);
        break;
      }

      case SAVE_SINGLE_COMPANY_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;

      case GET_COMPANY_LITE_SCORE:
        draft.loadingLite = true;
        break;
      case GET_COMPANY_LITE_SCORE_SUCCESS:
        draft.lite = action.score;
        draft.loadingLite = false;
        break;
      case GET_COMPANY_LITE_SCORE_ERROR:
        draft.liteError = action.error;
        draft.loadingLite = false;
        break;
    }
  });

export default companyhReducer;
