import * as _ from 'lodash';

const InstrumentFlag = {
  None: 0,
  First: 1,
  Second: 2,
  Third: 4,
  Fourth: 8,
  Fifth: 16,
};

const getFlagsIntegerValue = (value) => {
  if (value == null) return [];

  let flagarr = [];

  if (value & InstrumentFlag.First) {
    flagarr.push(1);
  }
  if (value & InstrumentFlag.Second) {
    flagarr.push(2);
  }
  if (value & InstrumentFlag.Third) {
    flagarr.push(3);
  }
  if (value & InstrumentFlag.Fourth) {
    flagarr.push(4);
  }
  if (value & InstrumentFlag.Fifth) {
    flagarr.push(5);
  }

  return flagarr;
};

const getFlagBitwiseValuesFromInteger = (value) => {
  if (value == null) return [];

  let flagarr = [];

  if (value & InstrumentFlag.First) {
    flagarr.push(InstrumentFlag.First);
  }
  if (value & InstrumentFlag.Second) {
    flagarr.push(InstrumentFlag.Second);
  }
  if (value & InstrumentFlag.Third) {
    flagarr.push(InstrumentFlag.Third);
  }
  if (value & InstrumentFlag.Fourth) {
    flagarr.push(InstrumentFlag.Fourth);
  }
  if (value & InstrumentFlag.Fifth) {
    flagarr.push(InstrumentFlag.Fifth);
  }

  return flagarr;
};

const getHighestIntegerValue = (value) => {
  if (value == null) {
    return 0;
  } 

  const flags = getFlagsIntegerValue(value);

  return _.maxBy(flags, (item) => item);
};

const getLowestIntegerValue = (value) => {
  const flags = getFlagsIntegerValue(value);

  return _.minBy(flags, (item) => item);
};

const hasFlag = (originalValue, compareValue) => {
    if (originalValue & compareValue) return true;

    return false;
};

export {
  InstrumentFlag,
  getFlagsIntegerValue,
  getFlagBitwiseValuesFromInteger,
  getHighestIntegerValue,
  getLowestIntegerValue,
  hasFlag,
};
