import React from 'react';
import moment from 'moment';

import GridItem from 'components/Grid/GridItem';

import InfoBox from 'components/Infobox';

const sourceRobot = 'Asiakastieto';
const sourceFinder = 'Finder';
const sourceAnor = 'BotV2';

const Robotrobot = ({ robot, finder, anorBot }) => {

	const getEconimicData = (item, robotField) => {
		const fieldValue = item != null && finder && finder?.economicalInfo && finder?.economicalInfo.length > 0 ?
			finder?.economicalInfo[0][item] :
			null;

		if (fieldValue) return { source: getSource(sourceFinder, finder?.created), data: fieldValue };

		if (!robot) return null;

		return { source: getSource(sourceRobot, robot?.created), data: robot[robotField] };
	};

	const getSource = (source, time) => {
		if (!source) return null;

		const date = time ? moment(time).format('D.M.YYYY') : '';

		return date ? `${source}: ${date}` : source;
	};

	const taxDepth = anorBot?.taxLiabilityInformation ? { source: getSource(sourceAnor, anorBot?.created), data: anorBot.taxLiabilityInformation.hasTaxLiability ? 'Kyllä' : 'Ei' } : getEconimicData(null, 'verovelka');
	const economy = anorBot?.asiakastietoInformation ? { source: getSource(sourceAnor, anorBot?.created), data: anorBot?.asiakastietoInformation.economy } : getEconimicData(null, 'talous');
	const background = anorBot?.asiakastietoInformation ? { source: getSource(sourceAnor, anorBot?.created), data: anorBot?.asiakastietoInformation.background } : getEconimicData(null, 'tausta');
	const payment = anorBot?.asiakastietoInformation ? { source: getSource(sourceAnor, anorBot?.created), data: anorBot?.asiakastietoInformation.paymentMethod } : getEconimicData(null, 'maksutapa');
	const rating = anorBot?.asiakastietoInformation ? { source: getSource(sourceAnor, anorBot?.created), data: anorBot?.asiakastietoInformation.ratingCode } : getEconimicData(null, 'Rating Alfa');
	const quickRatio = getEconimicData('quickRatio', 'Quick ratio');
	const currentRatio = getEconimicData('currentRatio', 'Current ratio');
	const solvency = getEconimicData('solvency', 'omavaraisuusaste');
	const lifecycle = finder?.businessLifeCycle ? { source: sourceFinder, data: finder?.businessLifeCycle } : null;

	const renderrobot = () => {
		return (
			<React.Fragment>
				{rating ? <GridItem xs={12} xl={6}><InfoBox title="Rating Alfa:" source={rating.source} content={rating.data} /></GridItem> : null}
				{lifecycle ? <GridItem xs={12} xl={6}><InfoBox source={lifecycle.source} title="Finder elinkaari:" content={lifecycle.data} /></GridItem> : null}
				{quickRatio ? <GridItem xs={12} xl={6}><InfoBox title="Quick Ratio:" source={quickRatio.source} content={quickRatio.data} /></GridItem> : null}
				{currentRatio ? <GridItem xs={12} xl={6}><InfoBox title="Current ratio:" source={currentRatio.source} content={currentRatio.data} /></GridItem> : null}
				{solvency ? <GridItem xs={12} xl={6}><InfoBox title="Omavaraisuusaste:" source={solvency.source} content={solvency.data} /></GridItem> : null}
				{payment ? <GridItem xs={12} xl={6}><InfoBox title="Maksutapa:" source={payment.source} content={payment.data} /></GridItem> : null}
				{background ? <GridItem xs={12} xl={6}><InfoBox title="Tausta:" source={background.source} content={background.data} /></GridItem> : null}
				{economy ? <GridItem xs={12} xl={6}><InfoBox title="Talous:" source={economy.source} content={economy.data} /></GridItem> : null}
				{taxDepth ? <GridItem xs={12} xl={6}><InfoBox title="Verovelka:" source={taxDepth.source} content={taxDepth.data} /></GridItem> : null}
			</React.Fragment>
		);
	};

	return <React.Fragment>{renderrobot()}</React.Fragment>;
};

export default Robotrobot;
