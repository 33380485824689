import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import styles from 'assets/jss/grants/components/checkboxRadioSwitch.js';

const useStyles = makeStyles(styles);

export const GrantsSwitch = (props) => {
  const classes = useStyles();

  const { input, label } = props;

  return (
    <FormControlLabel
      control={
        <Switch
          checked={input.value ? true : false}
          onChange={input.onChange}
          value={input.value}
          classes={{
            switchBase: classes.switchBase,
            checked: classes.switchChecked,
            thumb: classes.switchIcon,
            track: classes.switchBar,
          }}
        />
      }
      classes={{
        label: classes.label,
      }}
      label={label}
    />
  );
};
