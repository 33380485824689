import React from 'react';
import * as _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    customTooltip: {
        padding: '1rem',
        border: '1px solid #ccc',
        background: 'rgba(255, 255, 255, 0.8)',
    },
}));

const CustomTooltip = ({ payload, label, translatedValue }) => {
    const classes = useStyles();

    const iteratePayload = payload => {
        return _.map(payload, item => {
            const val = translatedValue(`instrument.radar.choice.${item.payload.compareProperty}.${item.value}`);

            return <p key={item.name + item.dataKey} className='label'>{`${item.name} : ${val}`}</p>;
        });
    };

    return (
        <div className={classes.customTooltip}>
            <h5>{translatedValue(`instrument.radar.choice.${label}`)}</h5>
            {iteratePayload(payload)}
        </div>
    );
};

export default CustomTooltip;
