import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  accordion,
  accordionSummary,
  accordionTitle,
} from 'assets/jss/grants/components/accordion';

const Accordion = withStyles(accordion)(MuiAccordion);
const AccordionSummary = withStyles(accordionSummary)(MuiAccordionSummary);
const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(accordionTitle);

export default function CustomizedAccordions(props) {
  const { title, expanded, handleChange, prefix, rightTitle } = props;
  const classes = useStyles();

  return (
    <div>
      <Accordion square expanded={expanded} onChange={handleChange} color="secondary">
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
          color="secondary"
        >
          <Typography className={classes.titlePrefix}>{prefix}</Typography>
          <Typography className={classes.titleName}>{title}</Typography>
          <Typography align="right" className={classes.rightContent}>
            {rightTitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
