import { color } from 'assets/jss/grants/common/grantsBase.js';

const contentStyles = {
    content: {
        padding: '2rem 0',
        paddingTop: '5rem',
    },
    label: {
        fontSize: '1rem',
        fontFamily: 'Poppins, sans-serif',
        letterSpacing: 1,
        paddingLeft: '0.5rem',
        color: color.primaryColor,
    },
};

export default contentStyles;
