import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    logo: {
        marginRight: '1rem',
        '& .a': {
            fill: theme.palette.primary.main,
        },
        '& .b': {
            fill: '#000000',
        },
    },
}));

const Logo = ({ width = '100' }) => {
    const classes = useStyles();

    return (
        <>
            <svg className={classes.logo} xmlns='http://www.w3.org/2000/svg' width={width} viewBox={'0 0 214.289 23'}>
                <g transform='translate(-373 -255)'>
                    <g transform='translate(373 255)'>
                        <g transform='translate(0 0)'>
                            <path
                                className='a'
                                d='M128.721,189.428a8.771,8.771,0,0,0,1.015-4.33,7.117,7.117,0,0,0-2.427-5.758,10.306,10.306,0,0,0-6.837-2.046h-9.994v11.643H108.54l-1.745,4.441h3.684v6.155h5.806v-6.155H120.6l3.236,6.155H130.4l-4.631-7.265A7.019,7.019,0,0,0,128.721,189.428Zm-5.473-1.571a3.91,3.91,0,0,1-2.744.889h-4.219v-6.821H120.5a3.959,3.959,0,0,1,2.744.872,3.182,3.182,0,0,1,.968,2.491A3.312,3.312,0,0,1,123.248,187.857Z'
                                transform='translate(-82.736 -176.944)'
                            />{' '}
                            <path
                                className='a'
                                d='M248.409,190.478l-10.12-13.388h-5.107v22.239h5.425V186.037l10.088,13.293h5.076V177.09h-5.393Z'
                                transform='translate(-156.416 -176.826)'
                            />
                            <path
                                className='a'
                                d='M308.806,177.09H289.739v4.79h6.6v17.449h5.806V181.881h6.662Z'
                                transform='translate(-189.388 -176.826)'
                            />
                            <path
                                className='a'
                                d='M178.212,177.293l-5,11.643h-2.871l-1.745,4.441h2.709l-2.643,6.155h5.964l2.419-6.155h7.9l2.371,6.155H193.5l-9.327-22.239Zm.58,11.643,2.244-5.71,2.2,5.71Z'
                                transform='translate(-118.765 -176.944)'
                            />
                            <path
                                className='a'
                                d='M58.144,182.23a6.106,6.106,0,0,1,3.2-.857,7.882,7.882,0,0,1,3.093.666,9.256,9.256,0,0,1,2.776,1.808l3.3-4.061a12.856,12.856,0,0,0-4.188-2.427,14.335,14.335,0,0,0-4.949-.9,13.151,13.151,0,0,0-6.266,1.491,11.239,11.239,0,0,0-4.41,4.109,10.988,10.988,0,0,0-1.6,5.853,11.385,11.385,0,0,0,1.555,5.9,11.138,11.138,0,0,0,4.267,4.14A12.292,12.292,0,0,0,61,199.456a16.436,16.436,0,0,0,4.9-.809,14.824,14.824,0,0,0,4.362-2.11v-8.09H63.7l-1.745,4.441H65.25v.54a8.776,8.776,0,0,1-3.839,1.078,6.273,6.273,0,0,1-3.236-.857,6.057,6.057,0,0,1-2.284-2.363,6.913,6.913,0,0,1-.825-3.379,6.76,6.76,0,0,1,.825-3.331A6.163,6.163,0,0,1,58.144,182.23Z'
                                transform='translate(-49.102 -176.456)'
                            />
                            <path
                                className='a'
                                d='M338.45,186.074h-1.245l-1.745,4.441h2.99v8.814h19.067V194.7H344.256v-4.183h8.022l1.746-4.441h-9.767v-4.193h13.261v-4.79H338.45Z'
                                transform='translate(-216.042 -176.826)'
                            />
                            <path
                                className='a'
                                d='M406.208,177.09H395.536v22.239h10.672c5.644,0,10.8-4.575,10.8-10.219v-1.8C417.007,181.665,411.852,177.09,406.208,177.09Zm5.56,11.658a5.95,5.95,0,0,1-5.95,5.95h-4.476V181.722h4.476a5.95,5.95,0,0,1,5.95,5.95Z'
                                transform='translate(-251.065 -176.826)'
                            />
                        </g>
                        <g transform='translate(176.96 0.264)'>
                            <path className='b' d='M489.139,202.8h3.255l-1.628-4.14Z' transform='translate(-475.594 -189.666)' />
                            <path
                                className='b'
                                d='M483.189,177.09H467.457c-5.644,0-10.8,4.575-10.8,10.219v1.8c0,5.644,5.155,10.219,10.8,10.219h15.731c5.644,0,10.8-4.575,10.8-10.219v-1.8C493.988,181.665,488.832,177.09,483.189,177.09Zm-7.907,17.726-.784-1.962h-5.333l-.785,1.962h-3.765l5.334-13.476h3.765l5.334,13.476Zm8.43,0h-3.354V181.339h3.354Z'
                                transform='translate(-456.658 -177.09)'
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </>
    );
};

export default Logo;
