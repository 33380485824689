import { put, takeLatest, select } from 'redux-saga/effects';
import {
  LOAD_COMPANY_EVENTS,
  SAVE_COMPANY_EVENTS,
} from './constants';
import { loadEventsSuccess, loadEventsError } from './actions';
import { getSingleCompany} from 'containers/CompanyBaseData/selectors';
import config from 'utils/config';
import axios from 'utils/axios';

export function* getEvents({ businessId }) {
  const requestURL = `${config.FETCH_COMPANY_API_URI}/events/${businessId}`;

  try {
    const response = yield axios.get(requestURL);

    yield put(loadEventsSuccess(response.data));
  } catch (err) {
    yield put(loadEventsError(err));
  }
}

export function* saveEvents({ events }) {
  const company = yield select(getSingleCompany());

  const requestURL = `${config.FETCH_COMPANY_API_URI}/events/${company.businessId}`;

  try {
    const response = yield axios.post(requestURL, events);

    yield put(loadEventsSuccess(response.data));
  } catch (err) {
    yield put(loadEventsError(err));
  }
}

export default function* companyData() {
  yield takeLatest(LOAD_COMPANY_EVENTS, getEvents);
  yield takeLatest(SAVE_COMPANY_EVENTS, saveEvents);

}
