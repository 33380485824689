import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectFinance = (state) => state.finance || initialState;

const getFinance = () =>
  createSelector(
    selectFinance,
    (financeState) => financeState.finance
  );

const getLoading = () =>
  createSelector(
    selectFinance,
    (financeState) => financeState.loading
  );

const getError = () =>
  createSelector(
    selectFinance,
    (financeState) => financeState.error
  );

export { selectFinance, getFinance, getLoading, getError };
