import React, { memo, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { createStructuredSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import ContentLoader from 'components/LoadingIndicator/ContentLoader';

import Form from 'containers/CompanyBaseData';
import Finance from 'containers/Finance/';
import BusinessPlan from 'containers/BusinessPlan';
import Events from 'containers/Events';
import WorkPackage from 'containers/WorkPackage';
import InstrumentRadar from 'containers/InstrumentRadar';

import { loadSingleCompanyData } from 'containers/CompanyBaseData/actions';
import reducer from 'containers/CompanyBaseData/reducer';
import saga from 'containers/CompanyBaseData/saga';
import { getSingleLoading, getSingleCompany } from 'containers/CompanyBaseData/selectors';

import AccountManagement from 'containers/GrantedAI/AccountManagement';
import DrawerMenu from './DrawerMenu';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { GridContainer } from 'components/Grid';
import { GridItem } from 'components/Grid';
import Robot from 'containers/Robot';

import Score from 'containers/Score';

const key = 'company';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        height: theme.spacing(6),
        marginLeft: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: '64px',
    },
    drawerHeader: {
        display: 'flex',
        //alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        height: '3rem',
    },
    iconButton: {
        height: '3rem',
        marginTop: '0.5rem',
    },
    companyHeader: {
        fontSize: '2rem',
        marginLeft: '0.4375rem',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

export function Company(props) {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga });

    const { match, loading, onLoadCompanyData, selected } = props;
    const { formatMessage } = useIntl();

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const company = useSelector(state => state.company?.company);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        onLoadCompanyData(match.params.businessId, {
            success: {
                title: formatMessage({
                    id: 'messages.generic.success.title',
                }),
                body: formatMessage({
                    id: 'messages.company.save.success',
                }),
            },
            error: {
                title: formatMessage({
                    id: 'messages.generic.error.title',
                }),
                body: formatMessage({
                    id: 'messages.company.fetch.error',
                }),
            },
        });
    }, [match.params.businessId]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading || props.selected === undefined || props.selected === null) {
        return (
            <React.Fragment>
                {loading ? <React.Fragment>{loading ? <ContentLoader /> : null}</React.Fragment> : <div>No data</div>}
            </React.Fragment>
        );
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                className={`${classes.menuButton} ${open && classes.hide}`}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                className={classes.drawer}
                variant='persistent'
                anchor='left'
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose} className={classes.iconButton}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <DrawerMenu {...props} />
                <Divider></Divider>
            </Drawer>
            <main className={`${classes.content} ${open && classes.contentShift}`}>
                <GridContainer>
                    <GridItem xs={12} lg={6} className={classes.companyHeader}>{`${company?.name}: ${company?.businessId}`}</GridItem>
                    <GridItem xs={12} sm={6} lg={2}>
                        <Robot businessId={match.params.businessId} />
                    </GridItem>
                    <GridItem>
                        <Score />
                    </GridItem>
                </GridContainer>
                <Route exact={true} path='/company/:businessId'>
                    <Form selected={selected} initialValues={selected} />
                </Route>
                <Route path='/company/:businessId/finance'>
                    <Finance {...props} />
                </Route>
                <Route path='/company/:businessId/story'>
                    <BusinessPlan {...props} />
                    <WorkPackage {...props} />
                </Route>
                <Route path='/company/:businessId/events'>
                    <Events {...props} />
                </Route>
                <Route path='/company/:businessId/radar'>
                    <InstrumentRadar {...props} />
                </Route>
                <Route path='/company/:businessId/grantedai'>
                    <AccountManagement {...props} />
                </Route>
            </main>
        </div>
    );
}

Company.propTypes = {
    loading: PropTypes.bool,
    match: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onLoadCompanyData: PropTypes.func,
    error: PropTypes.any,
    pristine: PropTypes.bool,
    reset: PropTypes.func,
    submitting: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    selected: getSingleCompany(),
    loading: getSingleLoading(),
});

export function mapDispatchToProps(dispatch) {
    return {
        onLoadCompanyData: (item, messages) => dispatch(loadSingleCompanyData(item, messages)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Company);
