import { defineMessages } from 'react-intl';

const workpackageScope = 'workpackage';
export const workpackage = defineMessages({
  title: {
    id: `${workpackageScope}.title`,
  },
  addPackage: {
    id: `${workpackageScope}.addPackage`,
    defaultMessage: 'Missing',
  },
  addTask: {
    id: `${workpackageScope}.addTask`,
    defaultMessage: 'Missing',
  },
  addResource: {
    id: `${workpackageScope}.addResource`,
    defaultMessage: 'Missing',
  },
  name: {
    id: `${workpackageScope}.name`,
    defaultMessage: 'Missing',
  },
  description: {
    id: `${workpackageScope}.description`,
    defaultMessage: 'Missing',
  },
  actions: {
    id: `${workpackageScope}.actions`,
    defaultMessage: 'Missing',
  },
});

const workpackageTasksScope = 'workpackage.task';
export const workpackageTask = defineMessages({
  title: {
    id: `${workpackageTasksScope}.title`,
    defaultMessage: 'Missing',
  },
  name: {
    id: `${workpackageTasksScope}.name`,
    defaultMessage: 'Missing',
  },
  description: {
    id: `${workpackageTasksScope}.description`,
    defaultMessage: 'Missing',
  },
  startingDate: {
    id: `${workpackageTasksScope}.startingDate`,
    defaultMessage: 'Missing',
  },
  endDate: {
    id: `${workpackageTasksScope}.endDate`,
    defaultMessage: 'Missing',
  },
});

const workpackageResourceScope = 'workpackage.resource';
export const workpackageResource = defineMessages({
  typeUnit: {
    id: `${workpackageResourceScope}.type.unit`,
    defaultMessage: 'Missing',
  },
  typeUnitMonth: {
    id: `${workpackageResourceScope}.type.unit.month`,
    defaultMessage: 'Missing',
  },
  typeUnitYear: {
    id: `${workpackageResourceScope}.type.unit.year`,
    defaultMessage: 'Missing',
  },
  typeUnitDay: {
    id: `${workpackageResourceScope}.type.unit.day`,
    defaultMessage: 'Missing',
  },
  name: {
    id: `${workpackageResourceScope}.name`,
    defaultMessage: 'Missing',
  },
  amount: {
    id: `${workpackageResourceScope}.amount`,
    defaultMessage: 'Missing',
  },
});

const workpackageExpenseScope = 'workpackage.expense';
export const workpackageExpense = defineMessages({
  addExpense: {
    id: `${workpackageExpenseScope}.addExpense`,
    defaultMessage: 'Missing',
  },
  name: {
    id: `${workpackageExpenseScope}.name`,
    defaultMessage: 'Missing',
  },
  type: {
    id: `${workpackageExpenseScope}.type`,
    defaultMessage: 'Missing',
  },
  typeServicePurchase: {
    id: `${workpackageExpenseScope}.type.servicePurchase`,
    defaultMessage: 'Missing',
  },
  typeSalaryExpense: {
    id: `${workpackageExpenseScope}.type.salaryExpense`,
    defaultMessage: 'Missing',
  },
  typeInvestment: {
    id: `${workpackageExpenseScope}.type.investment`,
    defaultMessage: 'Missing',
  },
  typeTraveling: {
    id: `${workpackageExpenseScope}.type.traveling`,
    defaultMessage: 'Missing',
  },
  amount: {
    id: `${workpackageExpenseScope}.amount`,
    defaultMessage: 'Missing',
  },
});
