import storage from './localstorage';
import { TOKEN_KEY } from './constants';
import jwt_decode from 'jwt-decode';

const minutesConst = 2; // secure that token is not old, when making a request

/**
 * SET local storage token object
 * @param {token object} token
 */
const setToken = token => {
    storage.set(TOKEN_KEY, token);
};

/**
 * GET local storage token object
 */
const getToken = () => {
    return storage.get(TOKEN_KEY);
};

/**
 * GET local storage token object and combines token type to token for authorization
 */
const getAccessToken = () => {
    const token = getToken();

    if (!token) return null;

    return token;
};

/**
 * Get refresh token from local storage
 */
const getRefreshToken = () => {
    const token = getToken();

    if (!token) return null;

    return token.refresh_token;
};

/**
 * Check if given token is expired
 * @param {token object} token
 */
const isExpired = (token, minutes = minutesConst) => {
    if (token === undefined || token === null) return false;

    const now = new Date();
    const expires = new Date(token.expiresOn);

    return expires.getTime() <= now.getTime() + (minutes * 60000);
};

/**
 * Get decoded access token
 */
const getDecodedAccessToken = () => {
    const accessToken = getAccessToken();
    if (accessToken == null) return null;

    return jwt_decode(accessToken);
};

export { setToken, getToken, getAccessToken, getRefreshToken, isExpired, getDecodedAccessToken };
