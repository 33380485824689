import {
    LOAD_INSTRUMENTS,
    LOAD_INSTRUMENTS_SUCCESS,
    LOAD_INSTRUMENTS_ERROR,
    SAVE_INSTRUMENT,
    SAVE_INSTRUMENT_FAIL,
    SAVE_INSTRUMENT_SUCCESS,
    OPEN_INSTRUMENT_MODAL,
    CLOSE_INSTRUMENT_MODAL,
    PUBLISH_SELECTED
} from './constants.js';

export const loadInstruments = () => {
    return {
        type: LOAD_INSTRUMENTS,
    };
};

export const loadInstrumentsError = error => {
    return {
        type: LOAD_INSTRUMENTS_ERROR,
        error,
    };
};

export const loadInstrumentsSuccess = instruments => {
    return {
        type: LOAD_INSTRUMENTS_SUCCESS,
        instruments,
    };
};

export const saveInstrument = (instrument, isDraft) => {
    return {
        type: SAVE_INSTRUMENT,
        instrument,
        isDraft,
    };
};

export const saveInstrumentFail = error => {
    return {
        type: SAVE_INSTRUMENT_FAIL,
        error: error,
    };
};

export const saveInstrumentSuccess = (instrument, isNew) => {
    return {
        type: SAVE_INSTRUMENT_SUCCESS,
        instrument,
        isNew,
    };
};

export const openInstrumentModal = () => {
    return {
        type: OPEN_INSTRUMENT_MODAL,
    };
};

export const closeInstrumentModal = () => {
    return {
        type: CLOSE_INSTRUMENT_MODAL,
    };
};

export const publishSelected = (silent, selected) => {
    return {
        silent: silent,
        selected: selected,
        type: PUBLISH_SELECTED,
    };
};