import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectWorkPackage = (state) => state.workPackage || initialState;

const makeSelectWorkPackage = () =>
  createSelector(selectWorkPackage, (workPackageState) => workPackageState.workPackage);

const makeSelectLoading = () =>
  createSelector(selectWorkPackage, (workPackageState) => workPackageState.loading);

const makeSelectError = () =>
  createSelector(selectWorkPackage, (workPackageState) => workPackageState.error);

export { selectWorkPackage, makeSelectWorkPackage, makeSelectLoading, makeSelectError };
