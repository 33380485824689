import { put, takeLatest, select } from 'redux-saga/effects';
import {
    LOAD_TIMELINE,
    SAVE_TIMELINE,
    SAVE_TIMELINE_INSTRUMENT,
    ADD_INSTRUMENT_TO_TIMELINE,
    EDIT_INSTRUMENT_IN_TIMELINE,
    DELETE_INSTRUMENT_FROM_TIMELINE,
} from './constants.js';
import {
    loadTimelineError,
    loadTimelineSuccess,
    addInstrumentToTimelineSuccess,
    closeInstrumentModal,
    addInstrumentToTimelineError,
    editInstrumentInTimelineError,
    editInstrumentInTimelineSuccess,
    deleteInstrumentFromTimelineSuccess,
    deleteInstrumentFromTimelineError,
} from './actions';
import { getSingleCompany } from 'containers/CompanyBaseData/selectors';
import { replaceCompanyInformation } from './dynamicDataReplace';

import axios from 'utils/axios';
import config from 'utils/config';

import { getStartDateArr, handleInstrument } from './timelineParser';

export function* getTimeline() {
    try {
        const company = yield select(getSingleCompany());

        const response = yield axios.get(`${config.INSTRUMENT_URI}/timeline/${company.businessId}`);

        if (response.data && !response.data.instruments) response.data.instruments = [];

        const timeline = response.data ? response.data : { instruments: [] };

        getStartDateArr(timeline); // mutates the response object

        yield put(loadTimelineSuccess(timeline));
    } catch (err) {
        yield put(loadTimelineError(err));
    }
}

export function* saveTimelineAndInstruments({ timeline }) {
    try {
        const company = yield select(getSingleCompany());

        timeline.base = replaceCompanyInformation(timeline.base, company);
        timeline.expertComments = replaceCompanyInformation(timeline.expertComments, company);
        timeline.precondition = replaceCompanyInformation(timeline.precondition, company);
        timeline.notice = replaceCompanyInformation(timeline.notice, company);
        timeline.followup = replaceCompanyInformation(timeline.followup, company);

        const uri = timeline.isDraft
            ? `${config.INSTRUMENT_URI}/timeline/${company.businessId}`
            : `${config.INSTRUMENT_URI}/timeline/${company.businessId}/publish`;
        const response = yield axios.post(uri, timeline);

        if (response.data && !response.data.instruments) response.data.instruments = [];

        getStartDateArr(response.data); // mutates the response object

        yield put(loadTimelineSuccess(response.data));
    } catch (err) {
        yield put(loadTimelineError(err));
    }
}

export function* addInstrumentToTimeline({ instrument }) {
    const timeline = yield select(state => state.timeline?.timeline);
    try {
        const company = yield select(getSingleCompany());

        let url = `${config.INSTRUMENT_URI}/timeline/${company.businessId}/instrument`;

        const updatedInstrument = handleInstrument(instrument, timeline);

        const response = yield axios.post(url, updatedInstrument);
        const updatedResponseData = handleInstrument(response.data, timeline);

        yield put(addInstrumentToTimelineSuccess(updatedResponseData));
        yield put(closeInstrumentModal());
        //yield put(scrollToTimeline());
        //yield put(resetIsScrolling());
        //toast.success(<FormattedMessage id='instrument.adding.successed' />);
    } catch (error) {
        yield put(addInstrumentToTimelineError(error));
        yield put(closeInstrumentModal());
        //yield put(scrollToTimeline());
        // yield put(resetIsScrolling());
        //s toast.error(<FormattedMessage id='instrument.adding.failed' />);
    }
}

export function* editInstrumentInTimeline({ instrument }) {
    const timeline = yield select(state => state.timeline?.timeline);

    try {
        const company = yield select(getSingleCompany());

        let url = `${config.INSTRUMENT_URI}/timeline/${company.businessId}/instrument`;

        const updatedInstrument = handleInstrument(instrument, timeline);

        yield axios.post(url, updatedInstrument);
        yield put(editInstrumentInTimelineSuccess(updatedInstrument));
        yield put(closeInstrumentModal());
    } catch (error) {
        yield put(editInstrumentInTimelineError(error));
        yield put(closeInstrumentModal());
    }
}

export function* removeTimelineInstrument({ instrument }) {
    try {
        const company = yield select(getSingleCompany());

        if (instrument.rowKey) {
            const response = yield axios.delete(`${config.INSTRUMENT_URI}/timeline/${company.businessId}/instrument/${instrument.rowKey}`);

            if (response.data === false) {
                //  yield put(saveTimelineInstrumentError('Delete failed', index));
                yield put(deleteInstrumentFromTimelineError('Delete failed'));
                return;
            } else if (response.status === 200) {
                yield put(deleteInstrumentFromTimelineSuccess(instrument.rowKey));
            }
        }
    } catch (err) {
        // yield put(saveTimelineInstrumentError(err, index));
        yield put(deleteInstrumentFromTimelineError(err));
    }
}

export default function* timelineWatcher() {
    yield takeLatest(LOAD_TIMELINE, getTimeline);
    yield takeLatest(SAVE_TIMELINE, saveTimelineAndInstruments);
    yield takeLatest(SAVE_TIMELINE_INSTRUMENT, addInstrumentToTimeline);
    yield takeLatest(ADD_INSTRUMENT_TO_TIMELINE, addInstrumentToTimeline);
    yield takeLatest(EDIT_INSTRUMENT_IN_TIMELINE, editInstrumentInTimeline);
    yield takeLatest(DELETE_INSTRUMENT_FROM_TIMELINE, removeTimelineInstrument);
}
