import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { color } from 'assets/jss/grants/common/grantsBase.js';

const useStyles = makeStyles({
	box: {
		backgroundColor: color.primaryColor,
		margin: 0,
		display: 'flex',
		alignItems: 'center',
		minHeight: '3rem',
		justifyContent: 'flex-start'
	},
	title: {
		color: color.secondaryColor,
		fontSize: '1rem',
		paddingLeft: '1rem',
		fontVariantCaps: 'all-petite-caps',
	},
	content: {
		color: '#fff',
		paddingLeft: '1rem',
	},
	source: {
		color: color.secondaryColor,
		paddingRight: '1rem',
		position: 'relative',
		marginLeft: 'auto'
	},
	icon: {
		color: '#fff',
		paddingRight: '1rem',
		position: 'relative',
		marginLeft: 'auto',
		top: '2px'
	}
});

export default function InfoBox(props) {
	const classes = useStyles();
	const { title, content, icon, source, isIcon } = props;

	return (
		<div className={classes.box}>
			<label className={classes.title}>{title}</label>
			<span className={classes.content}>{content}</span>
			<span className={`${classes.source} ${isIcon ? classes.icon : '' }`}>{source}</span>
			{icon ? <span className={classes.icon}>{icon}</span> : null}
		</div>
	);
}
