import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRadar = (state) => state.radar || initialState;

const getRadar = () =>
  createSelector(selectRadar, (radarState) => radarState.radar);

const getRadarLoading = () =>
  createSelector(selectRadar, (radarState) => radarState.loading);

const getRadarError = () =>
  createSelector(selectRadar, (radarState) => radarState.error);

const getSelectedLimit = () =>
  createSelector(selectRadar, (radarState) => radarState.company);

const selectInstruments = () =>
  createSelector(selectRadar, (radarState) => radarState.instruments);

const selectSenarios = () =>
  createSelector(selectRadar, (radarState) => radarState.scenarios);

export {
  selectRadar,
  getRadar,
  getRadarLoading,
  getRadarError,
  getSelectedLimit,
  selectSenarios,
  selectInstruments,
};
