import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';

import ListItem from '@material-ui/core/ListItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { GridContainer, GridItem } from 'components/Grid';
import InstrumentRadar from './InstrumentRadar';
import InstrumentDrawer from './InstrumentDrawer';

const useStyles = makeStyles(theme => ({
    name: {
        fontSize: '1.2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        fontWeight: 600,
        color: theme.palette.textPrimary.white,
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
        },
    },
    namePrimary: {
        marginTop: theme.spacing(2),
        fontSize: '1.2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        fontWeight: 600,
        color: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
        },
    },
    provider: {
        fontSize: '0.9rem',
        color: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            display: 'inline-block',
            paddingLeft: theme.spacing(3),
        },
    },
    icon: {
        color: theme.palette.secondary.main,
    },
    accordion: {
        boxShadow: 'none',
        width: '100%',
        backgroundColor: theme.palette.success.main,
        border: '1px solid',
        borderWidth: '2px',
        borderColor: theme.palette.success.main,
        borderRadius: '4px',
    },
    accordionDetails: {
        backgroundColor: '#F6F4F6',
    },
    description: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    bgYellow: {
        backgroundColor: theme.palette.primary.darkYellow,
        borderColor: theme.palette.primary.darkYellow,
    },
    bgRed: {
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    fontViolet: {
        color: theme.palette.primary.main,
    },
    item: {
        paddingTop: theme.spacing(2),
    },
    button: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.primary.white,
        marginTop: theme.spacing(2),
    },
    ingress: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        //textAlign: 'justify',
    },
    chipGreen: {
        [theme.breakpoints.down('xs')]: {
            '& span': {
                display: 'none',
                margin: 0,
            },
            '& svg': {
                margin: '0 4px',
                padding: '0',
            },
        },
        //  backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.white,
        borderColor: theme.palette.success.main,
    },
    chipYellow: {
        [theme.breakpoints.down('xs')]: {
            '& span': {
                display: 'none',
                margin: 0,
            },
            '& svg': {
                margin: '0 4px',
                padding: '0',
            },
        },
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.darkYellow,
        borderColor: theme.palette.primary.darkYellow,
    },
    accordionHeader: {
        color: 'red',
        maxWidth: '75%',
    },
    arrowChip: {
        '& span': {
            display: 'none',
            margin: 0,
        },
        '& svg': {
            margin: '0 4px',
            padding: '0',
        },
        opacity: '0.8',
    },
    headerLink: {
        width: 'auto',
        padding: 0,
        margin: 0,
    },
    link: {
        fontSize: '1.1rem',
        color: theme.palette.success.main,
        '&:hover': {
            color: theme.palette.primary.darkYellow,
        },
    },
}));

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.main,
        boxShadow: theme.shadows[1],
        fontSize: '0.9rem',
    },
}))(Tooltip);

const InstrumentItem = ({ instrument, company, addToTimeline }) => {
    const { id, name, provider, ingress, url } = instrument;
    const [expanded, setExpanded] = useState(false);
    const [openInstrumentDrawer, setOpenInstrumentDrawer] = useState(false);
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    const ref = useRef();
    const [radarHeight, setRadarHeight] = useState(null);
    const containerWidthDependency = ref.current?.clientWidth;

    // const isNew = newInstruments.includes(id);
    // const isImproved = improvedInstruments.includes(id);

    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname === `/instrument/${id}`) {
            setExpanded(id);
            openInstDrawer();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useLayoutEffect(() => {
        const updateWidth = () => {
            const containerWidth = ref.current?.clientWidth;
            sm ? setRadarHeight(containerWidth - 32) : setRadarHeight(0.5 * containerWidth - 16);
        };

        window.addEventListener('resize', updateWidth);
        updateWidth();

        return () => window.removeEventListener('resize', updateWidth);
    }, [containerWidthDependency, sm]);

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const openInstDrawer = () => setOpenInstrumentDrawer(true);
    const closeInstDrawer = () => {
        setOpenInstrumentDrawer(false);
        if (pathname !== '/') {
            setExpanded(false);
            window.history.replaceState(null, null, '/');
        }
    };

    const instrumentRest = {
        id: instrument?.id,
        name: instrument?.name,
        provider: instrument?.provider,
        ingress: instrument?.ingress,
        type: instrument?.type,
    };

    return (
        <ListItem>
            <Accordion
                square
                expanded={expanded === id}
                onChange={handleChange(id)}
                className={`${classes.accordion} ${instrument.level === 1 && classes.bgYellow} ${instrument.level === 0 && classes.bgRed}`}
                TransitionProps={{ unmountOnExit: true }}
                ref={ref}
            >
                <AccordionSummary
                    aria-controls={`${id}-content`}
                    expandIcon={
                        expanded ? (
                            <RemoveIcon fontSize='large' className={classes.icon} />
                        ) : (
                            <AddIcon fontSize='large' className={classes.icon} />
                        )
                    }
                >
                    <GridContainer justify='space-between' alignItems='center'>
                        <GridItem className={classes.accordionHeader}>
                            <Typography variant='h2' className={classes.name}>
                                {name}
                            </Typography>
                            <Typography component='p' className={`${classes.provider} ${instrument.level === 1 && classes.fontViolet}`}>
                                {provider}
                            </Typography>
                        </GridItem>
                        <GridItem noPadding className={classes.headerLink}>
                            <LightTooltip title={intl.formatMessage({ id: 'chip.tooltip.new' })}>
                                <Button
                                    round='true'
                                    onClick={event => {
                                        event.stopPropagation();
                                        addToTimeline(instrumentRest);
                                    }}
                                    label={intl.formatMessage({ id: 'timeline.add.tooltip' })}
                                    disableElevation
                                    className={classes.chipGreen}
                                    startIcon={<AddCircleOutlineIcon />}
                                >
                                    {intl.formatMessage({ id: 'timeline.add.button' })}
                                </Button>
                            </LightTooltip>
                        </GridItem>
                    </GridContainer>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <GridContainer>
                        <GridItem xs={12} md={6}>
                            <GridContainer align='center' direction='column'>
                                <GridItem style={{ width: '100%' }}>
                                    <InstrumentRadar
                                        data={instrument?.limitators}
                                        name={instrument?.name}
                                        companyName={company?.name}
                                        height={radarHeight}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} md={6} className={classes.description}>
                            <GridItem>
                                <Typography variant='h2' className={classes.namePrimary}>
                                    {name}
                                </Typography>
                                <Typography variant='h3' className={`${classes.provider} ${classes.fontViolet}`}>
                                    {provider}
                                </Typography>
                            </GridItem>
                            <GridItem className={classes.link}>
                                {url ? <a href={`${url}`} rel='noopener noreferrer' target='_blank'>{`${url}`}</a> : null}
                            </GridItem>
                            <GridItem className={classes.ingress}>
                                <Typography component='span'>{ReactHtmlParser(ingress)}</Typography>
                            </GridItem>
                            <Box textAlign='center'>
                                <Button round='true' variant='contained' onClick={openInstDrawer} className={classes.button}>
                                    {intl.formatMessage({ id: 'button.text.acquaintance' })}
                                </Button>
                                <InstrumentDrawer
                                    open={openInstrumentDrawer}
                                    close={closeInstDrawer}
                                    instrument={instrument}
                                    company={company}
                                    height={radarHeight}
                                />
                            </Box>
                        </GridItem>
                    </GridContainer>
                </AccordionDetails>
            </Accordion>
        </ListItem>
    );
};

export default InstrumentItem;
