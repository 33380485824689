import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles(() => ({
    holder: {
        width: '100%',
        backgroundColor: '#f7f5f0',
        padding: '0.5rem',
        borderRadius: '5px',
    },
    label: {
        fontSize: '1rem',
        margin: '1rem 0.5rem 0.5rem',
    },
    toolbar: {
        position: 'sticky',
        top: -16,
        zIndex: 100,
        boxShadow: '0px 3px 8px -0px rgba(0,0,0,0.2);',
    },
    editor: {
        padding: '0 0.5rem',
    },
    wrapper: {
        padding: '1rem',
        backgroundColor: '#f7f5f0',
    },
}));

const ControlledEditor = ({
    disabled,
    menubar = true,
    toolbar = true,
    helper,
    onChange,
    title,
    holderClassName,
    placeholder,
    defaultValue
}) => {
    const classes = useStyles();
    const [localValue, setValue] = useState(defaultValue ?? '');

    const editorConfig = {
        height: 250,
        menubar,
        //skin: 'snow',
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
            toolbar &&
            'undo redo | formatselect | bold italic backcolor | \n' +
            'alignleft aligncenter alignright alignjustify | \n' +
            'bullist numlist outdent indent | removeformat | table | preview',
        language: 'fi',
    };

    return (
        <div className={`${classes.holder} ${holderClassName ? holderClassName : ''}`}>
            <FormControl fullWidth={true}>
                <FormLabel className={classes.label}>{title}</FormLabel>
                <div className={classes.editor}>
                    <Editor
                        disabled={disabled}
                        init={editorConfig}
                        apiKey='ti4oskfqq9phlq73w61y7ppjr41gf72l7b32jbo97ei1qrnx'
                        placeholder={placeholder}
                        initialValue={defaultValue}
                        value={localValue}
                        onEditorChange={newValue => {
                            setValue(newValue);
                            onChange(newValue);
                        }}
                    />
                    <FormHelperText component='div'>{helper}</FormHelperText>
                </div>
            </FormControl>
        </div>
    );
};

export default ControlledEditor;
