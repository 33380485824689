import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import FinderFinanceData from 'containers/Finance/FinderFinanceData';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
    accordion: {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
        '&.MuiAccordion-root.Mui-expanded': {
            margin: 0,
        },
        border: `1px solid ${theme.palette.primary.main}`,
    },
    accordionDetails: {
        backgroundColor: theme.palette.primary.white,
        padding: '0.5rem',
    },
    accordionTitle: {
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.palette.primary.white,
    },
    expandIcon: {
        color: theme.palette.primary.white,
    },
}));

let FinanceAccordion = () => {
    const classes = useStyles();
    const intl = useIntl();
    const finder = useSelector(state => state?.company?.finder);

    const finderDate = () => {
        return moment(finder?.created).format('D.M  .YYYY');
    };

    if (!finder || !finder?.economicalInfo || finder?.economicalInfo.length === 0) return null;

    return (
        <GridContainer className={classes.root}>
            <GridItem>
                <Accordion square TransitionProps={{ unmountOnExit: true }} className={classes.accordion}>
                    <AccordionSummary aria-controls='company-tables' expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
                        <Typography component='p' className={classes.accordionTitle}>
                            {`${intl.formatMessage({ id: 'company.information.system.finder' })} ${finderDate()}`}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <GridContainer direction='column'>
                            <FinderFinanceData />
                        </GridContainer>
                    </AccordionDetails>
                </Accordion>
            </GridItem>
        </GridContainer>
    );
};

export default FinanceAccordion;
