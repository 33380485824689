import React from 'react';
import { useIntl } from 'react-intl';
import { Field, FieldArray } from 'redux-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/HighlightOff';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

import { GrantsInput } from 'components/GrantsComponents/Input';
import AddButton from 'components/GrantsComponents/AddButton';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormSpaceHeader from 'components/FormSpaceHeader';
import { gridTranslations } from 'translations/gridTranslations';

const useStyles = makeStyles(theme => ({
    dataGrid: {
        marginTop: theme.spacing(2),
        '& .MuiDataGrid-cell': {
            lineHeight: '0 !important',
        },
    },
    buttonWrapper: {
        marginTop: theme.spacing(2),
    },
    button: {
        color: theme.palette.warning.main,
        '&:hover': {
            color: theme.palette.warning.main,
            backgroundColor: theme.palette.primary.white,
            opacity: 0.8,
        },
    },
    icon: {
        color: theme.palette.warning.main,
    },
}));

const CompanyAddresses = ({ messages }) => {
    const intl = useIntl();
    const classes = useStyles();

    const renderArray = ({ fields, meta: { error } }) => {
        const columns = [
            {
                field: 'street',
                headerName: intl.formatMessage({ id: 'company.basicdata.streetaddress' }),
                width: 200,
                renderCell: params => (
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.street}</div>
                ),
            },
            {
                field: 'postCode',
                headerName: intl.formatMessage({ id: 'company.basicdata.postcode' }),
                width: 200,
                renderCell: params => (
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.postCode}</div>
                ),
            },
            {
                field: 'city',
                headerName: intl.formatMessage({ id: 'company.basicdata.city' }),
                width: 200,
                renderCell: params => (
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.city}</div>
                ),
            },
            {
                field: '',
                headerName: '',
                width: 120,
                disableClickEventBubbling: true,
                disableColumnMenu: true,
                renderCell: ({ id }) => (
                    <Button
                        size='small'
                        startIcon={<DeleteIcon className={classes.icon} />}
                        onClick={() => fields.remove(id)}
                        className={classes.button}
                    >
                        {intl.formatMessage({ id: 'shared.remove' })}
                    </Button>
                ),
            },
        ];

        const rows = fields?.map((item, index) => ({
            id: index,
            street: <Field name={`${item}.street`} type='text' component={GrantsInput} />,
            postCode: <Field name={`${item}.postCode`} type='text' component={GrantsInput} />,
            city: <Field name={`${item}.city`} type='text' component={GrantsInput} />,
        }));

        return (
            <>
                {rows && (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        autoHeight
                        hideFooter={true}
                        localeText={gridTranslations(intl)}
                        className={classes.dataGrid}
                    />
                )}
                <Box textAlign='end' className={classes.buttonWrapper}>
                    <AddButton size='small' onClick={() => fields.push()} text={intl.formatMessage(messages.addressData.add)} />
                </Box>
                {error && <li className='error'>{error}</li>}
            </>
        );
    };

    return (
        <GridContainer>
            <GridItem xs={12}>
                <FormSpaceHeader header={intl.formatMessage(messages.addressData.title)} anchor='form_addresses' />
                <FieldArray name='addresses' component={renderArray} />
            </GridItem>
        </GridContainer>
    );
};

export default CompanyAddresses;
