import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from 'date-fns/locale/fi';

import { GridItem, GridContainer } from 'components/Grid';
import { GrantsInput } from 'components/GrantsComponents/Input';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    error: {
        color: theme.palette.error.main,
    },
    editor: {
        marginBottom: '1rem',
    },
}));

export const licenseType = {
    trial: 'trial',
    licensed: 'licensed',
    freemium: 'freemium',
    advanced: 'advanced'
};

const LicenseForm = ({ license, control, errors, watch }) => {
    const intl = useIntl();
    const classes = useStyles();

    const startTimeWatch = watch('startDate', license?.startDate);

    return (
        <GridContainer noPadding>
            <GridItem className={classes.editor} xs={12} md={6}>
                <Controller
                    name='rowKey'
                    control={control}
                    defaultValue={license?.rowKey ?? ''}
                    render={() => <input type='hidden' value={license?.rowKey ?? ''} />}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                    <Controller
                        name='startDate'
                        defaultValue={license?.startDate ?? null}
                        control={control}
                        render={rest => (
                            <KeyboardDatePicker
                                style={{ margin: 0, padding: 0, width: '100%' }}
                                {...rest}
                                clearable="true"
                                // openTo="year"
                                disableToolbar
                                autoOk
                                variant='inline'
                                format='dd.MM.yyyy'
                                invalidDateMessage={intl.formatMessage({ id: 'timeline.instrument.date.invalid' })}
                                placeholder={intl.formatMessage({ id: 'instrument.form.datepicker.placeholder' })}
                                margin='normal'
                                label={intl.formatMessage({ id: 'timeline.instrument.startTime' })}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    />
                </MuiPickersUtilsProvider>
            </GridItem>
            <GridItem className={classes.editor} xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                    <Controller
                        name='endDate'
                        defaultValue={license?.endDate ?? null}
                        control={control}
                        render={rest => (
                            <KeyboardDatePicker
                                style={{ margin: 0, padding: 0, width: '100%' }}
                                {...rest}
                                clearable="true"
                                // openTo="year"
                                disableToolbar
                                autoOk
                                variant='inline'
                                format='dd.MM.yyyy'
                                invalidDateMessage={intl.formatMessage({ id: 'timeline.instrument.date.invalid' })}
                                placeholder={intl.formatMessage({ id: 'instrument.form.datepicker.placeholder' })}
                                margin='normal'
                                label={intl.formatMessage({ id: 'timeline.instrument.endTime' })}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputLabelProps={{ shrink: true }}
                                minDate={startTimeWatch}
                                minDateMessage=''//{intl.formatMessage({ id: 'timeline.instrument.endTime.mindate' })}
                            />
                        )}
                    />
                </MuiPickersUtilsProvider>
                {errors && (
                    <Typography component='p' className={classes.error}>
                        {errors?.endDate?.message}
                    </Typography>
                )}
            </GridItem>

            <GridItem className={classes.editor} xs={6}>
                <FormControl fullWidth>
                    <InputLabel id='sortOrder-label'>{intl.formatMessage({ id: 'account.license.form.type' })}</InputLabel>
                    <Controller
                        name='type'
                        control={control}
                        defaultValue={license?.type ?? licenseType.licensed}
                        as={
                            <Select>
                                <MenuItem value={licenseType.trial} classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                    {intl.formatMessage({ id: 'account.license.form.type.1' })}
                                </MenuItem>
                                <MenuItem value={licenseType.licensed} classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                    {intl.formatMessage({ id: 'account.license.form.type.2' })}
                                </MenuItem>
                                <MenuItem value={licenseType.freemium} classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                    {intl.formatMessage({ id: 'account.license.form.type.3' })}
                                </MenuItem>
                                <MenuItem value={licenseType.advanced} classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}>
                                    {intl.formatMessage({ id: 'account.license.form.type.4' })}
                                </MenuItem>
                            </Select>
                        }
                    />
                </FormControl>
                {errors && (
                    <Typography component='p' className={classes.error}>
                        {errors?.type?.message}
                    </Typography>
                )}
            </GridItem>
            <GridItem className={classes.editor} xs={12}>
                <Controller
                    name='comments'
                    control={control}
                    multiline
                    defaultValue={license?.comments ?? ''}
                    render={props => (
                        <GrantsInput variant='standard' multiline label={intl.formatMessage({ id: 'account.license.form.comments' })} {...props} />
                    )}
                />
            </GridItem>
        </GridContainer>
    );
};

export default LicenseForm;