import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import scenarioValues from 'utils/mock/scenarioValues.json';
import { getFlagBitwiseValuesFromInteger } from 'utils/bitwiseOperations';
import { GridItem, GridContainer } from 'components/Grid';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '0.5rem',
    },
    center: {
        textAlign: 'center',
    },
    error: {
        color: theme.palette.error.main,
        marginLeft: '0.5rem',
    },
}));

const MultiSelect = ({ control, errors }) => {
    const classes = useStyles();
    const intl = useIntl();

    const { fields } = useFieldArray({
        control,
        name: 'instrument.limitators',
    });

    const getScenarioValuesByType = (value, item) => {
        const scenarioItemValues = scenarioValues.find(scenarioValue => {
            return scenarioValue.type === item.compareProperty;
        });

        if (!scenarioItemValues) return null;

        const items = scenarioItemValues.values.map((scenarioItemvalue, index) => {
            return (
                <MenuItem key={item.compareProperty + index} value={scenarioItemvalue.value}>
                    <Checkbox color='primary' checked={value.includes(scenarioItemvalue.value)} />
                    <ListItemText primary={intl.formatMessage({ id: `instrument.radar.choice.${item.compareProperty}.${index + 1}` })} />
                </MenuItem>
            );
        });

        return items;
    };

    return (
        <GridContainer>
            {fields.map((item, index) => {
                const id = `instrument.radar.choice.${item.compareProperty}`;

                return (
                    <GridItem key={item.id} xs={12} sm={6} md={3}>
                        <Controller
                            control={control}
                            name={`limitators.${item.compareProperty}`}
                            defaultValue={getFlagBitwiseValuesFromInteger(item.requiredValue)} // get flags from integer
                            rules={{
                                required: `${intl.formatMessage({ id: id })} ${intl.formatMessage({
                                    id: 'message.isRequired',
                                })}`,
                            }}
                            render={({ onChange, value }) => {
                                return (
                                    <TextField
                                        classes={{ root: classes.root }}
                                        select
                                        fullWidth
                                        id={`instrument.radar.choice.${item.compareProperty}`}
                                        // variant='outlined'
                                        label={intl.formatMessage({ id: `instrument.radar.choice.${item.compareProperty}` })}
                                        SelectProps={{
                                            multiple: true,
                                            value: value,
                                            renderValue: selected => {
                                                selected.sort(function (a, b) {
                                                    return a - b;
                                                });

                                                const arrIndex = [1, 2, 4, 8, 16];
                                                const translated = selected.map(i => intl.formatMessage({ id: `instrument.radar.choice.${item.compareProperty}.${arrIndex.indexOf(i) + 1}` }));
                                                
                                                return translated.join(', ');
                                            },
                                            onChange: onChange,
                                        }}
                                    >
                                        {getScenarioValuesByType(value, item)}
                                    </TextField>
                                );
                            }}
                        />
                        {errors && (
                            <Typography component='p' className={classes.error}>
                                {errors?.limitators && errors?.limitators[index]
                                    ? <span>{intl.formatMessage({ id: `instrument.radar.choice.${item.compareProperty}` })} {intl.formatMessage({ id: 'common.required' })}</span>
                                    : null}
                            </Typography>
                        )}
                    </GridItem>
                );
            })}
        </GridContainer>
    );
};

export default MultiSelect;
